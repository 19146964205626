import { makeStyles, Paper } from '@material-ui/core';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import ReserveHistoryCard from '../../reserved-history-card';

const useStyles = makeStyles(() => ({
  paperRoot: { padding: '30px 30px 10px 30px' },
}));

const ReservedName = () => {
  const classes = useStyles();
  const { id } = useParams();

  const [listData, setListData] = useState();

  const fetchHistory = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/v1/companies/history/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        setListData(res.data?.company);
      })
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    fetchHistory();
  }, [fetchHistory, id]);

  return (
    <Paper className={classes.paperRoot}>
      {listData &&
        listData.map((data, index) => {
          return (
            <ReserveHistoryCard
              fetchHistory={fetchHistory}
              data={data}
              key={index}
            />
          );
        })}
    </Paper>
  );
};

export default ReservedName;
