import APP_EN from './app';

export const TRANSLATIONS_EN = {
  ...APP_EN,
  //staff
  staff: 'Staff',
  ID: 'ID',
  'full-name': 'Full Name',
  'email-address': 'E-mail Address',
  'phone-number': 'Phone Number',
  'add-new-staff': 'Add New Staff',
  'staff-update': 'Staff Update',
  'approve-user': 'Approve User',
  'block-user': 'Block User',
  'approve': 'Approve',
  'cancel': 'Cancel',
  'staff-id': 'Staff\'s ID',
  'staff-name':'Staff\'s Name',
  //approved company
  'approved-company': 'Approved Company',
  'generate-documents': 'Generate Documents',
  'moc-documents': 'MoC Documents',
  'pick-a-representative': 'Pick a representative',
  "approved-by": "Approved By",
  //registered company
  'registered-company': 'Registered Company',
  'registered': 'Registered',
  'not-registered': 'Not Registered',
  //reviewing-company
  'reviewing-company': 'Reviewing Company',
  'waiting-for-review': 'Waiting for review',
  'waiting-for-approval': 'Waiting for approval',
  'review': 'Review',
  'not-review': 'Not review',
  //company-info
  //individiual-particular
  'passport-number': 'Passport Number',
  'id-card-number': 'ID Card Number',
  'download-id-card': 'Download ID Card',
  'download-passport': 'Download Passport',
  'download-form-101-b': 'Download Form 101 B',
  'individual': 'Individual',
  'create-individual': 'Create Individual',
  'create-new-individual-legal-particular': 'Create New Individual Legal Particular',
  'view-individual-particular': 'View Individual Particular',
  'update-individual-particular': 'Update Individual Particular',
  //legal-entity-particulars
  'company-information': 'Company Information',
  'company-representative-information': 'Company Representation Information',
  //bank-info
  'bank-name': 'Bank Name',
  'account-number': 'Account Number',
  'signer': 'Signer',
  'iBanking': 'iBanking',
  'bank-info-attachment': 'Bank Info Attachment',
  'add-bank-account': 'Add Bank Account',
  // post-registration
  'coi-number': 'COI Number',
  'coi-number-label': 'COI Number',
  'coi-date': 'COI Date',
  'coi-date-label': 'COI Date',
  'upload-files': 'Upload files',
  'tax-identification-number': 'Tax Identification Number (TIN)',
  'patent-tax-number': "Patent Tax Number",
  'vat-number': 'Vat Number',
};
