// Third party's imports
import { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useTheme } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { CustomSweetAlert } from '../../components/custom-sweet-altert2/custom-sweet-alter';

const useBusinessActivity = () => {
	const theme = useTheme();
	const { enqueueSnackbar } = useSnackbar();

	const [businessActivityDialog, setBusinessActivityDialog] = useState({
		open: false,
		data: null,
		preview: false,
	});
	const [triggerFetch, setTriggerFetch] = useState(false);

	const handleOpenBusinessActivityDialog = data =>
		setBusinessActivityDialog(prev => ({ ...prev, open: true, data, preview: false }));

	const handleCloseBusinessActivityDialog = () =>
		setBusinessActivityDialog(prev => ({ ...prev, open: false, data: null }));

	const handlePreviewBusinessActivity = data =>
		setBusinessActivityDialog(prev => ({ ...prev, data, open: true, preview: true }));

	/**
	 * delete business activity by `id`
	 * @param {String} id
	 */
	const handleDeleteBusinessActivity = async id => {
		try {
			await CustomSweetAlert.fire({
				title: 'Delete business activity',
				text: 'Do you want to delete it?',
				icon: 'warning',
				confirmButtonText: 'Delete',
				confirmButtonColor: theme.palette.secondary.main,
				showLoaderOnConfirm: true,
				showCancelButton: true,
				allowOutsideClick: () => !Swal.isLoading(),
				preConfirm: async () => {
					try {
						const response = await axios.delete(
							`${process.env.REACT_APP_API_URL}/v1/business-activity/${id}`
						);
						enqueueSnackbar(`${response.data.message}`, { variant: 'success' });
						setTriggerFetch(prev => !prev); // refetch data after delete successfully
					} catch (error) {
						console.log('error delete business activity :>> ', error);
						enqueueSnackbar(`${error.response.data.message || 'Something went wrong.'}`, { variant: 'error' });
					}
				},
			});
		} catch (error) {
			console.log('error delete business activity :>> ', error);
			Swal.fire({
				title: 'Error',
				text: error.response.data.message || 'Something went wrong.',
				icon: 'error',
				timer: 2000,
			});
		}
	};

	return {
		triggerFetch,
		setTriggerFetch,

		businessActivityDialog,
		handleOpenBusinessActivityDialog,
		handleCloseBusinessActivityDialog,
		handlePreviewBusinessActivity,

		handleDeleteBusinessActivity,
	};
};

export default useBusinessActivity;
