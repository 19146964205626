// Third party's imports
import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Grid,
	Typography,
	makeStyles,
	Divider,
	IconButton,
	Switch,
	FormControlLabel,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { MdAdd, MdDelete } from 'react-icons/md';

// Local imports
import { RHFTextInput } from '../../../components/rhf-controlled-input';
import useBusinessActivityDialog from './use-business-activity-dialog';

const useStyles = makeStyles(theme => ({
	dialogTitle: {
		textAlign: 'center',
		color: theme.palette.primary.main,
	},
	subActivityTitle: {
		display: 'flex',
		alignItems: 'center',
	},
}));

const BusinessActivityDialog = ({ open, onClose, selectedItem, setTriggerFetch, isPreviewMode }) => {
	const classes = useStyles();

	const {
		isLoading,

		control,
		isDirty,
		handleSubmit,
		onSubmit,
		handleResetField,
		handleSetSelectedDataToForm,

		fields,
		handleAppendField,
		handleRemoveField,
	} = useBusinessActivityDialog({
		selectedItem: selectedItem,
		handleCloseDialog: onClose,
		setTriggerFetch,
	});

	return (
		<Dialog
			open={open}
			onClose={onClose}
			fullWidth
			maxWidth='md'
			TransitionProps={{
				onExited: () => {
					handleResetField(); // reset field when close dialog
				},
				onEntering: () => {
					handleSetSelectedDataToForm(); // set data to input form when in update mode
				},
			}}
		>
			<DialogTitle classes={{ root: classes.dialogTitle }}>{'Business Activity'}</DialogTitle>
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogContent>
					<Grid container spacing={2} alignItems='center'>
						<Grid item xs={12}>
							<Typography>Main Activity</Typography>
						</Grid>
						<Grid item xs={5}>
							<RHFTextInput
								name='mainActivityEN'
								label='English'
								required
								control={control}
								inputProps={{ readOnly: isPreviewMode }} // read only in preview mode
								multiline
							/>
						</Grid>
						<Grid item xs={5}>
							<RHFTextInput
								name='mainActivityKH'
								label='Khmer'
								required
								control={control}
								inputProps={{ readOnly: isPreviewMode }} // read only in preview mode
								multiline
							/>
						</Grid>
						<Grid item xs={2}>
							<Controller
								name='license'
								control={control}
								defaultValue={false}
								render={({ field }) => (
									<FormControlLabel
										control={
											<Switch
												onChange={e => field.onChange(e.target.checked)}
												checked={field.value}
												color='primary'
												disabled={isPreviewMode} // disable in preview mode
											/>
										}
										label='License'
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item xs={12} className={classes.subActivityTitle}>
							<Typography>Sub Activity</Typography>
							<Button
								color='primary'
								variant='text'
								size='small'
								startIcon={<MdAdd />}
								onClick={handleAppendField}
								style={{ marginLeft: '0.75rem' }}
								disabled={isPreviewMode} // disable button in preview mode
							>
								Add
							</Button>
						</Grid>
						{/* sub business activities */}
						{fields.map((field, index) => (
							<React.Fragment key={field.id}>
								<Grid item xs={5}>
									<RHFTextInput
										name={`subActivities.${index}.subActivityEN`}
										label='English'
										control={control}
										inputProps={{ readOnly: isPreviewMode }} // read only in preview mode
										multiline
									/>
								</Grid>
								<Grid item xs={isPreviewMode ? 5 : 4}>
									<RHFTextInput
										name={`subActivities.${index}.subActivityKH`}
										label='Khmer'
										control={control}
										inputProps={{ readOnly: isPreviewMode }} // read only in preview mode
										multiline
									/>
								</Grid>
								<Grid item xs={isPreviewMode ? 1 : 2}>
									<Controller
										name={`subActivities.${index}.license`}
										control={control}
										defaultValue={false}
										render={({ field }) => (
											<FormControlLabel
												control={
													<Switch
														onChange={e => field.onChange(e.target.checked)}
														checked={field.value}
														color='primary'
														disabled={isPreviewMode} // disable in preview mode
													/>
												}
												label='License'
											/>
										)}
									/>
								</Grid>
								{/* hide when preview mode */}
								{!isPreviewMode && (
									<Grid item xs={1}>
										<IconButton
											color='secondary'
											onClick={() => {
												handleRemoveField(index);
											}}
											disabled={isPreviewMode} // disable in preview mode
										>
											<MdDelete />
										</IconButton>
									</Grid>
								)}
							</React.Fragment>
						))}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						color={isPreviewMode ? 'primary' : 'secondary'}
						size='small'
						onClick={onClose}
						disabled={isLoading}
						variant={isPreviewMode ? 'contained' : 'text'}
					>
						{isPreviewMode ? 'Close' : 'Cancel'}
					</Button>
					{/* hide when preview mode */}
					{!isPreviewMode && (
						<Button color='primary' variant='contained' type='submit' disabled={isLoading || !isDirty}>
							{selectedItem ? 'Update' : 'Create'}
						</Button>
					)}
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default BusinessActivityDialog;
