import React, { useEffect } from 'react';
import { makeStyles, Grid, TextField, InputAdornment, Button, Typography } from '@material-ui/core';
import { Email } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import Title from '../../components/Title';

const useStyles = makeStyles(theme => ({
	rootContainer: {
		position: 'relative',
		height: '100vh',
		width: '100%',
	},
	container: {
		backgroundColor: 'white',
		borderRadius: '5px',
		maxWidth: '520px',
		padding: '2.5rem',
		boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
		[theme.breakpoints.down('xs')]: {
			borderRadius: '0rem',
			boxShadow: '0px 0px 0px',
			border: '0px',
			padding: '3rem 1rem',
			paddingTop: '5rem',
		},
	},
	submitButton: {
		textTransform: 'capitalize',
	},
	forgotPasswordCredentialText: {
		padding: '0 0 25px 0',
	},
}));

const ForgotPassword = ({ forgotPassword, forgotPasswordInfo }) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	useEffect(() => {
		if (forgotPasswordInfo.isForgotPasswordFail) {
			enqueueSnackbar(forgotPasswordInfo?.message || 'Error forgot password', { variant: 'error' });
		}
		if (forgotPasswordInfo?.result?.status === 200) {
			enqueueSnackbar('OTP is sent to your email', { variant: 'success' });
		}
	}, [forgotPasswordInfo]);

	const onSubmit = data => {
		forgotPassword(data);
	};

	return (
		<>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignItems='center'
				className={classes.rootContainer}
			>
				<Grid className={classes.container}>
					<Grid item container xs={12} justifyContent='center'>
						<Title title='Forgot Password' />
						<Typography className={classes.forgotPasswordCredentialText}>
							Enter your credentials to access your account
						</Typography>
					</Grid>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<TextField
									{...register('email', {
										required: true,
										validate: value => {
											const email = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(value);
											return email;
										},
									})}
									error={errors.email ? true : false}
									helperText={errors.email ? 'Email is required' : ''}
									variant='outlined'
									placeholder='Email'
									type='email'
									fullWidth
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<Email color='primary' />
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12} container justifyContent='center'>
								<Button
									type='submit'
									variant='contained'
									color='primary'
									fullWidth
									className={classes.submitButton}
									disabled={forgotPasswordInfo.isLoading}
								>
									Continue
								</Button>
							</Grid>
						</Grid>
					</form>
					<Grid item container xs={12} justifyContent='center' style={{ paddingTop: '20px' }}>
						<Button
							component={Link}
							to='/'
							size='small'
							fullWidth
							color='primary'
							disabled={forgotPasswordInfo.isLoading}
						>
							Back to login
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default ForgotPassword;
