import { compose } from 'recompose'
import { connect } from 'react-redux';
import ForgotPassword from './presenter';
import { forgotPassword } from '../../actions/forgotPassword';

const mapStateToProps = (state) => ({
    forgotPasswordInfo: state.ForgotPasswordInfo,
});

const mapDispatchToProps = ( dispatch ) => ({
    forgotPassword: ( data ) => dispatch( forgotPassword( data.email ) ),
});

export default compose(
    connect( mapStateToProps, mapDispatchToProps ),
)(ForgotPassword);