import React from 'react';
import { Paper, makeStyles } from '@material-ui/core';

import BasicTabs from '../basic-tabs';
import theme from '../theme';
import BusinessActivity from './business-activity';
import CompanyInfo from './company-info';
import IndividualParticulars from './individual-particulars';
import LegalEntityParticulars from './legal-entity-particulars';

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    padding: '30px 30px 30px 30px',
    "& .tabsBox": {
      marginBottom: '2.5rem !important',
    }
  },
  tabs: {
    '& .MuiTab-root.Mui-selected': {
      color: theme.palette.primary.main,
    },
    '& .MuiTab-root': {
      color: '#000000',
      textTransform: 'capitalize !important',
      fontWeight: 600,
    },
  },
}));

const CompanyInfoTab = ({ company }) => {
  const classes = useStyles();

  if (!company) return <></>;

  const tabs = [
    { label: 'Company Info', component: <CompanyInfo company={company} /> },
    {
      label: 'Business Activity',
      component: (
        <BusinessActivity
          businessActivity={company?.businessActivity}
          activityOnPatentCertificateInKhmer={company?.activityOnPatentCertificateInKhmer} 
          activityOnPatentCertificateInLatin={company?.activityOnPatentCertificateInLatin}
        />
      ),
    },
    {
      label: 'Individual',
      component: (
        <IndividualParticulars
          individualParticular={company?.individualParticular}
        />
      ),
    },
    {
      label: 'Legal Entity',
      component: (
        <LegalEntityParticulars
          legalEntityParticular={company?.legalParticular}
        />
      ),
    },
  ];

  return (
    <Paper className={classes.paperRoot}>
      <BasicTabs
        tabs={tabs}
        className={classes.tabs}
        TabIndicatorProps={{
          style: { backgroundColor: theme.palette.primary.main },
        }}
      />
    </Paper>
  );
};

export default CompanyInfoTab;
