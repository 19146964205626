import axios from 'axios';

const pdfFormApi = {
	// type enum = ['individual', 'legal']
	personalInformation: (company_id, person_id, type) =>
		axios.get('/pdf-form/personal-information', {
			params: {
				company_id,
				person_id,
				type,
			},
			responseType: 'blob',
		}),
};

export default pdfFormApi;
