import React, { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Grid,
	Button,
	TextField,
	Slide,
	DialogActions,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import Title from '../../../components/Title';

const RolePermissionForm = ({ open, handleClose, fetchData }) => {
	const { handleSubmit, control, reset } = useForm();
	const { enqueueSnackbar } = useSnackbar();

	const [isLoading, setIsLoading] = useState(false);

	const onSubmit = async data => {
		setIsLoading(true);
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/roles/create`, {
				withCredentials: true,
				...data,
			});
			enqueueSnackbar(`${response.data.message}`, { variant: 'success' });
			setIsLoading(false);
			handleClose(); // close dialog
			fetchData();
		} catch (error) {
			console.log('error create new role :>> ', error);
			setIsLoading(false);
			enqueueSnackbar(`${error?.response?.data?.message || 'error create new role'}`, { variant: 'error' });
		}
	};

	return (
		<Dialog
			open={open}
			TransitionComponent={Slide}
			fullWidth={true}
			TransitionProps={{ onExited: () => reset() }}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogTitle disableTypography>
					<Title title='Create New Role' />
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid container item xs={12} alignItems='center'>
							<Grid item xs={3}>
								Role Name :
							</Grid>
							<Grid item xs={9}>
								<Controller
									control={control}
									render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
										<TextField
											variant='outlined'
											placeholder='Role Name'
											type='text'
											fullWidth
											inputRef={ref}
											size='small'
											value={value}
											onChange={onChange}
											error={!!error}
										/>
									)}
									name='roleName'
									defaultValue=''
									rules={{ required: true }}
								/>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions
					style={{ padding: '1rem 1.5rem', justifyContent: 'space-between', alignItems: 'center' }}
				>
					<Button onClick={handleClose} color='secondary' disabled={isLoading}>
						Close
					</Button>
					<Button type='submit' variant='contained' color='primary' disabled={isLoading}>
						Create
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default RolePermissionForm;
