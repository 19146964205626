import React, { useContext } from 'react';
import { UserContext } from '../../context-provider/user-context-provider';

// use fallback prop when you want to display custom component if use does not have permission
const AccessControl = ({ feature, action, children, fallback: FallbackComponent = () => null }) => {
	const { checkAccess } = useContext(UserContext);

	if (checkAccess({ feature, action })) {
		return children;
	}
	return <FallbackComponent />;
};

export default AccessControl;
