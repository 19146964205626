import { FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { KeyboardDatePicker } from '@material-ui/pickers';

const IdOrPassport = ({ control }) => {
	const { t } = useTranslation();

	return (
		<Grid item container xs={12}>
			<Grid item xs={4} style={{ paddingTop: '0.5rem' }}>
				<Typography>ID/Passport</Typography>
			</Grid>
			<Grid item container xs={8} spacing={2}>
				<Grid item xs={12}>
					<Controller
						name={`idOrPassport.isPassport`}
						control={control}
						defaultValue={'false'}
						render={({ field: { value, onChange } }) => (
							<RadioGroup row value={String(value)} onChange={onChange}>
								<FormControlLabel
									value='false'
									control={<Radio color='primary' />}
									label={t('id-card-label')}
								/>
								<FormControlLabel
									value='true'
									control={<Radio color='primary' />}
									label={t('passport-label')}
								/>
							</RadioGroup>
						)}
					/>
				</Grid>
				<Grid item xs={6}>
					<Controller
						render={({ field, fieldState: { error } }) => (
							<TextField
								{...field}
								variant='outlined'
								label='ID Number'
								type='text'
								fullWidth
								size='small'
								error={!!error}
							/>
						)}
						control={control}
						name='idOrPassport.number'
						defaultValue=''
						rules={{ required: true }}
					/>
				</Grid>
				<Grid item container xs={6}>
					<Grid item xs={12}>
						<Controller
							control={control}
							name={`idOrPassport.issuedDate`}
							defaultValue={null}
							rules={{ required: true }}
							render={({ field: { value, onChange }, fieldState: { error } }) => (
								<KeyboardDatePicker
									initialFocusedDate={new Date()}
									autoOk
									label={t('issued-date')}
									fullWidth
									variant='inline'
									inputVariant='outlined'
									size='small'
									format='dd/MM/yyyy'
									margin='none'
									value={value}
									onChange={onChange}
									KeyboardButtonProps={{
										style: {
											outline: 'none',
										},
									}}
									error={!!error}
									InputProps={{ readOnly: true }}
								/>
							)}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default IdOrPassport;
