import { compose, withHandlers } from 'recompose';
import Navbar from './presenter';
import auth from '../auth';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

const handlers = withHandlers({
    logout: () => () => {
        return auth.logout
    }
});

const mapStateToProps = ( state ) => ({
    authUser: state.LoginInfo,
});

const mapDispatchToProps = ( dispatch, ownProps ) => ({
    
});

export default withRouter(compose(
    connect(mapStateToProps, mapDispatchToProps),
    handlers
)(Navbar));