import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import { RHFAutocompleteInput } from '../../rhf-controlled-input';

/*
  include:
    - the select box to choose existing individual particular
    - view button
    - update button
  note:
    - action buttons only enabled when option is selected
*/
const IndividualParticularBox = props => {
	const { t } = useTranslation();
	const {
		handleView,
		handleUpdate,
		handleDelete,
		onExpandForm,
		isExpanded,
		field,
		classes,
		name = 'individualParticular',
		hideDeleteButton = false,
	} = props;

	const watchIndividual = useWatch({ name: `${name}[${props.ipKey}].individual` });

	return (
		<Grid container>
			<Grid item xs={8}>
				<RHFAutocompleteInput
					name={`${name}[${props.ipKey}].individual`}
					rules={{ required: true }}
					defaultValue={''}
					optionsURL={`${process.env.REACT_APP_API_URL}/v1/individual/list?search={search}&page=1&limit=10`}
					getOptionSelected={(option, value) => option._id === value._id}
					getOptionLabel={option => option.label || option.nameLatin || ''}
					renderOption={option => option.nameLatin}
					dataConfig={'individualParticular'}
					noOptionsText={t('no-individual-found')}
					disableClearable={false}
					label={t('full-name-latin-label')}
				/>
			</Grid>
			{/* action buttons */}
			<Grid item container xs={4}>
				<Grid item container xs={3}>
					<IconButton
						disabled={!Boolean(watchIndividual)}
						color='primary'
						onClick={() => handleView(watchIndividual)}
					>
						<VisibilityIcon />
					</IconButton>
				</Grid>
				<Grid item container xs={3}>
					<IconButton
						disabled={!Boolean(watchIndividual)}
						color='primary'
						onClick={() => handleUpdate(watchIndividual)}
					>
						<EditIcon />
					</IconButton>
				</Grid>

				<Grid item container xs={3}>
					{!hideDeleteButton && (
						<IconButton color='secondary' onClick={() => handleDelete(props.ipKey)}>
							<DeleteIcon />
						</IconButton>
					)}
				</Grid>

				<Grid item container xs={3}>
					<IconButton onClick={() => onExpandForm(field.id)} className={classes.root}>
						{isExpanded[field.id] ? (
							<KeyboardArrowUp color='primary' />
						) : (
							<KeyboardArrowDown color='primary' />
						)}
					</IconButton>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default IndividualParticularBox;
