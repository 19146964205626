import React from 'react';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { Router, Switch, Route, Redirect } from 'react-router-dom';

import history from './history';
// import Navbar from './components/navbar';
import theme from './components/theme';
// import Sidebar from './components/sidebar';
import DashboardLayout from './components/layout/dashboard';
import PrivateRoute from './components/private-route';
import RouteX from './components/routeX';

import ContextProviderWrapper from './components/context-provider';
import SnackbarProvider from './components/context-provider/snackbar-provider';

// pages
import LoginForm from './views/LoginForm';
import SignupForm from './views/SignupForm';
import ForgotPassword from './views/ForgotPassword';
// import Dashboard from './views/Dashboard/index';
// import NewCompany from './views/Company/NewCompany';
// import EditCompany from './views/Company/EditCompany';
// import ReviewCompany from './views/Company/ReviewCompany';
// import Staff from './views/Staff';
// import ProfileUpdate from './views/ProfileUpdate';
import ResetPassword from './views/ResetPassword';
// import EditStaff from "./views/Staff/EditStaff";
// import ResetPasswordSuccessfully from "./views/ResetPasswordSuccessfully";

import './App.css';

import dashboardRoutes from './routes/dashboard';

/**
 * ===== NOTE =====
 * initialize axios default config
 * when make any request using axios no need to pass process.env.REACT_APP_SERVER_URI
 * it already config as baseURL
 * you can config axios for other setting in /src/lib/fetcher.js
 */
import './lib/fetcher';

const App = () => {
	// const DefaultContainer = () => (
	// 	<div className='d-flex flex-row position-absolute' style={{ top: 0, right: 0, bottom: 0, left: 0 }}>
	// 		<div style={{ height: '100vh', zIndex: 2000 }}>
	// 			<Sidebar />
	// 		</div>
	// 		<div className='d-flex flex-grow-1'>
	// 			<Navbar />
	// 			<div className='d-flex pt-5' style={{ flex: 1, overflow: 'auto' }}>
	// 				<Switch>
	// 					<Route path='/dashboard' render={props => <Dashboard />} />
	// 					<Route path='/newCompany' render={props => <NewCompany />} />

	// 					{/* <Route
  //             path="/staff/:staffId/edit"
  //             render={(props) => <EditStaff />}
  //           /> */}
	// 					<Route path='/staff' render={props => <Staff />} />

	// 					<Route path='/company/:companyId/edit' render={props => <EditCompany />} />
	// 					<Route path='/company/:companyId/review' render={props => <ReviewCompany />} />

	// 					<Route path='/profile' render={props => <ProfileUpdate />} />
	// 				</Switch>
	// 			</div>
	// 		</div>
	// 	</div>
	// );

	return (
		<ThemeProvider theme={theme}>
			<SnackbarProvider>
			<CssBaseline />
			<Router history={history}>
				<Switch>
					{/* <Route exact path="/" render={(props) => <LoginForm />} />  */}
					<Route exact path='/signup' render={props => <SignupForm />} />
					<Route exact path='/forgot-password' render={props => <ForgotPassword />} />
					<Route path='/reset-password' render={props => <ResetPassword />} />
					<PrivateRoute path='/dashboard/:path*'>
						<ContextProviderWrapper>
							<DashboardLayout>
								<Switch>
									{dashboardRoutes.map((route, index) => (
										<Route {...route} key={index}>
											{route.redirect && <Redirect to={route.redirect} />}
										</Route>
									))}
								</Switch>
							</DashboardLayout>
						</ContextProviderWrapper>
					</PrivateRoute>
					<RouteX path='/' component={LoginForm} redirect='/dashboard' />
				</Switch>
			</Router>
			</SnackbarProvider>
		</ThemeProvider>
	);
};

export default App;
