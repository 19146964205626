const APP_EN = {
  // reserved company page
  'company-name': 'Company Name',
  'reserve-company': 'Reserve Company',
  'reserve-history': 'Reserve History',
  'prepared-by': 'Prepared By',
  'waiting-to-review': 'Waiting to review',
  reviewed: 'Reviewed',
  'reviewed-by': 'Reviewed By',
  attachment: 'Attachment',
  status: 'Status',
  action: 'Action',
  // reserve company status
  'reject-button': 'Reject',
  'accept-button': 'Accept',
  accepted: 'Accepted',
  rejected: 'Rejected',
  Pending: 'Pending',

  // create new reserved company form
  'add-new-company': 'Add New Company',
  'information-for-registration': 'Information for Registration',
  'company-name-latin': 'Company Name (Latin)',
  'company-name-latin-label': 'ឈ្មោះក្រុមហ៊ុនជាអក្សរឡាតាំង',
  'company-name-khmer': 'Company Name (Khmer)',
  'company-name-khmer-label': 'ឈ្មោះក្រុមហ៊ុនជាភាសាខ្មែរ',
  'form-of-business': 'Form of Business',
  'form-of-business-label': 'ទម្រង់ក្រុមហ៊ុន',
  'share-capital': 'Share Capital',
  'share-capital-label': 'ដើមទុនក្រុមហ៊ុន (USD)',
  'mobile-phone-number': 'Mobile Phone Number',
  'mobile-phone-number-label': 'ទូរស័ព្ទចល័ត',
  'office-telephone-number': 'Office Telephone Number',
  'office-telephone-number-label': 'ទូរស័ព្ទលើតុ',
  'register-address': 'Register Address',
  'geographical-coordinate': 'Geographical Coordinate',
  'latitude-label': 'រយះទទឹង',
  'latitude': 'Latitude',
  'longitude-label': 'រយះបណ្តោយ',
  'longitude': 'Longitude',
  occupation: 'Occupation',
  'occupation-label': 'មុខរបរ',
  No: 'លេខផ្ទះ',
  street: 'លេខផ្លូវ',
  city: 'ខេត្ត/រាជធានី',
  district: 'ស្រុក/ខណ្ឌ',
  commune: 'ឃុំ/សង្កាត់',
  village: 'ភូមិ',
  group: 'ក្រុម',
  ownership: 'Ownership',
  rent: 'Rent',
  'property-registration-number': 'លេខចុះបញ្ជីអចលនៈទ្រព្យ',
  'expiry-date': 'ចុះថ្ងៃទី',
  'branch-rental-fee': 'តម្លៃជួល (USD)',
  'main-activity': 'Main Activity',
  'sub-activity': 'Sub Activity',
  'add-more-activity-btn': 'Add more activity',
  'activity-on-patent-certificate-in-khmer': 'Activity on Patent Certificate (Khmer)',
  'activity-on-patent-certificate-in-latin': 'Activity on Patent Certificate (Latin)',
  'activity-patent-certificate-in-khmer-label': 'សកម្មភាពលើប័ណ្ណប៉ាតង់ជាអក្សរខ្មែរ',
  'activity-patent-certificate-in-latin-label': 'សកម្មភាពលើប័ណ្ណប៉ាតង់ជាអក្សរឡាតាំង',
  'estimate-total-number-of-employee': 'Estimate Total Number of Employee',
  'total-employee': 'ចំនួនបុគ្គលិកសរុប',
  'total-female-employee': 'ចំនួនបុគ្គលិកស្រី',
  'number-of-foreigner-employee': 'Number of Foreigner Employee',
  'total-foreigner-employee': 'ចំនួនបុគ្គលិកបរទេស',
  'total-female-foreigner-employee': 'ចំនួនបុគ្គលិកបរទេសស្រី',
  'weekly-holiday': 'Weekly Holiday',
  'weekly-holiday-placeholder':'ថ្ងៃឈប់សម្រាកប្រចាំសប្តាហ៍',
  'number-of-working-per-day-or-week': 'Number of Working Per Day or Week',
  'number-of-working-per-day-or-week-placeholder':'រយះពេលធ្ចើការក្នុងមួយថ្ងៃឫសប្តាហ៏',
  'total-employee-salary': 'Total Employee Salary',
  'total-employee-salary-placeholder': 'ប្រាក់ខែបុគ្គលិកសរុប',
  'company-employee-information': 'Company Employee Information',
  // individual-particulars | step 3
  'full-name-latin': 'Full Name (Latin)',
  'full-name-khmer': 'Full Name (Khmer)',
  nationality: 'Nationality',
  'place-of-birth': 'Place of Birth',
  'current-address': 'Current Address',
  'id-or-passport': 'ID/Passport',
  'add-individual': 'Add individual',
  'add-another-individual-legal-particular-btn': 'Add another individual Legal particular',
  'full-name-latin-label': 'គោត្តនាម និងនាមជាអក្សរឡាតាំង',
  'full-name-khmer-label': 'គោត្តនាម និងនាមជាអក្សរខ្មែរ',
  male: 'Male',
  female: 'Female',
  shareholder: 'Shareholder',
  chairman: 'Chairman',
  director: 'Director',
  representative: 'Representative',
  'nationality-label': 'សញ្ជាតិ',
  'phone-number-label': 'លេខទូរសព្ទ',
  'email-label': 'អ៊ីម៉ែល',
  'id-card-label': 'ID Card',
  'passport-label': 'Passport',
  'file-attachment-label': 'រូបភាពអត្តសញ្ញាណប័ណ្ណ/លិខិតឆ្លងដែន',
  //Add new company step4
  'legal-name-latin': 'Legal name (Latin)',
  'legal-name-khmer': 'Legal name (Khmer)',
  'registration-information': 'Registration Information',
  'registration-number': 'លេខចុះបញ្ជឺ',
  'tax-identification-number': 'Tax Identification Number (TIN)',
  'tax-identification-number-label': 'លេខអត្តសញ្ញាណពន្ធ',
  'share-percentage': 'Share Percentage',
  'share-percentage-label': 'ភាគរយនៃភាគហ៊ុន (%)',
  'registered-address': 'Registered Address',
  'individual-legal-particular': 'Individual Legal Particular',
  'represented-by': 'Represented By',
  'represented-by-label': 'តំណាងដោយ',
  'add-legal-entity': 'Add legal entity',
  'file-attachment': 'File Attachment',

  // global
  'created-by': 'Created By',
  search: 'Search',
  description: 'Description',
  cancel: 'Cancel',
  add: 'Add',
  unit: 'Unit',
  brand: 'Brand',
  note: 'Note',
  icon: 'Icon',
  sku: 'SKU',
  upc: 'UPC/EAN/ISBN/PART NUMBER',
  name: 'Name',
  email: 'E-mail',
  phone: 'Phone Number',
  website: 'Website',
  address: 'Address',
  fullname: 'FullName',
  position: 'Position',
  'low-stock': 'LOW STOCK LIMIT (REORDER ALERT)',
  firstname: 'First Name',
  lastname: 'Last Name',
  gender: 'Gender',
  'date-of-birth': 'Date of Birth',
  vendor: 'Vendor',
  'created-date': 'Created Date',
  image: 'Image',
  'product-name': 'Product Name',
  quantity: 'Quantity',
  'cost-price': 'Cost Price',
  tax: 'Tax',
  amount: 'Amount',
  subtotal: 'Subtotal',
  'shipping-fee': 'Shipping Fee',
  'other-fee': 'Other Fee',
  discount: 'Discount',
  'grand-total': 'Grand Total',
  update: 'Update',
  'unit-price': 'Unit Price',
  save: 'Save',
  barcode: 'Barcode',
  print: 'Print',
  'edit-options': 'Edit Options',
  filter: 'Filter',
  export: 'Export',
  'before-tax': 'Before Tax',
  'payment-status': 'Payment Status',
  'save-changes': 'Save Changes',
  cash: 'Cash',
  'stock-on-hand': 'Stock on hand',
  'are-you-sure': 'Are you sure?',
  confirm: 'Confirm',
  'action-cant-undone': 'Your action cannot be undone.',
  'action-confirmation': 'Action Confirmation',
  'returned-confirmation': 'Returned Confirmation',
  'no.': 'No.',
  total: 'Total',
  'amount-due': 'Amount Due',
  'print-invoice': 'Print Invoice',
  'print-shipping-label': 'Print Shipping Label',
  payment: 'Payment',
  'credit-note': 'Credit Note',
  order: 'Order',
  create: 'Create',
  delete: 'Delete',
  user: 'User',
  'api-key': 'Api Key',
  'delivery-provider': 'Delivery Provider',
  other: 'Other',
  'payment-account': 'Payment Account',
  'delivery-status': 'Delivery Status',
  'received-all': 'Received All',
  'assign-storage': 'Assign Storage',
  received: 'Received',
  receive: 'Receive',
  receiver: 'Receiver',
  storage: 'Storage',
  altCode: 'AltCode',
  'n/a': 'N/A',
  'costing-total': 'Costing Total',
  'no-product-found': 'No Product Found',
  'no-storage-found': 'No Storage Found',
  cost: 'Cost',
  'cost-date': 'Cost Date',
  reference: 'Reference',
  comment: 'Comment',
  costlines: 'Costlines',
  items: 'Items',
  'returned-order': 'Returned Order',
  'shipping-address': 'Shipping Address',
  customer: 'Customer',
  id: 'Id',
  return: 'Return',
  'to-pick': 'To Pick',
  edit: 'Edit',

  //delivery provider
  tada: 'TADA',

  // status
  'get-document': 'Get Documents',
  pending: 'Pending',
  approved: 'Approved',
  packing: 'Packing',
  shipping: 'Shipping',
  delivered: 'Delivered',
  'delivery-failed': 'Delivery Failed',
  failed: 'Failed',
  cancelled: 'Cancelled',
  complete: 'Complete',
  returned: 'Returned',
  'partially-returned': 'Partially Returned',
  unpaid: 'Unpaid',
  paid: 'Paid',
  'unpaid-invoice': 'Unpaid Invoice',
  'partially-paid': 'Partially Paid',
  date: 'Date',
  'paid-date': 'Paid Date',

  pack: 'Pack',
  ship: 'Ship',

  'order-quantity': 'Order Quantity',
  'packing-order': 'Pack Order',
  'packing-purchase-return': 'Pack Purchase Return',
  'cancel-purchase-return': 'Cancel Purchase Return',
  'failed-order': 'Fail Order',

  // title bar
  'titlebar-dashboard': 'Dashboard',
  'titlebar-unit': 'List Unit',
  'titlebar-brand': 'List Brand',
  'titlebar-category': 'List Category',
  'titlebar-product': 'List Product',
  'titlebar-product-create': 'Create Product',
  'titlebar-product-edit': 'Edit Product',
  'titlebar-currency': 'List Currency',
  'titlebar-vendor': 'List Vendor',
  'titlebar-vendor-create': 'Create Vendor',
  'titlebar-vendor-edit': 'Edit Vendor',
  'titlebar-customer': 'List Customer',
  'titlebar-customer-create': 'Create Customer',
  'titlebar-customer-edit': 'Edit Customer',
  'titlebar-purchase': 'List Purchase',
  'titlebar-purchase-create': 'Create Purchase',
  'titlebar-purchase-edit': 'Edit Purchase',
  'titlebar-purchase-in': 'List Purchase In',
  'titlebar-purchase-in-edit': 'Edit Purchase In',
  'titlebar-purchase-in-confirm': 'Confirm Purchase In',
  'titlebar-purchase-return': 'List Purchase Return',
  'titlebar-purchase-return-create': 'Create Purchase Return',
  'titlebar-purchase-return-edit': 'Edit Purchase Return',
  'titlebar-purchase-receive': 'Receive Purchase',
  'titlebar-sale-invoice': 'List Sale Invoice',
  'titlebar-sale-invoice-create': 'Create Invoice',
  'titlebar-sale-invoice-sale-return': 'List Sale Return',
  'titlebar-sale-invoice-edit': 'Edit Invoice',
  'titlebar-sale-invoice-repack': 'List Repack',
  'titlebar-stock': 'List Stock',
  'titlebar-storage': 'List Storage',
  'titlebar-report': 'Report',
  'titlebar-report-sale': 'Sale Report',
  'titlebar-report-payment': 'Payment Report',
  'titlebar-paymentAccount': 'List Payment Account',
  'titlebar-setting': 'Setting',
  'titlebar-deliverySetting': 'Delivery Setting',

  // dashboard
  'total-paid': 'Total Paid',
  'total-amount-due': 'Total AmountDue',
  'total-approved': 'Total Approved',
  'total-rejected': 'Total Rejected',
  'total-sale': 'Total Sale',
  'total-order': 'Total Order',
  'total-products': 'Total Products',
  'low-stock-products': 'Low Stock Products',
  'expiry-products': 'Expiry Products',
  'stock-price': 'Stock Price',

  // product page
  'create-product': 'Create Product',
  'edit-product': 'Edit Product',
  'summary-description': 'Summary Description',
  'age-variation': 'Age Variation',
  'create-attributes-and-options': 'CREATE ATTRIBUTES AND OPTIONS',
  'purchase-information': 'Purchase Information',
  'selling-price': 'Selling Price',
  'product-description': 'Product Description',
  'low-stock-limit': 'Low Stock Limit (Reorder Alert)',
  'selling-information': 'Selling Information',
  attribute: 'Attribute',
  options: 'Options',
  'add-more-attribute': 'Add More Attributes',
  'item-name': 'Item Name',
  'product-information': 'Product Information',
  'generate-sku': 'Generate SKU',
  'copy-to-all': 'Copy to All',
  'sku-data-insufficient': 'Please fill in (product name, vendor, brand)',
  'fill-product-name': 'Please fill in product name',
  'fill-in-first-field': 'Please fill in the first field',
  'sale-price': 'Sale Price',
  different: 'Different',

  // unit page
  'unit-name': 'Unit Name',
  'create-unit': 'Create Unit',
  'unit-form-title': 'UNIT INFORMAION',
  'unit-placeholder': 'EG: PCS',

  // brand page
  'brand-form-title': 'BRAND INFORMATION',
  'create-brand': 'Create Brand',
  'brand-name': 'Brand Name',

  // category page
  'create-category': 'Create Category',
  'category-form-title': 'CATEGORY INFORMATION',
  'category-name': 'CATEGORY NAME',
  'parent-category': 'PARENT CATEGORY',

  // currency page
  'currency-name': 'Currency Name',
  'currency-symbol': 'Currency Symbol',
  'currency-form-title': 'CURRENCY INFORMATION',
  'price-per-dollar': 'Price Per 1USD',
  'create-currency': 'New Currency',

  // vendor page
  'company-information': 'Company Information',
  'vendor-form-title': 'VENDOR INFORMATION',
  'contact-information': 'Contact Information',
  'create-vendor': 'Create Vendor',
  'edit-vendor': 'Edit Vendor',
  'add-more-contact': 'Add more contact',

  // customer
  'customer-information': 'CUSTOMER INFORMATION',
  'select-customer': 'SELECT CUSTOMER',
  'create-customer': 'Create Customer',
  'edit-customer': 'Edit Customer',
  nearby: 'Nearby',
  'city-province': 'City / Province',
  // district: 'District',
  // commune: 'Commune',
  'google-map-url': 'Google Map URL',

  // purchase order
  'purchase-order-number': 'Purchase Order No.',
  'reference-number': 'Reference No.',
  'expected-delivery-date': 'Expected Delivery Date',
  // 'expiry-date': 'Expiry Date',
  'create-purchase': 'Create Purchase',
  'edit-purchase': 'Edit Purchase',
  'expected-delivery': 'Expected Delivery',
  'vendor-name': 'Vendor Name',

  //purchase return
  'create-purchase-return': 'Create Purchase Return',

  // sale-invoice
  'invoice-not-found': 'Invoice not found',
  'invoice-number': 'Invoice No',
  'customer-name': 'Customer Name',
  'create-invoice': 'Create Invoice',
  'edit-invoice': 'Edit Invoice',
  'select-payment-methods': 'SELECT PAYMENT METHODS',
  'payment-methods': 'Payment Methods',
  'cash-on-delivery': 'Cash on delivery',
  'online-payment': 'Online Payment',
  'cash-transfer': 'Cash Transfer',
  'bank-transfer': 'Bank Transfer',
  'invoice-detail': 'INVOICE DETAIL',
  'total-returned-over-amount-due': 'Total returned over amount due',
  'paid-to': 'Paid to',
  'parcel-size': 'Parcel Size',
  'who-pay': 'Who Pay?',
  'instructions-to-driver': 'Instructions to Driver',
  'shipping-with-provider': 'Shipping with Provider',

  //payment-account-code
  'payment-account-form-title': 'PAYMENT ACCOUNT INFORMATION',
  'create-payment-account': 'Create Payment Account',
  'account-name': 'Account Name',
  'account-code': 'Account Code',

  // storage
  'create-storage': 'Create Storage',
  'storage-form-title': 'STORAGE INFORMATION',

  // stock
  'stock-detail': 'Stock Detail',

  //login-page
  'email-or-phone': 'Email / Phone Number',
  password: 'Password',
  login: 'Login',
  'forgot-password': 'Forgot Password?',

  //setting
  //auto sequence setting
  'automatic-sequencing': 'Automatic Sequencing',
  'invoice-prefix': 'Invoice Prefix',
  'next-number': 'Next Number',
  'purchase-order-prefix': 'Purchase Order Prefix',

  // delivery

  // Sidebar
  'app-sidebar-dashboard': 'Dashboard',
  'app-sidebar-product': 'Product',
  'app-sidebar-product-list': 'List Product',
  'app-sidebar-product-add': 'Add Product',
  'app-sidebar-product-listVariant': 'List Variant Product',
  'app-sidebar-product-addVariant': 'Add Variant Product',
  'app-sidebar-product-unit': 'Unit',
  'app-sidebar-product-brand': 'Brand',
  'app-sidebar-sale': 'Sale Invoice',
  'app-sidebar-sale-add': 'Create Invoice',
  'app-sidebar-sale-repack': 'Repack',
  'app-sidebar-sale-saleReturn': 'Sale Return',
  'app-sidebar-sale-list': 'Invoice List',
  'app-sidebar-category': 'Category',
  'app-sidebar-category-list': 'Category',
  'app-sidebar-category-add': 'Add Category',
  'app-sidebar-customer': 'Customer',
  'app-sidebar-customer-list': 'List Customer',
  'app-sidebar-customer-add': 'Add Customer',
  'app-sidebar-vendor': 'Vendor',
  'app-sidebar-vendor-list': 'List Vendor',
  'app-sidebar-vendor-add': 'Add Vendor',
  'app-sidebar-purchase': 'Purchase',
  'app-sidebar-purchase-list': 'Purchase Order',
  'app-sidebar-purchase-add': 'Add Purchase Order',
  'app-sidebar-warehouse': 'Warehouse',
  'app-sidebar-warehouse-transferProduct': 'Product Transfer',
  'app-sidebar-warehouse-addTransferProduct': 'Add Product Transfer',
  'app-sidebar-users': 'Users',
  'app-sidebar-users-list': 'List Users',
  'app-sidebar-users-add': 'Add Users',
  'app-sidebar-memberCard': 'Membership Card',
  'app-sidebar-memberCard-list': 'List Card',
  'app-sidebar-memberCard-add': 'Add Card',
  'app-sidebar-event': 'Event',
  'app-sidebar-event-coupon': 'Coupon',
  'app-sidebar-event-productDiscount': 'Product Discount',
  'app-sidebar-reports': 'Reports',
  'app-sidebar-reports-saleReport': 'Sale Report',
  'app-sidebar-reports-paymentReport': 'Payment Report',
  'app-sidebar-reports-productReport': 'Product Report',
  'app-sidebar-reports-saleByCategory': 'Sale By Category',
  'app-sidebar-reports-saleByPaymentType': 'Sale By Payment Type',
  'app-sidebar-reports-saleByStaff': 'Sale By Staff',
  'app-sidebar-reports-shift': 'Shift',
  'app-sidebar-reports-profitAndLose': 'Profit and Lose',
  'app-sidebar-setting': 'Setting',
  'app-sidebar-setting-brand': 'Brand',
  'app-sidebar-setting-currencyOption': 'Currency Option',
  'app-sidebar-setting-paymentType': 'Payment Type',
  'app-sidebar-setting-store': 'Store',
  'app-sidebar-setting-pos': 'POS',
  'app-sidebar-setting-paymentAccount': 'Payment Account',
  'app-sidebar-setting-general': 'General',
  'app-sidebar-setting-delivery': 'Delivery',
  'app-sidebar-stock': 'Stock',
  'app-sidebar-stock-list': 'List Stock',
  'app-sidebar-storage': 'Storage',
  'app-sidebar-storage-list': 'List Storage',
  'app-sidebar-purchase-in-list': 'Purchase In',
  'app-sidebar-purchase-return-list': 'Purchase Return',
  // dialog
  'app-dialog-cancelButton': 'Cancel',
  'app-dialog-addButton': 'Add',
  'app-dialog-updateButton': 'Update',
  // create/edit amendment page
  'share-capital-input-label': 'ដើមទុនក្រុមហ៊ុន',
};

export default APP_EN;
