import s3 from '../aws-s3';

/**
 * handle upload multiple files to S3
 * @param {Array} files
 * @param {String} s3FolderName
 * @returns {Array} fileObject
 */
export const uploadMultipleFilesToS3 = async (files, s3FolderName) => {
	const uploadedFilesObject = await Promise.all(
		files.map(async item => {
			// upload each file to S3
			const response = await s3.uploadImage({
				image: item.file,
				type: item.file.type,
				folder: s3FolderName,
			});

			const fileItem = {
				key: response,
				mimeType: item.file.type,
			};
			return fileItem;
		})
	);

	return uploadedFilesObject;
};
