import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import SwitchItem from './switch-item';

const SwitchList = ( { featureName, permissions, register, control } ) => (
  <Grid container>
    <Grid item sm={12} md={5} container justifyContent="center">
      <Typography style={{ fontWeight: 'bold', textTransform: 'capitalize' }} gutterBottom>
        {featureName === 'rolePermission' ? 'role-permission' : featureName}
      </Typography>
    </Grid>
    <Grid item sm={12} md={7} container>
      {permissions.map( ( permission, index ) => (
        <Grid item xs={6} lg={3} key={index}>
          <SwitchItem
            feature={featureName}
            permission={permission}
            register={register}
            control={control}
          />
        </Grid>
      ) )}
    </Grid>
  </Grid>
);

export default SwitchList;
