import numberFormat from './numberFormat';

const generateAddress = (address, locale) => {
  return address?.isManual
    ? numberFormat(address?.[locale], locale, true)
    : locale === 'en'
    ? `${getHomeNumber(address?.homeNumber, locale)}${getStreetNumber(
        address?.street,
        locale
      )}${numberFormat(
        address?.commune?.en,
        locale,
        true
      )} commune, ${numberFormat(
        address?.district?.en,
        locale,
        true
      )} district, ${
        address?.village
          ? numberFormat(address?.village?.en, locale, true) + ', '
          : ''
      }${
        address?.group
          ? `Group ${numberFormat(address?.group, locale, true)}, `
          : ''
      }${address?.city?.en}${
        address?.city?.type === 'ខេត្ត' ? ' province' : ' city'
      }`
    : `${getHomeNumber(address?.homeNumber, locale)}${getStreetNumber(
        address?.street,
        locale
      )}${
        address?.village
          ? `${
              address?.village?.kh?.indexOf('ភូមិ')
                ? address?.village?.type
                : ''
            }${address?.village?.kh} `
          : ''
      }${address?.commune?.type}${address?.commune?.kh} ${
        address?.district?.type
      }${address?.district?.kh} ${
        address?.group
          ? `ក្រុម ${numberFormat(address?.group, locale, true)} `
          : ''
      }${address?.city?.type}${address?.city?.kh}`;
};

const getHomeNumber = (homeNumber, locale) => {
  if (!homeNumber || homeNumber === '') return '';

  return locale === 'en'
    ? `No. ${numberFormat(homeNumber, locale, true)}, `
    : `ផ្ទះ​ ${numberFormat(homeNumber, locale, true)} `;
};

const getStreetNumber = (street, locale) => {
  if (!street || street === '') return '';

  return locale === 'en'
    ? `Street ${numberFormat(street, locale, true)}, `
    : `ផ្លូវ ${numberFormat(street, locale, true)} `;
};

export default generateAddress;
