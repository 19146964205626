// data param is from response api (ex: response.data)
const downloadBlobFile = ({ filename = 'file', data }) => {
	const url = window.URL.createObjectURL(new Blob([data]));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', filename);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export default downloadBlobFile;

/**
 * ===== USAGE =====
 * fill data to pdf form get download it from backend
 * const handleDownloadPersonalInformation = async () => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_API_URL}/v1/pdf-form/personal-information`,
				{
					lastname: 'ប្រេមម៉ា',
					firstname: 'អេង',
					gender: 'female',
				},
				{ responseType: 'blob' }
			);
			downloadFile({ filename: 'personal-information.pdf', data: response.data });
		} catch (error) {
			console.log('error download personal information :>> ', error);
		}
	};
 */
