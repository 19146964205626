import React, { useEffect, useState } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import FilePreview from '../../file-upload/file-preview';

const BusinessActivity = ({ businessActivity, activityOnPatentCertificateInKhmer, activityOnPatentCertificateInLatin }) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState({})

  useEffect(()=>{
    const data = businessActivity?.map((item) => {
      if(item.hasOwnProperty('fileAttachment')) {
        return { files: item.fileAttachment }
      }
      return { files: [] };
    })
    
    setFiles(data)
  }, [businessActivity])

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <Typography>
          {t('activity-on-patent-certificate-in-khmer')}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>
          {activityOnPatentCertificateInKhmer || 'N/A'}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography>
          {t('activity-on-patent-certificate-in-latin')}
        </Typography>
      </Grid>
      <Grid item xs={8} style={{marginBottom: '1.5rem'}}>
        <Typography>
          {activityOnPatentCertificateInLatin || 'N/A'}
        </Typography>
      </Grid>
      {businessActivity?.map((eachBusinessActivity, index) => (
          <Grid item container key={index}>
            {/* main activity */}
            <Grid
              item
              container
              style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
            >
              <Grid item xs={4}>
                <Typography>
                  {t('main-activity')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  {eachBusinessActivity.mainBusinessActivity.nameEN || eachBusinessActivity.mainBusinessActivity.label}
                </Typography>
              </Grid>
            </Grid>

            {/* sub activity */}
            <Grid
              item
              container
              style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
            >
              <Grid item xs={4}>
                <Typography>
                  {t('sub-activity')}
                </Typography>
              </Grid>
              <Grid item container xs={8}>
                {eachBusinessActivity.subActivities?.map((subActivity, index) => (
                  <Grid key={index} item xs={12} style={{ paddingBottom: '0.5rem' }}>
                    <Typography>{subActivity.nameEN || subActivity.label}</Typography>
                  </Grid>
                ))}
              </Grid>
          </Grid>
          
            {/* file Upload */}
            <Grid
              item
              container
              style={{ paddingBottom: '0.5rem' }}
            >
              <Grid item xs={4}>
                <Typography>
                  {t('file-attachment')}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                { 
                  (files?.[index]?.files?.length > 0) ? <FilePreview files={files?.[index]} isPreviewMode /> : 'N/A'  
                }
              </Grid>
            </Grid>
          </Grid>
      ))}
    </Grid>
  );
};

export default BusinessActivity;
