import React, { useState, useRef} from 'react';
import {
  Button,
  Avatar,
  Typography,
  makeStyles,
  Popper,
  Grow,
  Paper,
  ListItem,
  List,
  ListItemIcon,
  ClickAwayListener,
  ListItemText,
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { PersonOutlined } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { useUserContext } from '../../context-provider/user-context-provider';

const useStyles = makeStyles(() => ({
  profileButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none',
    textAlign: 'start',
    '& p': {
      margin: '0 10px',
    },
    '&:focus': {
      outline: 'none',
    }
  },
  paper: {
    padding: '10px',
    marginTop: '8px',
  },
  listItem: {
    "& .MuiListItem-root": {
      textAlign:'center',
    }
  }
}));

const Profile = () => {
  const classes = useStyles();
  const { userInfo } = useUserContext();
  const history = useHistory();
  const anchorRef = useRef();

  const [open, setOpen] = useState(false);

  // open drop down
  const handleOpen = () => setOpen(true);
  // close drop down
  const handleClose = () => setOpen(false);

  const handleLogout = () => {
    axios
			.get(`${process.env.REACT_APP_API_URL}/v1/auth/logout`, { withCredentials: true })
			.then(response => {
        history.push('/');
			})
			.catch(error => {
				console.log('error logout :>> ', error);
			});
  };

  const handleViewProfile = () => {
    history.push('/dashboard/me');
    setOpen(false);
  }

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={handleOpen}
        className={classes.profileButton}
        color="primary"
      >
        <Avatar src={userInfo?.image?.key} alt={userInfo?.nameLatin} />
        <Typography>Hello,<br/>{userInfo?.nameLatin || 'N/A'}</Typography>
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement="bottom-end"
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper elevation={3} className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <List disablePadding>
                  <ListItem style={{backgroundColor:"beige"}}>
                      <ListItemText disableTypography primary={<Typography type="body2" style={{ textAlign:'center' }}>{userInfo?.nameLatin}</Typography>}/>
                  </ListItem>
                  <ListItem button onClick={handleViewProfile}>
                    <ListItemIcon>
                      <PersonOutlined color="primary" />
                    </ListItemIcon>
                    <ListItemText>My Profile</ListItemText>
                  </ListItem>
                  <ListItem button onClick={handleLogout}>
                    <ListItemIcon>
                      <ExitToAppIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                  </ListItem>
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
};

export default Profile;
