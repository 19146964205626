import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';

import generateAddress from '../../../views/Company/ReviewCompany/generateAddress';

const CompanyInfo = ({ company }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Typography color='primary' paragraph style={{ fontWeight: 'bold' }}>I. {t('information-for-registration')}</Typography>
      {/* name khmer */}
      <Grid item container>
        <Grid item xs={4}>
          <Typography>{t('company-name-khmer')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{company.companyNameKhmer}</Typography>
        </Grid>
      </Grid>
      {/* name latin */}
      <Grid item container>
        <Grid item xs={4}>
          <Typography>{t('company-name-latin')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{company.companyNameLatin}</Typography>
        </Grid>
      </Grid>
      {/* form of business */}
      <Grid item container>
        <Grid item xs={4}>
          <Typography>{t('form-of-business')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{company.formOfBusiness}</Typography>
        </Grid>
      </Grid>
      {/* share capital */}
      <Grid item container>
        <Grid item xs={4}>
          <Typography>{t('share-capital')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{company.shareCapital}</Typography>
        </Grid>
      </Grid>

      <Grid item container>
        <Grid item xs={4}>
          <Typography>{t('email')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{company?.email || 'N/A'}</Typography>
        </Grid>
      </Grid>

      <Grid item container>
        <Grid item xs={4}>
          <Typography>{t('mobile-phone-number')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{company?.mobilePhoneNumber || 'N/A'}</Typography>
        </Grid>
      </Grid>

      <Grid item container>
        <Grid item xs={4}>
          <Typography>{t('office-telephone-number')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{company?.officeTelephoneNumber || 'N/A'}</Typography>
        </Grid>
      </Grid>

      <Grid item container>
        <Grid item xs={4}>
          <Typography>{t('share-capital')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>{company.shareCapital}</Typography>
        </Grid>
      </Grid>
      {/* registered address */}
      <Grid item container>
        <Grid item xs={4}>
          <Typography>{t('registered-address')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>
            {generateAddress(company.registeredAddress, 'en')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={4}>
          <Typography>{t('geographical-coordinate')}</Typography>
        </Grid>
        <Grid item container xs={8}>
          <Grid item xs={4}>
            <Typography>{t('latitude')}: {company?.geographicalCoordinate?.latitude || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>{t('longitude')}: {company?.geographicalCoordinate?.longitude || 'N/A'}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={4}>
          <Typography>{t('estimate-total-number-of-employee')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>
            {`${company?.employee?.totalEmployee} (Female: ${company?.employee?.femaleEmployee})`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={4}>
          <Typography>{t('number-of-foreigner-employee')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>
            {`${company?.employee?.foreignerEmployee} (Female: ${company?.employee?.femaleForeignerEmployee})`}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={4}>
          <Typography>{t('total-employee-salary')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>
            { company?.totalEmployeeSalary }
          </Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={4}>
          <Typography>{t('weekly-holiday')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>
            { company?.weeklyHoliday }
          </Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs={4}>
          <Typography>{t('number-of-working-per-day-or-week')}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography>
            { company?.numberOfWorkingPerDayOrWeek }
          </Typography>
        </Grid>
      </Grid>
      
    </Grid>
  );
};

export default CompanyInfo;
