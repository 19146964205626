import React from 'react';
import {
	Box,
	Grid,
	List,
	ListItem,
	makeStyles,
	Paper,
	Typography,
	CircularProgress,
} from '@material-ui/core';

import NotificationLine from './notification-line';

const useStyles = makeStyles(theme => ({
	rootPaper: {
		padding: theme.spacing(2),
		background: 'white',
	},
}));

const NotificationCard = ({ notifications, setNotifications, isLoading, handleMarkAllAsRead }) => {
	const classes = useStyles();

	return (
		<Paper className={classes.rootPaper}>
			{/* top part */}
			<Grid container spacing={1}>
				<Grid item xs={12} container alignItems='center'>
					<Grid item xs={6}>
						<Typography variant='h5'>Notifications</Typography>
					</Grid>
					<Grid item xs={6} container justifyContent='flex-end'>
						<Typography component='a' variant='body2' href='' onClick={handleMarkAllAsRead}>
							Mark all as read
						</Typography>
					</Grid>
				</Grid>
				{/* notification listings */}
				<Grid item xs={12}>
					{isLoading ? (
						<Box display='flex' alignItems='center' justifyContent='center'>
							<CircularProgress size={20} />
						</Box>
					) : notifications.length === 0 ? (
						<Typography variant='body2' align='center' color='textSecondary'>
							No Notification
						</Typography>
					) : (
						<List disablePadding>
							{notifications.map((notification, index) => (
								<ListItem key={index} disableGutters>
									<NotificationLine notification={notification} setNotifications={setNotifications} />
								</ListItem>
							))}
						</List>
					)}
				</Grid>
			</Grid>
		</Paper>
	);
};

export default React.memo(NotificationCard);
