import { useContext } from 'react';
import { UserContext } from '../../components/context-provider/user-context-provider';
import AccessControl from '../../components/reusable/access-control';

const usePermission = () => {
  const { checkAccess } = useContext( UserContext );

  const checkPermission = ( { operator = 'or', permissionsAndFeatures } ) => {
    if ( operator.toLowerCase() === 'and' ) {
      const haveAccess = permissionsAndFeatures.reduce(
        ( canAccess, currentValue ) => checkAccess( currentValue ) && canAccess,
         true,
      );
      return haveAccess;
    }
    if ( operator.toLowerCase() === 'or' ) {
      const haveAccess = permissionsAndFeatures.reduce(
        ( canAccess, currentValue ) => checkAccess( currentValue ) || canAccess,
        false,
      );
      return haveAccess;
    }
  };

  return { checkPermission, AccessControl };
};

export default usePermission;
