const convertPdfToBase64 = file => new Promise( resolve => {
    var fileReader = new FileReader();
    fileReader.onload = fileLoadedEvent => {
        let result = fileLoadedEvent.target.result;

        resolve( result );
    };

    fileReader.readAsDataURL( file );
});

export default convertPdfToBase64;