import { makeStyles } from '@material-ui/core';

// custom style table

const useStyles = makeStyles(() => ({
  root: {
    '& .tableX th': {
      fontWeight: 'bold',
      textWrap: 'no-wrap',
      textAlign: 'center',
      textTransform: 'capitalize',
      '& span': {
        whiteSpace: 'nowrap',
      },
    },
    '& .tableX td': {
      // border: 0,
      textAlign: 'center',
      cursor: 'pointer',
    },
    // '& .tableX tr:nth-child(even)': {
    //   '& td': {
    //     background: '#f1f8f9'
    //   },
    // },
    '& .tableX table': {
      marginTop: '40px',
    },
    // action type cell
    '& .tableX-action-cell': {
      textDecoration: 'underline',
      color: 'blue',
      cursor: 'pointer',
    },
    '& .MuiTableCell-root': {
      padding: '10px',
    }
  },
}));

export default useStyles;
