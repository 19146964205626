// Third party's imports
import React,{useState} from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';
// import mime from 'mime-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

// Local imports
import TableX from '../tableX';
import config from '../tableX/config';
import StatusChip from '../status-chip';
import DashboardTitle from '../Title';
import DialogPopup from '../../views/Company/ReserveCompanyName/dialog-popup';
import ActionIconButton from '../action-icon-button'
import AccessControl from '../reusable/access-control';
import usePermission from '../../hooks/use-permission';

const ReserveCompany = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { checkPermission } = usePermission();
  const updateReservedCompanyPermission = checkPermission({
    operator: 'and',
    permissionsAndFeatures: [{ feature: 'reserve-company', action: 'update' }],
  });

  const [openCreateReserveCompanyDialog, setOpenCreateReserveCompanyDialog] = useState(false);
  const [triggerFetch, setTriggerFetch] = useState(false);

  const handleActionButton = (data) => {
    const { _id } = data
    switch (data.currentStatus.name) {
      case 'pending': {
        return history.push(`/dashboard/company-status/reserved-company/reserve-history/${_id}`)
      }
      case 'accepted': {
        return history.push(`/dashboard/company-status/new/${_id}`)
      }
      case 'rejected': {
        return (
          history.push(`/dashboard/company-status/reserved-company/re-reserve/${_id}`)
        )
      }
      default:
        return history.push('/');
      }
    }

  const handleShowHistory = (data) => {
    const { _id,  currentStatus } = data;
    history.push({ pathname: `/dashboard/company-status/reserved-company/reserve-history/${_id}`, status: currentStatus })
  }

  const handleReserveCompany = () => setOpenCreateReserveCompanyDialog(true);

  // const getAttachmentHandler = async (data) => {
  //   const { url, mimeType }  = data.feedback?.attachment
  //   const filename = `${data.companyNameLatin}.${mime.extension(mimeType)}`

  //   // Create Dummy A Element
  //   let a = document.createElement('a');

  //   const fetched = await fetch(url).then(res => res.blob());
  //   const href = URL.createObjectURL(fetched);

  //   a.href = href;
  //   a.download = filename;

  //   // Download file and remove dummy element
  //   document.body.appendChild(a);
  //   a.click();
  //   a.remove();
  // }

  // function for checking whether key exist in obj
  // const isEmpty = (obj) => {
  //   for (var key in obj) {
  //     // checking key exist
  //     if (obj.hasOwnProperty(key)) return false; // if key exist
  //   }
  //   return true; // if no key exist
  // }

  const tableSchema = [
    {
      id: 'no',
      label: t('no.'),
      type: 'numbering',
      sort: false,
    },
    { id: 'companyNameLatin', label: t('company-name') },
    {
      id: 'created-at',
      label: t('created-at'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return (
          <Typography>{moment(data?.createdAt).format('DD/MM/YYYY')}</Typography>
        )
      }
    },
    {
      id: 'created-by',
      label: t('created-by'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        const { createdBy } = data
        return (
            <Typography>{createdBy?.nameLatin}</Typography>
        )
      }
    },
    // { 
    //   id: 'get-attachment', 
    //   label: t('attachment'),
    //   type: 'component',
    //   dataConfig: [],
    //   components: (data) => {
    //     let isDisabled = isEmpty(data?.feedback?.attachment);
    //     return (
    //       <Chip
    //         label={ isDisabled ? 'N/A' : 'Get Document' }
    //         color='primary'
    //         style={{ width: '140px' }}
    //         disabled={isDisabled}   
    //         onClick={(e)=>{
    //           e.stopPropagation();
    //           getAttachmentHandler(data)
    //         }}
    //       />
    //     )
    //   }
    // },
    {
      id: 'currentStatus',
      label: t('status'),
      type: 'component',
      dataConfig: [],
      components: ( data ) => {
        return (
          <StatusChip currentStatus={data?.currentStatus?.name} />
        )
      }
    },
    {
      id: 'component',
      sort: false,
      label: t('action'),
      type: 'component',
      dataConfig: [],
      components: ( data ) => {
        return (
          <>
            <ActionIconButton
              variant={data?.currentStatus?.name}
              disabled={!updateReservedCompanyPermission}
              onClick={e => {
                e.stopPropagation();
                handleActionButton(data)
              }}
            />
            <ActionIconButton
              variant='view'
              onClick={e => {
                e.stopPropagation();
                handleShowHistory(data);
              }}
            />
          </>
        )
      }
    },
  ];

  return (
    <>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <DashboardTitle title="reserve-company" alignStart />
          <AccessControl feature='reserve-company' action='create'>
            <Button
              color='primary'
              variant="contained"
              onClick={handleReserveCompany}
            >
              Create Company
            </Button>
          </AccessControl>
        </Box>
        {/* create reserve company dialog */}
        <DialogPopup 
          open={openCreateReserveCompanyDialog}
          setOpen={setOpenCreateReserveCompanyDialog}
          setTriggerFetch={setTriggerFetch}
        />
        <TableX
            onRowClick={handleShowHistory}
            triggerFetch={triggerFetch}
            config={tableConfig}
            schema={tableSchema}
        />
      </Grid>
    </>
  )
}

const tableConfig = {
    ...config,
    url: `${process.env.REACT_APP_API_URL}/v1/companies/reserve`,
    dataConfig: ['company'],
};

export default ReserveCompany
