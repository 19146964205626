import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		justifyContent: 'start',
		flexWrap: 'wrap',
		listStyle: 'none',
		padding: theme.spacing(0.5),
		margin: 0,
	},
	chipContainer: {
		margin: `${theme.spacing(0.5)}px 0px`,
	},
	chipRoot: {
		margin: theme.spacing(1),
		height: '100%',
		display: 'flex',
	},
	chipLabel: {
		overflowWrap: 'break-word',
		whiteSpace: 'normal',
		textOverflow: 'clip',
	},
}));

const FilePreview = ({ files, isPreviewMode, handleRemove }) => {
	const classes = useStyles();
	return (
		<div component='ul' className={classes.root}>
			{files?.files?.map((file, index) => {
				return (
					<li key={index} className={classes.chipContainer}>
						{file?.file?.name ? (
							<Chip
								label={file.file?.name}
								classes={{ root: classes.chipRoot, label: classes.chipLabel }}
								onDelete={() => {
									handleRemove(index);
								}}
							/>
						) : (
							<Chip
								classes={{ root: classes.chipRoot, label: classes.chipLabel }}
								label={file?.key.substring(file?.key.lastIndexOf('/') + 1, file?.key.length)}
								component='a'
								href={file?.url}
								target='_blank'
								rel='noopener'
								clickable
								onDelete={
									isPreviewMode
										? null
										: e => {
												e.preventDefault();
												handleRemove(index);
										  }
								}
							/>
						)}
					</li>
				);
			})}
		</div>
	);
};

export default FilePreview;
