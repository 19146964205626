import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import swal from 'sweetalert2';
import { useHistory } from 'react-router';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, makeStyles, Typography, TextField, RadioGroup, FormControlLabel, FormControl, Radio, Button, Checkbox } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';

import browserHistroy from '../../history';
import ProvinceInfo from '../../utils/ProvinceInfo';
import Title from '../../components/Title/index';
import { onlyEnglishCharacter, onlyKhmerCharacter } from '../../utils/functions/validateString'

const useStyles = makeStyles((theme)=>({
    rootContainer:{
        position: 'relative',
        height: '100vh',
        width: '100%',
        background: "url(https://images.unsplash.com/photo-1507679799987-c73779587ccf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1471&q=80) no-repeat center center/cover",
    },
    container: {
        backgroundColor: 'white',
        borderRadius: '5px',
        maxWidth: '720px',
        padding: '1rem 2rem',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        [theme.breakpoints.down('xs')]: {
            borderRadius: '0rem',
            boxShadow: '0px 0px 0px',
            border: '0px',
            padding: '3rem 1rem',
            paddingTop: '5rem',
        },
    },
}))

const SignupForm = () => {
    const classes = useStyles();
    const history = useHistory();
    const { register, handleSubmit, watch, control, setValue, formState:{errors} } = useForm({
        defaultValues: {
            address: {
                housenumber: null,
                street: null,
                city: null,
                district: null,
                commune: null,
                village: null,
                isManual:false
            }
        }
    });

    const addressManual = watch('address.isManual');
    const address = watch('address');


    const [ idOrPassport, setIdOrPassport ] = useState('id');

    const radioChangeHandler = (e) => {
        setIdOrPassport(e.target.value)
    }
    
    const onSubmit = data => {
        swal.fire({
            text: 'Saving...',
            allowOutsideClick: false,
            onOpen: () => {
                swal.showLoading();
            }
        });

        // `JWT ${ localStorage.getItem('access_token') }`
        axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/users`, data).then( response => {
            swal.fire({
                text: 'Saved',
                timer: 1000,
                icon: 'success',
                onOpen: () => {
                    swal.hideLoading()
                }
            }).then( () => {
                //const link = document.getElementById('closeButton');
                //link.click();
                browserHistroy.goBack();
            });
        }).catch( err => {
            console.error( err.response );
            swal.fire({
                text: err.response.data.message,
                icon: 'error',
                onOpen: () => {
                    swal.hideLoading()
                }
            });
        });
    }

    
    return (    
        <Grid 
            container 
            justifyContent='center'
            alignItems='center' 
            className={classes.rootContainer}
        >
            <Grid item container className={classes.container}>
                {/* Title */}
                <Grid item container xs={12} justifyContent="center"  >
                    <Title title="Create a new user" />
                </Grid>
               {/* <Grid item container > */}
                    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', padding: '1rem 0', }}>
                        <Grid container spacing={1}>
                            <Grid item container xs={12} direction="row" justifyContent="space-between">
                                <Grid item container xs={5} alignItems="center">
                                    <Typography>
                                        Full Name (Latin)
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField
                                        variant="outlined"
                                        label='Name (Latin)'
                                        type="text"
                                        fullWidth
                                        { ...register("nameLatin", {
                                            required: true,
                                            validate: value => onlyEnglishCharacter({ value })
                                        })}
                                        error={!!errors?.nameLatin}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} direction="row" justifyContent="space-between">
                                <Grid item container xs={5} alignItems="center">
                                    <Typography>
                                        Full Name (Khmer)
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField 
                                        variant="outlined" 
                                        label='Name (Khmer)'
                                        type="text"
                                        fullWidth
                                        { ...register("nameKhmer", {
                                            required: true,
                                            validate: value => onlyKhmerCharacter({ value })
                                        })}
                                        error={!!errors?.nameKhmer}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} direction="row" justifyContent="space-between">
                                <Grid item container xs={5} alignItems="center">
                                    <Typography>
                                        Email
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField 
                                        variant="outlined"
                                        label='Email'
                                        type="email"
                                        fullWidth
                                        { ...register("email", {
                                            required: true,
                                            validate: value => {
                                                const email = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(value);
                                                return email;
                                            },
                                        })}
                                        error={!!errors.email}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} direction="row" justifyContent="space-between">
                                <Grid item container xs={5} alignItems="center">
                                    <Typography>
                                        Password
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField 
                                        variant="outlined"
                                        label='Password'
                                        type="password"
                                        { ...register("password", {
                                            required: true,
                                        }) }
                                        fullWidth
                                        size="small"
                                        error={!!errors.password}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} direction="row" justifyContent="space-between">
                                <Grid item container xs={5} alignItems="center">
                                    <Typography>
                                        Confirm Password
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <TextField 
                                        variant="outlined" 
                                        name='confirmPassword'
                                        label='Confirm Password'
                                        type="password"
                                        fullWidth
                                        error={!!errors.confirmPassword}
                                        { ...register("confirmPassword",{
                                            required: true,
                                            validate: (value) => {
                                                return value === watch('password')
                                            }
                                        }) }
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                            {/* <Grid item container xs={12} direction="row" justifyContent="space-between">
                                <Grid item container xs={5} alignItems="center">
                                    <Typography>
                                        6. Role
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Controller
                                        control={control}
                                        as={TextField}
                                        name='role'
                                        fullWidth
                                        select
                                        variant='outlined'
                                        margin='dense'
                                        value=''
                                        defaultValue=''
                                        InputProps={{
                                            className: classes.input
                                        }}
                                    
                                    >
                                        <MenuItem value='Admin'>Admin</MenuItem>
                                        <MenuItem value='Stuff'>Stuff</MenuItem>
                                    </Controller>
                                </Grid>
                            </Grid> */}
                            <Grid item container xs={12} direction="row" justifyContent="space-between">
                                <Grid item container xs={5} style={{paddingTop: '0.5rem'}}>
                                    <Typography>
                                        ID/Passport
                                    </Typography>
                                </Grid>
                                <Grid item container xs={7}  >
                                    <Grid item xs={12}>
                                        <FormControl component="fieldset">  
                                            <RadioGroup
                                                row
                                                aria-label="gender"
                                                name="controlled-radio-buttons-group"
                                                value={idOrPassport}
                                                onChange={radioChangeHandler}
                                            >
                                                <FormControlLabel value="id" control={<Radio color="primary" />} label="ID Card" />
                                                <FormControlLabel value="passport" control={<Radio color="primary" />} label="Passport" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6} style={{paddingRight:'5px'}}>
                                        <TextField 
                                            variant="outlined" 
                                            label={idOrPassport==='id' ? 'ID Card Number':'Passport Number'}
                                            type="text"
                                            fullWidth
                                            { ...register("idOrPassport.number",{
                                                required: true
                                            }) }
                                            size="small"
                                            error={!!errors?.idOrPassport?.number}
                                        />
                                    </Grid>
                                    <Grid item container xs={6} style={{paddingLeft:'5px'}} >
                                    <Grid item xs={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <Controller
                                                control={control}
                                                defaultValue={new Date()}
                                                rules={{
                                                    validate: value => moment(value).isValid()
                                                }}
                                                name="idOrPassport.issuedDate"
                                                render={({ field:{ value, onChange }, fieldState: { error } })=>(
                                                    <KeyboardDatePicker
                                                        className={classes.root}
                                                        initialFocusedDate={new Date()}
                                                        autoOk
                                                        label='Issued Date'
                                                        variant="inline"
                                                        inputVariant="outlined"
                                                        size="small"
                                                        format="dd/MM/yyyy"
                                                        margin="none"
                                                        value={value}
                                                        onChange={(date) => onChange(date)}
                                                        KeyboardButtonProps={{
                                                            style: {
                                                            outline: "none",
                                                            },
                                                        }}
                                                        error={!!error}
                                                    />
                                                )}
                                            />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                            <Grid item container xs={12} direction="row" justifyContent="space-between">
                                <Grid item container xs={5} alignItems="flex-start">
                                    <Typography>
                                        Current address
                                    </Typography>
                                </Grid>
                                {/* Current address section */}
                                <Grid item container xs={7}>
                                    <Grid item container xs={6} style={{padding: '0 5px 8px 0'}}>
                                        <TextField 
                                            variant="outlined" 
                                            disabled={ address.isManual }
                                            label='No.'
                                            type="text"
                                            fullWidth
                                            { ...register("address.housenumber", {
                                                required: false
                                            }) }
                                            size="small"
                                        />
                                    </Grid>
                                     <Grid item container xs={6} style={{padding: '0 0 8px 5px'}}>
                                        <TextField 
                                            variant="outlined" 
                                            disabled={ address.isManual }
                                            label='Street'
                                            type="text"
                                            fullWidth
                                            { ...register("address.street", {
                                                required: false
                                            }) }
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{padding: '0 5px 8px 0'}}>
                                        <Controller
                                            render={( {field: { onChange, value }}) => (
                                                <Autocomplete
                                                    id="city"
                                                    value={value}
                                                    disabled={ addressManual }
                                                    options={ProvinceInfo.generateCityOptions( address )}
                                                    getOptionLabel={(option) => option.en}
                                                    getOptionSelected={(option, value) => option.value === value.value}
                                                    onChange={(e, data) => {
                                                        onChange(data);
                                                        setValue('address.district', null);
                                                        setValue('address.commune', null);
                                                        setValue('address.village', null);
                                                        setValue('nameKhmer', null);

                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="City" variant="outlined" size="small" />}
                                                />
                                            )}
                                            control={ control }
                                            name="address.city"
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{padding: '0 0 8px 5px'}}>
                                        <Controller
                                            render={({ field: { onChange, value } })=>(
                                                <Autocomplete
                                                    id="district"
                                                    value={value}
                                                    disabled={ addressManual }
                                                    options={ProvinceInfo.generateDistrictOptions( address )}
                                                    getOptionLabel={(option) => option.en}
                                                    getOptionSelected={(option, value) => option.value === value.value}
                                                    onChange={(e, data) => {
                                                        onChange(data);
                                                        setValue('address.commune', null);
                                                        setValue('address.village', null);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="District" variant="outlined" size="small"/>}
                                                />
                                            )}
                                            control={ control }
                                            name="address.district"
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{padding: '0 5px 8px 0'}}>
                                        <Controller
                                            render={({ field: { onChange, value } })=>(
                                                <Autocomplete
                                                    id="commune"
                                                    value={value}
                                                    disabled={ addressManual }
                                                    options={ProvinceInfo.generateCommuneOptions( address )}
                                                    getOptionLabel={(option) => option.en}
                                                    getOptionSelected={(option, value) => option.value === value.value}
                                                    onChange={(e, data) => {
                                                        onChange(data)
                                                        setValue('address.village', null);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} label="Commune" variant="outlined" size="small" />}
                                                />
                                            )}
                                            control={ control }
                                            name="address.commune"
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{padding: '0 0 8px 5px'}}>
                                        <Controller
                                            render={({ field: { onChange, value } })=>(
                                                <Autocomplete
                                                    id="village"
                                                    value={value}
                                                    options={ProvinceInfo.generateVillageOptions( address )}
                                                    disabled={ addressManual }
                                                    getOptionLabel={(option) => option.en}
                                                    getOptionSelected={(option, value) => option.value === value.value}
                                                    onChange={( e, data ) => onChange( data )}
                                                    renderInput={(params) => <TextField {...params} label="Village" variant="outlined" size="small" />}
                                                />
                                            )}
                                            control={ control }
                                            name="address.village"
                                        />
                                    </Grid> 

                                    {/* Insert address manual section */}
                                    <Grid item container style={{ display: address.isManual? '' : 'none' }}>
                                        <TextField 
                                            variant="outlined" 
                                            placeholder='Full Address'
                                            name='address.fullAddress'
                                            { ...register("address.fullAddress",{ required: watch('address.isManual') ? true : false }) }
                                            type="text"
                                            fullWidth
                                            multiline
                                            size="small"
                                            error={!!errors.address?.fullAddress}
                                        />
                                    </Grid>
                                    {/* manual address checkbox */}
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    type='checkbox'
                                                    id="addressManual"
                                                    { ...register("address.isManual") }
                                                />
                                            }
                                            label="Insert Address Manually"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{padding: '1rem 0'}}>
                            <Button
                                type='submit'
                                variant='contained'
                                color='primary'
                                fullWidth
                            >
                                Sign Up
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                fullWidth
                                onClick={() => history.goBack()}
                                style={{color: '#184782'}}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </form>
            </Grid>
        </Grid>
    )
};

export default SignupForm;