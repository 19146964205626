// Third party's imports
import React, { useEffect } from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { Grid, Typography, TextField, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider, KeyboardDatePicker  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router';
import axios from 'axios'

// Local imports
import ProvinceInfo from '../../utils/ProvinceInfo';
import { inputNumberOnly } from '../../utils';
import { isEmail, onlyEnglishCharacter, onlyKhmerCharacter } from '../../utils/functions/validateString'
import { RHFTextInput } from '../rhf-controlled-input';

const useStyles = makeStyles ({
    root: {
        "& .MuiIconButton-root": {
            padding: 0,
        }
    }
});

const weeklyHoliday = [
    'Saturday and Sunday',
    'Sunday',
    'One day by taking turn'
]

const numberOfWorkingPerDayOrWeek = [
    '8 hours per day'
]

const formsOfBusiness = [
    'Private Limited Company (Co., Ltd)',
    'Public Limited Company (Plc)'
];

const CompanyInfo = ( props ) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const controller = new AbortController();
    const { control, setValue, reset, trigger } = useFormContext();
    const { id } = useParams();

    // file upload of international firm 
    const { setFiles, setBusinessFiles } = props;

    const employee = useWatch({ name: 'employee' });
    const registeredAddress = useWatch({ name: 'registeredAddress' });
    const registeredAddressRental = useWatch({ name: 'registeredAddress.isRental' });    

    useEffect(() => {
        if (id) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/v1/companies/reserve/${id}`, { signal: controller.signal })
                .then(({ data }) => {
                    const companyDetail = data?.companyDetail;
                    reset(companyDetail); // set company detail data to form
                    const { legalParticular, businessActivity } = companyDetail;

                    // get file url for business activity and set to businessFiles state
                    const businessFiles = businessActivity?.map(item => {
                        if (item.hasOwnProperty('fileAttachment')) {
                            return { files: item.fileAttachment, remove: [] };
                        }
                        return { files: [], remove: [] };
                    })
                    setBusinessFiles(businessFiles);

                    // get file url for legal entity
                    const legalFiles = legalParticular?.map(item => {
                        if (item.hasOwnProperty('fileAttachment')) {
                            return { files: item.fileAttachment, remove: [] };
                        }
                        return { files: [], remove: [] };
                    })
                    setFiles(legalFiles)
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        console.log('cancel request')
                    }
                    console.log('error get company detail :>> ', error)
                });
        }
        return () => {
            controller.abort();
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={2}>
                <Typography color='primary' paragraph style={{ fontWeight: 'bold' }}>I. {t('information-for-registration')}</Typography>
                <Grid item container xs={12} direction="row" justifyContent="space-between">
                    <Grid item container xs={5}>
                        <Typography>
                            {t('company-name-khmer')}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Controller
                            control={control}
                            rules={{ 
                                required: true, 
                                validate: (value) => onlyKhmerCharacter( { value } ),
                            }}
                            defaultValue=""
                            name="companyNameKhmer"
                            render={({field: {onChange, value, ref}, fieldState: {error}}) => {
                            return (
                                <TextField
                                    variant="outlined"
                                    label={t('company-name-khmer-label')}
                                    type="text"
                                    fullWidth
                                    size="small"
                                    inputRef={ref}
                                    onChange={( e ) => { 
                                        onChange( e );
                                        // trigger validate on every input
                                        trigger("companyNameKhmer");
                                    }}
                                    value={value || ''}
                                    error={!!error}
                                    helperText={ error?.message }
                                />
                            )}}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} direction="row" justifyContent="space-between">
                    <Grid item container xs={5}>
                        <Typography>
                            {t('company-name-latin')}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Controller
                            control={control}
                            render={({field: {value, onChange, ref}, fieldState: {error}}) => (
                                <TextField
                                    variant="outlined"
                                    label={t('company-name-latin-label')}
                                    type="text"
                                    fullWidth
                                    inputRef={ref}
                                    size="small"
                                    value={value || ''}
                                    onChange={( e ) => { 
                                        onChange( e );
                                        // trigger validate on every input
                                        trigger("companyNameLatin");
                                    }}
                                    error={!!error}
                                    helperText={ error?.message }
                                />
                            )}
                            name="companyNameLatin"
                            defaultValue=""
                            rules={{
                                required: true,
                                validate: (value) => onlyEnglishCharacter( { value } ),
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} direction="row" justifyContent="space-between">
                    <Grid item container xs={5} alignItems="center">
                        <Typography>
                            {t('form-of-business')}
                        </Typography>
                    </Grid>
                    <Grid item container xs={7}>
                        <Controller
                            render={({ field: { onChange, value }, fieldState: {error} }) => (
                                <Autocomplete
                                    autoHighlight
                                    value={value}
                                    options={formsOfBusiness}
                                    getOptionLabel={(option) => option}
                                    getOptionSelected={(option, value) => {
                                        if (value === "") {
                                            return true;
                                        } else if (value === option) {
                                            return true;
                                        }
                                    } }
                                    onChange={(e, newValue) => onChange(newValue)}
                                    style={{ width: '100%'}}  
                                    renderInput={(params) => <TextField 
                                        {...params} 
                                        label={t("form-of-business-label")} 
                                        variant="outlined" 
                                        size="small" 
                                        fullWidth 
                                        error={!!error}
                                    />}
                                />
                            )}
                            control={ control }
                            defaultValue={''}
                            name="formOfBusiness"
                            rules={{ required: true }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} direction="row" justifyContent="space-between">
                    <Grid item xs={5}>
                        <Typography>
                            {t('share-capital')}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Controller
                            control={control}
                            name="shareCapital"
                            defaultValue=''
                            rules={{ required: true, validate: value => value > 0 }}
                            render={({field: {value, onChange}, fieldState: {error}}) => (
                                <TextField
                                    variant="outlined"
                                    label={t('share-capital-label')}
                                    type="text"
                                    fullWidth
                                    value={value || ''}
                                    onChange={onChange}
                                    size="small"
                                    error={!!error}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} direction="row" justifyContent="space-between">
                    <Grid item xs={5}>
                        <Typography>
                            {t('email')}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <RHFTextInput
                            name='email'
                            control={control}
                            required
                            label={t('email-label')}
                            rules={{
                                validate: v => isEmail(v),
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} direction="row" justifyContent="space-between">
                    <Grid item xs={5}>
                        <Typography>
                            {t('mobile-phone-number')}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <RHFTextInput
                            name='mobilePhoneNumber'
                            control={control}
                            required
                            label={t('mobile-phone-number-label')}
                            rules={{
                                minLength: 8,
                                maxLength: 10
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} direction="row" justifyContent="space-between">
                    <Grid item xs={5}>
                        <Typography>
                            {t('office-telephone-number')}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <RHFTextInput
                            name='officeTelephoneNumber'
                            control={control}
                            required
                            label={t('office-telephone-number-label')}
                            rules={{
                                minLength: 8,
                                maxLength: 10
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} direction="row" justifyContent="space-between">
                    <Grid item container xs={5}>
                        <Typography>
                            {t('register-address')}
                        </Typography>
                    </Grid>
                    <Grid item container xs={7}>
                        <Grid item xs={6} style={{padding: '0 8px 16px 0'}}>
                            <Controller
                                control={control}
                                rules={{ required: true }}
                                name="registeredAddress.homeNumber"
                                defaultValue=""
                                render={({field: {value, onChange}, fieldState: {error}}) => (
                                    <TextField
                                        variant="outlined"
                                        label={t('No')}
                                        type="text"
                                        fullWidth
                                        value={value || ''}
                                        onChange={onChange}
                                        size="small"
                                        error={!!error}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} style={{padding: '0 0 16px 8px'}}>
                            <Controller
                                control={control}
                                rules={{ required: true }}
                                name="registeredAddress.street"
                                defaultValue=""
                                render={({field: {value, onChange}, fieldState: {error}}) => (
                                    <TextField
                                        variant="outlined"
                                        label={t('street')}
                                        type="text"
                                        fullWidth
                                        value={value || ''}
                                        onChange={onChange}
                                        size="small"
                                        error={!!error}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} style={{padding: '0 8px 16px 0'}}>
                            <Controller
                                render={( {field: { value, onChange }, fieldState: {error}}) => (
                                    <Autocomplete
                                        autoHighlight
                                        value={value || null}
                                        options={ProvinceInfo.generateCityOptions()}
                                        getOptionLabel={(option) => {
                                            return option.en ? option.en : null
                                        }}
                                        getOptionSelected={(option, value) => {
                                            if (value === null) { return true; }
                                            else if (value.value === option.value) { return true; }
                                        } }
                                        onChange={( e, data ) => {
                                            // onChange(data.en)
                                            onChange( data )
                                            setValue('registeredAddress.district', null)
                                            setValue('registeredAddress.commune', null)
                                            setValue('registeredAddress.village', null)
                                        } }
                                        renderInput={(params) => <TextField 
                                            {...params} 
                                            label={t('city')} 
                                            variant="outlined" 
                                            size="small" 
                                            error={!!error}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                        />}
                                    />
                                )}
                                control={ control }
                                name="registeredAddress.city"
                                defaultValue={null}
                                rules={{ required: true }}
                            />
                        </Grid>
                        <Grid item xs={6} style={{padding: '0 0 16px 8px'}}>
                            <Controller
                                render={({ field: { value, onChange }, fieldState: {error} })=>(
                                    <Autocomplete
                                        autoHighlight
                                        value={value || null}
                                        options={ProvinceInfo.generateDistrictOptions( registeredAddress )}
                                        getOptionLabel={(option) => option ? option.en : null}
                                        getOptionSelected={(option, value) => {
                                            if (value === null) { return true; }
                                            else if (value.value === option.value) { return true; }
                                        } }
                                        onChange={( e, data ) => {
                                            onChange( data )
                                            setValue('registeredAddress.commune', null)
                                            setValue('registeredAddress.village', null)
                                        }}
                                        renderInput={(params) => <TextField 
                                            {...params} 
                                            label={t("district")} 
                                            variant="outlined" 
                                            size="small" 
                                            error={!!error}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password',
                                            }}
                                        />}
                                        disabled={!registeredAddress.city}
                                    />
                                )}
                                control={ control }
                                name="registeredAddress.district"
                                defaultValue={null}
                                rules={{ required: true }}
                            />
                        </Grid>
                        <Grid item xs={6} style={{padding: '0 8px 16px 0'}}>
                            <Controller
                                render={({ field: { value, onChange }, fieldState: {error} })=>(
                                    <Autocomplete
                                        autoHighlight
                                        value={value || null}
                                        options={ProvinceInfo.generateCommuneOptions( registeredAddress )}
                                        getOptionLabel={(option) => option ? option.en : null}
                                        getOptionSelected={(option, value) => {
                                            if (value === null) { return true; }
                                            else if (value.value === option.value) { return true; }
                                        } }
                                        onChange={( e, data ) => {
                                            onChange( data )
                                            setValue('registeredAddress.village', null)
                                        }}
                                        renderInput={(params) => 
                                            <TextField 
                                                {...params} 
                                                label={t("commune")} 
                                                variant="outlined" 
                                                size="small" 
                                                error={!!error} 
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password',
                                                }}
                                            />}
                                        disabled={!registeredAddress.district}
                                    />
                                )}
                                control={ control }
                                name="registeredAddress.commune"
                                defaultValue={null}
                                rules={{ required: true }}
                            />
                        </Grid>
                        <Grid item xs={6} style={{padding: '0 0 16px 8px'}}>
                            <Controller
                                render={({ field: { value, onChange }, fieldState: {error} })=>(
                                    <Autocomplete
                                        autoHighlight
                                        value={value || null}
                                        options={ProvinceInfo.generateVillageOptions( registeredAddress )}
                                        getOptionLabel={(option) => option ? option.en : null}
                                        getOptionSelected={(option, value) => {
                                            if (value === null) { return true; }
                                            else if (value.value === option.value) { return true; }
                                        } }
                                        onChange={( e, data ) => onChange( data )}
                                        renderInput={(params) => 
                                            <TextField 
                                                {...params} 
                                                label={t("village")} 
                                                variant="outlined" 
                                                size="small" 
                                                error={!!error} 
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password',
                                                }}
                                            />}
                                        disabled={!registeredAddress.commune}
                                    />
                                )}
                                control={ control }
                                name="registeredAddress.village"
                                defaultValue={null}
                                rules={{required: true}}
                            />
                        </Grid> 
                        <Grid item xs={6} style={{padding: '0 8px 16px 0'}}>
                            <Controller
                                control={control}
                                name="registeredAddress.group"
                                defaultValue=''
                                render={({field: {value, onChange}, fieldState: {error}}) => (
                                    <TextField
                                        variant="outlined"
                                        label={t('group')}
                                        type="text"
                                        fullWidth
                                        value={value || ''}
                                        onChange={onChange}
                                        size="small"
                                        error={!!error}
                                    />
                                )}
                            />
                        </Grid> 
                        <Grid item xs={12}>
                                <Controller
                                    name="registeredAddress.isRental"
                                    control={control}
                                    defaultValue={null}
                                    render={({ field:{value,onChange} }) => (
                                        <RadioGroup row aria-label="isRental" value={value} onChange={onChange} >
                                            <Grid item container spacing={2}> 
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        style={{ marginBottom: 0 }}
                                                        value={'false'}
                                                        control={<Radio size='small' color='primary' />}
                                                        label={t('ownership')}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Controller
                                                        control={control}
                                                        name="registeredAddress.propertyIdentificationNumber"
                                                        defaultValue=''
                                                        render={({field: {value, onChange}, fieldState: {error}}) => (
                                                            <TextField
                                                                variant="outlined"
                                                                label={t('property-registration-number')}
                                                                type="text"
                                                                fullWidth
                                                                value={registeredAddressRental === 'false' ? value : '' }
                                                                onChange={onChange}
                                                                size="small"
                                                                error={!!error}
                                                                disabled={registeredAddressRental === 'true'}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Controller
                                                    control={control}
                                                    name="registeredAddress.issuedDate"
                                                    defaultValue={null}
                                                    render={({ field: {value, onChange}, fieldState: {error} })=>(
                                                        <KeyboardDatePicker
                                                            className={classes.root}
                                                            autoOk
                                                            label={`${t('ចុះថ្ងៃទី')}`}
                                                            variant="inline"
                                                            inputVariant="outlined"
                                                            size="small"
                                                            format="dd/MM/yyyy"
                                                            margin="none"
                                                            value={value}
                                                            onChange={onChange}
                                                            KeyboardButtonProps={{
                                                                style: {
                                                                    outline:'none',
                                                                },
                                                            }}
                                                            error={!!error}
                                                            disabled={registeredAddressRental === 'true'}
                                                        />
                                                    )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        style={{ marginBottom: 0 }}
                                                        value={'true'}
                                                        control={<Radio size='small' color='primary' />}
                                                        label={t('rent')}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Controller
                                                        control={control}
                                                        name="registeredAddress.rentalPrice"
                                                        defaultValue=''
                                                        rules={{ required: registeredAddressRental === 'true' }}
                                                        render={({field: {value, onChange}, fieldState: {error}}) => (
                                                            <TextField
                                                                variant="outlined"
                                                                label={t('branch-rental-fee')}
                                                                fullWidth
                                                                onKeyPress={inputNumberOnly}
                                                                value={registeredAddressRental === 'true' ? value : '' }
                                                                disabled={ registeredAddressRental === 'false' }
                                                                onChange={onChange}
                                                                size="small"
                                                                error={!!error}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </RadioGroup>)
                                    }
                                />                        
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xs={5}>
                        <Typography>
                            {t('geographical-coordinate')}
                        </Typography>
                    </Grid>
                    <Grid item container xs={7}>
                        <Grid item xs={6} style={{ padding:'0 8px 0 0'}}>
                            <RHFTextInput
                                name='geographicalCoordinate.latitude'
                                control={control}
                                required
                                label={t('latitude-label')}
                        />
                        </Grid>
                        <Grid item xs={6} style={{padding: '0 0 0 8px'}}>
                            <RHFTextInput
                                name='geographicalCoordinate.longitude'
                                control={control}
                                required
                                label={t('longitude-label')}
                            />  
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xs={5}>
                        <Typography>
                            {t('estimate-total-number-of-employee')}
                        </Typography>
                    </Grid>
                    <Grid item container xs={7}>
                        <Grid item xs={6} style={{ padding:'0 8px 0 0'}}>
                            <Controller
                                control={control}
                                name="employee.totalEmployee"
                                defaultValue={0}
                                rules={{ required: true, validate: value => value >= 0 }}
                                render={({field: {value, onChange}, fieldState: {error}}) => (
                                    <TextField
                                        variant="outlined"
                                        label={t('total-employee')}
                                        type="text"
                                        fullWidth
                                        value={value || ''}
                                        onChange={onChange}
                                        size="small"
                                        error={!!error}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} style={{padding: '0 0 0 8px'}}>
                            <Controller
                                control={control}
                                name="employee.femaleEmployee"
                                defaultValue={0}
                                rules={{
                                    required: true,
                                    validate: {
                                        positive: value => Number(value) >= 0,
                                        lessThanTotalEmployee: value => Number(value) <= Number(employee.totalEmployee)
                                    }
                                }}
                                render={({field: {value, onChange}, fieldState: {error}}) => (
                                    <TextField
                                        variant="outlined"
                                        label={t('total-female-employee')}
                                        type="text"
                                        fullWidth
                                        value={value || ''}
                                        onChange={onChange}
                                        size="small"
                                        error={!!error}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item xs={5}>
                        <Typography>
                            {t('number-of-foreigner-employee')}
                        </Typography>
                    </Grid>
                    <Grid item container xs={7}>
                        <Grid item xs={6} style={{ padding:'0 8px 0 0'}}>
                            <Controller
                                control={control}
                                name="employee.foreignerEmployee"
                                defaultValue={0}
                                rules={{
                                    required: true,
                                    validate: {
                                        positive: value => Number(value) >= 0,
                                        lessThanTotalEmployee: value => Number(value) <= Number(employee.totalEmployee)
                                    }
                                }}
                                render={({field: {value, onChange}, fieldState: {error}}) => (
                                    <TextField
                                        variant="outlined"
                                        label={t('total-foreigner-employee')}
                                        type="text"
                                        fullWidth
                                        value={value || ''}
                                        onChange={onChange}
                                        size="small"
                                        error={!!error}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} style={{padding: '0 0 0 8px'}}>
                            <Controller
                                control={control}
                                name="employee.femaleForeignerEmployee"
                                defaultValue={0}
                                rules={{
                                    required: true,
                                    validate: {
                                        positive: value => Number(value) >= 0,
                                        lessThanTotalForeignerEmployee: value => Number(value) <= Number(employee.foreignerEmployee)
                                    }
                                }}
                                render={({field: {value, onChange}, fieldState: {error}}) => (
                                    <TextField
                                        variant="outlined"
                                        label={t('total-female-foreigner-employee')}
                                        type="text"
                                        fullWidth
                                        value={value || ''}
                                        onChange={onChange}
                                        size="small"
                                        error={!!error}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item container xs={5}>
                        <Typography>
                            {t('total-employee-salary')}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Controller
                            control={control}
                            name="totalEmployeeSalary"
                            defaultValue=''
                            rules={{ required: true, validate: value => value >= 0 }}
                            render={({field: {value, onChange}, fieldState: {error}}) => (
                                <TextField
                                    variant="outlined"
                                    label={t('total-employee-salary-placeholder')}
                                    type="text"
                                    fullWidth
                                    value={value || ''}
                                    onChange={onChange}
                                    size="small"
                                    error={!!error}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item container xs={5}>
                        <Typography>
                            {t('weekly-holiday')}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Controller
                            render={({ field: { onChange, value }, fieldState: {error} }) => (
                                <Autocomplete
                                    autoHighlight
                                    value={value}
                                    options={weeklyHoliday}
                                    getOptionLabel={(option) => option}
                                    getOptionSelected={(option, value) => {
                                        return option === value;
                                    }}
                                    onChange={(e, newValue) => onChange(newValue)}
                                    style={{ width: '100%'}}  
                                    renderInput={(params) => <TextField 
                                        {...params} 
                                        label={t("weekly-holiday-placeholder")} 
                                        variant="outlined" 
                                        size="small" 
                                        fullWidth 
                                        error={!!error}
                                    />}
                                />
                            )}
                            control={ control }
                            defaultValue={''}
                            name="weeklyHoliday"
                            rules={{ required: true }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12}>
                    <Grid item container xs={5}>
                        <Typography>
                            {t('number-of-working-per-day-or-week')}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Controller
                            render={({ field: { onChange, value }, fieldState: {error} }) => (
                                <Autocomplete
                                    autoHighlight
                                    value={value}
                                    options={numberOfWorkingPerDayOrWeek}
                                    getOptionLabel={(option) => option}
                                    getOptionSelected={(option, value) => {
                                        return option === value
                                    } }
                                    onChange={(e, newValue) => onChange(newValue)}
                                    style={{ width: '100%'}}  
                                    renderInput={(params) => <TextField 
                                        {...params} 
                                        label={t("number-of-working-per-day-or-week-placeholder")} 
                                        variant="outlined" 
                                        size="small" 
                                        fullWidth 
                                        error={!!error}
                                    />}
                                />
                            )}
                            control={ control }
                            defaultValue={null}
                            name="numberOfWorkingPerDayOrWeek"
                            rules={{ required: true }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    </>
}
export default CompanyInfo;