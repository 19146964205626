import React, { useState, useEffect, useCallback } from 'react';
import {  MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import {Grid, Typography, TextField, makeStyles, Paper} from '@material-ui/core';
import axios from 'axios';
// import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { debounce } from 'debounce';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';


import FileUpload from '../file-upload';
import AddIndividual from '../../components/individual-particulars-new/add-individual';
import { RHFTextInput } from '../rhf-controlled-input';
import { inputNumberOnly } from '../../utils';
import ProvinceInfo from '../../utils/ProvinceInfo';
import { onlyKhmerCharacter, isEmail } from '../../utils/functions/validateString';

import "react-datepicker/dist/react-datepicker.css";
import './index.css';

const useStyles = makeStyles((theme)=>({
    root: {
        "& .MuiIconButton-root": {
            padding: 0,
        }
    },
    paperRoot: { padding: '30px 30px 10px 30px', backgroundColor: theme.palette.primary.light },
}))

const filter = createFilterOptions();

const LegalEntityParticular = ( props ) => {
    const { files, setFiles, ipKey, isLocalForm } = props;
    const classes = useStyles();
    const { control, setValue, trigger } = useFormContext();
    const { t } = useTranslation();
    const address = useWatch({ name: `legalParticular[${props.ipKey}].address` });

    const [ legalParticularData, setLegalParticularData ] = useState([]);
    const [ legalParticularOption, setLegalParticularOption] = useState([]);
    const [loading, setLoading] = useState(true);
    // const [individualParticularData, setIndividualParticularData ] = useState([]);
    const [ individualParticularOption, setIndividualParticularOption] = useState([]);

    const fetchData = (searchText = '') => {
        setLoading(true);
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/v1/legal-particular/list`,
                {
                    withCredentials: true,
                    params: {
                        search: typeof searchText === 'string' ? searchText : '',
                        page: 1,
                        limit:10
                    },
                }
            )
            .then(res => {
                const legalParticulars = res.data?.legalParticular;
                setLegalParticularData(legalParticulars);
                const data = legalParticulars?.map(legal => ({
                    value: legal._id,
                    label: `${legal.companyNameLatin}`,
                    en: legal.companyNameLatin,
                }));
                setLegalParticularOption(data);
            })
            .catch(err => {
                console.log('err get legal particular', err);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    
    // debounce search legal particular
    const debounceSearch = useCallback(
        debounce((text) => {
            fetchData(text)
        }, 500),
        []
    );

    const handleSelectLegalParticular = selectedItem => {
			// do not get address from legalParticular field
			// get from registeredAddress instead
			const found = legalParticularData?.find(element => element._id === selectedItem?.value);
			setValue(`legalParticular[${props.ipKey}].nameLatinSelection`, selectedItem);
			if (found) {
				// company name
				setValue(`legalParticular[${props.ipKey}].nameLatin`, found.companyNameLatin);
				setValue(`legalParticular[${props.ipKey}].nameKhmer`, found.companyNameKhmer);
				// register info (coi number, tin number)
				setValue(`legalParticular[${props.ipKey}].registrationInfo`, {
					number: found?.postRegistration?.ministryOfCommerce?.coiNumber || '',
					issuedDate: found?.postRegistration?.ministryOfCommerce?.date || '',
				});
				setValue(
					`legalParticular[${props.ipKey}].taxIdNumber`,
					found?.postRegistration?.generalDepartmentOfTax?.tinNumber || ''
				);
				setValue(`legalParticular[${props.ipKey}].sharePercentage`, '');
                setValue(`legalParticular[${props.ipKey}].email`,found?.email || '' )
                setValue(`legalParticular[${props.ipKey}].mobilePhoneNumber`,found?.mobilePhoneNumber || '' )
                setValue(`legalParticular[${props.ipKey}].officeTelephoneNumber`,found?.officeTelephoneNumber || '' )
				// registered address
				setValue(`legalParticular[${props.ipKey}].address`, {
					homeNumber: found?.registeredAddress?.homeNumber,
					street: found?.registeredAddress?.street,
					city: found?.registeredAddress?.city,
					district: found?.registeredAddress?.district,
					commune: found?.registeredAddress?.commune,
					village: found?.registeredAddress?.village,
					group: found?.registeredAddress?.group,
				});
			} else {
                setValue(`legalParticular[${props.ipKey}].nameLatin`, '');
                setValue(`legalParticular[${props.ipKey}].nameKhmer`, '');
                
				setValue(`legalParticular[${props.ipKey}].registrationInfo`, {
					number: '',
					issuedDate: null,
				});
				setValue(`legalParticular[${props.ipKey}].taxIdNumber`, '');
                setValue(`legalParticular[${props.ipKey}].sharePercentage`, '');
                setValue(`legalParticular[${props.ipKey}].email`, '' )
                setValue(`legalParticular[${props.ipKey}].mobilePhoneNumber`, '' )
                setValue(`legalParticular[${props.ipKey}].officeTelephoneNumber`, '' )
				setValue(`legalParticular[${props.ipKey}].address`, {
					homeNumber: '',
					street: '',
					city: null,
					district: null,
					commune: null,
					village: null,
					group: '',
				});
			}
		};

    useEffect(() => {
        return () => {
            debounceSearch.clear();
        };
    }, [debounceSearch]); 

    const debounceSearchIndividuals = useCallback(
        debounce((text) => {
            fetchIndividuals(text)
        }, 500),
        []
    );

    useEffect(() => {
        return () => {
            debounceSearchIndividuals.clear();
        };
    }, [debounceSearchIndividuals]); 

    const fetchIndividuals = (searchText = '') => {
        setLoading(true);
        axios
            .get(`${process.env.REACT_APP_API_URL}/v1/individual/list`, {
                withCredentials: true,
                params: {
                    search: typeof searchText === 'string' ? searchText : '',
                    page: 1,
                    limit: 10,
                },
            })
            .then(res => {
                const individualParticulars = res.data?.individualParticular;

                // setIndividualParticularData(individualParticulars);
                const data = individualParticulars?.map(individual => ({
                    value: individual._id,
                    label: individual.nameLatin,
                    nameLatin: individual.nameLatin,
                    nameKhmer: individual.nameKhmer,
                    gender: individual.gender,
                    nationality: individual.nationality,
                    placeOfBirth: individual.placeOfBirth,
                    dob: individual.dob,
                    phoneNumber: individual.phoneNumber,
                    email: individual.email,
                    identity: individual.identity,
                    file: individual.file,
                    address: individual.address
                }));
                setIndividualParticularOption(data);
            })
            .catch(err => {
                console.log('err', err);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    // handleDropFile
    const onDropFile = useCallback(
        async acceptedFiles => {
            if (acceptedFiles.length > 0) {
                let newFile = acceptedFiles.filter(file => {
                    if (files.length > 0) {
                        return !files.find(image => JSON.stringify(image) === JSON.stringify(file));
                    }
                    return file;
                });
        
                // pre convert to ObjectURL to prevent lagging
                newFile = newFile.map(file => ({
                    file: file,
                    ObjectURL: URL.createObjectURL(file),
                }));

                const newArrayFiles = [...files];
                newArrayFiles[ipKey].files.push(...newFile);

                setFiles(newArrayFiles);
            }
        },
        [files, ipKey, setFiles]
    );

    const handleRemoveFile = key => index => {
        const newArrayFiles = [...files];

        const fileToRemove = newArrayFiles?.[key]?.files[index]; // get file to remove

        newArrayFiles[key].remove.push(fileToRemove); // push file to remove to remove array

        newArrayFiles[key].files.splice(index, 1); // remove file form files array

        setFiles(newArrayFiles);
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={2} style={{ paddingTop:'20px' }}>
                <Grid item container xs={12} direction="row" justifyContent="space-between">
                    {isLocalForm ?  (
                        <>
                            <Grid item container xs={5}>
                                <Typography>
                                    {t('company-name-khmer')}
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Controller
                                    control={control}
                                    render={({field: {value, onChange, ref}, fieldState:{error}}) => (
                                        <TextField
                                            variant="outlined"
                                            label={t('company-name-khmer-label')}
                                            type="text"
                                            fullWidth
                                            inputRef={ref}
                                            size="small"
                                            value={value}
                                            onChange={( e ) => { 
                                                onChange( e );
                                                // trigger validate on every input
                                                trigger(`legalParticular[${props.ipKey}].nameKhmer`);
                                            }}
                                            error={!!error}
                                            helperText={ error?.message }
                                        />
                                    )}
                                    name={`legalParticular[${props.ipKey}].nameKhmer`}
                                    defaultValue=""
                                    rules={{ 
                                        required: true, 
                                        validate: (value) => onlyKhmerCharacter( { value } ),
                                    }}
                                />
                            </Grid>
                        </>
                    ) : null}    
                </Grid>
                <Grid item container xs={12} direction="row" justifyContent="space-between">
                    <Grid item container xs={5}>
                        <Typography>
                            {t('company-name-latin')}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Controller 
                            control={control}
                            render={({field: {value}})=>(
                                <input type="hidden" 
                                    value={value}
                                />
                            )}
                            name={`legalParticular[${props.ipKey}].nameLatin`}
                            defaultValue=''
                        />
                        <Controller
                            control={control}
                            render={({field: {value, onChange, ref}, fieldState:{error}}) => (
                            <Autocomplete
                                autoHighlight
                                value={value}
                                options={legalParticularOption || []}
                                onOpen={fetchData}
                                filterSelectedOptions
                                clearOnBlur
                                loading={loading}
                                getOptionLabel={(option) => {
                                    if(typeof option === 'string') {
                                        return option;
                                    }
                                    if(option.inputValue) {
                                        return option.label;
                                    }
                                    return option.label;
                                }}
                                getOptionSelected={(option, value) => {
                                    if (value === null) { return true; }
                                    else if (value._id === option._id) { return true; }
                                }}
                                onInputChange={(event, text) => {
                                    setLoading(true);
                                    debounceSearch(text);
                                }}
                                onChange={(e, newValue) => {
                                    if(typeof newValue === 'string') {
                                        onChange( newValue )
                                    } else if( newValue && newValue.inputValue) {
                                        onChange(newValue.inputValue)
                                        setValue(`legalParticular[${props.ipKey}].nameLatin`, newValue.inputValue)
                                    } else if( newValue === null) {
                                        handleSelectLegalParticular(newValue)
                                        setValue(`legalParticular[${props.ipKey}].nameLatin`, '')
                                        onChange(newValue)
                                    } else {
                                        handleSelectLegalParticular(newValue)
                                        onChange(newValue)
                                    }
                                }}
                                filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    if (params.inputValue !== '') {
                                            filtered.push({
                                            inputValue: params.inputValue,
                                            label: `Add "${params.inputValue}"`,
                                        });
                                    }                
                                    return filtered;
                                }}
                                renderInput={(params) => 
                                    <TextField 
                                        inputRef={ref}
                                        {...params} 
                                        label={t('company-name-latin-label')} 
                                        variant="outlined" 
                                        size="small" 
                                        error={!!error} 
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password',
                                        }}
                                    />}
                                />
                            )}
                            name={`legalParticular[${props.ipKey}].companyNameLatinSelection`}
                            defaultValue={null}
                            rules={{ required: true }}
                        />
                    </Grid>
                </Grid>
                {
                    isLocalForm === true && (
                        <>
                            <Grid item container xs={12} direction="row" justifyContent="space-between">
                                <Grid item container xs={5}>
                                    <Typography> {t('registration-information')}</Typography>
                                </Grid>
                                <Grid item container xs={7}>
                                    <Grid item container xs={6} style={{padding: '0 8px 0px 0'}}>
                                        <Controller
                                            control={control}
                                            render={({field: {value, onChange, ref}, fieldState:{error}}) => (
                                                <TextField
                                                    variant="outlined"
                                                    label={t('registration-number')} 
                                                    type="text"
                                                    fullWidth
                                                    inputRef={ref}
                                                    size="small"
                                                    value={value}
                                                    onChange={onChange}
                                                    error={!!error}
                                                />
                                            )}
                                            name={`legalParticular[${props.ipKey}].registrationInfo.number`}
                                            defaultValue=""
                                            rules={{ required: true }}
                                        />
                                    </Grid>
                                    <Grid item container xs={6} style={{padding: '0 0 0px 8px'}} >
                                        <Controller
                                            control={control}
                                            name={ `legalParticular[${props.ipKey}].registrationInfo.issuedDate` }
                                            defaultValue={null}
                                            rules={{required: true}}
                                            render={({ field: {value, onChange}, fieldState: {error} })=>(
                                                <KeyboardDatePicker
                                                    // initialFocusedDate={new Date('1990/01/01')}
                                                    className={classes.root}
                                                    autoOk
                                                    label={t('ចុះថ្ងៃទី')}
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    size="small"
                                                    format="dd/MM/yyyy"
                                                    margin="none"
                                                    value={value}
                                                    onChange={onChange}
                                                    KeyboardButtonProps={{
                                                        style: {
                                                            outline:'none',
                                                        },
                                                    }}
                                                    error={!!error}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item container xs={12} direction="row" justifyContent="space-between">
                                <Grid item container xs={5}>
                                    <Typography>
                                        {t('tax-identification-number')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                    <Controller
                                        control={control}
                                        render={({field: {value, onChange, ref}, fieldState:{error}}) => (
                                            <TextField
                                                variant="outlined"
                                                label={t('tax-identification-number-label')}
                                                type="text"
                                                fullWidth
                                                inputRef={ref}
                                                size="small"
                                                value={value}
                                                onChange={onChange}
                                                error={!!error}
                                            />
                                        )}
                                        name={`legalParticular[${props.ipKey}].taxIdNumber`}
                                        defaultValue=""
                                        rules={{ required: true }}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )
                }
                <Grid item container xs={12} direction="row" justifyContent="space-between">
                    <Grid item container xs={5}>
                        <Typography>
                            {t('share-percentage')}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Controller
                            control={control}
                            render={({field: {value, onChange, ref}, fieldState:{error}}) => (
                                <TextField
                                    variant="outlined"
                                    label={t('share-percentage-label')}
                                    onKeyPress={inputNumberOnly}
                                    fullWidth
                                    inputRef={ref}
                                    size="small"
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                />
                            )}
                            name={`legalParticular[${props.ipKey}].sharePercentage`}
                            defaultValue=""
                            rules={{ required: true }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} direction="row" justifyContent="space-between">
                    <Grid item xs={5}>
                        <Typography>
                            {t('email')}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <RHFTextInput
                            name={`legalParticular[${props.ipKey}].email`}
                            control={control}
                            required
                            label={t('email-label')}
                            rules={{
                                validate: v => isEmail(v),
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} direction="row" justifyContent="space-between">
                    <Grid item xs={5}>
                        <Typography>
                            {t('mobile-phone-number')}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <RHFTextInput
                            name={`legalParticular[${props.ipKey}].mobilePhoneNumber`}
                            control={control}
                            required
                            label={t('mobile-phone-number-label')}
                            rules={{
                                minLength: 8,
                                maxLength: 10
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} direction="row" justifyContent="space-between">
                    <Grid item xs={5}>
                        <Typography>
                            {t('office-telephone-number')}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <RHFTextInput
                            name={`legalParticular[${props.ipKey}].officeTelephoneNumber`}
                            control={control}
                            required
                            label={t('office-telephone-number-label')}
                            rules={{
                                minLength: 8,
                                maxLength: 10
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item container xs={12} direction="row" justifyContent="space-between">
                    <Grid item container xs={5}>
                        <Typography>
                            {t('registered-address')}
                        </Typography>
                    </Grid>
                    <Grid item container xs={7}>
                        <Grid item xs={6} style={{padding: '0 8px 16px 0'}}>
                            <Controller
                                control={control}
                                render={({field: {value, onChange, ref}, fieldState:{error}}) => (
                                    <TextField
                                        variant="outlined"
                                        label={t('No')}
                                        type="text"
                                        fullWidth
                                        inputRef={ref}
                                        size="small"
                                        value={value}
                                        onChange={onChange}
                                        error={!!error}
                                    />
                                )}
                                name={`legalParticular[${props.ipKey}].address.homeNumber`}
                                defaultValue=""
                                rules={{ required: true }}
                            />
                        </Grid>
                        <Grid item xs={6} style={{padding: '0 0 16px 8px'}}>
                            <Controller
                                control={control}
                                render={({field: {value, onChange, ref}, fieldState:{error}}) => (
                                    <TextField
                                        variant="outlined"
                                        label={t('street')}
                                        type="text"
                                        fullWidth
                                        inputRef={ref}
                                        size="small"
                                        value={value}
                                        onChange={onChange}
                                        error={!!error}
                                    />
                                )}
                                name={`legalParticular[${props.ipKey}].address.street`}
                                defaultValue=""
                                rules={{ required: true }}
                            />
                        </Grid>
                        <Grid item xs={6} style={{padding: '0 8px 16px 0'}}>
                            <Controller
                                render={( {field: { value, onChange }, fieldState:{error}}) => (
                                    <Autocomplete
                                        autoHighlight
                                        value={value}
                                        options={ProvinceInfo.generateCityOptions()}
                                        getOptionLabel={(option) => option ? option.en : null}
                                        getOptionSelected={(option, value) => {
                                            if (value === null || value === undefined) { return true; }
                                            else if (value.value === option.value) { return true; }
                                        } }
                                        onChange={( e, data ) => {
                                            onChange( data )
                                            setValue(`legalParticular[${props.ipKey}].address.district`, null)
                                            setValue(`legalParticular[${props.ipKey}].address.commune`, null)
                                            setValue(`legalParticular[${props.ipKey}].address.village`, null)
                                        }}
                                        renderInput={(params) => <TextField {...params} label={t('city')} variant="outlined" size="small" error={!!error} />}
                                    />
                                )}
                                control={ control }
                                name={ `legalParticular[${props.ipKey}].address.city` }
                                defaultValue={null}
                                rules={{required: true}}
                            />
                        </Grid>
                        <Grid item xs={6} style={{padding: '0 0 16px 8px'}}>
                            <Controller
                                render={({ field: { value, onChange }, fieldState:{error} })=>(
                                    <Autocomplete
                                        autoHighlight
                                        value={value}
                                        options={ProvinceInfo.generateDistrictOptions( address )}
                                        getOptionLabel={(option) => option ? option.en : null}
                                        getOptionSelected={(option, value) => {
                                            if (value === null) { return true; }
                                            else if (value.value === option.value) { return true; }
                                        } }
                                        onChange={( e, data ) => {
                                            onChange( data )
                                            setValue(`legalParticular[${props.ipKey}].address.commune`, null)
                                            setValue(`legalParticular[${props.ipKey}].address.village`, null)
                                        }}
                                        renderInput={(params) => <TextField {...params} label={t("district")} variant="outlined" size="small" error={!!error} />}
                                    />
                                )}
                                control={ control }
                                name={ `legalParticular[${props.ipKey}].address.district` }
                                rules={{required: true}}
                                defaultValue={null}
                            />
                        </Grid>
                        <Grid item xs={6} style={{padding: '0 8px 16px 0'}}>
                            <Controller
                                render={({ field: { value, onChange }, fieldState:{error} })=>(
                                    <Autocomplete
                                        autoHighlight
                                        value={value}
                                        options={ProvinceInfo.generateCommuneOptions( address )}
                                        getOptionLabel={(option) => option ? option.en : null}
                                        getOptionSelected={(option, value) => {
                                            if (value === null) { return true; }
                                            else if (value.value === option.value) { return true; }
                                        } }
                                        onChange={( e, data ) => {
                                            onChange(data)
                                            setValue(`legalParticular[${props.ipKey}].address.village`, null)
                                        }}
                                        renderInput={(params) => <TextField {...params} label={t("commune")} variant="outlined" size="small" error={!!error} />}
                                    />
                                )}
                                control={ control }
                                name={ `legalParticular[${props.ipKey}].address.commune` }
                                rules={{required: true}}
                                defaultValue={null}
                            />
                        </Grid>
                        <Grid item xs={6} style={{padding: '0 0 16px 8px'}}>
                            <Controller
                                render={({ field: { value, onChange }, fieldState:{error} })=>(
                                    <Autocomplete
                                        autoHighlight
                                        value={value}
                                        options={ProvinceInfo.generateVillageOptions(address)}
                                        getOptionLabel={(option) => option ? option.en : null}
                                        getOptionSelected={(option, value) => {
                                            if (value === null) { return true; }
                                            else if (value.value === option.value) { return true; }
                                        } }
                                        onChange={( e, data ) => onChange( data )}
                                        renderInput={(params) => <TextField {...params} label={t("village")} variant="outlined" size="small" error={!!error} />}
                                    />
                                )}
                                control={ control }
                                name={ `legalParticular[${props.ipKey}].address.village` }
                                rules={{required: true}}
                                defaultValue={null}
                            />
                        </Grid> 
                        <Grid item xs={6} style={{padding: '0 8px 16px 0'}}>
                            <Controller
                                control={control}
                                render={({field: {value, onChange, ref}, fieldState:{error}}) => (
                                    <TextField
                                        variant="outlined"
                                        label={t('group')}
                                        type="text"
                                        fullWidth
                                        inputRef={ref}
                                        size="small"
                                        value={value}
                                        onChange={onChange}
                                        error={!!error}
                                    />
                                )}
                                name={`legalParticular[${props.ipKey}].address.group`}
                                defaultValue=""
                            />
                        </Grid> 
                    </Grid>
                </Grid>

                <Grid item container xs={12} direction="row" justifyContent="space-between">
                    <Grid item container xs={5}>
                        <Typography>
                            {t('individual')}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={classes.paperRoot}>
                        <AddIndividual
                            control={control}
                            name={`legalParticular[${props.ipKey}].individualLegalParticular`}
                            nameOnly
                            positionAndPercentProps={ { showPercentageField: false, showRepresentative: false } }
                            addButtonTitle={ t('add-individual') }
                        />
                    </Paper>
                </Grid>

                <Grid item container xs={12} direction="row" justifyContent="space-between">
                    <Grid item container xs={5}>
                        <Typography>
                            {t('represented-by')}
                        </Typography>
                    </Grid>
                    <Grid item xs={7}>
                        <Controller
                            control={control}
                            render={({field: {value, onChange, ref}, fieldState:{error}}) => (
                                <Autocomplete
                                    autoHighlight
                                    value={value}
                                    options={individualParticularOption || []}
                                    onOpen={fetchIndividuals}
                                    clearOnBlur
                                    loading={loading}
                                    getOptionLabel={(option) => option.label}
                                    getOptionSelected={(option, value) => {
                                        return option.value === value.value
                                    }}
                                    onChange={( e, data ) => onChange( data )}
                                    onInputChange={(event, text) => {
                                        setLoading(true);
                                        debounceSearchIndividuals(text);
                                    }}
                                    renderInput={(params) => 
                                        <TextField 
                                            inputRef={ref}
                                            {...params} 
                                            label={t('represented-by-label')} 
                                            variant="outlined" 
                                            size="small" 
                                            error={!!error}
                                        />}
                                    />
                                )}
                            name={`legalParticular[${props.ipKey}].representative`}
                            defaultValue={null}
                            rules={{ required: true }}
                        />
                    </Grid>
                </Grid>

                {/* file upload attachment for international firm */}
                {
                    isLocalForm === false && (
                        <Grid item container xs={12}>
                            <Grid item xs={5}>
                                <Typography>
                                    {t('upload-files')}
                                </Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <FileUpload
                                    files={files?.[ipKey]}
                                    customOnDropFn={onDropFile}
                                    customRemoveFileFn={handleRemoveFile(ipKey)} />
                            </Grid>
                        </Grid>
                    )
                }
            </Grid>
        </MuiPickersUtilsProvider>
    )
}
export default LegalEntityParticular;