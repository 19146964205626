// Third party's imports
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useSnackbar } from 'notistack';

// Local imports
import TableX from '../../../components/tableX';
import GenDocsDialog from '../../../components/approved-company/gen-docs-dialog';
import config from '../../../components/tableX/config';
import DashboardTitle from '../../../components/Title';
import StatusChip from '../../../components/reusable/status-chip';
import RepresentativeSelectionDialog from '../../../components/approved-company/representative-selection-dialog';
import ActionIconButton from '../../../components/action-icon-button';
import { CustomSweetAlert } from '../../../components/custom-sweet-altert2/custom-sweet-alter';

const useStyles = makeStyles(() => ({
  tableWrapper: {
    '& .tableX th': {
      textAlign: 'inherit !important',
    },
    '& .tableX td': {
      textAlign: 'inherit !important',
    },
    '& .MuiTableRow-root': {
      verticalAlign: 'baseline',
    },
  },
}));

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API_URL}/v1/companies/approve/list`,
  dataConfig: ['company'],
};

const ApprovedCompanyList = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [openGenDocsDialog, setOpenGenDocsDialog] = useState(false);
  const [openRepSelectDialog, setOpenRepSelectDialog] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [locale, setLocale] = useState('en');

  const handleCloseGenDocsDialog = () => {
    setOpenGenDocsDialog(false);
  };

  const handleClickGenDocs = (company) => {
    setSelectedCompany(company);
    setOpenGenDocsDialog(true);
  };

  const handleClickRegister = (company) => {
    setSelectedCompany(company);
    // setOpenConfirmRegisterDialog(true);
    CustomSweetAlert.fire({
      title: 'Confirm Register',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'register',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(`${process.env.REACT_APP_API_URL}/v1/companies/register`, company, { withCredentials: true })
          .then((res) => {
            Swal.hideLoading();
            enqueueSnackbar('Company has been registered successfully', { variant: 'success' });
            history.push('/dashboard/company-status/registered-company')
          })
          .catch((error) => {
            console.log('error register company :>> ', error);
            enqueueSnackbar(`${error.response?.data.message || 'error register company'}`, { variant: 'error' });
          });
      }
    });

  };

  const handleOpenRepSelectDialog = () => {
    setOpenRepSelectDialog(true);
  };

  const handleCloseRepSelectDialog = () => {
    setOpenRepSelectDialog(false);
  };

  const handleEdit = (company) => {
    const { _id } = company;
    history.push(`/dashboard/company-status/new/${_id}`);
  };

  const handleView = (company) => {
    history.push(`/dashboard/company-status/approved/view/${company._id}`);
  };

  const tableSchema = [
    {
      id: 'no',
      label: t('no.'),
      type: 'numbering',
      sort: false,
    },
    { id: 'companyNameLatin', label: t('company-name') },
    {
      id: 'createdAt',
      label: t('prepared-by'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        const { createdBy } = data
        return (
          <Typography>{createdBy?.nameLatin ? createdBy?.nameLatin : 'N/A'}</Typography>
        );
      },
    },
    {
      id: 'reviewedAt',
      label: t('reviewed-by'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        const { reviewedBy } = data;
        return (
          <Typography>{reviewedBy?.user?.nameLatin ? reviewedBy?.user?.nameLatin : 'N/A'}</Typography>
        );
      },
    },
    {
      id: 'approvedAt',
      label: t('approved-by'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        const { approvedBy } = data
        return (
          <Typography>{approvedBy?.nameLatin ? approvedBy?.nameLatin : 'null'}</Typography>
        );
      },
    },
    {
      id: 'type',
      label: t('status'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        let isDisabled = !(data.type === 'approved');
        return (
          <>
            <StatusChip
              label={t('generate-documents')}
              disabled={isDisabled}
              onClick={(e) => {
                e.stopPropagation();
                handleClickGenDocs(data);
              }}
            />
          </>
        );
      },
    },
    {
      id: 'component',
      sort: false,
      label: t('action'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return (
          <>
              <ActionIconButton 
                variant='edit' 
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(data);
                }} 
              />
              <ActionIconButton 
                variant='view' 
                onClick={(e)=>{
                  e.stopPropagation();
                  handleView(data);
                }} 
              />
            {!(data.type === 'registered') && (
              <ActionIconButton
                  variant='confirm' 
                  onClick={(e)=>{
                    e.stopPropagation();
                    handleClickRegister(data);
                  }} 
                />
              )}
          </>
        );
      },
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display='flex' alignItems='center' justifyContent='flex-start'>
          <DashboardTitle title='approved-company' alignStart />
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.tableWrapper}>
        <TableX
          config={tableConfig}
          schema={tableSchema}
        />
      </Grid>
      <GenDocsDialog
        company={selectedCompany}
        open={openGenDocsDialog}
        handleClose={handleCloseGenDocsDialog}
        handleOpenRepSelectDialog={handleOpenRepSelectDialog}
        setLocale={setLocale}
      />
      {/* <ConfirmRegisterDialog
        company={selectedCompany}
        open={openConfirmRegisterDialog}
        handleClose={handleCloseConfirmRegisterDialog}
      /> */}
      <RepresentativeSelectionDialog
        company={selectedCompany}
        open={openRepSelectDialog}
        handleClose={handleCloseRepSelectDialog}
        locale={locale}
      />
    </Grid>
  );
};

export default ApprovedCompanyList;
