import React, { useState, useEffect } from 'react';
import { Grid, Box, Button, Collapse, makeStyles} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import IndividualParticularBox from '../individual-particular-box';
import PositionAndSharePercentage from '../position-and-share-percentage';
import IndividualParticularDialog from '../individual-particular-dialog';
import IndividualParticularNameOnlyBox from '../individual-particular-name-only-box';
import { defaultValues } from '../../../views/Company/NewCompany/presenter'

const useStyles = makeStyles(() => ({}));

// note: need to use this with react-hook-form since it need 'control' for it work
const AddIndividual = ( {
    control, // requires field
    name = 'individualParticular',
    afterAddCallBack = ( ) => {},
    render = null,
    positionAndPercentProps = {},
    individualParticularBoxProps = {},
    nameOnly = false, // use to switch between two different component
    addButtonTitle = null,
} ) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { fields: arrayField, append, remove, } = useFieldArray({
        control,
        name,
    });
    const { trigger, formState: { errors } } = useFormContext();
    
    // handle expand or collapse item
    const [isExpanded, setIsExpanded] = useState({});

    // dialog status should only switch between 'view' and 'update',
    const [dialog, setDialog] = useState( { open: false, status: 'view', active: {} } )

    // alway expandeds the last item of array
    useEffect(() => {
        setIsExpanded({
            ...isExpanded,
            [arrayField[arrayField.length - 1]?.id]: true
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [arrayField])

    // handle add new item to arrayField
    const handleAddIndividual = async (e) => {
        try {
            // trigger validation when try to add new data to arrayField
            await trigger(`${name}[${arrayField.length - 1}]`);

            // if have error prevent it from adding new data
            if (Object.keys(errors).length > 0) {
                return;
            }

            // handle collapse before add
            const oldExpanded = isExpanded;
            Object.keys(isExpanded).forEach(item => {
                oldExpanded[item] = false;
            });
            // collapse all Expanded when try add new one
            setIsExpanded(oldExpanded);

            // check to see which data to add
            if (nameOnly) {
                append(defaultValues.legalParticular[0].individualLegalParticular[0]);
            } else {
                append(defaultValues.individualParticular[0]);
            }

            afterAddCallBack();
        } catch (error) {
            console.log('error add individual :>> ', error);
        }
    }


    // handle view, update, delete, expand in 'IndividualParticularBox'
    const handleView = (data) => {
        setDialog( { open: true, status: 'view', active: data } )
    };

    const handleUpdate = (data) => {
        setDialog( { open: true, status: 'update', active: data } )
    };

    const handleDeleteIndividual = (key) => {
        remove(key)
    }

    const onExpandForm = (id) => {
        setIsExpanded(prev => {
            return {
                ...prev,
                [id]: !prev[id]
            }
        });
    }

    // return all the children that should be render
    const returnChildren = ( field, key ) => {
        return (
            <Box mb={2}>
                <Box mb={1}>
                    {field?.id && (
                        nameOnly ? (
                            <IndividualParticularNameOnlyBox
                            field={field}
                            ipKey={key}
                            handleDelete={handleDeleteIndividual}
                            onExpandForm={onExpandForm}
                            isExpanded={isExpanded}
                            classesProps={classes}
                            name={name}
                            hideDeleteButton={arrayField.length === 1 } // hide delete button if only 1 array left
                            {...individualParticularBoxProps}
                        />
                        ) : ( 
                            <IndividualParticularBox
                                field={field}
                                ipKey={key}
                                handleView={handleView}
                                handleUpdate={handleUpdate}
                                handleDelete={handleDeleteIndividual}
                                onExpandForm={onExpandForm}
                                isExpanded={isExpanded}
                                classes={classes}
                                name={name}
                                hideDeleteButton={arrayField.length === 1 } // hide delete button if only 1 array left
                                {...individualParticularBoxProps}
                            />
                        ) 
                    )}
                </Box>
                <Collapse in={isExpanded[field.id]} timeout='auto'>
                    <PositionAndSharePercentage
                        field={field}
                        ipKey={key}
                        name={name}
                        {...positionAndPercentProps}
                    />
                </Collapse>
            </Box>
        )
    };

    return (
        <>
            {arrayField.map((field, key) => (
                <div key={field.id}>
                    {render ? render(returnChildren( field, key )): returnChildren( field, key )}
                </div>
            ))}
            <Grid
            item
            container
            xs={12}
            justifyContent='flex-end'
            style={{ padding: '0.5rem 0' }}
            >
                <Button
                    color='primary'
                    variant='contained'
                    onClick={handleAddIndividual}
                    startIcon={<AddCircleIcon/>}
                >
                {addButtonTitle ? addButtonTitle :  t('add-individual')}
                </Button>
            </Grid>

            <IndividualParticularDialog
                open={dialog.open}
                setOpen={( open ) => setDialog( pre => ({ ...pre, open: open }) ) }
                mode={dialog.status}
                activeIndividual={dialog.active}
            />
        </>
    );

}

export default AddIndividual;