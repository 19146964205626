import React, { useState } from 'react';
import { Grid, makeStyles, Paper, TextField, Button, Dialog, DialogContent, Slide } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import axios from 'axios';
import { useSnackbar } from 'notistack';
import FileUpload from '../file-upload';

import { ACCEPTED, REJECTED } from '../../constants/company-status';
import { uploadMultipleFilesToS3 } from '../../utils/functions/upload-multiple-files';

const useStyles = makeStyles(() => ({
	rootPaper: {
		padding: '1.6rem 1.6rem',
	},
	button: {
		borderRadius: 4,
		padding: '6px 12px',
		width: '100%',
		backgroundColor: '#EDF4FA',
		color: '#184782',
	},
	attachmentName: {
		fontWeight: 400,
	},
	backBtn: {
		backgroundColor: '#ffffff',
		color: '#184782',
	},
	submitBtn: {
		backgroundColor: '#184782',
		color: '#ffffff',
		'&:hover': { backgroundColor: '#184782' },
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />;
});

const NoteWithAttachment = ({ open, setCurrentStatus, setOpen, clickedButton, id, fetchHistory }) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const [selectedAttachments, setSelectedAttachments] = useState({ files: [], remove: [] });
	// const [filename, setFilename] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm();

	const handleClose = () => {
		setOpen(false);
	};

	if (clickedButton === 'accept') {
		clickedButton = ACCEPTED;
	} else {
		clickedButton = REJECTED;
	}

	const resetForm = () => {
		reset();
		setSelectedAttachments({ files: [], remove: [] });
	};

	const onSubmit = async data => {
		try {
			if (!selectedAttachments.files.length) {
				return enqueueSnackbar('Attachment is required', { variant: 'error' });
			}

			setIsLoading(true);

			const uploadedFiles = await uploadMultipleFilesToS3(selectedAttachments.files, 'reserved_company');

			const prepareUploadData = {
				feedback: { reason: data.reason, attachments: uploadedFiles },
				status: clickedButton,
				id,
			};

			await axios.put(`${process.env.REACT_APP_API_URL}/v1/companies/update-status`, prepareUploadData, {
				withCredentials: true,
			});

			setCurrentStatus(clickedButton);
			enqueueSnackbar("Update company's status successfully", { variant: 'success' });
			fetchHistory();

			setIsLoading(false);
			setOpen(false); // close dialog
		} catch (error) {
			console.log('error update company status :>> ', error);
			setIsLoading(false);
			enqueueSnackbar(`${error?.response?.data?.message || 'error update company status'}`, {
				variant: 'error',
			});
		}
	};

	return (
		<Dialog
			className={classes.dialog}
			open={open}
			TransitionComponent={Transition}
			keepMounted
			TransitionProps={{
				onExited: () => resetForm(), // reset field after close dialog
			}}
		>
			<DialogContent style={{ overflow: 'hidden', padding: 0 }}>
				<Paper className={classes.rootPaper}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									{...register('reason', {
										required: 'You must specify a reason',
									})}
									fullWidth
									variant='outlined'
									multiline
									rows={4}
									placeholder='Reason'
									error={!!errors.reason} // set errors.reason to boolean type
									helperText={errors.reason?.message}
								/>
							</Grid>
							<Grid item xs={12}>
								<FileUpload
									files={selectedAttachments}
									setFiles={setSelectedAttachments}
									acceptFileTypes={['.png', '.jpeg', '.jpg', '.pdf']}
								/>
							</Grid>
							<Grid item container xs={12} justifyContent='space-between'>
								<Grid item xs={2}>
									<Button
										className={classes.backBtn}
										onClick={handleClose}
										variant='contained'
										disabled={isLoading}
									>
										Back
									</Button>
								</Grid>
								<Grid item xs={2}>
									<Button
										disabled={isLoading}
										type='submit'
										variant='contained'
										className={classes.submitBtn}
									>
										{isLoading ? 'Loading' : 'Submit'}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</form>
				</Paper>
			</DialogContent>
		</Dialog>
	);
};

export default NoteWithAttachment;
