// Third party's imports
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, makeStyles, Grid, Typography, TextField, DialogActions, Button } from "@material-ui/core";
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';

// Local imports
import { onlyEnglishCharacter, onlyKhmerCharacter } from '../../../utils/functions/validateString';

const useStyles = makeStyles(() => ({
    dialogRoot: {
        zIndex: 9999,
        "& .Mui-disabled": {
            background: "initial !important",
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "800px !important",
        },
    },
    dialogTitle: {
        textAlign: "center",
        color: "#184782",
        padding: "20px 10px",
    }
}));

const formsOfBusiness = [
    'Private Limited Company (Co., Ltd)',
    'Public Limited Company (Plc)'
    ];

const DialogPopup = ({ open, setOpen, setTriggerFetch }) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        handleSubmit,
        reset,
        control,
        setValue,
        trigger,
    } = useForm();

    const [isLoading, setIsLoading] = useState(false)

    const handleClose = () => setOpen(false)

    const onSubmit = (data) => {
        setIsLoading(true)
        axios.post(`${ process.env.REACT_APP_API_URL }/v1/companies/create-reserve`, data, {withCredentials:true} )
            .then(res => {
                enqueueSnackbar('The Reserve Company has been created successfully!', { variant: 'success' });
                setIsLoading(false)
                setTriggerFetch(prev => !prev); // trigger fetch reserve company list
                setOpen(false);
            })
            .catch( err => {
                console.log('err',err.response)
                if(err.response?.status === 409){
                enqueueSnackbar(`${data?.companyNameLatin} already existed!`, { variant: 'error' });
                setValue('companyNameLatin', '');
                setValue('companyNameKhmer', '');
                setValue('formOfBusiness', '');
                }
                setIsLoading(false)
            })
    };
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            className={classes.dialogRoot}
            TransitionProps={{ 
                onExited: () => reset() // reset field when close dialog
            }}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle className={classes.dialogTitle}>
                    Create Reserve Company
                </DialogTitle>
                <DialogContent>
                    <Grid
                        item
                        container
                        spacing={2}
                        style={{ paddingBottom: "2rem" }}
                    >
                        <Grid
                            item
                            container
                            xs={12}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item container xs={5}>
                                <Typography>Company Name (Latin)</Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Controller
                                    control={control}
                                    render={({field: {value, onChange, ref}, fieldState: {error}}) => (
                                        <TextField
                                            className={classes.input}
                                            variant="outlined"
                                            label={t('company-name-latin-label')}
                                            type="text"
                                            fullWidth
                                            inputRef={ref}
                                            size="small"
                                            value={value}
                                            onChange={( e ) => { 
                                                onChange( e );
                                                // trigger validate on every input
                                                trigger("companyNameLatin");
                                            }}
                                            error={!!error}
                                            helperText={ error?.message }
                                        />
                                    )}
                                    name="companyNameLatin"
                                    defaultValue=""
                                    rules={{
                                        required: true,
                                        validate: (value) => onlyEnglishCharacter({ value }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item container xs={5}>
                                <Typography>Company Name (Khmer)</Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Controller
                                    control={control}
                                    render={({field: {value, onChange, ref}, fieldState: {error}}) => (
                                        <TextField
                                            className={classes.input}
                                            variant="outlined"
                                            label={t('company-name-khmer-label')}
                                            type="text"
                                            fullWidth
                                            inputRef={ref}
                                            size="small"
                                            value={value}
                                            onChange={( e ) => { 
                                                onChange( e );
                                                // trigger validate on every input
                                                trigger("companyNameKhmer");
                                            }}
                                            error={!!error}
                                            helperText={ error?.message }
                                        />
                                    )}
                                    name="companyNameKhmer"
                                    defaultValue=""
                                    rules={{ 
                                        required: true, 
                                        validate: (value) => onlyKhmerCharacter({ value }),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item container xs={5}>
                                <Typography>Form of Business</Typography>
                            </Grid>
                            <Grid item xs={7}>
                                <Controller
                                    render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                                        <Autocomplete
                                            autoHighlight
                                            value={value}
                                            id="form-of-business-select"
                                            options={formsOfBusiness}
                                            getOptionLabel={(option) => option}
                                            getOptionSelected={(option, value) => option === value}
                                            onChange={(e, newValue) => onChange(newValue)}
                                            renderInput={(params) => {
                                                return (
                                                    <TextField
                                                        error={!!error}
                                                        inputRef={ref}
                                                        {...params}
                                                        label={t('form-of-business-label')}
                                                        variant='outlined'
                                                        size='small'
                                                        fullWidth
                                                    />
                                                );
                                            }}
                                        />
                                    )}
                                    control={ control }
                                    name="formOfBusiness"
                                    defaultValue={null}
                                    rules={{ required: true }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions
                    style={{ padding: "1rem 1.5rem", justifyContent: "space-between" }}
                >
                    <Button onClick={handleClose}  color="secondary" disabled={isLoading}>
                        Close
                    </Button>
                    <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
                        {isLoading ? 'Submit...' : 'Submit'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
    }

export default DialogPopup