import numeral from 'numeral';

const toKhmerNumber = ( number, locale = 'en', noFormat = false ) => {
    if( !noFormat ) number = numeral( number ).format(0,0);
    
    let result = null;

    if( locale === 'kh' ){
        result = String( number ).split('').map( num => {
            switch( num ){
                case '1': return '\u17E1';
                case '2': return '\u17E2';
                case '3': return '\u17E3';
                case '4': return '\u17E4';
                case '5': return '\u17E5';
                case '6': return '\u17E6';
                case '7': return '\u17E7';
                case '8': return '\u17E8';
                case '9': return '\u17E9';
                case '0': return '\u17E0';
                case ',': return '.';
                case '.': return ',';
                default: return num;
            } 
         } ).join('');
    }else if( locale === 'en' ){
        result = String( number ).split('').map( num => {
            switch( num ){
                case '១': return '1';
                case '២': return '2';
                case '៣': return '3';
                case '៤': return '4';
                case '៥': return '5';
                case '៦': return '6';
                case '៧': return '7';
                case '៨': return '8';
                case '៩': return '9';
                case '០': return '0';
                //case ',': return '.';
                //case '.': return ',';
                default: return num;
            } 
         } ).join('');
    }else{
        throw Error('Locale for number format is not supported.');
    }

    return result;
}

export default toKhmerNumber;