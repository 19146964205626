import { Grid, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { capitalize } from '@material-ui/core';

const Role = ({ control }) => {
	const [roles, setRoles] = useState([]);

	useEffect(() => {
		const fetchRoles = async () => {
			try {
				const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/v1/roles`);
				const { data: fetchedRoles } = data;
				setRoles(fetchedRoles.map(role => ({ id:role._id, name:role.roleName })));
			} catch (err) {
				console.log(err);
			}
		};
		fetchRoles();
	}, []);

	return (
		<Grid item container xs={12} spacing={2}>
			<Grid item xs={4} style={{ paddingTop: '0.5rem' }}>
				<Typography>Role</Typography>
			</Grid>
			<Grid item xs={8}>
				<Controller
					render={({ field: { onChange, value }, fieldState: { error } }) => {
						return (
							<Autocomplete
								autoHighlight
								value={value}
								id='role'
								options={roles}
								getOptionLabel={option => (option.name ? capitalize(option.name) : '')}
								getOptionSelected={(option, value) => option?.id !== value?.id}
								onChange={(e, data) => onChange(data)}
								renderInput={params => (
									<TextField {...params} label='Role' variant='outlined' size='small' error={!!error} />
								)}
							/>
						);
					}}
					control={control}
					name='role'
					defaultValue={''}
					rules={{ required: true }}
				/>
			</Grid>
		</Grid>
	);
};

export default Role;
