import { Grid, TextField, Typography, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useParams, useHistory } from 'react-router';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSnackbar } from 'notistack';

import Title from '../../../components/Title';

import { onlyEnglishCharacter, onlyKhmerCharacter } from '../../../utils/functions/validateString';

const formsOfBusiness = ['Private Limited Company (Co., Ltd)', 'Public Limited Company (Plc)'];

const ReserveCompanyName = props => {
	const history = useHistory();
	const { id } = useParams();
	const { handleSubmit, control, reset, setValue, trigger } = useForm();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (id) {
			axios
				.get(`${process.env.REACT_APP_API_URL}/v1/companies/reserve/${id}`, { withCredentials: true })
				.then(({ data }) => {
					reset(data.companyDetail);
					if (!formsOfBusiness.some(item => data.companyDetail.formOfBusiness === item)) {
						setValue('formOfBusiness', '');
					}
				})
				.catch(error => console.log('error get reserve company info :>> ', error));
		}
	}, [id, reset, setValue]);

	const onSubmit = data => {
		if (!id) return;

		setIsLoading(true);
		const reReserveData = {
			companyNameLatin: data.companyNameLatin,
			companyNameKhmer: data.companyNameKhmer,
			formOfBusiness: data.formOfBusiness,
		};
		axios
			.put(`${process.env.REACT_APP_API_URL}/v1/companies/re-reserve/${id}`, reReserveData, {
				withCredentials: true,
			})
			.then(res => {
				enqueueSnackbar('The Reserve Company has been created successfully!', { variant: 'success' });
				setIsLoading(false);
				history.push('/dashboard/company-status/reserved-company');
			})
			.catch(error => {
				console.log('error re reserve company :>> ', error);
				enqueueSnackbar(`${data?.companyNameLatin} already existed!`, { variant: 'error' });
				setIsLoading(false);
			});
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container direction='column' spacing={3}>
				{/* Company name in Latin */}
				<Title title='create reserve company' alignStart />
				<Grid item container xs={12} alignItems='center' style={{ paddingTop: '20px' }}>
					<Grid item container xs={12} md={4} lg={3}>
						<Typography variant='body1'>1. Company Name (Latin)</Typography>
					</Grid>
					<Grid item container xs={12} md={8} lg={6}>
						<Controller
							control={control}
							render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
								<TextField
									variant='outlined'
									label={t('company-name-latin-label')}
									type='text'
									fullWidth
									inputRef={ref}
									size='small'
									value={value}
									onChange={e => {
										onChange(e);
										// trigger validate on every input
										trigger('companyNameLatin');
									}}
									error={!!error}
									helperText={error?.message}
								/>
							)}
							name='companyNameLatin'
							defaultValue=''
							rules={{
								required: true,
								validate: value => onlyEnglishCharacter({ value }),
							}}
						/>
					</Grid>
				</Grid>
				<Grid item container xs={12} alignItems='center'>
					<Grid item container xs={12} md={4} lg={3}>
						<Typography variant='body1'>2. Company Name (Khmer)</Typography>
					</Grid>
					<Grid item container xs={12} md={8} lg={6}>
						<Controller
							control={control}
							render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
								<TextField
									variant='outlined'
									label={t('company-name-khmer-label')}
									type='text'
									fullWidth
									inputRef={ref}
									size='small'
									value={value}
									onChange={e => {
										onChange(e);
										// trigger validate on every input
										trigger('companyNameKhmer');
									}}
									error={!!error}
									helperText={error?.message}
								/>
							)}
							name='companyNameKhmer'
							defaultValue=''
							rules={{
								required: true,
								validate: value => onlyKhmerCharacter({ value }),
							}}
						/>
					</Grid>
				</Grid>
				<Grid item container xs={12} alignItems='center' style={{ paddingBottom: '30px' }}>
					<Grid item container xs={12} md={4} lg={3}>
						<Typography variant='body1'>3. Form of Business</Typography>
					</Grid>
					<Grid item container xs={12} md={8} lg={6}>
						<Controller
							render={({ field: { onChange, value } }) => (
								<Autocomplete
									value={value}
									id='form-of-business-select'
									options={formsOfBusiness}
									getOptionLabel={option => option}
									getOptionSelected={(option, value) => option === value}
									onChange={(e, newValue) => onChange(newValue)}
									style={{ width: '100%' }}
									renderInput={params => (
										<TextField
											{...params}
											label={t('form-of-business-label')}
											variant='outlined'
											size='small'
											fullWidth
										/>
									)}
								/>
							)}
							control={control}
							name='formOfBusiness'
							defaultValue=''
						/>
					</Grid>
				</Grid>

				{/*  button */}
				<Grid item xs={12} container spacing={3}>
					<Grid item xs={12} sm={4} md={3} lg={2} container>
						<Button
							variant='outlined'
							color='primary'
							fullWidth
							onClick={() => history.goBack()}
							disabled={isLoading}
						>
							Back
						</Button>
					</Grid>
					<Grid item xs={12} sm={4} md={3} lg={2} container>
						<Button type='submit' variant='contained' color='primary' fullWidth disabled={isLoading}>
							{isLoading ? 'Submitting...' : 'Submit'}
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
};

export default ReserveCompanyName;
