import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { useController } from 'react-hook-form';

const AutocompleteWithExistingData = ({
  multiple = false,
  options,
  rules,
  required,
  control,
  name,
  variant = 'outlined',
  label,
  size = 'small',
  fullWidth = true,
  defaultValue = '',
  onChange,
  ...other
}) => {
  const {
    field: { onChange: ucOnChange, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control, // optional when use inside formContext
    rules: { required, ...rules },
    defaultValue: defaultValue,
  });

  const handleOnChange = (e, newValue) => {
    ucOnChange(newValue);
  };

  return (
    <Autocomplete
      multiple={multiple}
      value={value}
      options={options}
      {...other}
      onChange={onChange ? (e, newValue) => onChange(newValue, ucOnChange(newValue)) : handleOnChange}
      style={{ width: '100%' }}
      renderInput={params => (
        <TextField
          {...params}
          inputRef={ref}
          label={label}
          variant={variant}
          size={size}
          fullWidth={fullWidth}
          error={!!error}
        />
      )}
    />
  );
};

export default AutocompleteWithExistingData;
