import React, { useState } from 'react';
import { Grid, Typography, Button, makeStyles, Paper, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import NoteWithAttachment from '../note-with-attachment';
import moment from 'moment';
import StatusChip from '../status-chip';

const useStyles = makeStyles(() => ({
	rootPaper: {
		width: '100%',
		padding: '1.2rem 1.2rem',
	},
}));

const ReserveHistoryCard = ({ data, fetchHistory }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	const statusData = data.currentStatus?.name;
	const { companyNameLatin, updatedAt, createdBy, feedback } = data;

	const [currentStatus, setCurrentStatus] = useState(statusData);
	const [clickedButton, setClickedButton] = useState();

	const handleAccept = () => {
		setClickedButton('accept');
	};
	const handleReject = () => {
		setClickedButton('reject');
	};

	return (
		<>
			<Grid container style={{ paddingBottom: '20px' }}>
				<Paper
					className={classes.rootPaper}
					style={{
						backgroundColor:
							currentStatus === 'accepted' || currentStatus === 'pending' ? '#edf4fa' : '#fcecf1',
					}}
					elevation={0}
				>
					<Grid container alignItems='flex-start' justifyContent='space-between'>
						<Grid item container xs={4} spacing={1}>
							<Grid item xs={4}>
								<Typography>Reserved Name:</Typography>
							</Grid>
							<Grid item xs={8}>
								<Typography>{companyNameLatin}</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography>Reserved By:</Typography>
							</Grid>
							<Grid item xs={8}>
								<Typography>{createdBy?.nameLatin}</Typography>
							</Grid>
							<Grid item xs={4}>
								<Typography>Date:</Typography>
							</Grid>
							<Grid item xs={8}>
								<Typography>{moment(updatedAt).format('DD/MM/YYYY')}</Typography>
							</Grid>
						</Grid>

						{currentStatus !== 'pending' && (
							<Grid item container xs={5} spacing={1}>
								<Grid item xs={4}>
									<Typography>Reason:</Typography>
								</Grid>
								<Grid item xs={8}>
									<Typography>{feedback?.reason}</Typography>
								</Grid>
								<Grid item xs={4}>
									<Typography>Attachments:</Typography>
								</Grid>
								<Grid item container={!!feedback?.attachments?.length} xs={8}>
									{feedback?.attachments?.length ? (
										feedback?.attachments?.map(file => {
											return (
												<Grid item xs={12} key={file.key}>
													<Link href={file.url} target='_blank' rel='noopener'>
														{file.key.split('/')[1]}{' '}
													</Link>
												</Grid>
											);
										})
									) : (
										<Typography>N/A</Typography>
									)}
								</Grid>
							</Grid>
						)}
						<Grid
							item
							container
							xs={3}
							direction='column'
							alignItems='flex-end'
							justifyContent='space-between'
							spacing={2}
						>
							<Grid item xs={12}>
								<StatusChip currentStatus={currentStatus} />
							</Grid>
							<Grid item xs={12}>
								{currentStatus === 'pending' && (
									<Grid item container xs='auto' direction='row' spacing={2}>
										<Grid item>
											<Button color='secondary' variant='outlined' onClick={handleReject}>
												{t('reject-button')}
											</Button>
										</Grid>
										<Grid item>
											<Button
												disableElevation={true}
												variant='contained'
												style={{ backgroundColor: '#49be25', color: 'white' }}
												onClick={handleAccept}
											>
												{t('accept-button')}
											</Button>
										</Grid>
									</Grid>
								)}
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Grid>

			<NoteWithAttachment
				setCurrentStatus={setCurrentStatus}
				fetchHistory={fetchHistory}
				clickedButton={clickedButton}
				open={clickedButton === 'accept' || clickedButton === 'reject'}
				setOpen={setClickedButton}
				id={data?.reference}
			/>
		</>
	);
};

export default ReserveHistoryCard;
