import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';
import axios from 'axios';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import Title from '../../../components/Title';
import NameEmailPhone from '../../../components/person-info/name-email-phone';
import Role from '../../../components/person-info/role';
import IdOrPassport from '../../../components/person-info/id-or-passport';
import Address from '../../../components/person-info/address';
import Profile from '../../../components/person-info/profile';
import PasswordSection from '../../../components/person-info/password';

const useStyles = makeStyles(() => ({
	rootContainer: {
		position: 'relative',
		width: '100%',
	},
}));

const CreateAndUpdateStaff = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { id } = useParams(); // staff's id
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const { handleSubmit, watch, setValue, control, reset } = useForm({
		defaultValues: {
			address: {
				city: null,
				district: null,
				commune: null,
				village: null,
				isManual: false,
			},
		},
	});

	const [selectImage, setSelectImage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		id && fetchStaff();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchStaff = () => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/v1/staff/${id}`)
			.then(res => {
				let staff = res.data?.data;
				let { image, role, ...staffWithoutImageAndRole } = staff;

				let issuedDate = moment(staffWithoutImageAndRole.idOrPassport.issuedDate).toDate();
				staffWithoutImageAndRole.idOrPassport.issuedDate = issuedDate;

				reset(staffWithoutImageAndRole);

				// set role
				if (role[0]) {
					setValue('role', { id: role[0].id,name: role[0].name });
				}

				setSelectImage({ imageURL: staff?.image?.key });
			})
			.catch(error => {
				console.log('error get staff info :>> ', error);
				enqueueSnackbar(`${error?.response?.data?.message || 'Error get staff info'}`, {
					variant: 'error',
				});
			});
	};

	const handleCreateStaff = async data => {
		try {
			setIsLoading(true);
			const { data: response } = await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/users`, data);

			setIsLoading(false);
			enqueueSnackbar(`${response?.message || 'Create staff successfully'}`, { variant: 'success' });
			history.push('/dashboard/staff');
		} catch (error) {
			console.log('error create staff :>> ', error);
			setIsLoading(false);
			enqueueSnackbar(`${error?.response?.data?.message || 'Error create staff'}`, {
				variant: 'error',
			});
		}
	};

	const handleUpdateStaff = async data => {
		try {
			setIsLoading(true);
			const { data: response } = await axios.put(`${process.env.REACT_APP_API_URL}/v1/staff/${id}/update`, {
				data,
			});

			setIsLoading(false);
			enqueueSnackbar(`${response?.message || 'Update staff successfully'}`, { variant: 'success' });
			history.push('/dashboard/staff');
		} catch (error) {
			console.log('error update staff :>> ', error);
			setIsLoading(false);
			enqueueSnackbar(`${error?.response?.data?.message || 'Error update staff'}`, {
				variant: 'error',
			});
		}
	};

	const onSubmit = async data => {
		if (data.role) {
			data.role = [data.role];
		} else {
			data.role = [];
		}

		data.idOrPassport.isPassport = data.idOrPassport.isPassport === 'true' ? true : false;

		if (id) {
			await handleUpdateStaff(data);
		} else {
			await handleCreateStaff(data);
		}
	};

	return (
		<Grid container className={classes.rootContainer} spacing={2}>
			<Grid item container xs={12}>
				<Title title={id ? t('update-staff') : t('create-staff')} />
			</Grid>
			<Grid item container xs={12} md={4}>
				<Profile selectImage={selectImage} setSelectImage={setSelectImage} setValue={setValue} />
			</Grid>
			<Grid item container xs={12} md={8}>
				<form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
					<Grid container spacing={2}>
						{/* name email phone */}
						<NameEmailPhone control={control} />
						{/* role */}
						<Role control={control} />
						{/* id or passport */}
						<IdOrPassport control={control} />
						{/* current address */}
						<Address control={control} watch={watch} rhfSetValue={setValue} />
						{/* password section */}
						<PasswordSection control={control} id={id} />
					</Grid>
					<Grid item xs={12} container spacing={2}>
						<Grid item xs={4} />
						<Grid item xs={8}>
							<Button
								type='submit'
								variant='contained'
								color='primary'
								fullWidth
								style={{ textTransform: 'capitalize' }}
								disabled={isLoading}
							>
								Save
							</Button>
						</Grid>
					</Grid>
				</form>
			</Grid>
		</Grid>
	);
};

export default CreateAndUpdateStaff;
