import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

import { CustomSweetAlert } from '../../../components/custom-sweet-altert2/custom-sweet-alter';
import TableX from '../../../components/tableX';
import config from '../../../components/tableX/config';
import StatusChip from '../../../components/status-chip';
import ActionIconButton from '../../../components/action-icon-button';
import DashboardTitle from '../../../components/Title';

const CompanyDraftTable = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const tableSchema = [
      {
        id: 'no',
        label: t('no.'),
        type: 'numbering',
        sort: false,
      },
      { id: 'companyNameLatin', label: t('company-name') },
      {
        id: 'prepareColumn',
        label: t('prepare'),
        type: 'component',
        components: () => {
          return (
            <Typography> Waiting for prepare </Typography>
          )
        }
      },
      {
        id: 'reviewColumn',
        label: t('review'),
        type: 'component',
        components: () => {
          return (
            <Typography> Waiting for review </Typography>
          )
        }
      },
      {
        id: 'approveColumn',
        label: t('approve'),
        type: 'component',
        components: () => {
          return (
            <Typography> Waiting for approval </Typography>
          )
        }
      },
      {  
        id: 'statusColumn',
        label: t('status'), type: 'component',
        dataConfig: [],
        components: ( data ) => {
          return (
            <StatusChip />
          )
        }
      },
      { id: "action-button", 
        label: t('action'),
        sort: false,
        type: 'component',
        dataConfig: [],
        components: ( data ) => {
          return (
            <>
              <ActionIconButton 
                variant='edit' 
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(data)
                }} 
              />
              <ActionIconButton 
                variant='delete' 
                onClick={(e)=>{
                  e.stopPropagation();
                  handleDelete(data)
                }} 
              />
            </>
          )
        }
      },
    ]

    const handleEdit = (data) => {
      const { _id } = data;
      history.push(`/dashboard/company-status/new/${_id}`);
    }
    const handleDelete = (data) => {
      const { _id } = data;
      CustomSweetAlert.fire({
        title: "Are you sure?",
        text: "Once deleted, it is not recoverable.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: "Delete"
      })
      .then((result) => {
        if (result.isConfirmed) {
          axios.put(`${process.env.REACT_APP_API_URL}/v1/companies/save-draft/remove/${_id}`, data , { withCredentials: true })
          .then( res => {
            
            Swal.fire(
              'Deleted!',
              'Your file has been deleted.',
              'success'
            )
          } )
          .catch( err => {
            console.log('err', err)
          }) 
        }
      })
    }


    return (
      <>
        <DashboardTitle title="draft company" alignStart />
        <TableX
              config={tableConfig}
              schema={tableSchema}
        />
      </>
    )
}
const tableConfig = {
    ...config,
    url: `${process.env.REACT_APP_API_URL}/v1/companies/save-draft/list`,
    dataConfig: ['company'],
};

export default CompanyDraftTable
