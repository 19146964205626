// Third party's imports
import React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

// Local imports
import TableX from '../../../components/tableX';
import config from '../../../components/tableX/config';
import DashboardTitle from '../../../components/Title';
import GenDocsDialog from '../../../components/approved-company/gen-docs-dialog';
import StatusChip from '../../../components/reusable/status-chip';
import ActionIconButton from '../../../components/action-icon-button';

const useStyles = makeStyles(() => ({
  tableWrapper: {
    '& .tableX th': {
      textAlign: 'inherit !important',
    },
    '& .tableX td': {
      textAlign: 'inherit !important',
    },
    '& .MuiTableRow-root': {
      verticalAlign: 'baseline',
    },
  },
}));

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API_URL}/v1/companies/register/list`,
  dataConfig: ['company'],
};

const RegisteredCompanyList = () => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const [selectedCompany] = React.useState({});

  const handleClose = () => {
    setOpen(false);
  };

  const handleView = (company) => {
    history.push(
      `/dashboard/company-status/registered-company/view/${company._id}`
    );
  };

  // const handleShowHistory = (company) => {
  //   const { _id } = company;
  //   history.push(`/dashboard/company-status/registered-company/history/${_id}`);
  // };

  const tableSchema = [
    {
      id: 'no',
      label: t('no.'),
      type: 'numbering',
      sort: false,
    },
    { id: 'companyNameLatin', label: t('company-name') },
    {
      id: 'type',
      label: t('status'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return (
          <>
            <StatusChip
              label={(data.type === 'registered') ? t('registered') : t('not-registered')}
            />
          </>
        );
      },
    },
    {
      id: 'component',
      sort: false,
      label: t('action'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return (
          <ActionIconButton 
            variant='view' 
            onClick={(e) => {
              e.stopPropagation();
              handleView(data);
            }} 
          />
        );
      },
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display='flex' alignItems='center' justifyContent='flex-start'>
          <DashboardTitle title='registered-company' alignStart />
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.tableWrapper}>
        <TableX
          config={tableConfig}
          schema={tableSchema}
        />
      </Grid>
      <GenDocsDialog
        company={selectedCompany}
        open={open}
        handleClose={handleClose}
      />
    </Grid>
  );
};

export default RegisteredCompanyList;
