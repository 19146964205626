import mime from 'mime-types';

const downloadFile = async (file, name) => {
  const { url, mimeType } = file;

  const filename = `${name}.${mime.extension(mimeType)}`;

  // Create Dummy A Element
  let a = document.createElement('a');

  const fetched = await fetch(url).then((res) => res.blob());
  const href = URL.createObjectURL(fetched);

  a.href = href;
  a.download = filename;

  // Download file and remove dummy element
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export default downloadFile;