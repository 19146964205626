import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import Dialog from '../../dialog';
import { roleApi, staffApi } from '../../../api';
import { RFHAutocompleteWithExistingData } from '../../rhf-controlled-input';

const ApproveOrBlockStaffDialog = ({ selectedStaff, open, onClose, handleTriggerFetch }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const { data: response } = await roleApi.get();
        setRoles(response.data.map(role => ({ id: role._id, name: role.roleName })));
      } catch (error) {
        console.log('error get roles :>> ', error);
        enqueueSnackbar('Error get roles', {variant: 'error' })
      }
    };
    fetchRoles();
  }, [enqueueSnackbar]);
  
  const onSubmit = async data => {
    try {
      setIsLoading(true);
      await staffApi.toggleApprove(selectedStaff._id, data.role);
      handleTriggerFetch();
      onClose();
      setIsLoading(false);
    } catch (error) {
      console.log('error toggle approval :>> ', error);
      enqueueSnackbar('Error toggle approval', { variant:' error' })
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      title={selectedStaff?.isApproved ? t('block-user') : t('approve-user')}
      onClose={isLoading ? null : onClose}
      TransitionProps={{
        onEntering: () => {
          reset({ role: selectedStaff?.role });
        },
      }}
      buttons={[
        {
          label: t('cancel'),
          onClick: () => onClose(),
          disabled: isLoading,
          size: 'small',
        },
        {
          label: selectedStaff?.isApproved ? t('block') : t('approve'),
          onClick: () => {
            handleSubmit(onSubmit)();
          },
          variant: 'contained',
          color: 'primary',
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Typography>{t('staff-id')}</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{selectedStaff?.idOrPassport?.number}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography>{t('staff-name')}</Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography>{selectedStaff?.nameLatin}</Typography>
        </Grid>
        <Grid item xs={12}>
          <RFHAutocompleteWithExistingData
            multiple
            options={roles}
            label={t('role')}
            name="role"
            required={true}
            getOptionLabel={option => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
            control={control}
            defaultValue={[]}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ApproveOrBlockStaffDialog;
