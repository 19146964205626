import React, { createContext, useEffect, useState, useContext } from 'react';
import axios from 'axios';

export const UserContext = createContext( {
  userInfo: {},
  rolePermission: {},
  loading: false,
  checkAccess: () => { },
  refreshData: () => { },
});

/**
 * custom hook to get user context no need to import useContext from react when using this hook
 * @returns {Object} userContext
 */
export const useUserContext = () => useContext(UserContext);

const UserContextProvider = ( { children } ) => {
  const [rolePermission, setRolePermission] = useState( {} );
  const [userInfo, setUserInfo] = useState( {} );
  const [loading, setLoading] = useState( true );

  const fetchData = async ( ) => {
    try {
      setLoading( true );
      const [ { data: roleData }, { data: userData }  ] =  await Promise.all( [
        axios.get( `${process.env.REACT_APP_API_URL}/v1/roles` ),
        axios.get(`${process.env.REACT_APP_API_URL}/v1/auth/users/me`, { withCredentials : true } ),
      ]);

      setRolePermission( prepareRolePermissionObject( roleData.data ) );
      setUserInfo( userData.user )
      setLoading( false );
    } catch (error) {
      setLoading(false);
      console.log('error get me :>> ', error);
    }
  }

  useEffect( () => {
    fetchData();
  }, [] );


  const checkAccess = ( { feature, action } ) => {
    if ( !loading ) {
      const isPermissionGranted = userInfo.role.some( ( role ) => {
        try {
          if ( rolePermission[role.name][feature][action] ) {
            return true;
          }
          return false;
        } catch ( err ) {
          console.error( err );
          return false;
        }
      } );
      return isPermissionGranted;
    }
    return false;
  };

  return (
		<UserContext.Provider
			value={{
				userInfo,
				rolePermission,
				loading,
        checkAccess,
        refreshData: fetchData
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

const prepareRolePermissionObject = ( rolePermissionArray ) => {
  const rolePermissionObject = rolePermissionArray.reduce( ( accumulator, current ) => {
    accumulator[current.roleName] = current.features.reduce( ( accumulator, current ) => {
      accumulator[current.featureName] = current.permissions.reduce( ( accumulator, current ) => {
        accumulator[current.permissionName] = current.boolean;
        return accumulator;
      }, {} );
      return accumulator;
    }, {} );
    return accumulator;
  }, {} );
  return rolePermissionObject;
};

export default UserContextProvider;
