import {
  TableCell,
  TableRow,
  TableHead,
  TableSortLabel,
} from '@material-ui/core';
import Checkbox from '../checkbox';
import React from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const TableHeadX = ({
  select,
  selected,
  schema,
  handleSort,
  sort,
  handleSelectMultiple,
  dataList,
}) => {
  const createSortHandler = (property) => (event) => {
    handleSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
          {select && (
            <TableCell>
                <Checkbox
                  indeterminate={
                    selected.length > 0 && selected.length < dataList.length
                  }
                  checked={
                    selected.length > 0 && selected.length === dataList.length
                  }
                  onClick={handleSelectMultiple}
                ></Checkbox>
            </TableCell>
          )}
        {schema.map((item) => (
          <TableCell key={item.id}>
            {item.sort === false ? (
              item.label
            ) : (
              <TableSortLabel
                IconComponent={KeyboardArrowDownIcon}
                active={item.id in sort}
                direction={item.id in sort ? sort[item.id] : 'asc'}
                onClick={createSortHandler(item.id)}
              >
                {item.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeadX;
