import { combineReducers } from 'redux';
import FormInfo from './formInfo';
import LoginInfo from './login';
import Staff from './staff';
import Company from './company';
import CompanyDetails from './companyDetails';
import ForgotPasswordInfo from './forgotPassword';
import ResetPasswordInfo from './resetPassword';

export default combineReducers({
	FormInfo,
	LoginInfo,
	Staff,
	Company,
	CompanyDetails,
	ForgotPasswordInfo,
	ResetPasswordInfo,
});
