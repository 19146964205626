import React from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import { Grid, Typography, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { inputNumberOnly } from '../../../utils';
import { RHFCheckbox } from '../../rhf-controlled-input';

const PositionAndSharePercentage = ({ 
	ipKey, 
	showPercentageField = true,
	showRepresentative = true ,
	name = 'individualParticular'
 }) => {
	const { t } = useTranslation();
	const { setValue } = useFormContext();
	const isShareholders = useWatch({ name: `${name}[${ipKey}].position.isShareholders` });

	return (
		<>
			<Grid item container spacing={2} style={{ paddingBottom: '2rem' }}>
				<Grid item container xs={12} direction='row' justifyContent='space-between' alignItems='flex-start'>
					<Grid item xs={5}>
						<Typography>{t('position')}</Typography>
					</Grid>
					<Grid item container xs={7} alignContent='center'>
						<Grid item xs={6}>
							<RHFCheckbox
								label={t('shareholder')}
								name={`${name}[${ipKey}].position.isShareholders`}
								customOnChange={(e, onChange) => {
									onChange(e.target.checked);

									if (!e.target.checked) setValue(`${name}[${ipKey}].sharePercentage`, 0);
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<RHFCheckbox
								label={t('chairman')}
								name={`${name}[${ipKey}].position.isChairman`}
								customOnChange={(e, onChange) => {
									onChange(e.target.checked);

									if (e.target.checked) setValue(`${name}[${ipKey}].position.isDirector`, false);
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<RHFCheckbox
								label={t('director')}
								name={`${name}[${ipKey}].position.isDirector`}
								customOnChange={(e, onChange) => {
									onChange(e.target.checked);

									if (e.target.checked) setValue(`${name}[${ipKey}].position.isChairman`, false);
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							{showRepresentative && <RHFCheckbox label={t('representative')} name={`${name}[${ipKey}].position.isRepresentative`} />}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{showPercentageField && (
				<Grid item container xs={12} justifyContent='space-between' alignItems='center'>
					<Grid item xs={5}>
						<Typography>{t('share-percentage')}</Typography>
					</Grid>
					<Grid item xs={7}>
						<Controller
							name={`${name}[${ipKey}].sharePercentage`}
							defaultValue=''
							rules={{ required: isShareholders }}
							render={({ field: { value, onChange, ref, ...inputProps }, fieldState: { error } }) => (
								<TextField
									{...inputProps}
									inputRef={ref}
									disabled={!isShareholders}
									variant='outlined'
									label={`ភាគរយនៃភាគហ៊ុន (%)${
										!isShareholders ? ` -​ ត្រូវជ្រើសថាជាម្ចាស់ភាគហ៊ុនជាមុនសិន` : ``
									}`}
									fullWidth
									size='small'
									onKeyPress={inputNumberOnly}
									value={value || ''}
									onChange={onChange}
									error={!!error}
								/>
							)}
						/>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default PositionAndSharePercentage;
