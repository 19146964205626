import React, { useEffect, useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';

const useStyles = isApproved =>
  makeStyles(() => ({
    root: {
      borderRadius: 40,
      padding: '0 1.2rem',
      backgroundColor: isApproved ? 'rgba(227, 0, 77, 0.07)' : 'rgba(1, 106, 178, 0.07)',
      color: isApproved ? 'rgba(227, 0, 77)' : 'rgba(1, 106, 178)',
      // boxShadow: '0px 3px 6px -2px rgba(9, 59, 118, 0.24)',
    },
  }));

const StatusButton = ({ staff, onClick, disabled = false, ...other }) => {
  const [isApproved, setIsApproved] = useState(staff.isApproved);

  const classes = useStyles(isApproved)();

  useEffect(() => {
    setIsApproved(staff.isApproved);
  }, [staff]);

  return (
    <Button
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      className={classes.root}
      {...other}
    >
      {isApproved ? 'Block' : 'Approve'}
    </Button>
  );
};

export default StatusButton;
