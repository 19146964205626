import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Box,
  Typography,
  IconButton,
  Collapse,
  Button,
  useTheme
} from '@material-ui/core';
import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import generateAddress from '../../../views/Company/ReviewCompany/generateAddress';
import { downloadFile, downloadBlobFile } from '../../../utils';
import { pdfFormApi } from '../../../api';


const useStyles = makeStyles((theme) => ({
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'space-between',
    '&:hover': {
      cursor: 'pointer',
    },
    backgroundColor: 'rgba(1, 106, 178, 0.07)',
    borderRadius:"5px"
  },
  headerText: {
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    paddingLeft:'10px'
  },
  listItemText: {
    flex: '1 1 0px !important',
  },
}));

const Detail = ({ individualParticular }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const { id: companyId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const individualInfo = individualParticular.individual;

  const getPositions = (position) => {
    const positions = [
      { key: 'isRepresentative', value: t('representative') },
      { key: 'isShareholders', value: t('shareholder') },
      { key: 'isDirector', value: t('director') },
      { key: 'isChairman', value: t('chairman') },
    ];

    return positions
      .flatMap((eachPosition) =>
        position?.[eachPosition.key] === 'true' || position?.[eachPosition.key] ? eachPosition.value : []
      )
      .join(', ');
  };

  const handleDownload = async () =>
    individualInfo?.file?.forEach((eachFile) =>
      downloadFile(eachFile, individualInfo.nameLatin)
    );
  
  const handleDownloadPersonalInfo = async () => { 
    try {
      setIsLoading(true)
      const response = await pdfFormApi.personalInformation(companyId, individualInfo._id, 'individual');
      downloadBlobFile({ filename: 'individual-info.pdf', data: response.data });
    } catch (error) {
      console.log('error download personal information pdf form :>> ', error);
      enqueueSnackbar('error download personal information pdf form', { variant: 'error' });
    } finally {
      setIsLoading(false)
    }
   }

  const infos = [
    { label: t('full-name-khmer'), value: individualInfo.nameKhmer },
    { label: t('full-name-latin'), value: individualInfo.nameLatin },
    { label: t('gender'), value: individualInfo.gender },
    {
      label: t('position'),
      value: getPositions(individualParticular.position),
    },
    {
      label: t('share-percentage'),
      value: individualParticular.sharePercentage
        ? individualParticular.sharePercentage
        : 'null',
    },
    { label: t('nationality'), value: individualInfo.nationality.label },
    {
      label: t('place-of-birth'),
      value: generateAddress(individualInfo.placeOfBirth, 'en'),
    },
    {
      label: t('date-of-birth'),
      value: moment(individualInfo.dob).format('DD/MM/YYYY'),
    },
    { label: t('phone-number'), value: individualInfo.phoneNumber },
    { label: t('email'), value: individualInfo.email },
    {
      label:
      individualInfo.identity.isPassport === 'true'
          ? t('passport-number')
          : t('id-card-number'),
      value: individualInfo.identity.number,
    },
  ];

  return (
    <List>
      <>
        {infos.map((info, index) => (
          <ListItem
            key={index}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <ListItemText
              className={classes.listItemText}
              primary={`${info.label}:`}
            />
            <ListItemText
              className={classes.listItemText}
              primary={info.value}
            />
          </ListItem>
        ))}
        <ListItem>
          <Button variant='contained' color='primary' onClick={handleDownload}>
            {individualInfo.identity.isPassport === 'true'
              ? t('download-passport')
              : t('download-id-card')}
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={handleDownloadPersonalInfo}
            style={{ marginLeft: theme.spacing(2) }}
            disabled={isLoading}
          >
            {t('download-form-101-b')}
          </Button>
        </ListItem>
      </>
    </List>
  );
};

const IndividualParticulars = ({ individualParticular }) => {
  const [selectedIndex, setSelectedIndex] = React.useState('');
  const classes = useStyles();
  
  const handleClick = (index) => {
    if (selectedIndex === index) {
      setSelectedIndex('');
    } else {
      setSelectedIndex(index);
    }
  };

  return (
    <List>
      {individualParticular.map((eachIndividualParticular, index) => (
        <React.Fragment key={index}>
          <ListItem>
            {/* header */}
            <Box
              className={classes.headerBox}
              onClick={() => handleClick(index)}
            >
              <Typography className={classes.headerText}>
                {index + 1}. {eachIndividualParticular.individual.nameLatin}
              </Typography>
              <IconButton
                color='primary'
                style={{ backgroundColor: '#EDF4FA' }}
              >
                {index === selectedIndex ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            </Box>
          </ListItem>
          {/* detail */}
          <ListItem>
            <Collapse in={index === selectedIndex} timeout='auto' unmountOnExit>
              <Detail individualParticular={eachIndividualParticular} />
            </Collapse>
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  );
};

export default IndividualParticulars;
