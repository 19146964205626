import React from 'react';
import {
	makeStyles,
	Typography,
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	ListItem,
	Button,
	List,
	ListItemText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const PRIVATE_COMPANY_FORM = 'Private Limited Company (Co., Ltd)';

const useStyles = makeStyles(theme => ({
	root: {
		'& a': {
			textDecoration: 'underline',
		},
	},
	underline: {
		display: 'inline-block',
		paddingBottom: 5,
		position: 'relative',
		'&::before': {
			position: 'absolute',
			content: '""',
			width: '50%',
			height: '1px',
			bottom: 0,
			borderBottom: `2px solid #000000`,
		},
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

const GenDocsDialog = ({ company, open, handleClose, handleOpenRepSelectDialog, setLocale }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	// check if company has local legal entity
	const localLegalEntity = company?.legalParticular?.some(legal => legal.isLocal === true);

	const downloadDocument = (link, doc) => {
		axios({
			url: link,
			method: 'GET',
			responseType: 'blob', // important
			withCredentials: true,
		})
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${company.companyNameLatin}_${doc}.docx`);
				document.body.appendChild(link);
				link.click();
			})
			.catch(error => {
				console.log('error download document :>> ', error);
			});
	};

	return (
		<Dialog open={open} keepMounted onClose={handleClose} fullWidth={true}>
			<DialogTitle style={{ textAlign: 'center', color: '#184782' }}>{t('generate-documents')}</DialogTitle>
			<DialogContent>
				<Typography className={classes.underline}>{t('moc-documents')}</Typography>
				<List className={classes.root}>
					{/* English document */}
					<ListItem disableGutters>
						<ListItemText primary='English' />
					</ListItem>
					<List disablePadding>
						<ListItem
							component='a'
							href='#rightTransferEn'
							onClick={() =>
								downloadDocument(
									`${process.env.REACT_APP_API_URL}/v1/docs/moc-exchange-right/${company._id}?lang=en`,
									'auth_letter'
								)
							}
							className={classes.nested}
						>
							<ListItemText primary='MoC Letter of Authorization' />
						</ListItem>
						<ListItem
							component='a'
							href='#mocArticleEn'
							onClick={() =>
								downloadDocument(
									`${process.env.REACT_APP_API_URL}/v1/docs/moc-article/${company._id}?lang=en`,
									'moc_memorandum_and_articles_of_incorporation'
								)
							}
							className={classes.nested}
						>
							<ListItemText primary='MoC Memorandum and Articles Of Incorporation' />
						</ListItem>
						{localLegalEntity && (
							<ListItem
								component='a'
								href='#mocShareholdersResolutionEn'
								onClick={() => {
									if (company?.formOfBusiness !== PRIVATE_COMPANY_FORM) {
										setLocale('en');
										handleClose();
										handleOpenRepSelectDialog();
									}
								}}
								className={classes.nested}
								// disable for private company
								disabled={company?.formOfBusiness === PRIVATE_COMPANY_FORM}
							>
								<ListItemText primary='MoC Shareholder Resolutions' />
							</ListItem>
						)}
						<ListItem
							component='a'
							href='#mocNonCivilAndNonCriminalEn'
							onClick={() =>
								downloadDocument(
									`${process.env.REACT_APP_API_URL}/v1/docs/moc-criminal-declaration/${company._id}?lang=en`,
									'moc_criminal_declaration'
								)
							}
							className={classes.nested}
						>
							<ListItemText primary='MoC Declaration Letter of Non Civil and Non Criminal' />
						</ListItem>
						{company.postRegistration && (
							<ListItem
								component='a'
								href='#taxationForm'
								onClick={
									() => downloadDocument()
									// `${process.env.REACT_APP_API_URL}/v1/docs/moc-criminal-declaration/${company._id}?lang=en`,
									// 'moc_criminal_declaration'
								}
								className={classes.nested}
							>
								<ListItemText primary='Tax of Sole Proprietorship' />
							</ListItem>
						)}
					</List>
					{/* khmer document */}
					<ListItem disableGutters>
						<ListItemText primary='Khmer' />
					</ListItem>
					<List>
						<ListItem
							href='#mocRightTransferKh'
							component='a'
							className={classes.nested}
							onClick={() =>
								downloadDocument(
									`${process.env.REACT_APP_API_URL}/v1/docs/moc-exchange-right/${company._id}?lang=kh`,
									'auth_letter'
								)
							}
						>
							<ListItemText>MoC លិខិតផ្ទេរសិទ្ធិ</ListItemText>
						</ListItem>
						<ListItem
							component='a'
							href='#mocArticleKh'
							onClick={() =>
								downloadDocument(
									`${process.env.REACT_APP_API_URL}/v1/docs/moc-article/${company._id}?lang=kh`,
									'moc_memorandum_and_articles_of_incorporation'
								)
							}
							className={classes.nested}
						>
							<ListItemText>MoC លក្ខន្តិកៈក្រុមហ៊ុន</ListItemText>
						</ListItem>
						{localLegalEntity && (
							<ListItem
								component='a'
								href='#mocShareholdersResolutionKh'
								onClick={() => {
									if (company?.formOfBusiness !== PRIVATE_COMPANY_FORM) {
										setLocale('kh');
										handleClose();
										handleOpenRepSelectDialog();
									}
								}}
								className={classes.nested}
								// disable for private company
								disabled={company?.formOfBusiness === PRIVATE_COMPANY_FORM}
							>
								<ListItemText>MoC សេចក្តីសម្រេចម្ចាស់ភាគហ៊ុន</ListItemText>
							</ListItem>
						)}
						<ListItem
							component='a'
							href='#mocNonCivilAndNonCriminalkh'
							onClick={() =>
								downloadDocument(
									`${process.env.REACT_APP_API_URL}/v1/docs/moc-criminal-declaration/${company._id}?lang=kh`,
									'moc_criminal_declaration'
								)
							}
							className={classes.nested}
						>
							<ListItemText>MoC លិខិតប្រកាសដោយសុច្ចចិត្ត</ListItemText>
						</ListItem>
						{company.postRegistration && (
							<ListItem
								component='a'
								href='#taxationForm'
								onClick={
									() => downloadDocument()
									// `${process.env.REACT_APP_API_URL}/v1/docs/moc-criminal-declaration/${company._id}?lang=en`,
									// 'moc_criminal_declaration'
								}
								className={classes.nested}
							>
								<ListItemText primary='Tax សហ្រគាសឯកបុគ្គល' />
							</ListItem>
						)}
					</List>
				</List>
			</DialogContent>
			<DialogActions style={{ padding: '1rem 1.5rem', justifyContent: 'center' }}>
				<Button onClick={handleClose} color='primary' variant='contained'>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default GenDocsDialog;
