import Swal from 'sweetalert2';
import theme from '../../components/theme';

/**
 * Returns an extended version of Swal containing params as defaults. 
 * Useful for reusing Swal configuration.
 */
export const CustomSweetAlert = Swal.mixin({
	reverseButtons: true,
	confirmButtonColor: theme.palette.success.main,
});
