import React, { useEffect, useState } from 'react';
import { Box, Button, makeStyles, Paper } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import AddBankAccountDialog from './add-bank-account-dialog';
import BankInfoCard from './bank-info-card';

const useStyles = makeStyles(() => ({
	paperRoot: {
		padding: '2rem',
	},
}));

const BankInfo = () => {
	const controller = new AbortController(); // cancel axios request
	const classes = useStyles();

	const [open, setOpen] = useState(false); // open add-bank-acc dialog
	const { id } = useParams(); // company id`

	const [banks, setBanks] = useState([]);
	const [selectedBank, setSelectedBank] = useState(null);
	const [expandBankInfoCard, setExpandBankInfoCard] = useState(false);
	const [selectedBankHistory, setSelectedBankHistory] = useState([]); // ank history for each active bank

	const handleAddBank = () => {
		setOpen(true);
		setSelectedBank(null);
	};

	const fetchBanks = () => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/v1/companies/bank/list/${id}`, {
				withCredentials: true,
				signal: controller.signal,
			})
			.then(res => {
				const banks = res.data?.banks;
				setBanks(banks);
			})
			.catch(error => {
				if (axios.isCancel(error)) return;

				console.log('error fetch bank :>> ', error);
			});
	};

	useEffect(() => {
		fetchBanks();
		return () => {
			controller.abort();
		};
	}, []);

	const handleOpenEditBankDialog = selectedBank => {
		setOpen(true);
		setSelectedBank(selectedBank);
	};

	// double arrows function
	const handleExpandBankInfoCard = bankId => (event, isExpanded) => {
		setExpandBankInfoCard(isExpanded ? bankId : false);
		handleGetBankHistory(bankId);
	};

	/**
	 * get bank's history when expand bank info card
	 * @param {String} bankId
	 */
	const handleGetBankHistory = bankId => {
		const bankHistories = banks.filter(item => item.reference === bankId);
		const reversedBankHistories = bankHistories.reverse();
		setSelectedBankHistory(reversedBankHistories);
	};

	/**
	 * append new bank history
	 * @param {String} selectedBankId
	 * @param {Object} newBankHistory
	 */
	const handleAppendNewBankHistory = (selectedBankId, newBankHistory) => {
		const oldBankHistory = banks.filter(bank => bank.reference === selectedBankId);
		setSelectedBankHistory([...oldBankHistory, newBankHistory]);
	};

	return (
		<>
			<Paper className={classes.paperRoot}>
				<Box mb={3}>
					{banks
						.filter(bank => bank.reference === null)
						.map((bank, index) => (
							<Box mb={2} key={index}>
								<BankInfoCard
									bank={bank}
									expanded={expandBankInfoCard}
									bankHistory={selectedBankHistory}
									setBanks={setBanks}
									handleOpenEditBankDialog={handleOpenEditBankDialog}
									handleExpand={handleExpandBankInfoCard}
								/>
							</Box>
						))}
				</Box>
				<Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button color='primary' variant='contained' startIcon={<AddCircleIcon />} onClick={handleAddBank}>
						Add more bank account
					</Button>
				</Box>
			</Paper>
			<AddBankAccountDialog
				open={open}
				setOpen={setOpen}
				fetchBanks={fetchBanks}
				selectedBank={selectedBank}
				handleAppendNewBankHistory={handleAppendNewBankHistory}
			/>
		</>
	);
};

export default BankInfo;
