import React from 'react';
import { Grid, makeStyles, Box, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useSnackbar } from 'notistack';
import BlockIcon from '@material-ui/icons/Block';

import TableX from '../../../components/tableX';
import config from '../../../components/tableX/config';
import DashboardTitle from '../../../components/Title';

import StatusChip from '../../../components/reusable/status-chip';
import ActionIconButton from '../../../components/action-icon-button';
import { CustomSweetAlert } from '../../../components/custom-sweet-altert2/custom-sweet-alter';

import usePermission from '../../../hooks/use-permission';

const useStyles = makeStyles(() => ({
  tableWrapper: {
    '& .tableX th': {
      textAlign: 'inherit !important',
    },
    '& .tableX td': {
      textAlign: 'inherit !important',
    },
    '& .MuiTableRow-root': {
      verticalAlign: 'baseline',
    },
  },
}));

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API_URL}/v1/companies/review/list`,
  dataConfig: ['company'],
};

const ReviewingCompanyList = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { AccessControl } = usePermission();
  const { enqueueSnackbar } = useSnackbar();

  const handleEdit = (data) => {
    const { _id } = data;
    history.push(`/dashboard/company-status/new/${_id}`);
  };

  const handleDelete = (data) => {
    const { _id } = data;
    CustomSweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, it is not recoverable.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .put(
            `${process.env.REACT_APP_API_URL}/v1/companies/review/remove/${_id}`,
            data,
            { withCredentials: true }
          )
          .then((res) => {
            enqueueSnackbar('Your file has been deleted.', { variant: 'success' });
            history.go(0);
          })
          .catch((err) => {
            console.log('err', err);
          });
      }
    });
  };

  const handleApprove = (data) => {
    CustomSweetAlert.fire({
      title: 'Confirm Approve',
      // text: 'Are you sure?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Approve',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/v1/companies/create-approve`,
            { ...data, reference: data._id },
            { withCredentials: true }
          )
          .then((res) => {
            Swal.hideLoading();
            enqueueSnackbar('Company has been approved successfully', { variant: 'success' });
            history.push('/dashboard/company-status/approved')
          })
          .catch((error) => {
            console.log('error approve company :>> ', error);
            enqueueSnackbar(`${error?.response?.data?.message || 'Error approve company'}`, { variant: 'success' });
          });
      }
    });
  };

  const tableSchema = [
    {
      id: 'no',
      label: t('no.'),
      type: 'numbering',
      sort: false,
    },
    { id: 'companyNameLatin', label: t('company-name') },
    {
      id: 'createdAt',
      label: t('prepared-by'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        const { createdBy } = data
        return (
          <Typography>{createdBy?.nameLatin ? createdBy?.nameLatin : 'N/A'}</Typography>
        );
      },
    },
    {
      id: 'reviewedAt',
      label: t('reviewed-by'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        const { reviewedBy } = data
        return (
          <Typography>{reviewedBy?.user?.nameLatin ? reviewedBy?.user?.nameLatin : 'N/A'}</Typography>
        );
      },
    },
    // {
    //   id: 'approvedAt',
    //   label: t('approve'),
    //   type: 'component',
    //   dataConfig: [],
    //   components: (data) => {
    //     return (
    //       <>
    //         <Typography>{t('waiting-for-approval')}</Typography>
    //       </>
    //     );
    //   },
    // },
    {
      id: 'type',
      label: t('status'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return (
          <>
            <StatusChip
              label={(data.type === 'reviewed') ? t('reviewed') : t('waiting-to-review')}
            />
          </>
        );
      },
    },
    {
      id: 'action-button',
      sort: false,
      label: t('action'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return (
          <>
              <ActionIconButton 
                variant='edit' 
                onClick={(e) => {
                  e.stopPropagation();
                  handleEdit(data);
                }} 
              />
              <ActionIconButton 
                variant='delete' 
                onClick={(e)=>{
                  e.stopPropagation();
                  handleDelete(data);
                }} 
              />
              {/* the approve button is only shown if and only if:
                  - the company has been reviewed
                  - the user has permission to create approved company
              */}
              {(data.type === 'reviewed') && (
                <AccessControl feature='approved-company' action='create'>
                  <ActionIconButton 
                    variant='confirm' 
                    onClick={(e)=>{
                      e.stopPropagation();
                      handleApprove(data);
                    }} 
                  />
                </AccessControl>
              )}
          </>
        );
      },
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display='flex' alignItems='center' justifyContent='flex-start'>
          <DashboardTitle title='reviewing-company' alignStart />
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.tableWrapper}>
        <AccessControl feature='reviewing-company' action='view'
          fallback={() => {
            return (
              <Box display='flex' flexDirection='column' alignItems='center' >
                <BlockIcon style={{ fontSize: 67, marginBottom: 8 ,}} color='error' />
                <Typography align='center' variant='h5' color='error'>
                  Access Denied
                </Typography>
                <Typography align='center' color='textSecondary'>
                  You does not have access to this page
                </Typography>
              </Box>
            )
          }}
        >
          <TableX
            // onRowClick={handleShowHistory}
            // triggerFetch={triggerFetch}
            config={tableConfig}
            schema={tableSchema}
          />
        </AccessControl>
      </Grid>
    </Grid>
  );
};

export default ReviewingCompanyList;
