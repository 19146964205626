import NewCompany from "../views/Company/NewCompany";
import ProfileUpdate from "../views/ProfileUpdate";
import ReserveCompanyName from "../views/Company/ReserveCompanyName";
import ReserveCompany from "../components/reserved-company";
import Dashboard from "../views/Dashboard";
import ReservedHistory from "../components/reserved-history";
import AllStaff from "../views/Staff/AllStaff";
import CreateAndUpdateStaff from "../views/Staff/createAndUpdateStaff";
import ApprovedCompanyList from "../views/Company/ApprovedCompanyList";
import ApprovedCompanyView from "../views/Company/ApprovedCompanyView";
import RegisteredCompanyList from "../views/Company/RegisteredCompanyList";
import CompanyDraftTable from "../views/Company/draft";
import ReviewingCompanyList from "../views/Company/ReviewingCompanyList";
import RegisteredCompanyView from "../views/Company/RegisteredCompanyView";
import RolePermission from "../views/RolePermission";
// import MAndAAmendmentPage from "../views/m-and-a-amendment";
// import CreateEditAmendmentPage from "../views/creat-edit-amendment";
import IndividualParticular from "../views/IndividualParticular";
import OtherCompany from "../views/OtherCompany";
import BusinessActivityPage from "../views/business-activity";

const routes = [
  {
    exact: true,
    path: "/dashboard/",
    component: Dashboard,
  },
  {
    exact: true,
    path: "/dashboard/newCompany",
    component: NewCompany,
  },
  {
    exact: true,
    path: "/dashboard/me",
    component: ProfileUpdate,
  },
  {
    exact: true,
    path: "/dashboard/company-status/reserved-company",
    component: ReserveCompany,
  },
  {
    exact: true,
    path: "/dashboard/company-status/reserved-company/reserve-name",
    component: ReserveCompanyName,
  },
  {
    exact: true,
    path: "/dashboard/company-status/reserved-company/reserve-history/:id",
    component: ReservedHistory,
  },
  {
    exact: true,
    path: "/dashboard/company-status/reserved-company/re-reserve/:id",
    component: ReserveCompanyName,
  },
  {
    exact: true,
    path: "/dashboard/company-status/new/:id",
    component: NewCompany,
  },
  {
    exact: true,
    path: "/dashboard/company-status/draft",
    component: CompanyDraftTable,
  },
  {
    exact: true,
    path: "/dashboard/staff",
    component: AllStaff,
  },
  {
    exact: true,
    path: "/dashboard/staff/add-new-staff",
    component: CreateAndUpdateStaff,
  },
  {
    exact: true,
    path: "/dashboard/staff/staff-update/:id",
    component: CreateAndUpdateStaff,
  },
  {
    exact: true,
    path: "/dashboard/company-status/approved",
    component: ApprovedCompanyList,
  },
  {
    exact: true,
    path: "/dashboard/company-status/approved/view/:id",
    component: ApprovedCompanyView,
  },
  {
    exact: true,
    path: "/dashboard/company-status/registered-company",
    component: RegisteredCompanyList,
  },
  {
    exact: true,
    path: "/dashboard/company-status/reviewing",
    component: ReviewingCompanyList,
  },
  {
    exact: true,
    path: "/dashboard/company-status/registered-company/view/:id",
    component: RegisteredCompanyView,
  },
  {
    exact: true,
    path: "/dashboard/roles",
    component: RolePermission,
  },
  // {
  //   exact: true,
  //   path: "/dashboard/service/m&a-amendment",
  //   component: MAndAAmendmentPage,
  // },
  // {
  //   exact: true,
  //   path: "/dashboard/service/amendment/create",
  //   component: CreateEditAmendmentPage,
  // },
  // {
  //   exact: true,
  //   path: "/dashboard/service/amendment/edit/:id",
  //   component: CreateEditAmendmentPage,
  // },
  {
    exact: true,
    path: "/dashboard/individual-particular",
    component: IndividualParticular,
  },
  {
    exact: true,
    path: "/dashboard/other-company",
    component: OtherCompany,
  },
  {
    exact: true,
    path: "/dashboard/business-activity",
    component:  BusinessActivityPage,
  },
];

export default routes;
