export const RESERVED = 'reserved';

export const DRAFT = 'draft';

export const REVIEWING = 'reviewing';

export const REVIEWED = 'reviewed';

export const APPROVED = 'approved';

export const REGISTERED = 'registered';

export const REJECTED = 'rejected';

export const ACCEPTED = 'accepted';