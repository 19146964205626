import * as ResetPassword from '../actions/resetPassword';

const initialState = {
	isResetPasswordFail: false,
	isLoading: false,
};

const resetPassword = (state = { ...initialState }, action) => {
	switch (action.type) {
		case ResetPassword.RESET_REQUEST:
			return {
				...state,
				isResetPasswordFail: false,
				isLoading: true,
			};
		case ResetPassword.RESET_SUCCESS:
			return {
				...state,
				...action.data,
				isLoading: false,
				isResetPasswordFail: false,
			};
		case ResetPassword.RESET_FAIL:
			return {
				...state,
				...action.data,
				isResetPasswordFail: true,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default resetPassword;
