import axios from 'axios';
// import compressImage from '../image-compress';

const uploadFile = async ( { url, file, type } ) => {
  let currentFile = file;

  try {
    await axios.put( url, currentFile, {
      headers: {
        'Content-Type': type,
      },
      withCredentials: false,
    } );
  } catch ( err ) {
    console.log( err );
  }
};

export default uploadFile;
