import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { useController } from 'react-hook-form';

const RHFCheckbox = ({
	color = 'primary',
	label,
	name,
	control,
	customOnChange,
	defaultValue = false,
	rules = {},
	checkboxProps,
}) => {
	const {
		field: { ref, value, onChange, ...inputProps },
	} = useController({
		control,
		name,
		defaultValue,
		rules,
	});

	return (
		<FormControlLabel
			label={label}
			control={
				<Checkbox
					{...inputProps}
					{...checkboxProps}
					inputRef={ref}
					color={color}
					checked={value}
					onChange={customOnChange ? e => customOnChange(e, onChange) : e => onChange(e.target.checked)}
				/>
			}
		/>
	);
};

export default RHFCheckbox;
