// Third party's imports
import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
    Grid,
    makeStyles,
    Typography,
    TextField,
    FormControlLabel,
    Button,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    InputAdornment,
    IconButton
} from '@material-ui/core';
import swal from 'sweetalert2';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Visibility, VisibilityOff } from '@material-ui/icons';

// Local imports
import Profile from '../../components/person-info/profile';
import Title from '../../components/Title';
import { RFHAutocompleteWithExistingData, RHFDatePicker, RHFTextInput, RHFRadioButton } from '../../components/rhf-controlled-input';

import { useUserContext } from '../../components/context-provider/user-context-provider';

import ProvinceInfo from '../../utils/ProvinceInfo';

const useStyles = makeStyles(() => ({
    userProfile: {
        width:'150px',
        height:'150px',
    }
}))

const ProfileUpdate = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { register, handleSubmit, watch, setValue, control,reset, getValues, formState: { errors, dirtyFields } } = useForm({
        defaultValues: {
            address: {
                city: null,
                district: null,
                commune: null,
                village: null
            }
        }
    });

    const watchIsPassport = useWatch({
        name: 'idOrPassport.isPassport',
        control
    })

    const addressManual = watch('address.isManual');
    const address = watch('address');
    const { refreshData } = useUserContext();

    const [openEnterPasswordDialog, setOpenEnterPasswordDialog] = useState( false );
    // profile image
    const [selectImage, setSelectImage] = useState( null );
    const [ currentPassword, setCurrentPassword ] = useState('');
    const [userId, setUserId] = useState();
    const [showPassword, setShowPassword] = useState(false)
    
    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_API_URL}/v1/auth/users/me`, { withCredentials : true } )
        .then((res) => {
            const { image, _id, ...rest } = res.data?.user;
            setUserId(_id);
            reset({
                ...rest,
                idOrPassport: {
                    ...rest.idOrPassport,
                    isPassport: rest.idOrPassport.isPassport ? 'passport' : 'idCard'
                }
            });
            setSelectImage({ imageURL: image?.key });
        })
        .catch((error) => {
            console.log('error get my profile info :>> ', error);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const handleCloseEnterPasswordDialog = () => setOpenEnterPasswordDialog(false);

    const handleToggleShowPassword = () => setShowPassword(prev => !prev) // toggle show password in password input field

    const onSubmit = () => setOpenEnterPasswordDialog(true)

    //Check Password & submit to backend
    const handleConfirmPassword = async () => {
        try {
            setOpenEnterPasswordDialog(false);
            let formData = getValues();
            formData.password = currentPassword;
            formData.idOrPassport.isPassport = formData.idOrPassport.isPassport === 'passport' ? true : false

            swal.fire({
                text: 'Saving...',
                allowOutsideClick: false,
                onOpen: () => {
                    swal.showLoading();
                }
            });

            await axios.put(`${process.env.REACT_APP_API_URL}/v1/auth/users/me/${userId}`, { data: formData }, { withCredentials: true })
            
            // if user change profile or change latin name call refreshData function get to latest data for navbar
            if (selectImage?.type || Object.keys(dirtyFields).includes('nameLatin')) {
                refreshData();
            }
            
            swal.fire({
                text: 'Update Success',
                timer: 1500,
                icon: 'success',
                onOpen: () => {
                    swal.hideLoading()
                }
            })
        } catch (error) {
            console.log('error update profile info :>> ', error );
            swal.fire({
                text: error?.response?.data?.message,
                icon: 'error',
                onOpen: () => {
                    swal.hideLoading()
                }
            });
        }
    };

    return (
        <Grid container className={classes.rootContainer}>
            <Grid item container xs={12}>
                <Title title='User Profile' />
            </Grid>
            <Grid item container xs={4}>
                <Profile selectImage={selectImage} setSelectImage={setSelectImage} setValue={setValue} />
            </Grid>
            <Grid 
                item 
                xs={8}
                container 
                justifyContent='center'
                alignItems='center' 
                className={classes.rootContainer}
            >
                <Grid item container className={classes.container}>
                    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', }}>
                        <Grid container spacing={2}>
                            <Grid item container xs={12} alignItems='center' spacing={2}>
                                <Grid item xs={4}>
                                    <Typography>Full Name (Latin)</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <RHFTextInput
                                        label='Name (Latin)'
                                        name='nameLatin'
                                        control={control}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} alignItems='center' spacing={2}>
                                <Grid item xs={4} >
                                    <Typography>Email</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <RHFTextInput
                                        label='Name (Latin)'
                                        name='email'
                                        control={control}
                                        required
                                        rules={{
                                            validate: value => {
                                                const email = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(value);
                                                return email;
                                            },
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} alignItems='center' spacing={2}>
                                <Grid item xs={4}>
                                    <Typography>Phone Number</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <RHFTextInput
                                        label='Phone Number'
                                        name='phoneNumber'
                                        control={control}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12}>
                                <Grid item xs={4}>
                                    <Typography>ID/Passport</Typography>
                                </Grid>
                                <Grid item container xs={8} spacing={2}>
                                    <Grid item xs={12}>
                                        <RHFRadioButton
                                            name='idOrPassport.isPassport'
                                            control={control}
                                            rules={{ require: true }}
                                            options={[    
                                                { value: 'idCard', label: 'ID Card' },
                                                { value: 'passport', label: 'Passport' },
                                            ]}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFTextInput
                                            label={watchIsPassport === 'passport' ? 'Passport Number' : 'ID Card Number'}
                                            name='idOrPassport.number'
                                            control={control}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RHFDatePicker
                                            label='Issued Date'
                                            name='idOrPassport.issuedDate'
                                            control={control}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                            <Grid item container xs={12}>
                                <Grid item xs={4}>
                                    <Typography>
                                        Current address
                                    </Typography>
                                </Grid>
                                
                                <Grid item container xs={8} spacing={2}>
                                    <Grid item container xs={6}>
                                        <RHFTextInput
                                            label='No.'
                                            name='address.housenumber'
                                            control={control}
                                            required
                                        />
                                    </Grid>
                                    <Grid item container xs={6}>
                                        <RHFTextInput
                                            label='Street'
                                            name='address.street'
                                            control={control}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RFHAutocompleteWithExistingData
                                            autoHighlight
                                            control={control}
                                            options={ProvinceInfo.generateCityOptions(address)}
                                            label={t('city')}
                                            name='address.city'
                                            disabled={addressManual}
                                            required={false}
                                            defaultValue={null}
                                            getOptionLabel={option => option.en}
                                            getOptionSelected={(option, value) => option !== value}
                                            onChange={() => {
                                                setValue('address.district', null);
                                                setValue('address.commune', null);
                                                setValue('address.village', null);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RFHAutocompleteWithExistingData
                                            autoHighlight
                                            control={control}
                                            options={ProvinceInfo.generateDistrictOptions( address )}
                                            label={t('district')}
                                            name='address.district'
                                            required={false}
                                            defaultValue={null}
                                            getOptionLabel={option => option.en}
                                            getOptionSelected={(option, value) => option !== value}
                                            onChange={() => {
                                                setValue('address.commune', null);
                                                setValue('address.village', null);
                                            }}
                                            disabled={addressManual ||!address.city}
                                            // disabled={!registerAddress.city}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <RFHAutocompleteWithExistingData
                                            autoHighlight
                                            control={control}
                                            options={ProvinceInfo.generateCommuneOptions(address)}
                                            label={t('commune')}
                                            name='address.commune'
                                            required={false}
                                            defaultValue={null}
                                            getOptionLabel={option => option.en}
                                            getOptionSelected={(option, value) => option !== value}
                                            onChange={() => {
                                                setValue('address.village', null);
                                            }}
                                            disabled={addressManual || !address.district}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>                                    
                                        <RFHAutocompleteWithExistingData
                                            autoHighlight
                                            control={control}
                                            options={ProvinceInfo.generateVillageOptions(address)}
                                            label={t('village')}
                                            name='address.village'
                                            required={false}
                                            defaultValue={null}
                                            getOptionLabel={option => option.en}
                                            getOptionSelected={(option, value) => option !== value}
                                            disabled={addressManual || !address.commune}
                                            />
                                    </Grid>
                                    <Grid item xs={12} style={{ display: addressManual ? '' : 'none' }}>
                                        <TextField 
                                            variant="outlined"
                                            name='address.fullAddress'
                                            placeholder='Full Address'
                                            { ...register("address.fullAddress",{ required: watch('address.isManual') ? true : false }) }
                                            type="text"
                                            fullWidth
                                            multiline
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={ 
                                                <Checkbox
                                                    color="primary"
                                                    type='checkbox'
                                                    id="addressManual"
                                                    checked={address?.isManual ? true : false}
                                                    { ...register("address.isManual") }
                                                />}
                                            label="Insert Address Manually"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={4}>
                                    <Typography>
                                        Change Password
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField 
                                        label='New Password'
                                        variant="outlined"
                                        name='password'
                                        type="password"
                                        { ...register("newPassword", {
                                            required: false,
                                        }) }
                                        fullWidth
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={4} />
                                <Grid item xs={8}>
                                    <TextField 
                                        label='Confirm Password'
                                        variant="outlined" 
                                        name='confirmPassword'
                                        type="password"
                                        fullWidth
                                        { ...register("confirmNewPassword",{
                                            required: false,
                                            validate: (value) => {
                                                return value === watch('newPassword')
                                            }
                                        }) }
                                        size="small"
                                        error={errors.confirmNewPassword}
                                        helperText={errors.confirmNewPassword ? 'Password does not match' : ""}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item container xs={12} spacing={2} justifyContent='flex-end'>
                            <Grid item xs={8}>
                                <Button
                                    type='submit'
                                    variant='contained'
                                    color='primary'
                                    fullWidth
                                >
                                    Save Changed
                                </Button>
                                
                                {/* enter password dialog */}
                                <Dialog
                                    open={openEnterPasswordDialog}
                                    onClose={handleCloseEnterPasswordDialog}
                                    TransitionProps={{ 
                                        // after close password dialog set password input to password type back
                                        onExited: () => setShowPassword(false)
                                    }}
                                >
                                    <DialogTitle style={{textAlign: 'center', color: '#184782'}}>Enter your password</DialogTitle>
                                    <DialogContent style={{width: '400px'}}>
                                        <TextField
                                            autoFocus
                                            variant="outlined"
                                            id="password"
                                            label="Current Password"
                                            type={showPassword ? 'text' : 'password'}
                                            fullWidth
                                            size="small"
                                            onChange={e => setCurrentPassword(e.target.value)}
                                            InputProps={{ 
                                                endAdornment: <InputAdornment position='end'>
                                                    <IconButton onClick={handleToggleShowPassword}>
                                                        {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            }}
                                        />
                                    </DialogContent>
                                    <DialogActions style={{padding: '1rem 1.5rem', justifyContent: 'space-between'}}>
                                        <Button
                                            color="primary"
                                            onClick={handleCloseEnterPasswordDialog}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={handleConfirmPassword}
                                        >
                                            Submit
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
        </Grid>
    )
};

export default ProfileUpdate;