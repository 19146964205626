import React from 'react';
import {
	Grid,
	IconButton,
	makeStyles,
	Typography,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
// icons
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import BankInfoCardContent from './bank-info-card-content';

const useStyles = makeStyles(theme => ({
	accordionSummary: {
		padding: theme.spacing(2),
		background: theme.palette.primary.light,
		boxShadow: 'none',
	},
	actionButton: {
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
	},
	bankHistory: {
		background: theme.palette.grey['100'],
		margin: theme.spacing(1),
		padding: theme.spacing(1.5),
		borderRadius: theme.shape.borderRadius,
	},
}));

const BankInfoCard = ({ expanded, bankHistory, bank, setBanks, handleOpenEditBankDialog, handleExpand }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { id } = useParams(); // company id

	const handleDelete = () => {
		const prepareUploadData = {
			bankId: bank._id,
		};

		Swal.fire({
			title: 'Are you sure?',
			text: 'Once deleted, it is not recoverable.',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#3085d6',
			confirmButtonText: 'Delete',
		}).then(result => {
			if (result.isConfirmed) {
				axios
					.put(`${process.env.REACT_APP_API_URL}/v1/companies/bank/remove/${id}`, prepareUploadData, {
						withCredentials: true,
					})
					.then(res => {
						Swal.fire({
							title: 'Deleted!',
							text: 'Your file has been deleted.',
							timer: 1000,
							icon: 'success',
							onOpen: () => {
								Swal.hideLoading();
							},
						}).then(() => {
							setBanks(previousStateBanks =>
								previousStateBanks.filter(previousBank => previousBank._id !== bank._id)
							);
						});
					})
					.catch(err => {
						console.log('err', err);
						Swal.fire({
							text: err.response.data.message,
							icon: 'error',
							onOpen: () => {
								Swal.hideLoading();
							},
						});
					});
			}
		});
	};

	return (
		<Accordion
			TransitionProps={{
				unmountOnExit: false,
			}}
			expanded={expanded === bank._id}
			onChange={handleExpand(bank._id)}
		>
			<AccordionSummary className={classes.accordionSummary}>
				<BankInfoCardContent bank={bank} />
				{/* action button */}
				<Grid item container xs={2} direction='row' alignItems='flex-start' justifyContent='flex-end'>
					<Grid item xs={3} className={classes.actionButton}>
						<IconButton size='small' color='primary'>
							{expanded === bank._id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						</IconButton>
					</Grid>
					<Grid item xs={3} className={classes.actionButton}>
						<IconButton
							size='small'
							color='primary'
							onClick={e => {
								e.stopPropagation();
								handleOpenEditBankDialog(bank);
							}}
						>
							<EditIcon />
						</IconButton>
					</Grid>
					<Grid item xs={3} className={classes.actionButton}>
						<IconButton
							size='small'
							color='secondary'
							onClick={e => {
								e.stopPropagation();
								handleDelete();
							}}
						>
							<DeleteIcon />
						</IconButton>
					</Grid>
				</Grid>
			</AccordionSummary>
			<AccordionDetails>
				<Grid container>
					<Grid item xs={12}>
						<Typography variant='h6' color='primary' gutterBottom>
							History
						</Typography>
					</Grid>
					{/* bank history */}
					{!bankHistory.length ? (
						<Grid item xs={12}>
							<Typography align='center' gutterBottom>
								{t('no-history')}
							</Typography>
						</Grid>
					) : (
						bankHistory.map((bank, index) => (
							<Grid item xs={12} key={index} className={classes.bankHistory}>
								<BankInfoCardContent bank={bank} />
							</Grid>
						))
					)}
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
};

export default BankInfoCard;
