import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ApartmentIcon from '@material-ui/icons/Apartment';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
// import { FaHandshake } from 'react-icons/fa';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';

// eslint-disable-next-line react/react-in-jsx-scope
// const CustomizedFaHandshake = () => <FaHandshake style={{ width: '21px', height: '21px' }} />;
const CustomizedDashboardIcon = () => <DashboardIcon style={{ width: '21px', height: '21px' }} />
const CustomizedApartmentIcon = () => <ApartmentIcon style={{ width: '21px', height: '21px' }} />
const CustomizedPeopleAltIcon = () => <PeopleAltIcon style={{ width: '21px', height: '21px' }} />
const CustomizedPersonIcon = () => <PersonIcon style={{ width: '21px', height: '21px' }} />


const convertIcon = ( CustomizedIcon ) =>
    // need to return it as a function
    () => <CustomizedIcon style={{ width: '21px', height: '21px' }} />

const baseName = '/dashboard';
const menu = [
    {
        name: 'Dashboard',
        path: baseName,
        icon: CustomizedDashboardIcon,
    },
    {
        name: 'Company Status',
        icon: CustomizedApartmentIcon,
        submenu: [
            {
                name: 'Reserved Company',
                path: `${baseName}/company-status/reserved-company`,
            },
            {
                name: 'Draft',
                path: `${baseName}/company-status/draft`,
            },
            {
                name: 'Reviewing',
                path: `${baseName}/company-status/reviewing`,
            },
            {
                name: 'Approved',
                path: `${baseName}/company-status/approved`,
            },
            {
                name: 'Registered Company',
                path: `${baseName}/company-status/registered-company`,
            },
        ],
    },
    // {
    //     name: 'Service',
    //     icon: CustomizedFaHandshake,
    //     submenu: [
    //         {
    //             name: 'New Company Registration',
    //             path: `${baseName}/service/new-company-registration`,
    //         },
    //         {
    //             name: 'M&A Amendment',
    //             path: `${baseName}/service/m&a-amendment`,
    //         },
    //         {
    //             name: 'Close Company',
    //             path: `${baseName}/service/close-company`,
    //         },
    //         {
    //             name: 'Branch Registration',
    //             path: `${baseName}/service/branch-registration`,
    //         },
    //         {
    //             name: 'Labour Registration',
    //             path: `${baseName}/service/labour-registration`,
    //         },
    //     ],
    // },
    {
        name: 'Individual',
        path: `${baseName}/individual-particular`,
        icon: CustomizedPersonIcon,
    },
    {
        name: 'External Company',
        path: `${baseName}/other-company`,
        icon: CustomizedApartmentIcon,
    },
    {
        name: 'Staff',
        path: `${baseName}/staff`,
        icon: CustomizedPeopleAltIcon,
    },
    {
        name: 'Roles',
        path: `${baseName}/roles`,
        icon: convertIcon( SettingsIcon ),
    },
    {
        name: 'Business Activity',
        path: `${baseName}/business-activity`,
        icon: convertIcon( BusinessCenterIcon ),
    },
]

export default menu;

// import AvTimerIcon from '@material-ui/icons/AvTimer';
// import SettingsIcon from '@material-ui/icons/Settings';
// import ListAltIcon from '@material-ui/icons/ListAlt';
// import { FiPackage } from 'react-icons/fi';
// import { IoCartOutline } from 'react-icons/io5';
// import { AiOutlineUser, AiOutlineShop } from 'react-icons/ai';
// import { IoFileTrayStackedOutline } from 'react-icons/io5';
// import { RiMapPin2Line } from 'react-icons/ri';
// import { GoGraph } from 'react-icons/go';

// const baseName = '/dashboard';
// const menu = [
//   {
//     name: 'app-sidebar-dashboard',
//     path: baseName,
//     icon: AvTimerIcon,
//   },
//   {
//     name: 'app-sidebar-product',
//     icon: FiPackage,
//     submenu: [
//       {
//         name: 'app-sidebar-product-list',
//         path: `${baseName}/product`,
//       },
//       {
//         name: 'app-sidebar-product-add',
//         path: `${baseName}/product/create`,
//       },
//       {
//         name: 'app-sidebar-category-list',
//         path: `${baseName}/category`,
//       },
//       {
//         name: 'app-sidebar-product-unit',
//         path: `${baseName}/unit`,
//       },
//       {
//         name: 'app-sidebar-product-brand',
//         path: `${baseName}/brand`,
//       },
//       // {
//       //   name: "app-sidebar-product-listVariant",
//       //   path: `${baseName}`,
//       // },
//       // {
//       //   name: "app-sidebar-product-addVariant",
//       //   path: `${baseName}`,
//       // },
//     ],
//   },
//   {
//     name: 'app-sidebar-stock',
//     icon: IoFileTrayStackedOutline,
//     submenu: [
//       {
//         name: 'app-sidebar-stock-list',
//         path: `${baseName}/stock`,
//       },
//     ],
//   },
//   {
//     name: 'app-sidebar-sale',
//     icon: ListAltIcon,
//     submenu: [
//       {
//         name: 'app-sidebar-sale-list',
//         path: `${baseName}/sale-invoice`,
//       },
//       {
//         name: 'app-sidebar-sale-add',
//         path: `${baseName}/sale-invoice/create`,
//       },
//       {
//         name: 'app-sidebar-sale-repack',
//         path: `${baseName}/sale-invoice/repack`,
//       },
//       {
//         name: 'app-sidebar-sale-saleReturn',
//         path: `${baseName}/sale-invoice/sale-return`,
//       },
//     ],
//   },
//   {
//     name: 'app-sidebar-customer',
//     icon: AiOutlineUser,
//     submenu: [
//       {
//         name: 'app-sidebar-customer-list',
//         path: `${baseName}/customer`,
//       },
//       {
//         name: 'app-sidebar-customer-add',
//         path: `${baseName}/customer/create`,
//       },
//     ],
//   },
//   {
//     name: 'app-sidebar-vendor',
//     icon: AiOutlineShop,
//     submenu: [
//       {
//         name: 'app-sidebar-vendor-list',
//         path: `${baseName}/vendor`,
//       },
//       {
//         name: 'app-sidebar-vendor-add',
//         path: `${baseName}/vendor/create`,
//       },
//     ],
//   },
//   {
//     name: 'app-sidebar-purchase',
//     icon: IoCartOutline,
//     submenu: [
//       {
//         name: 'app-sidebar-purchase-list',
//         path: `${baseName}/purchase`,
//       },
//       {
//         name: 'app-sidebar-purchase-add',
//         path: `${baseName}/purchase/create`,
//       },
//       {
//         name: 'app-sidebar-purchase-in-list',
//         path: `${baseName}/purchase-in`,
//       },
//       {
//         name: 'app-sidebar-purchase-return-list',
//         path: `${baseName}/purchase-return`,
//       },
//     ],
//   },
//   {
//     name: 'app-sidebar-storage',
//     icon: RiMapPin2Line,
//     submenu: [
//       {
//         name: 'app-sidebar-storage-list',
//         path: `${baseName}/storage`,
//       },
//     ],
//   },
//   // {
//   //   name: "app-sidebar-warehouse",
//   //   icon: BiBuildingHouse,
//   //   submenu: [
//   //     {
//   //       name: "app-sidebar-warehouse-transferProduct",
//   //       path: `${baseName}`,
//   //     },
//   //     {
//   //       name: "app-sidebar-warehouse-addTransferProduct",
//   //       path: `${baseName}`,
//   //     },
//   //   ],
//   // },
//   // {
//   //   name: "app-sidebar-users",
//   //   icon: BiUser,
//   //   submenu: [
//   //     {
//   //       name: "app-sidebar-users-list",
//   //       path: `${baseName}`,
//   //     },
//   //     {
//   //       name: "app-sidebar-users-add",
//   //       path: `${baseName}`,
//   //     },
//   //   ],
//   // },
//   // {
//   //   name: "app-sidebar-memberCard",
//   //   icon: MdCardGiftcard,
//   //   submenu: [
//   //     {
//   //       name: "app-sidebar-memberCard-list",
//   //       path: `${baseName}`,
//   //     },
//   //     {
//   //       name: "app-sidebar-memberCard-add",
//   //       path: `${baseName}`,
//   //     },
//   //   ],
//   // },
//   // {
//   //   name: "app-sidebar-event",
//   //   icon: RiCalendarEventLine,
//   //   submenu: [
//   //     {
//   //       name: "app-sidebar-event-coupon",
//   //       path: `${baseName}`,
//   //     },
//   //     {
//   //       name: "app-sidebar-event-productDiscount",
//   //       path: `${baseName}`,
//   //     },
//   //   ],
//   // },
//   {
//     name: 'app-sidebar-reports',
//     icon: GoGraph,
//     submenu: [
//       {
//         name: 'app-sidebar-reports-saleReport',
//         path: `${baseName}/report/sale`,
//       },
//       {
//         name: 'app-sidebar-reports-paymentReport',
//         path: `${baseName}/report/payment`,
//       },
//       // {
//       //   name: "app-sidebar-reports-productReport",
//       //   path: `${baseName}`
//       // },
//       // {
//       //   name: "app-sidebar-reports-saleByCategory",
//       //   path: `${baseName}`
//       // },
//       // {
//       //   name: "app-sidebar-reports-saleByPaymentType",
//       //   path: `${baseName}`
//       // },
//       // {
//       //   name: "app-sidebar-reports-saleByStaff",
//       //   path: `${baseName}`
//       // },
//       // {
//       //   name: "app-sidebar-reports-shift",
//       //   path: `${baseName}`
//       // },
//       // {
//       //   name: "app-sidebar-reports-profitAndLose",
//       //   path: `${baseName}`
//       // }
//     ],
//   },
//   {
//     name: 'app-sidebar-setting',
//     icon: SettingsIcon,
//     submenu: [
//       {
//         name: 'app-sidebar-setting-general',
//         path: `${baseName}/setting`,
//       },
//       {
//         name: 'app-sidebar-setting-paymentAccount',
//         path: `${baseName}/paymentAccount`,
//       },
//       {
//         name: 'app-sidebar-setting-currencyOption',
//         path: `${baseName}/currency`,
//       },
//       {
//         name: 'app-sidebar-setting-delivery',
//         path: `${baseName}/deliverySetting`,
//       },
//       // {
//       //   name: "app-sidebar-setting-pos",
//       //   path: `${baseName}`,
//       // },
//     ],
//   },
// ];

// export default menu;
