import React from 'react';
import { Card, CardContent, Box, Typography, makeStyles, IconButton  } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    card: {
        height: '152px',
        boxShadow: '0 3px 6px rgba(112, 137, 185, 0.16)',
    }
}))

const DashboardCard = ({title, qty, path, icon}) => {
    const classes = useStyles();
    const history = useHistory();
    
    return (
        <Card className={classes.card}>
            <CardContent style={{paddingBottom: '16px'}}>
                <Typography variant="h2" color="primary" style={{fontWeight: 600}}>
                    {qty}
                </Typography>
                <Box display="flex" alignItems="center" justifyContent="space-between" height="48px">
                    <Typography>
                        {title}
                    </Typography>
                    {icon &&
                        <IconButton
                            color="primary"
                            onClick={() => history.push(path)}
                        >
                            {React.createElement(icon)}
                        </IconButton>
                    }
                </Box>
            </CardContent>
        </Card>
    )
}

export default DashboardCard;
