// Third party's imports
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

// Local imports
import DashboardTitle from '../../../components/Title';
import StatusButton from '../../../components/staff/status-button';
import config from '../../../components/tableX/config';
import AvatarWithName from '../../../components/staff/avatar-with-name';
import TableX from '../../../components/tableX';
import ConfirmDeleteDialog from '../../../components/staff/confirm-delete-dialog';
import DeleteButton from '../../../components/reusable/delete-button';
import EditButton from '../../../components/reusable/edit-button';
import AccessControl from '../../../components/reusable/access-control';
import ApproveOrBlockStaffDialog from '../../../components/staff/approve-or-block-staff-dialog';
import usePermission from '../../../hooks/use-permission';

const useStyles = makeStyles(() => ({
  addCircleIcon: {
    cursor: 'pointer',
    marginRight: '0.5rem',
  },
  tableWrapper: {
    '& .tableX th': {
      textAlign: 'inherit !important',
    },
    '& .tableX td': {
      textAlign: 'inherit !important',
    },
  },
  actionBox: {
    '& > *': {
      marginRight: '1rem',
    },
  },
}));

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API_URL}/v1/staff/search`,
  dataConfig: ['staff'],
};

const AllStaff = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { checkPermission } = usePermission();
  const updateStaffPermission = checkPermission({
    operator: 'and',
    permissionsAndFeatures: [{ feature: 'staff', action: 'update' }],
  });

  const DeleteStaffPermission = checkPermission({
    operator: 'and',
    permissionsAndFeatures: [{ feature: 'staff', action: 'delete' }],
  });

  const [triggerFetch, setTriggerFetch] = useState(false);
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState();
  const [openApproveOrBlockUserDialog, setOpenApproveOrBlockUserDialog] = useState({
    open: false,
    selectedStaff: null,
  });

  const handleTriggerFetch = () => {
    setTriggerFetch(prev => !prev);
  };

  const handleOpenApproveOrBlockUserDialog = staff => {
    setOpenApproveOrBlockUserDialog(prev => ({ ...prev, open: true, selectedStaff: staff }));
  };

  const handleCloseApproveOrBlockUserDialog = () => {
    setOpenApproveOrBlockUserDialog(prev => ({ ...prev, open: false }));
  };

  const handleClickDelete = staff => {
    setSelectedStaff(staff);
    setOpenConfirmDeleteDialog(true);
  };

  const handleCloseConfirmDeleteDialog = () => {
    setOpenConfirmDeleteDialog(false);
  };

  const handleEdit = staff => {
    history.push(`/dashboard/staff/staff-update/${staff._id}`);
  };

  const handleAddStaff = () => {
    history.push('/dashboard/staff/add-new-staff');
  };

  const tableSchema = [
    {
      id: 'no',
      label: t('no.'),
      type: 'numbering',
      sort: false,
    },
    {
      label: t('id'),
      type: 'component',
      dataConfig: ['idOrPassport'],
      components: data => {
        return <Typography>{data.number ? data.number : 'N/A'}</Typography>;
      },
    },
    {
      label: t('full-name'),
      type: 'component',
      dataConfig: [],
      components: data => {
        return <AvatarWithName staff={data} />;
      },
    },
    {
      id: 'email',
      label: t('email-address'),
    },
    {
      label: t('phone-number'),
      type: 'component',
      dataConfig: ['phoneNumber'],
      components: data => {
        return <Typography>{data ? data : 'N/A'}</Typography>;
      },
    },
    {
      label: t('role'),
      type: 'component',
      dataConfig: [],
      components: data => {
        return (
          <Typography>
            {data?.role?.length ? data.role.map(item => item.name).join(', ') : 'N/A'}
          </Typography>
        );
      },
    },
    {
      label: t('status'),
      type: 'component',
      dataConfig: [],
      components: data => {
        return (
          <StatusButton
            staff={data}
            disabled={!updateStaffPermission}
            onClick={() => handleOpenApproveOrBlockUserDialog(data)}
          />
        );
      },
    },
    {
      sort: false,
      label: t('action'),
      type: 'component',
      dataConfig: [],
      components: data => {
        return (
          <Box className={classes.actionBox}>
            <EditButton
              disabled={!updateStaffPermission}
              onClick={e => {
                e.stopPropagation();
                handleEdit(data);
              }}
            />
            <DeleteButton
              disabled={!DeleteStaffPermission}
              onClick={() => handleClickDelete(data)}
            />
          </Box>
        );
      },
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <Box mr={2}>
            <DashboardTitle title="staff" />
          </Box>
          <Box>
            <AccessControl feature="staff" action="create">
              <Button variant="contained" color="primary" onClick={handleAddStaff}>
                Create
              </Button>
            </AccessControl>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.tableWrapper}>
        <TableX
          onRowClick={() => {}}
          triggerFetch={triggerFetch}
          config={tableConfig}
          schema={tableSchema}
        />
      </Grid>
      <ConfirmDeleteDialog
        staff={selectedStaff}
        open={openConfirmDeleteDialog}
        handleClose={handleCloseConfirmDeleteDialog}
      />
      <ApproveOrBlockStaffDialog
        open={openApproveOrBlockUserDialog.open}
        selectedStaff={openApproveOrBlockUserDialog.selectedStaff}
        onClose={handleCloseApproveOrBlockUserDialog}
        handleTriggerFetch={handleTriggerFetch}
      />
    </Grid>
  );
};

export default AllStaff;
