import axios from 'axios';
import browserHistory from '../history';

export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_FAIL = 'FORGOT_FAIL';
export const FORGOT_ACTION = 'FORGOT_ACTION';
export const FORGOT_REQUEST = 'FORGOT_REQUEST';

export const forgotSuccess = data => ({
	type: FORGOT_SUCCESS,
	data,
});

export const forgotFail = data => ({
	type: FORGOT_FAIL,
	data,
});

export const forgotAction = data => ({
	type: FORGOT_ACTION,
	data,
});

export const forgotPasswordRequest = () => ({
	type: FORGOT_REQUEST,
});

export const forgotPassword = email => {
	return dispatch => {
		const forgotPasswordData = { email: email };
		dispatch(forgotPasswordRequest());
		return axios
			.post(`${process.env.REACT_APP_API_URL}/v1/auth/forgot-password`, { data: forgotPasswordData })
			.then(result => {
				localStorage.setItem('email', result.data?.email);
				dispatch(forgotSuccess({ result }));
				browserHistory.push('/reset-password');
			})
			.catch(error => {
				dispatch(forgotFail(error.response.data));
			});
	};
};
