// Third party's imports
import React, { useCallback, useEffect, useState } from 'react';
import { Grid, makeStyles, Box, Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useHistory } from 'react-router';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AddIcon from '@material-ui/icons/Add';

// Local imports
import DashboardTitle from '../Title';
import ReserveHistoryCard from '../reserved-history-card';

const useStyles = makeStyles(() => ({
	root: {
		'&:focus': {
			outline: 'none',
		},
	},
	titleContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	backButtonWrapper: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		justifyContent: 'flex-end',
	},
}));

const ReservedHistory = () => {
	const classes = useStyles();
	const history = useHistory();
	const { id } = useParams();

	const [listData, setListData] = useState();
	const [currentStatus, setCurrentStatus] = useState();

	const fetchHistory = useCallback(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/v1/companies/history/${id}`, { withCredentials: true })
			.then(res => {
				setListData(res.data?.company);
				const latestStatus = res.data?.company?.[0]?.currentStatus?.name;
				setCurrentStatus(latestStatus);
			})
			.catch(err => console.log(err));
	}, [id]);

	useEffect(() => {
		fetchHistory();
	}, [fetchHistory, id]);

	const handleBack = () => history.push('/dashboard/company-status/reserved-company');

	const handleClick = currentStatus => {
		if (currentStatus === 'accepted') {
			return history.push(`/dashboard/company-status/new/${id}`);
		} else {
			return history.push(`/dashboard/company-status/reserved-company/re-reserve/${id}`);
		}
	};

	return (
		<Grid container alignItems='center'>
			<Grid item xs={12} className={classes.titleContainer}>
				<DashboardTitle title='reserve-history' alignStart />
				{currentStatus !== 'pending' && (
					<Button
						color='primary'
						variant='outlined'
						onClick={() => handleClick(currentStatus)}
						startIcon={currentStatus === 'rejected' ? <AutorenewIcon /> : <AddIcon />}
					>
						{currentStatus === 'rejected' ? 'Re-Reserve Company' : 'Create Company'}
					</Button>
				)}
			</Grid>
			<Grid item xs={12}>
				{listData &&
					listData.map((data, index) => {
						return <ReserveHistoryCard fetchHistory={fetchHistory} data={data} key={index} />;
					})}
				<Box className={classes.backButtonWrapper}>
					<Button
						disableElevation={true}
						variant='contained'
						color='primary'
						className={classes.backButton}
						onClick={handleBack}
					>
						Back
					</Button>
				</Box>
			</Grid>
		</Grid>
	);
};

export default ReservedHistory;
