// Third party's imports
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, makeStyles, Typography } from '@material-ui/core';

// Local imports
import FilePreview from './file-preview';

const useStyles = makeStyles(theme => ({
	root: ({ fileRejections }) => ({
		outline: 'none',
		padding: theme.spacing(1.5),
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		minHeight: '250px',
		backgroundColor: theme.palette.primary.light,
		border:
			fileRejections.length > 0
				? `3px dotted ${theme.palette.error.main}`
				: `3px dotted ${theme.palette.primary.main}`,
		cursor: 'default',
	}),
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center',
		height: '100%',
		'& .MuiAvatar-root': {
			'& svg': {
				color: '#6a969e',
				fontSize: '100px',
			},
		},
		'& button': {
			margin: '5px 0',
		},
	},
}));

const FileUpload = ({
	files,
	setFiles,
	customOnDropFn,
	customRemoveFileFn,
	multiple,
	isPreviewMode = false,
	acceptFileTypes = ['.pdf'],
}) => {
	const onDrop = useCallback(
		async acceptedFiles => {
			if (acceptedFiles.length > 0) {
				let newFile = acceptedFiles.filter(file => {
					if (files.length > 0) {
						return !files.find(image => JSON.stringify(image) === JSON.stringify(file));
					}
					return file;
				});

				// pre convert to ObjectURL to prevent lagging
				newFile = newFile.map(file => ({
					file: file,
					ObjectURL: URL.createObjectURL(file),
				}));

				setFiles(prev => ({
					...prev,
					files: [...prev.files, ...newFile],
				}));
			}
		},
		[files, setFiles]
	);

	const { getRootProps, getInputProps, open, fileRejections } = useDropzone({
		onDrop: customOnDropFn ? customOnDropFn : onDrop,
		noClick: true,
		accept: acceptFileTypes,
		maxFiles: 5,
		maxSize: 5242880, // 5MB
		multiple: multiple ? multiple : true,
		disabled: isPreviewMode,
	});

	const classes = useStyles({ fileRejections });

	const handleRemove = index => {
		const filter = [...files?.files];

		const remove = filter.splice(index, 1);

		setFiles(prev => ({
			remove: [...prev.remove, ...remove],
			files: filter,
		}));
	};

	return (
		<div {...getRootProps()} className={classes.root}>
			<input {...getInputProps()} />
			<div className={classes.container}>
				<Typography variant='caption'>Drag file(s) here</Typography>
				<Typography variant='caption'>or</Typography>
				{/* hide button in preview mode */}
				{!isPreviewMode && (
					<Button onClick={open} disableElevation size='small' variant='contained' color='primary'>
						Browse file
					</Button>
				)}
				<Typography variant='caption' gutterBottom>
					You can add up to 5 files, each not exceeding 5MB
				</Typography>
				{fileRejections.length > 0 && (
					<Typography color='error' variant='caption'>
						Invalid file
					</Typography>
				)}
				<FilePreview
					files={files}
					isPreviewMode={isPreviewMode}
					handleRemove={customRemoveFileFn ? customRemoveFileFn : handleRemove}
				/>
			</div>
		</div>
	);
};

export default FileUpload;
