import axios from 'axios';

const uploadImage = async ({ image, type, folder = '' }) => {
	const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/v1/aws-s3/upload`, {
		ContentType: type,
		folder,
	});
	await axios.put(data.uploadURL, image, {
		headers: {
			'Content-Type': type,
			'Content-Encoding': 'base64',
		},
		withCredentials: false,
	});

	return data.Key;
};

export default uploadImage;
