import React from 'react';
import { SnackbarProvider as NotiStackSnackbarProvider } from 'notistack';

const SnackbarProvider = ({ children }) => {
	return (
		<NotiStackSnackbarProvider
			anchorOrigin={{
				horizontal: 'center',
				vertical: 'top',
			}}
			autoHideDuration={4500}
		>
			{children}
		</NotiStackSnackbarProvider>
	);
};

export default SnackbarProvider;
