import browserHistory from '../../history'

const logout = () => {
    localStorage.clear('token');
    localStorage.clear('firstName');

    browserHistory.push('/login');
}

export default { logout }
