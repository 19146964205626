import { compose } from 'recompose';
import { connect } from 'react-redux';
import ResetPassword from './presenter';
import { resetPassword } from '../../actions/resetPassword';

const mapStateToProps = state => ({
	resetPasswordInfo: state.ResetPasswordInfo,
});

const mapDispatchToProps = dispatch => ({
	resetPassword: data => dispatch(resetPassword(data.newPassword, data.confirmNewPassword, data.otp)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(ResetPassword);
