import React, { useCallback, useEffect, useState } from 'react';
import { Grid, IconButton, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { debounce } from 'debounce';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { onlyEnglishCharacter, onlyKhmerCharacter } from '../../../utils/functions/validateString';

// note: only use this with react-hook-from since it required control to work
const IndividualParticularNameOnlyBox = ( {
  handleDelete,
  onExpandForm,
  isExpanded,
  field,
  classesProps,
  name = 'individualParticular',
  ipKey,
  hideDeleteButton = false,
} ) => {
  const { t } = useTranslation();
  const {  control, setValue, trigger, getValues } = useFormContext();

  const [individualList, setIndividualList] = useState([]);

  const [loading, setLoading] = useState(true);


  const fetchData = async (searchText = '') => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/individual/list?search=${searchText}&page=1&limit=10`,
        { withCredentials: true }
      );
      const individuals = res.data?.individualParticular || [];
      setIndividualList( individuals )
    } catch ( err ) {
      console.error( 'err' ,err )
    } finally {
      setLoading(false);
    }
  };

  const debounceSearch = useCallback(
    debounce((text) => {
      fetchData(text);
    }, 500),
    []
  );

  useEffect(() => {
    return () => {
      debounceSearch.clear();
    };
  }, [debounceSearch]);

  return (
    <Grid container spacing={2}>
      {/* text box */}
      <Grid item container xs={5}>
        <Controller
          control={control}
          render={({
            field: { value, onChange, ref },
            fieldState: { error },
          }) => (
            <Autocomplete
              fullWidth
              value={value}
              options={individualList || []}
              onOpen={fetchData}
              freeSolo
              loading={loading}
              getOptionLabel={(option) => {
                // something option can be object or string 
                if ( typeof option === 'string' ) {
                  return option
                }
                return option.nameLatin;
              }}
              onInputChange={(event, text) => {
                setLoading(true);
                debounceSearch(text);
                onChange(text);
                trigger( `${name}[${ipKey}].nameLatin` ) // trigger validate on every input
              }}  
              onChange={(e, newValue, reason) => {
                // check if newValue is an object and not null
                if ( typeof newValue === 'object' && newValue) {
                  onChange( newValue.nameLatin );
                  // set nameKhmer base on newValue
                  setValue( `${name}[${ipKey}]`,{ ...getValues( `${name}[${ipKey}]` ), ...newValue } )
                } else {
                  onChange( newValue );
                }
              }}
              renderInput={(params) =>(
                <TextField
                  inputRef={ref}
                  {...params}
                  label={t('full-name-latin-label')}
                  variant='outlined'
                  size='small'
                  error={!!error}
                  helperText={error?.message}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          )}
          name={`${name}[${ipKey}].nameLatin`}
          defaultValue={null}
          rules={{
            required: true,
            validate: ( value ) => onlyEnglishCharacter({ value }),
          }}
        />
      </Grid>
      <Grid item container xs={5}>
        <Controller
          control={control}
          render={({
            field: { value, onChange, ref },
            fieldState: { error },
          }) => (
            <Autocomplete
              fullWidth
              value={value}
              options={individualList || []}
              onOpen={fetchData}
              freeSolo
              loading={loading}
              getOptionLabel={(option) => {
                if ( typeof option === 'string' ) {
                  return option
                }
                return option.nameKhmer;
              }}
              onInputChange={(event, text) => {
                setLoading(true);
                debounceSearch(text);
                onChange(text);
                trigger( `${name}[${ipKey}].nameKhmer` )  
              }}  
              onChange={(e, newValue, reason) => {
                if ( typeof newValue === 'object' && newValue) {
                  onChange( newValue.nameKhmer );
                  setValue( `${name}[${ipKey}]`,{ ...getValues( `${name}[${ipKey}]` ), ...newValue } )
                } else {
                  onChange( newValue );
                }
              }}
              renderInput={(params) => (
                <TextField
                  inputRef={ref}
                  {...params}
                  label={t('full-name-latin-label')}
                  variant='outlined'
                  size='small'
                  error={!!error}
                  helperText={error?.message}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          )}
          name={`${name}[${ipKey}].nameKhmer`}
          defaultValue={null}
          rules={{
            required: true,
            validate: ( value ) => onlyKhmerCharacter({ value }),
          }}
        />
      </Grid>

      {/* action buttons */}
      <Grid item container xs={2}>
  
        <Grid item container xs={6}>
          { !hideDeleteButton && (
              <IconButton
                color='secondary'
                onClick={() => handleDelete(ipKey)}
              >
                <DeleteIcon />
              </IconButton>
          ) }
        </Grid>
        
        <Grid item container xs={6}>
          <IconButton
            onClick={() => onExpandForm(field.id)}
            className={classesProps.root}
          >
            {isExpanded[field.id] ? (
              <KeyboardArrowUp color='primary' />
            ) : (
              <KeyboardArrowDown color='primary' />
            )}
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IndividualParticularNameOnlyBox;
