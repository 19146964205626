import axios from 'axios';

/**
 * remove multiple files from S3
 * @param {Array} keys
 * @returns
 */
const removeFilesFromS3 = async keys => {
	const { data: response } = await axios.post(`${process.env.REACT_APP_API_URL}/v1/aws-s3/remove-file`, {
		keys,
	});
	return response;
};

export default removeFilesFromS3;
