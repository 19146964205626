import React, { useEffect, useState, useContext } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Typography,
  Box,
  makeStyles,
  Button,
  useTheme,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';

import SwitchList from './switch-list';

import { UserContext } from '../../../components/context-provider/user-context-provider';

const useStyles = makeStyles( ( theme ) => ( {
  accordion: {
    borderRadius: '5px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 3px 0px rgb(0 0 0 / 20%)',
    padding: '5px 50px',
    [theme.breakpoints.down( 'xs' )]: {
      padding: '5px',
    },
  },
} ) );

const RolePermission = ( { data, fetchData } ) => {
  const classes = useStyles();
  const theme = useTheme();
  const { register, handleSubmit, control, reset, } = useForm();
  const { checkAccess } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  
  const [show, setShow] = useState(false);
  
  useEffect( ( ) => {
    const prepareData = {}
    // prepare data before reset since the data from backend not in the correct format
    data.features.forEach( ( element ) => {
      // need to assign it to empty object otherwise it will be undefined and cause error
      prepareData[element.featureName] = {}
      element.permissions.forEach( ( item ) => {
        prepareData[element.featureName][item.permissionName] = !!item.boolean
      } )
    });
    reset(prepareData)
  }, [data, reset])
  

  const onSubmit = async ( dataPermission ) => {
    try {
      //   prepare data for update
      const preData = Object.keys( dataPermission ).map( ( featureKey ) => {
        const permissions = Object.keys( dataPermission[featureKey] ).map( ( permissionKey ) => ( {
          permissionName: permissionKey,
          boolean: dataPermission[featureKey][permissionKey],
        } ) );
        return {
          featureName: featureKey,
          permissions,
        };
      } );
      const { _id: roleId } = data;
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/v1/roles/update/${roleId}`, { features: preData });
      enqueueSnackbar(`${response.data.message}`, { variant: 'success' });
    } catch (error) {
      console.log('error update role permission :>> ', error);
      enqueueSnackbar(`${error?.response?.data?.message || 'Error update role permission'}`, { variant: 'error' });
    }
  };

  const handleDelete = async ( roleId, roleName ) => {
    try {
      const { data } = await axios.delete( `${process.env.REACT_APP_API_URL}/v1/roles/delete/${roleId}` );
      enqueueSnackbar(`${data.message}`, { variant: 'success' });
      fetchData();
    } catch (error) {
      console.log('error delete role permission :>> ', error);
      enqueueSnackbar(`${error?.response?.data?.message || 'Error delete role permission'}`, { variant: 'error' });
    }
  };

  return (
    <>
      <Accordion
        className={classes.accordion}
        onChange={ ( event, expanded ) => {
          // since the defaultValue is false, show will always true after changing once
          setShow( true )
        } }
      >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon style={{ fontSize: '28px' }} color="primary" />}
        >
          <Typography color="primary" style={{ fontSize: '18px', textTransform: 'capitalize' }}>{data.roleName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box marginBottom={2} marginTop={1}>
            <form onSubmit={handleSubmit( onSubmit )}>
              <Grid container spacing={2} justifyContent="center">
                { show &&
                /**
                 * when it first render, it have lot of loop to do
                 * So we use 'show' to prevent it from looping until the Accordion expanded for the first time
                */
                  data.features?.map( ( feature, index ) => (
                    <Grid item xs={12} md={10} key={index}>
                      <Box p={3} width="100%" style={{ backgroundColor: theme.palette.primary.light }}>
                        <SwitchList
                          featureName={feature.featureName}
                          permissions={feature.permissions}
                          register={register}
                          control={control}
                        />
                      </Box>
                    </Grid>
                  ) )
                }

                { checkAccess( { feature: 'role-permission', action: 'delete' } ) && (
                  <Grid item xs={12} md={10} container justifyContent="flex-end" style={{ marginTop: '1rem' }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<DeleteIcon />}
                      onClick={() => {
                        handleDelete( data._id, data.roleName ) 
                      }}
                    >
                      Delete
                    </Button>
                  </Grid>
                ) }
              </Grid>
            </form>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default RolePermission;
