import React, { useRef, useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import {
	makeStyles,
	Grid,
	TextField,
	InputAdornment,
	Button,
	Typography,
	IconButton,
} from '@material-ui/core';
import './index.css';
import LockIcon from '@material-ui/icons/Lock';
import VpnKey from '@material-ui/icons/VpnKey';
import { useForm } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
	rootContainer: {
		position: 'relative',
		height: '100vh',
		width: '100%',
		backgroundColor: theme.palette.primary.light,
	},
	container: {
		backgroundColor: 'white',
		borderRadius: '5px',
		maxWidth: '520px',
		padding: '2.5rem 3rem',
		boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
		[theme.breakpoints.down('xs')]: {
			borderRadius: '0rem',
			boxShadow: '0px 0px 0px',
			border: '0px',
			padding: '3rem 1rem',
			paddingTop: '5rem',
		},
	},
	title: {
		color: theme.palette.primary.main,
		fontWeight: 'bold',
	},
	subtitle: {
		padding: '20px 5px 50px 5px',
		color: theme.palette.text.secondary,
	},
	submitButton: {
		textTransform: 'capitalize',
	},
}));

// password must be at least eight characters long (?=.{8})
// contain at least one lowercase letter (?=.*[a-z])
// contain at least one uppercase letter (?=.*[A-Z])
// contain at least one digit (?=.*[0-9])
// contain at least one special character (?=.*[^A-Za-z0-9])
const passwordRegex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');

const ResetPassword = ({ resetPassword, resetPasswordInfo }) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm();
	const password = useRef({});
	password.current = watch('newPassword', '');

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	useEffect(() => {
		if (resetPasswordInfo.isResetPasswordFail) {
			enqueueSnackbar(resetPasswordInfo?.message || 'Error reset password', { variant: 'error' });
		} else if (!resetPasswordInfo.isResetPasswordFail && resetPasswordInfo?.success) {
			enqueueSnackbar(resetPasswordInfo?.message, { variant: 'success' });
		}
	}, [resetPasswordInfo]);

	const handleClickPassVisibility = () => {
		setShowPassword(!showPassword);
	};

	const handleClickConfirmPassVisibility = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	const onSubmit = data => {
		resetPassword(data);
	};

	return (
		<>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignItems='center'
				className={classes.rootContainer}
			>
				<Grid item container className={classes.container}>
					<Grid item container xs={12} justifyContent='center'>
						<Typography variant='h5' className={classes.title}>
							Reset Password
						</Typography>
					</Grid>
					<Grid item container xs={12} justifyContent='center'>
						<Typography variant='subtitle1' className={classes.subtitle}>
							Enter a new password
						</Typography>
					</Grid>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									variant='outlined'
									placeholder='6 Digits OTP'
									type='number'
									fullWidth
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<VpnKey color='primary' />
											</InputAdornment>
										),
									}}
									{...register('otp', {
										required: 'You must specify an OTP',
										minLength: 6,
										maxLength: 6,
									})}
									error={!!errors.otp}
									helperText={errors.otp?.message}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									{...register('newPassword', {
										required: 'You must specify a password',
										pattern: {
											value: passwordRegex,
											message:
												'Password must have at least 8 characters, 1 lowercase letter, 1 uppercase letter, 1 digit, and 1 special character',
										},
									})}
									variant='outlined'
									placeholder='New password'
									type={showPassword ? 'text' : 'password'}
									fullWidth
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<LockIcon color='primary' />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton onClick={handleClickPassVisibility}>
													{showPassword ? <Visibility color='primary' /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
									}}
									error={!!errors.newPassword}
									helperText={errors.newPassword?.message}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									{...register('confirmNewPassword', {
										required: true,
										validate: value => value === password.current || 'The passwords do not match',
									})}
									variant='outlined'
									placeholder='Confirm new password'
									type={showConfirmPassword ? 'text' : 'password'}
									fullWidth
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<LockIcon color='primary' />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton onClick={handleClickConfirmPassVisibility}>
													{showConfirmPassword ? <Visibility color='primary' /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
									}}
									error={!!errors.confirmNewPassword}
									helperText={errors.confirmNewPassword?.message}
								/>
							</Grid>
							<Grid item xs={12} container justifyContent='center'>
								<Button
									type='submit'
									variant='contained'
									color='primary'
									fullWidth
									className={classes.submitButton}
									disabled={resetPasswordInfo.isLoading}
								>
									Submit
								</Button>
							</Grid>
						</Grid>
					</form>
				</Grid>
			</Grid>
		</>
	);
};

export default ResetPassword;
