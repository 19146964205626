import React from 'react';
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { useController } from 'react-hook-form';

/**
 * options = [
 *  {value: '', label:''}
 * ]
 */

const RHFRadioButton = ({
	label,
	control,
	name,
	rules,
	defaultValue = '',
	options = [],
	radioButtonSize = 'small',
}) => {
	const { field } = useController({
		control,
		name,
		rules: rules,
		defaultValue,
	});

	return (
		<FormControl>
			<FormLabel>
				{label} {rules?.['required'] === true ? '*' : ''}
			</FormLabel>
			<RadioGroup row {...field}>
				{options.map(option => (
					<FormControlLabel
						key={option.value}
						value={option.value}
						label={option.label}
						control={<Radio color='primary' size={radioButtonSize} required={rules?.['required']} />}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
};

export default RHFRadioButton;
