import axios from 'axios';
import browserHistory from '../history';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_ACTION = 'LOGOUT_ACTION';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';

export const loginSuccess = data => ({
	type: LOGIN_SUCCESS,
	data,
});

export const loginFail = data => ({
	type: LOGIN_FAIL,
	data,
});

export const loginRequest = () => ({ type: LOGIN_REQUEST });

export const logoutAction = data => ({
	type: LOGOUT_ACTION,
	data,
});

export const login = (email, password) => {
	return dispatch => {
		const loginData = { email: email, password: password };
		dispatch(loginRequest());
		return axios
			.post(`${process.env.REACT_APP_API_URL}/v1/auth`, { data: loginData }, { withCredentials: true })
			.then(result => {
				localStorage.setItem('access_token', result.data?.token);
				localStorage.setItem('nameLatin', result.data?.nameLatin);
				localStorage.setItem('role', result.data?.role);
				dispatch(loginSuccess(result.data));
				browserHistory.push('/dashboard');
			})
			.catch(error => {
				dispatch(loginFail(error));
				return error;
			});
	};
};
