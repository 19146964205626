import React, { useState, useEffect, useMemo } from 'react';
import { Box, ClickAwayListener, Grow, IconButton, makeStyles, Popper, Badge } from '@material-ui/core';
import { Notifications } from '@material-ui/icons';
import NotificationCard from './notification-card';
import axios from 'axios';

const useStyles = makeStyles(() => ({
	notiBox: {
		width: 400,
		padding: '10px',
		marginTop: '8px',
	},
	arrow: {
		position: 'absolute',
		fontSize: 7,
		width: '3em',
		height: '3em',
		'&::before': {
			content: '""',
			margin: 'auto',
			display: 'block',
			width: 0,
			height: 0,
			borderStyle: 'solid',
		},
	},
	iconButton: {
		'&:focus': {
			outline: 'none !important',
		},
	},
}));

const Notification = () => {
	const controller = new AbortController();

	const classes = useStyles();

	const [notifications, setNotifications] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	const open = Boolean(anchorEl);

	const handleOpen = e => setAnchorEl(anchorEl ? null : e.currentTarget);

	const handleClose = () => setAnchorEl(null);

	useEffect(() => {
		const fetchNotifications = () => {
			setIsLoading(true);
			axios
				.get(`${process.env.REACT_APP_API_URL}/v1/notification/getAll`, { signal: controller.signal })
				.then(({ data }) => {
					setNotifications(data.notifications);
					setIsLoading(false);
				})
				.catch(err => {
					if (axios.isCancel(err)) return;

					console.log('error get all notification :>> ', err);
					setIsLoading(false);
				});
		};

		anchorEl && fetchNotifications();

		return () => {
			controller.abort(); // cancel request
		};
	}, [anchorEl]);

	const handleMarkAllAsRead = async e => {
		try {
			e.preventDefault();
			const ids = notifications.map(notification => notification._id);
			await axios.post(`${process.env.REACT_APP_API_URL}/v1/notification/read`, { ids });

			setNotifications(prev => {
				const newData = prev.map(item => ({ ...item, isRead: true }));

				return newData;
			});
		} catch (error) {
			console.log('error mark all as read :>> ', error);
		}
	};

	const isNotReadNotifications = useMemo(() => {
		const isNotRead = notifications.filter(notification => notification.isRead === false);
		return isNotRead.length;
	}, [notifications]);

	return (
		<>
			<IconButton onClick={handleOpen} className={classes.iconButton} size='small'>
				<Badge badgeContent={isNotReadNotifications} color='secondary' overlap='circular'>
					<Notifications color='primary' fontSize='large' />
				</Badge>
			</IconButton>
			<Popper open={open} anchorEl={anchorEl} placement='bottom-end' transition>
				{({ TransitionProps }) => (
					<ClickAwayListener onClickAway={handleClose}>
						<Grow {...TransitionProps}>
							<Box className={classes.notiBox}>
								<NotificationCard
									notifications={notifications}
									setNotifications={setNotifications}
									isLoading={isLoading}
									handleMarkAllAsRead={handleMarkAllAsRead}
								/>
							</Box>
						</Grow>
					</ClickAwayListener>
				)}
			</Popper>
		</>
	);
};

export default Notification;
