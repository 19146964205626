import React, { useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import Navbar from '../../navbar'
import Drawer from './drawer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  content: {
    flexGrow: 1,
    display:'flex',
    flexDirection:'column',
    overflow: 'hidden',
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      margin: 0,
    },
    '& > div': {
      // overflowX: "hidden"
    },
    overflow: 'overlay',
    flexGrow: 1,
    padding:"30px",
    backgroundColor: '#fff',
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [drawer, setDrawer] = useState(false);

  const handleClose = () => setDrawer(false);
  const handleOpen = () => setDrawer(true);
  return (
    <div className={classes.root}>
        <Drawer open={drawer} handleClose={handleClose}/>
      <div className={classes.content}>
        <Navbar handleOpen={handleOpen} />
        <Container className={classes.container} maxWidth="xl">
          {children}
        </Container>
      </div>
    </div>
  );
};

export default DashboardLayout;
