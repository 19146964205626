import React from 'react';
import { Grid, makeStyles, Typography, Box, useTheme } from '@material-ui/core';
import axios from 'axios';
import Moment from 'react-moment';

const useStyles = makeStyles(theme => ({
	timestamp: {
		color: theme.palette.grey['600'],
		marginBottom: theme.spacing(0.4),
	},
}));

const NotificationLine = ({ notification, setNotifications }) => {
	const classes = useStyles();
	const theme = useTheme();

	const handleMarkAsRead = async () => {
		try {
			await axios.post(`${process.env.REACT_APP_API_URL}/v1/notification/read`, { ids: [notification._id] });
			setNotifications(prev =>
				prev.map(item => {
					if (item._id === notification._id) {
						return { ...item, isRead: true };
					}
					return item;
				})
			);
		} catch (error) {
			console.log('error mark as read :>> ', error);
		}
	};

	return (
		<Grid container justifyContent='space-between' alignItems='center'>
			<Grid container item xs={11}>
				{/* timestamp */}
				<Grid item xs={12} className={classes.timestamp}>
					<Moment format='YYYY-MM-DD [at] hh:mm a'>{notification.createdAt}</Moment>
				</Grid>
				{/* message */}
				<Grid item xs={12}>
					<Typography>{notification.message}</Typography>
				</Grid>
			</Grid>
			{/* button */}
			<Grid container item xs={1} justifyContent='flex-end'>
				{notification.isRead ? null : (
					<Box
						width={theme.spacing(1.5)}
						height={theme.spacing(1.5)}
						bgcolor={theme.palette.primary.main}
						borderRadius='50%'
						style={{ cursor: 'pointer' }}
						onClick={handleMarkAsRead}
					/>
				)}
			</Grid>
		</Grid>
	);
};

export default NotificationLine;
