import { createTheme } from "@material-ui/core/styles";

const primary = "#184782";

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
      light: "#EDF4FA",
    },
    secondary: {
      main: "#BB1313",
    },
    white: {
      main: "white",
    },
    text: {
      secondary: "#A2A2A2",
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "Siemreap",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        "&:focus": {
          outline: "none !important",
        },
        padding: "0px 30px",
        lineHeight: "2.25",
      },
      outlined: {
        padding: "0px 30px",
      },
    },
  },
  zIndex: {
    mobileStepper: 500,
    speedDial: 600,
    appBar: 700,
    drawer: 800,
    modal: 900,
    snackbar: 1000,
    tooltip: 1100,
  },
});

export default theme;
