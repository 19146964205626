import * as Auth from '../actions/login';

const initialState = {
	isLoginFail: false,
	isLoading: false,
};

const login = (state = { ...initialState }, action) => {
	switch (action.type) {
		case Auth.LOGIN_REQUEST:
			return {
				...state,
				isLoginFail: false,
				isLoading: true,
			};
		case Auth.LOGIN_SUCCESS:
			return {
				...state,
				...action.data,
				isLoginFail: false,
				isLoading: false,
			};
		case Auth.LOGIN_FAIL:
			return {
				...state,
				...action.data,
				isLoginFail: true,
				isLoading: false,
			};
		case Auth.LOGOUT_ACTION:
			return {
				...action.data,
			};
		default:
			return state;
	}
};

export default login;
