import React from 'react';
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';

import Title from '../Title';

const Dialog = ({ open, onClose, title, children, buttons = [], ...dialogProps }) => {
  return (
    <MuiDialog open={open} fullWidth maxWidth="sm" onClose={onClose} {...dialogProps}>
      <DialogTitle disableTypography>
        <Title title={title} />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        {buttons.map((button, index) => (
          <Button key={index} onClick={button.onClick} {...button}>
            {button.label}
          </Button>
        ))}
      </DialogActions>
    </MuiDialog>
  );
};

export default Dialog;
