/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import {
  Grid,
  Typography,
  TextField,
  Radio,
  FormControlLabel,
  RadioGroup,
  makeStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { debounce } from "debounce";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useForm, Controller } from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import "react-datepicker/dist/react-datepicker.css";

import ProvinceInfo from "../../../utils/ProvinceInfo";
import { inputNumberOnly } from "../../../utils";
import { onlyKhmerCharacter } from "../../../utils/functions/validateString";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },
  dialogRoot: {
    zIndex: 9999,
    "& .Mui-disabled": {
      background: "initial !important",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "800px !important",
    },
  },
}));

const OtherCompanyPopupForm = ({open, handleClose, data, setTriggerFetch}) => {
  const classes = useStyles();
  const { control, trigger, watch, setValue, handleSubmit, reset } = useForm();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true); //loading for representative autocomplete
  // Individual autocomplete option
  const [individualParticularOption, setIndividualParticularOption] = useState(
    []
  );
  const [dialogTitle, setDialogTitle] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  // Watches
  const legalEntityParticularRental = watch("address.isRental");
  const address = {
    city: watch("address.city"),
    commune: watch("address.commune"),
    district: watch("address.district"),
  };

  const debounceSearchIndividuals = useCallback(
    debounce((text) => {
      fetchIndividuals(text);
    }, 500),
    []
  );

  useEffect(() => {
    return () => {
      debounceSearchIndividuals.clear();
    };
  }, [debounceSearchIndividuals]);

  const fetchIndividuals = (searchText = "") => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/individual/list?search=${searchText}&page=1&limit=10`,
        { withCredentials: true }
      )
      .then((res) => {
        const individualParticulars = res.data?.individualParticular;

        const data = individualParticulars?.map((individual) => ({
          value: individual._id,
          label: individual.nameLatin,
          nameLatin: individual.nameLatin,
          nameKhmer: individual.nameKhmer,
          gender: individual.gender,
          nationality: individual.nationality,
          placeOfBirth: individual.placeOfBirth,
          dob: individual.dob,
          phoneNumber: individual.phoneNumber,
          email: individual.email,
          identity: individual.identity,
          file: individual.file,
        }));
        setIndividualParticularOption(data);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Handle Submit
  const onSubmit = async (dataInForm) => {
    setIsLoading(true);
    try {
      if (data) {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/v1/other-company/update/${data._id}`,
          dataInForm
        );
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/other-company/create`,
          dataInForm
        );
      }
      setIsLoading(false);
      enqueueSnackbar(`External Company has been ${data ? "updated" : "created"}.`, { variant: 'success' });
      setTriggerFetch(prev => !prev); // refetch external company from backend
      handleClose();
    } catch (error) {
      console.log(`error ${data ? "update" : "create"} external company :>> `, error);
      setIsLoading(false);
      enqueueSnackbar(`Error ${data ? "update" : "create"} external company.`, { variant: 'error' });
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Dialog
        open={open}
        fullWidth={true}
        className={classes.dialogRoot}
        onClose={isLoading? null: handleClose}
        TransitionProps={{ 
          onEntering: () => {
            setDialogTitle(() => {
              return data ? "Update External Company" : "Create External Company"
            })
            if (data) reset(data)
          },
          onExited: () => {
            reset({
              representative: null,
              "address.issuedDate": null,
              "registrationInfo.issuedDate": null,
              "address.isRental": "false",
            })
          }
        }}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            color: "#184782",
            padding: "20px 10px",
          }}
        >
          {dialogTitle}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid
              item
              container
              xs={12}
              direction="row"
              justifyContent="space-between"
              style={{ marginTop: "1rem" }}
            >
              <Grid item container xs={5}>
                <Typography>1. {t("company-name-latin")}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Controller
                  control={control}
                  name="companyNameLatin"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <TextField
                      value={value}
                      fullWidth
                      onChange={onChange}
                      label={t("company-name-latin-label")}
                      variant="outlined"
                      size="small"
                      error={!!error}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item container xs={5}>
                <Typography>2. {t("company-name-khmer")}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Controller
                  control={control}
                  render={({
                    field: { value, onChange, ref },
                    fieldState: { error },
                  }) => (
                    <TextField
                      variant="outlined"
                      label={t("company-name-khmer-label")}
                      type="text"
                      fullWidth
                      inputRef={ref}
                      size="small"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        // trigger validate on every input
                        trigger("companyNameKhmer");
                      }}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                  name="companyNameKhmer"
                  defaultValue=""
                  rules={{
                    required: true,
                    validate: (value) => onlyKhmerCharacter({ value }),
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item container xs={5}>
                <Typography>3. {t("registration-information")}</Typography>
              </Grid>
              <Grid item container xs={7}>
                <Grid item container xs={6} style={{ padding: "0 8px 0px 0" }}>
                  <Controller
                    control={control}
                    name="registrationInfo.number"
                    defaultValue=""
                    rules={{ required: true }}
                    render={({
                      field: { value, onChange, ref },
                      fieldState: { error },
                    }) => (
                      <TextField
                        variant="outlined"
                        label={t("registration-number")}
                        type="text"
                        fullWidth
                        inputRef={ref}
                        size="small"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                      />
                    )}
                  />
                </Grid>
                <Grid item container xs={6} style={{ padding: "0 0 0px 8px" }}>
                  <Controller
                    control={control}
                    name="registrationInfo.issuedDate"
                    defaultValue={null}
                    rules={{ required: true }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <KeyboardDatePicker
                        // initialFocusedDate={new Date('1990/01/01')}
                        className={classes.root}
                        fullWidth
                        autoOk
                        label={t("ចុះថ្ងៃទី")}
                        variant="inline"
                        inputVariant="outlined"
                        size="small"
                        format="dd/MM/yyyy"
                        margin="none"
                        value={value}
                        onChange={onChange}
                        KeyboardButtonProps={{
                          style: {
                            outline: "none",
                          },
                        }}
                        error={!!error}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item container xs={5}>
                <Typography>4. {t("tax-identification-number")}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Controller
                  control={control}
                  render={({
                    field: { value, onChange, ref },
                    fieldState: { error },
                  }) => (
                    <TextField
                      variant="outlined"
                      label={t("tax-identification-number-label")}
                      type="text"
                      fullWidth
                      inputRef={ref}
                      size="small"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                    />
                  )}
                  name="taxIdNumber"
                  defaultValue=""
                  rules={{ required: true }}
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item container xs={5}>
                <Typography>5. {t("share-percentage")}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Controller
                  control={control}
                  render={({
                    field: { value, onChange, ref },
                    fieldState: { error },
                  }) => (
                    <TextField
                      variant="outlined"
                      label={t("share-percentage-label")}
                      onKeyPress={inputNumberOnly}
                      fullWidth
                      inputRef={ref}
                      size="small"
                      value={value}
                      onChange={onChange}
                      error={!!error}
                    />
                  )}
                  name="sharePercentage"
                  defaultValue=""
                  rules={{ required: true }}
                />
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item container xs={5}>
                <Typography>6. {t("registered-address")}</Typography>
              </Grid>
              <Grid item container xs={7}>
                <Grid item xs={6} style={{ padding: "0 8px 16px 0" }}>
                  <Controller
                    control={control}
                    render={({
                      field: { value, onChange, ref },
                      fieldState: { error },
                    }) => (
                      <TextField
                        variant="outlined"
                        label={t("No")}
                        type="text"
                        fullWidth
                        inputRef={ref}
                        size="small"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                      />
                    )}
                    name="address.homeNumber"
                    defaultValue=""
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item xs={6} style={{ padding: "0 0 16px 8px" }}>
                  <Controller
                    control={control}
                    render={({
                      field: { value, onChange, ref },
                      fieldState: { error },
                    }) => (
                      <TextField
                        variant="outlined"
                        label={t("street")}
                        type="text"
                        fullWidth
                        inputRef={ref}
                        size="small"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                      />
                    )}
                    name="address.street"
                    defaultValue=""
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item xs={6} style={{ padding: "0 8px 16px 0" }}>
                  <Controller
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        autoHighlight
                        value={value}
                        options={ProvinceInfo.generateCityOptions()}
                        getOptionLabel={(option) => (option ? option.en : null)}
                        getOptionSelected={(option, value) => {
                          if (value === null || value === undefined) {
                            return true;
                          } else if (value.value === option.value) {
                            return true;
                          }
                        }}
                        onChange={(e, data) => {
                          onChange(data);
                          setValue("address.district", null);
                          setValue("address.commune", null);
                          setValue("address.village", null);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("city")}
                            variant="outlined"
                            size="small"
                            error={!!error}
                          />
                        )}
                      />
                    )}
                    control={control}
                    name="address.city"
                    defaultValue={null}
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item xs={6} style={{ padding: "0 0 16px 8px" }}>
                  <Controller
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        autoHighlight
                        value={value}
                        options={ProvinceInfo.generateDistrictOptions(address)}
                        getOptionLabel={(option) => (option ? option.en : null)}
                        getOptionSelected={(option, value) => {
                          if (value === null) {
                            return true;
                          } else if (value.value === option.value) {
                            return true;
                          }
                        }}
                        onChange={(e, data) => {
                          onChange(data);
                          setValue("address.commune", null);
                          setValue("address.village", null);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("district")}
                            variant="outlined"
                            size="small"
                            error={!!error}
                          />
                        )}
                      />
                    )}
                    control={control}
                    name="address.district"
                    rules={{ required: true }}
                    defaultValue={null}
                  />
                </Grid>
                <Grid item xs={6} style={{ padding: "0 8px 16px 0" }}>
                  <Controller
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                      autoHighlight
                        value={value}
                        options={ProvinceInfo.generateCommuneOptions(address)}
                        getOptionLabel={(option) => (option ? option.en : null)}
                        getOptionSelected={(option, value) => {
                          if (value === null) {
                            return true;
                          } else if (value.value === option.value) {
                            return true;
                          }
                        }}
                        onChange={(e, data) => {
                          onChange(data);
                          setValue("address.village", null);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("commune")}
                            variant="outlined"
                            size="small"
                            error={!!error}
                          />
                        )}
                      />
                    )}
                    control={control}
                    name="address.commune"
                    rules={{ required: true }}
                    defaultValue={null}
                  />
                </Grid>
                <Grid item xs={6} style={{ padding: "0 0 16px 8px" }}>
                  <Controller
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        autoHighlight
                        value={value}
                        options={ProvinceInfo.generateVillageOptions(address)}
                        getOptionLabel={(option) => (option ? option.en : null)}
                        getOptionSelected={(option, value) => {
                          if (value === null) {
                            return true;
                          } else if (value.value === option.value) {
                            return true;
                          }
                        }}
                        onChange={(e, data) => onChange(data)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("village")}
                            variant="outlined"
                            size="small"
                            error={!!error}
                          />
                        )}
                      />
                    )}
                    control={control}
                    name="address.village"
                    rules={{ required: true }}
                    defaultValue={null}
                  />
                </Grid>
                <Grid item xs={6} style={{ padding: "0 8px 16px 0" }}>
                  <Controller
                    control={control}
                    rules={{required: true}}
                    render={({
                      field: { value, onChange, ref },
                      fieldState: { error },
                    }) => (
                      <TextField
                        variant="outlined"
                        label={t("group")}
                        type="text"
                        fullWidth
                        inputRef={ref}
                        size="small"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                      />
                    )}
                    name="address.group"
                    defaultValue=""
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="address.isRental"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <RadioGroup
                        row
                        aria-label="isRental"
                        value={String(value)}
                        onChange={onChange}
                      >
                        <FormControlLabel
                          value="false"
                          control={<Radio color="primary" />}
                          label={t("ownership")}
                        />
                        <FormControlLabel
                          value="true"
                          control={<Radio color="primary" />}
                          label={t("rent")}
                        />
                      </RadioGroup>
                    )}
                  />
                </Grid>
                <Grid item xs={12} container style={{ padding: "0 0 16px 0" }}>
                  <Controller
                    control={control}
                    render={({
                      field: { value, onChange, ref },
                      fieldState: { error },
                    }) => (
                      <TextField
                        variant="outlined"
                        label={t("property-registration-number")}
                        type="text"
                        fullWidth
                        inputRef={ref}
                        size="small"
                        value={value}
                        onChange={onChange}
                        error={!!error}
                      />
                    )}
                    name="address.propertyIdentificationNumber"
                    defaultValue=""
                    rules={{ required: true }}
                  />
                </Grid>

                <Grid item container xs={6} style={{ padding: "0 8px 0px 0" }}>
                  <Controller
                    control={control}
                    name="address.issuedDate"
                    defaultValue={null}
                    rules={{ required: true }}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <KeyboardDatePicker
                        // initialFocusedDate={new Date('1990/01/01')}
                        className={classes.root}
                        autoOk
                        label={t("ចុះថ្ងៃទី")}
                        variant="inline"
                        inputVariant="outlined"
                        size="small"
                        format="dd/MM/yyyy"
                        fullWidth
                        margin="none"
                        value={value}
                        onChange={onChange}
                        KeyboardButtonProps={{
                          style: {
                            outline: "none",
                          },
                        }}
                        error={!!error}
                      />
                    )}
                  />
                </Grid>
                <Grid item container xs={6} style={{ padding: "0 0 0px 8px" }}>
                  <Controller
                    control={control}
                    render={({
                      field: { value, onChange, ref },
                      fieldState: { error },
                    }) => (
                      <TextField
                        variant="outlined"
                        label={t("branch-rental-fee")}
                        type="text"
                        fullWidth
                        inputRef={ref}
                        size="small"
                        value={value}
                        disabled={legalEntityParticularRental === "false"}
                        onChange={onChange}
                        error={!!error}
                      />
                    )}
                    name="address.rentalFee"
                    defaultValue=""
                    rules={{ required: legalEntityParticularRental === "true" }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              container
              xs={12}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item container xs={5}>
                <Typography>7. {t("represented-by")}</Typography>
              </Grid>
              <Grid item xs={7}>
                <Controller
                  control={control}
                  render={({
                    field: { value, onChange, ref },
                    fieldState: { error },
                  }) => {
                    return (
                      <Autocomplete
                        autoHighlight
                        value={value}
                        options={individualParticularOption || []}
                        onOpen={fetchIndividuals}
                        filterSelectedOptions
                        clearOnBlur
                        loading={loading}
                        getOptionLabel={(option) => {
                          if (typeof option === "string") {
                            return option;
                          }
                          if (option.inputValue) {
                            return option.label;
                          }
                          return option.label || option.nameLatin;
                        }}
                        getOptionSelected={(option, value) => {
                          if (value === null) {
                            return true;
                          } else if (typeof value === "undefined") {
                            return true;
                          } else if (value._id === option._id) {
                            return true;
                          }
                        }}
                        onChange={(e, data) => onChange(data)}
                        onInputChange={(event, text) => {
                          setLoading(true);
                          debounceSearchIndividuals(text);
                        }}
                        renderInput={(params) => (
                          <TextField
                            inputRef={ref}
                            {...params}
                            label={t("represented-by-label")}
                            variant="outlined"
                            size="small"
                            error={!!error}
                          />
                        )}
                      />
                    );
                  }}
                  name="representative"
                  defaultValue={null}
                  rules={{ required: true }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        {/*  button */}
        <DialogActions
          style={{ padding: "1rem 1.5rem", justifyContent: "space-between" }}
        >
          <Grid
            item
            xs={12}
            container
            spacing={3}
            style={{ marginTop: "1rem" }}
          >
            <Grid item xs={12} sm={4} md={3} lg={2} container>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                fullWidth
                onClick={handleClose}
                disabled={isLoading}
                >
                Back
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2} container>
              <Button
                onClick={handleSubmit(onSubmit)}
                size="small"
                variant="contained"
                color="primary"
                fullWidth
                disabled={isLoading}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  );
};

export default OtherCompanyPopupForm;
