import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(227, 0, 77, 0.07)',
    color: '#E3004D',
    boxShadow: '0px 3px 6px 1px rgba(227, 0, 77, 0.11)',
    '& svg': {
      fontSize: '16px',
    },
    padding: '7px',
  },
}));

const DeleteButton = ({ ...other }) => {
  const classes = useStyles();

  return (
    <>
      <IconButton className={classes.root} {...other}>
        <DeleteIcon />
      </IconButton>
    </>
  );
};

export default DeleteButton;
