import React from 'react';
import { Chip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  statusChip: {
    backgroundColor: 'rgba(1, 106, 178, 0.07)',
    padding:'0 0.5rem',
    color: theme.palette.primary.main,
    boxShadow: '0px 3px 6px -2px rgba(9, 59, 118, 0.24)',
  },
}));

const StatusChip = ({ label, ...other }) => {
  const classes = useStyles();
  
  return <Chip className={classes.statusChip} label={label} {...other} />;
};

export default StatusChip;
