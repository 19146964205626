import { Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';

const NameEmailPhone = ({ control, showNameKhmer = false }) => {
	return (
		<>
			<Grid item container xs={12} spacing={2}>
				<Grid item xs={4}>
					<Typography>Full Name (Latin)</Typography>
				</Grid>
				<Grid item xs={8}>
					<Controller
						render={({ field, fieldState: { error } }) => (
							<TextField
								{...field}
								variant='outlined'
								label='Name (Latin)'
								type='text'
								fullWidth
								size='small'
								error={!!error}
							/>
						)}
						control={control}
						name='nameLatin'
						defaultValue=''
						rules={{ required: true }}
					/>
				</Grid>
			</Grid>
			{showNameKhmer && (
				<Grid item container xs={12} spacing={2}>
					<Grid item xs={5}>
						<Typography>Full Name (Khmer)</Typography>
					</Grid>
					<Grid item xs={7}>
						<Controller
							render={({ field, fieldState: { error } }) => (
								<TextField
									{...field}
									variant='outlined'
									label='Name (Khmer)'
									type='text'
									fullWidth
									size='small'
									error={!!error}
								/>
							)}
							control={control}
							name='nameKhmer'
							defaultValue=''
							rules={{ required: true }}
						/>
					</Grid>
				</Grid>
			)}
			<Grid item container xs={12} spacing={2}>
				<Grid item xs={4}>
					<Typography>Email</Typography>
				</Grid>
				<Grid item xs={8}>
					<Controller
						render={({ field, fieldState: { error } }) => (
							<TextField
								{...field}
								variant='outlined'
								label='Email'
								type='email'
								fullWidth
								size='small'
								error={!!error}
							/>
						)}
						control={control}
						name='email'
						defaultValue=''
						rules={{
							required: true,
							validate: value => {
								const email = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(value);
								return email;
							},
						}}
					/>
				</Grid>
			</Grid>
			<Grid item container xs={12} spacing={2}>
				<Grid item xs={4}>
					<Typography>Phone Number</Typography>
				</Grid>
				<Grid item xs={8}>
					<Controller
						render={({ field: { name, ...field }, fieldState: { error } }) => (
							<TextField
								{...field}
								variant='outlined'
								type='number'
								label='Phone number'
								fullWidth
								size='small'
								error={!!error}
							/>
						)}
						control={control}
						name='phoneNumber'
						defaultValue=''
						rules={{ required: true }}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default NameEmailPhone;
