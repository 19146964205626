import React from 'react';
import { AppBar, makeStyles, Toolbar} from '@material-ui/core';
import Profile from './profile';
import Notification from './notification';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white',
    color: 'black',
    '& > div': {
      justifyContent: 'flex-end',
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '30px',
  },
}));

const Navbar = ({ handleOpen }) => {
  const classes = useStyles();

  return (
    <AppBar position='static' className={classes.root} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Notification />
        <Profile />
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

// import React, { useState, useEffect } from 'react';
// import browserHistory from '../../history';
// import { faBell } from '@fortawesome/free-solid-svg-icons';
// import ProfilePic from '../../assets/images/17004.png';
// import './index.css';
// import Axios from 'axios';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// const Navbar = (props) => {
//     const [notificationState, setNotificationState] = useState([]);

//     useEffect(() => {
//         const unsubscribe = () => {
//             Axios.get(`${process.env.REACT_APP_API_URL}/notification`, {
//                 headers: {
//                     'Authorization': `bearer ${localStorage.getItem('access_token')}`,
//                 }
//             }).then( ({ data }) => {
//                 setNotificationState( data.notification );
//             }).catch( err => {
//                 console.log( 'get all notification', err );
//             });
//         };

//         unsubscribe();
//     }, []);

//     const handleMarkAllAsRead = () => {
//         Axios.put(`${process.env.REACT_APP_API_URL}/notification/mark-all-as-read`, null, {
//             headers: {
//                 'Authorization': `bearer ${localStorage.getItem('access_token')}`,
//             }
//         }).then( res => {
//             console.log( 'mark-all-as-read', res );
//         }).catch( err => console.log('mark-all-as-read', err));

//         setNotificationState( notificationState.map( (noti) => {
//             noti.isRead = true;
//             return noti;
//         }) );
//     }

//     const handleNotification = (companyId, index, type = 'click') => {
//         Axios.patch(`${process.env.REACT_APP_API_URL}/notification/${notificationState[index]._id}/read`, { data: !notificationState[index].isRead }, {
//             headers: {
//                 'Authorization': `bearer ${localStorage.getItem('access_token')}`,
//             }
//         }).then( ({data}) => {
//             console.log('patch mark as read', data );
//         }).catch( err => console.error( 'patch mark as read', err.message ));

//         setNotificationState( notificationState.map( (noti, idx) => {
//             if( index === idx )
//                 noti.isRead = !noti.isRead;

//             return noti;
//         }) );
//         type === 'click' && props.history.push(`/company/${companyId}/edit`);
//     };

//     const listAllNotification = () => {
//         return notificationState.map((notification, index) =>
//             <li key={index} className="list-group-item notification-box" onClick={ () => handleNotification(notification.company, index) }>
//                 { notification.isRead ? <div>{notification.message}</div> : <strong>{notification.message}</strong> }
//                 <div className="d-flex justify-content-end">
//                     <button type="button" className="btn btn-info btn-sm mt-2" onClick={ e => { e.stopPropagation(); handleNotification(notification.company, index, 'mark') } }>
//                         Mark as { notification.isRead ? 'Unread' : 'Read' }
//                     </button>
//                 </div>
//             </li>
//         )
//     }

//     /// i need to count from the server, in case there are too many unread notification
//     const getNotificationCounter = () => notificationState.filter( noti => noti.isRead === false ).length;

//     return(
//             <nav className="navbar navbar-expand navbar-light bg-dark fixed-top">
//                 <ul className="navbar-nav col-sm-10 mx-auto justify-content-end">
//                     <li className="nav-item dropdown">
//                         <a className="nav-link" href="#123" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
//                             <FontAwesomeIcon icon={ faBell } className="text-white" transform="grow-7 down-4 right-8" />
//                             { getNotificationCounter() > 0 && <span className="badge badge-danger align-top">
//                                 { getNotificationCounter() }
//                             </span> }
//                         </a>
//                         <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
//                             <div className="notification">
//                                 <li className="head sticky-top bg-light">
//                                     <div className="row">
//                                         <div className="col-lg-12 col-sm-12 col-12">
//                                             <span>Notifications ({getNotificationCounter()})</span>
//                                             <a href="#" onClick={ handleMarkAllAsRead } className="float-right">Mark all as read</a>
//                                         </div>
//                                     </div>
//                                 </li>
//                                 { listAllNotification() }
//                                 {/* <li className="footer bg-light text-center">
//                                     <a href="#123" className="">View All</a>
//                                 </li> */}
//                             </div>
//                         </ul>
//                     </li>
//                     <li className="nav-item dropdown mx-2">
//                         <a className="nav-link " href="#123" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown">
//                             <img src={ ProfilePic } width="32" height="32" className="d-inline-block align-top grey-border" alt="" />
//                         </a>
//                         <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
//                             <button className="dropdown-item" onClick={ () => browserHistory.push('/dashboard') }>Company</button>
//                             { localStorage.getItem('role') === 'admin' && <button className="dropdown-item" onClick={ () => browserHistory.push('/staff') }>Staff</button> }
//                             <button className="dropdown-item" onClick={ () => browserHistory.push('/profile') }>Edit Profile</button>
//                             <button className="dropdown-item" onClick={ props.logout() }>Logout</button>
//                         </div>
//                     </li>
//                 </ul>
//             </nav>
//     )
// }

// export default Navbar;
