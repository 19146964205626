import React, { useState, useRef } from 'react';
import { Box, Typography, Switch } from '@material-ui/core';
import {  Controller } from 'react-hook-form'


const SwitchItem = ( { permission: { permissionName, boolean }, feature, register, control } ) => {
  const [check, setCheck] = useState( boolean );
  const buttonRef = useRef();

  return (
    <Box>
      <Typography style={{ textTransform: 'capitalize' }} color={check ? 'primary' : 'inherit'} gutterBottom>{permissionName}</Typography>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
      <Controller
        control={control}
        name={`${feature}.${permissionName}`}
        render={({ field: {  onChange, value, ...field } }) => (
            <>
            <Switch 
              { ...field }
              checked={!!value}
              color="primary" 
              onChange={ ( event, check ) => { 
                onChange( check );
                // submit on every change
                buttonRef.current.click();
                setCheck( check )
               } }/>
            </>
        )}
      />
        <Typography
          color={check
            ? 'primary' : 'inherit'}
          style={{ minWidth: '35px' }}
        >
          {check ? 'ON' : 'OFF'}
        </Typography>
        <button ref={buttonRef} type="submit" style={{ display: 'none' }}>submit</button>
      </Box>
    </Box>
  );
};

export default SwitchItem;
