import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
    titleStyleCenter: {
        fontWeight: "bold",
        textAlign: "center",
        padding: '1rem',
    },
    titleStyleStart: {
        fontWeight: "bold",
        padding: '1rem 1rem 1rem 0',
        textTransform: 'capitalize'
    }
}))

const Title = ({ title, alignStart }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Typography variant="h4" color="primary" className={alignStart ? classes.titleStyleStart : classes.titleStyleCenter}>
            { t(title) }
        </Typography>
    )
}

export default Title
