export const FORM_INFO = 'FORM_INFO'

const FormInfo=( data ) => ({
    type: FORM_INFO,
    data
});

export const add = data => {
    return(dispatch)=>{
        dispatch( FormInfo( data ) );
    }
};

