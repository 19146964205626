import * as Company from '../actions/company';

export default ( state = {}, action ) => {
    switch( action.type ){
        case Company.SINGLE_COMPANY_LOAD_ACTION: return { ...state, ...action.data };
        case Company.SINGLE_COMPANY_LOAD_SUCCESS: return { ...state, ...action.data };
        case Company.SINGLE_COMPANY_LOAD_FAIL: return { ...state, ...action.data };

        //case Company.COMPANY_SAVE_ACTION: return { ...state, ...action.data };
        //case Company.COMPANY_SAVE_SUCCESS: return { ...state, ...action.data };
        //case Company.COMPANY_SAVE_FAIL: return { ...state, ...action.data };
        default: return state;
    }
}