import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const menu = [
    {
        title: 'Registered Companies',
        qty: '',
        path: '/dashboard/company-status/registered-company',
        icon: ArrowForwardIcon,
    },
    {
        title: 'PLC Companies',
        qty: "",
    },
    {
        title: 'Co., LTD Companies',
        qty: "",
    },
    {
        title: 'Reserved Company Name',
        qty: "",
        path: "/dashboard/company-status/reserved-company",
        icon: ArrowForwardIcon,
    },
    {
        title: 'Drafts',
        qty: "",
        path: "/dashboard/company-status/draft",
        icon: ArrowForwardIcon,
    },
    {
        title: 'Reviews',
        qty: "",
        path: "/dashboard/company-status/reviewing",
        icon: ArrowForwardIcon,
    },
    {
        title: 'Approved',
        qty: "",
        path: "/dashboard/company-status/approved",
        icon: ArrowForwardIcon,
    },
    {
        title: 'Other Company',
        qty: "",
        path: "/dashboard/other-company",
        icon: ArrowForwardIcon,
    },
]

export default menu;