import { Grid } from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import axios from 'axios';

import menu from '../../components/dashboardCard/data';
import DashboardCard from '../../components/dashboardCard/index';
import Title from '../../components/Title';

const Dashboard = () => {
    const [cloneMenu, setCloneMenu] = useState(menu);

    useEffect(()=>{
        axios.get(`${ process.env.REACT_APP_API_URL }/v1/companies/all-company-lists`, {withCredentials: true})
        .then( res => {
            const { query } = res?.data

            const temp = [...cloneMenu];
            for(let i=0; i<query?.length; i++){
                temp[i].qty = res?.data?.query[i];
            }
            setCloneMenu(temp);
        }).catch(error => {
            console.log('error get all company list :>> ', error);
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Grid container>
            <Grid item xs={12} style={{paddingBottom: '0.5rem'}}>
                <Title title="Dashboard" alignStart />
            </Grid>
            <Grid container spacing={3}>
                {cloneMenu?.map(({title, qty, path, icon}, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <DashboardCard title={title} qty={qty} path={path} icon={icon} />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}

export default Dashboard
