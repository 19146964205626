import React from 'react';
import {
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Grid,
  TextField,
} from '@material-ui/core';
import axios from 'axios';
import { useHistory } from 'react-router';
import swal from 'sweetalert2';
import Title from '../../Title';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  primButton: {
    backgroundColor: 'rgba(227, 0, 77, 0.07)',
    color: 'rgba(227, 0, 77, 1)',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  secButton: {
    backgroundColor: 'rgba(1, 106, 178, 0.07)',
    color: 'rgba(1, 106, 178)',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
}));

const DisabledTextField = ({ defaultValue }) => (
  <TextField
    key={defaultValue}
    fullWidth
    disabled
    variant='outlined'
    defaultValue={defaultValue}
    size='small'
  />
);

const ConfirmDeleteDialog = ({ staff, open, handleClose }) => {
  const history = useHistory();
  const classes = useStyles();

  const handleConfirm = () => {
    deleteStaff();
    handleClose();
  };

  const deleteStaff = async () => {
    swal.fire({
      text: 'Deleting...',
      allowOutsideClick: false,
      onOpen: () => {
        swal.showLoading();
      },
    });

    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/staff/${staff._id}/delete`,
        {
          headers: {
            Authorization: `bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );

      swal
        .fire({
          text: 'Deleted',
          timer: 1000,
          icon: 'success',
          onOpen: () => {
            swal.hideLoading();
          },
        })
        .then(() => {
          history.go(0);
        });
    } catch (error) {
      console.log(error);
      swal.fire({
        text: error.response.data.message,
        icon: 'error',
        onOpen: () => {
          swal.hideLoading();
        },
      });
    }
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth={true}
    >
      <DialogTitle disableTypography>
        <Title title='Confirm Staff Delete' />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid container item xs={12} alignItems='center'>
            <Grid item xs={4}>
              Id
            </Grid>
            <Grid item xs={8}>
              <DisabledTextField defaultValue={staff?.idOrPassport?.number} />
            </Grid>
          </Grid>
          <Grid container item xs={12} alignItems='center'>
            <Grid item xs={4}>
              Full Name
            </Grid>
            <Grid item xs={8}>
              <DisabledTextField defaultValue={staff?.nameLatin} />
            </Grid>
          </Grid>
          <Grid container item xs={12} alignItems='center'>
            <Grid item xs={4}>
              Email
            </Grid>
            <Grid item xs={8}>
              <DisabledTextField defaultValue={staff?.email} />
            </Grid>
          </Grid>
          <Grid container item xs={12} alignItems='center'>
            <Grid item xs={4}>
              Phone Number
            </Grid>
            <Grid item xs={8}>
              <DisabledTextField defaultValue={staff?.phoneNumber} />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        style={{ padding: '1rem 1.5rem', justifyContent: 'space-between' }}
      >
        <Button onClick={handleClose} className={classes.secButton}>
          Cancel
        </Button>
        <Button onClick={handleConfirm} className={classes.primButton}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
