import { compose, withHandlers } from 'recompose';
import preload from './preload';
import NewCompany from './presenter';
import { connect } from 'react-redux';
import * as CompanyActions from '../../../actions/company';
import { convertPdfToBase64 } from '../../../utils';

const handler = withHandlers({
    handleSubmit: ({ submitHandler }) => async ( data ) => {
        //console.log( data, data.individualParticular )
        data.isSaveDraft = false;
        data.individualParticular = await Promise.all(
            data.individualParticular.map( async indv => {
                if( indv.files[0] )
                    indv.file = await convertPdfToBase64(indv.files[0]);
                delete indv.files;
                return indv;
            })
        );

        // save data to server
        submitHandler( data );
    },
    handleSaveDraft: ({ submitHandler }) => async ( data ) => {
        data.isSaveDraft = true;

        data.individualParticular = await Promise.all(
            data.individualParticular.map( async indv => {
                if( indv.files[0] )
                    indv.file = await convertPdfToBase64(indv.files[0]);
                delete indv.files;
                return indv;
            })
        );
        // console.log( data );
        submitHandler(data);
    },
});

const mapStateToProps = ( state ) => ({

});

const mapDispatchToProps = ( dispatch ) => ({
    submitHandler: ( companyInfo ) => dispatch( CompanyActions.saveCompany( companyInfo ) ),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    preload,
    handler
)( NewCompany );