import React, {useState} from 'react';
import {
  Avatar,
  Box,
  Drawer,
  makeStyles,
  Typography,
  // useMediaQuery,
} from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import SubMenu from './menu/index';
import clsx from 'clsx';

const drawerWidth = 290;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  paper: {
    width: drawerWidth,
    borderRight: 0,
    display: 'flex',
    overflowY: 'overlay',
    backgroundColor: '#E6E7E8',
    // boxShadow:
    //   '2px 64px 4px 0px rgb(0 0 0 / 7%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 10%)',
  },
  logo: {
    width: '60%',
    height: 'auto',
    '& img': {
      objectFit: 'contain',
    },
  },
  logoContainer: {
    width: '100%',
    // marginTop: theme.spacing(1.8),
    height: '64px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // position: 'absolute',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create( 'width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    } ),
    borderRight: '0',
    backgroundColor: '#E6E7E8',
  },
  drawerClose: {
    transition: theme.transitions.create( 'width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    } ),
    overflowX: 'hidden',
    width: theme.spacing( 7.5 ) + 1,
    borderRight: '0',
    backgroundColor: '#E6E7E8',
  },
}));

const DrawerComponent = ({ handleClose }) => {
  const classes = useStyles();
  // const matchSM = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [open, setOpen] = useState( true );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Drawer
        open={open}
        variant="permanent"
        // variant={!matchSM ? 'permanent' : 'temporary'}
        // onClose={handleClose}
        // className={classes.drawer}
        // classes={{
        //   paper: classes.paper,
        // }}
        className={clsx( classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        } )}
        classes={{
          paper: clsx( {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          } ),
        }}
      >
          <Box className={classes.logoContainer}>
          {
            open ? (
              <>
                <Avatar
                  src="/logo.png"
                  alt="logo"
                  className={classes.logo}
                  variant="square"
                />
                <IconButton color="primary" edge="end" onClick={handleDrawerClose} style={{marginLeft: '0.5rem'}}>
                  <ChevronLeftIcon />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  color="primary"
                  onClick={handleDrawerOpen}
                >
                  <MenuIcon />
                </IconButton>
              </>
            )
          }
          </Box>
        <SubMenu handleClose={handleClose} open={open} setOpen={setOpen} />

        <Typography variant='caption' style={{ marginLeft: 8 }}>
          Version {process.env.REACT_APP_VERSION}
        </Typography>
      </Drawer>
    </>
  );
};

export default DrawerComponent;
