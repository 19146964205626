// Third party's imports
import { useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import axios from 'axios';
import { useSnackbar } from 'notistack';

const useBusinessActivityDialog = ({ selectedItem, handleCloseDialog, setTriggerFetch }) => {
	const {
		control,
		handleSubmit,
		reset,
		formState: { isDirty },
	} = useForm();
	const { fields, append, remove } = useFieldArray({ control, name: 'subActivities' });
	const { enqueueSnackbar } = useSnackbar();

	const [isLoading, setIsLoading] = useState(false);

	/**
	 * set selected business activity to input form
	 */
	const handleSetSelectedDataToForm = () => {
		if (selectedItem) {
			reset({
				mainActivityEN: selectedItem?.nameEN,
				mainActivityKH: selectedItem?.nameKH,
				license: selectedItem?.license,
				subActivities: selectedItem?.subItems.map(subItem => ({
					subActivityEN: subItem?.nameEN,
					subActivityKH: subItem?.nameKH,
					license: subItem?.license,
				})),
			});
		}
	};

	const onSubmit = async data => {
		try {
			setIsLoading(true);
			if (selectedItem) {
				// update
				await axios.put(`${process.env.REACT_APP_API_URL}/v1/business-activity/${selectedItem?._id}`, data);
				enqueueSnackbar('Update business activity successfully', { variant: 'success' });
				setIsLoading(false);
				handleCloseDialog();
			} else {
				if (!data.subActivities) {
					delete data.subActivities;
				}
				await axios.post(`${process.env.REACT_APP_API_URL}/v1/business-activity`, data, {
					withCredentials: true,
				});
				enqueueSnackbar('Create business activity successfully', { variant: 'success' });
				setIsLoading(false);
				handleCloseDialog();
			}
		} catch (error) {
			setIsLoading(false);
			console.log('error', error);
			enqueueSnackbar(`${error?.response?.data?.message || 'Something went wrong.'}`, { variant: 'error' });
		} finally {
			setTriggerFetch(prev => !prev); // refetch business activity
		}
	};

	const handleAppendField = () => append({ subActivityEN: '', subActivityKH: '' });

	const handleRemoveField = index => remove(index);

	const handleResetField = () => {
		reset({
			mainActivityEN: '',
			mainActivityKH: '',
			license: false,
			subActivities: [],
		});
	};

	return {
		isLoading,

		control,
		isDirty,
		handleSubmit,
		onSubmit,
		handleResetField,
		handleSetSelectedDataToForm,

		fields,
		handleAppendField,
		handleRemoveField,
	};
};

export default useBusinessActivityDialog;
