import axios from 'axios';
import browserHistory from '../history';

export const RESET_REQUEST = 'RESET_REQUEST';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAIL = 'RESET_FAIL';
export const RESET_ACTION = 'RESET_ACTION';

export const resetRequest = () => ({ type: RESET_REQUEST });

export const resetSuccess = data => ({
	type: RESET_SUCCESS,
	data,
});

export const resetFail = data => ({
	type: RESET_FAIL,
	data,
});

export const resetAction = data => ({
	type: RESET_ACTION,
	data,
});

export const resetPassword = (newPassword, confirmNewPassword, otp) => {
	return async dispatch => {
		const email = localStorage.getItem('email');

		const data = {
			otp: otp,
			newPassword: newPassword,
			confirmNewPassword: confirmNewPassword,
			email,
		};
		try {
			dispatch(resetRequest());
			const result_1 = await axios.post(`${process.env.REACT_APP_API_URL}/v1/auth/reset-password`, {
				data,
			});
			localStorage.removeItem('email');
			dispatch(resetSuccess(result_1.data));
			browserHistory.push('/reset-password-successfully');
		} catch (error) {
			dispatch(resetFail(error?.response?.data));
		}
	};
};
