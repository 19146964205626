import React from 'react';
import { Checkbox, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import { Controller, useWatch } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';

import ProvinceInfo from '../../../utils/ProvinceInfo';

const Address = ({ control, rhfSetValue }) => {
	const address = useWatch({ control, name: 'address' });

	return (

			<Grid item container xs={12}>
				<Grid item container xs={4} alignItems='flex-start'>
					<Typography>Current address</Typography>
				</Grid>
				{/* Current address section */}
				<Grid item container xs={8} spacing={2}>
					<Grid item  xs={6} >
						<Controller
							render={({ field }) => (
								<TextField
									{...field}
									variant='outlined'
									disabled={address.isManual}
									label='No.'
									type='text'
									fullWidth
									size='small'
								/>
							)}
							control={control}
							name='address.housenumber'
							defaultValue=''
							rules={{ required: false }}
						/>
					</Grid>
					<Grid item  xs={6} >
						<Controller
							render={({ field }) => (
								<TextField
									{...field}
									variant='outlined'
									disabled={address.isManual}
									label='Street'
									type='text'
									fullWidth
									size='small'
								/>
							)}
							control={control}
							name='address.street'
							defaultValue=''
							rules={{ required: false }}
						/>
					</Grid>
					<Grid item xs={6} >
						<Controller
							render={({ field: { onChange, value } }) => (
								<Autocomplete
									autoHighlight
									value={value}
									id='city'
									disabled={address.isManual}
									options={ProvinceInfo.generateCityOptions(address)}
									getOptionLabel={option => option.en || ''}
									getOptionSelected={(option, value) => option?.value === value?.value}
									onChange={(e, data) => {
										onChange(data);
										rhfSetValue('address.district', null);
										rhfSetValue('address.commune', null);
										rhfSetValue('address.village', null);
									}}
									renderInput={params => (
										<TextField {...params} label='City' variant='outlined' size='small' />
									)}
								/>
							)}
							control={control}
							name='address.city'
						/>
					</Grid>
					<Grid item xs={6} >
						<Controller
							render={({ field: { onChange, value } }) => (
								<Autocomplete
									autoHighlight	
									value={value}
									id='district'
									disabled={address.isManual || !address.city}
									options={ProvinceInfo.generateDistrictOptions(address)}
									getOptionLabel={option => option.en || ''}
									getOptionSelected={(option, value) => option?.value === value?.value }
									onChange={(e, data) => {
										onChange(data);
										rhfSetValue('address.commune', null);
										rhfSetValue('address.village', null);
									}}
									renderInput={params => (
										<TextField {...params} label='District' variant='outlined' size='small' />
									)}
								/>
							)}
							control={control}
							name='address.district'
						/>
					</Grid>
					<Grid item xs={6} >
						<Controller
							render={({ field: { onChange, value } }) => (
								<Autocomplete
									autoHighlight
									value={value}
									id='commune'
									disabled={address.isManual || !address.district}
									options={ProvinceInfo.generateCommuneOptions(address)}
									getOptionLabel={option => option.en || ''}
									getOptionSelected={(option, value) => option?.value === value?.value }
									onChange={(e, data) => {
										onChange(data);
										rhfSetValue('address.village', null);
									}}
									renderInput={params => (
										<TextField {...params} label='Commune' variant='outlined' size='small' />
									)}
								/>
							)}
							control={control}
							name='address.commune'
						/>
					</Grid>
					<Grid item xs={6} >
						<Controller
							render={({ field: { onChange, value } }) => (
								<Autocomplete
									autoHighlight
									value={value}
									id='village'
									options={ProvinceInfo.generateVillageOptions(address)}
									disabled={address.isManual || !address.commune}
									getOptionLabel={option => option.en || ''}
									getOptionSelected={(option, value) => option?.value === value?.value }
									onChange={(e, data) => onChange(data)}
									renderInput={params => (
										<TextField {...params} label='Village' variant='outlined' size='small' />
									)}
								/>
							)}
							control={control}
							name='address.village'
						/>
				</Grid>
				{/* manual address checkbox */}
					<Grid item xs={12}>
							<FormControlLabel
								control={
									<Controller
										name='address.isManual'
										control={control}
										render={({ field }) => (
											<Checkbox
												{...field}
												color='primary'
												type='checkbox'
												id='addressManual'
												checked={field.value}
												onChange={e => {
													field.onChange(e.target.checked);
													rhfSetValue('address.fullAddress', '');
												}}
											/>
										)}
									/>
								}
								label='Insert Address Manually'
							/>
					</Grid>
					{/* Insert address manual section */}
					<Grid item container style={{ display: address?.isManual ? '' : 'none' }}>
						<Controller
							render={({ field, fieldState: { error } }) => (
								<TextField
									disabled={!address.isManual}
									{...field}
									variant='outlined'
									label='Full address'
									type='text'
									fullWidth
									size='small'
									error={!!error}
									multiline
								/>
							)}
							control={control}
							name='address.fullAddress'
							defaultValue=''
							rules={{ required: address.isManual ? true : false }}
						/>
					</Grid>
				</Grid>
			</Grid>
	);
};

export default Address;
