import React from 'react';
import UserContextProvider from './user-context-provider';
import SnackbarProvider from './snackbar-provider';
import MuiPickerProvider from './mui-picker-provider';

const ContextProviderWrapper = ({ children }) => {
	return (
		<UserContextProvider>
			<MuiPickerProvider>{children}</MuiPickerProvider>
		</UserContextProvider>
	);
};

export default ContextProviderWrapper;
