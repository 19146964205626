// Third party's imports
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles, Paper, Grid, Typography, TextField, Button, useTheme } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import axios from 'axios';
import { useSnackbar } from 'notistack';

// Local imports
import { inputNumberOnly } from '../../utils';
import FileUpload from '../file-upload';
import awsS3 from '../../utils/aws-s3';

const useStyles = makeStyles(theme => ({
	paperRoot: {
		padding: '30px 30px 10px 30px',
		width: '100%',
	},
	titleText: {
		color: theme.palette.primary.main,
		textTransform: 'capitalize',
	},
	input1: {
		width: '139px',
	},
}));

const defaultValues = {
	ministryOfCommerce: {
		coiNumber: '',
		date: null,
		attachment: [],
	},
	generalDepartmentOfTax: {
		tinNumber: '',
		patentNumber: '',
		vatNumber: '',
		attachment: [],
	},
	labourVocationalTraining: {
		attachment: [],
	},
	nationalSocialSecurityFund: {
		attachment: [],
	},
	otherLicenses: {
		attachment: [],
	},
};

const PostRegistration = ({ isPreviewMode = false }) => {
	const classes = useStyles();
	const theme = useTheme();
	const { t } = useTranslation();
	const { id } = useParams();
	const { enqueueSnackbar } = useSnackbar();
	const { handleSubmit, control, reset } = useForm({ defaultValues });

	const [mocFiles, setMocFiles] = useState({ files: [], remove: [] });
	const [gdtFiles, setGdtFiles] = useState({ files: [], remove: [] });
	const [molFiles, setMolFiles] = useState({ files: [], remove: [] });
	const [nssfFiles, setNssfFiles] = useState({ files: [], remove: [] });
	const [otherLicensesFiles, setOtherLicensesFiles] = useState({ files: [], remove: [] });
	const [isLoading, setIsLoading] = useState(false);

	const fetchPostRegistration = () => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/v1/companies/post-registration/detail/${id}`)
			.then(res => {
				const data = res.data?.postRegistration;

				if (data?.ministryOfCommerce?.attachment) {
					setMocFiles({
						files: data?.ministryOfCommerce?.attachment,
						remove: [],
					});
				}
				if (data?.generalDepartmentOfTax?.attachment) {
					setGdtFiles({
						files: data?.generalDepartmentOfTax?.attachment,
						remove: [],
					});
				}
				if (data?.labourVocationalTraining?.attachment) {
					setMolFiles({
						files: data?.labourVocationalTraining?.attachment,
						remove: [],
					});
				}
				if (data?.nationalSocialSecurityFund?.attachment) {
					setNssfFiles({
						files: data?.nationalSocialSecurityFund?.attachment,
						remove: [],
					});
				}
				if (data?.otherLicenses?.attachment) {
					setOtherLicensesFiles({
						files: data?.otherLicenses?.attachment,
						remove: [],
					});
				}

				reset(data); // set data to form
			})
			.catch(err => {
				console.log('err get post registration', err);
			});
	};

	useEffect(() => {
		fetchPostRegistration();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const uploadAttachments = async (files, s3FolderName) => {
		const uploadedFiles = await Promise.all(
			files.map(async item => {
				if (!item.hasOwnProperty('key')) {
					const response = await awsS3.uploadImage({
						image: item.file,
						type: item.file.type,
						folder: s3FolderName,
					});
					const fileItem = {
						key: response,
						mimeType: item.file.type,
					};

					return fileItem;
				}
				return item;
			})
		);

		return uploadedFiles;
	};

	const onSubmit = async data => {
		try {
			setIsLoading(true);

			// delete files
			const mocFilesKeys = mocFiles.remove.filter(attachment => attachment.key).map(file => file.key);

			const gdtFilesKeys = gdtFiles.remove.filter(attachment => attachment.key).map(file => file.key);

			const molFilesKeys = molFiles.remove.filter(attachment => attachment.key).map(file => file.key);

			const nssfFilesKeys = nssfFiles.remove.filter(attachment => attachment.key).map(file => file.key);

			const otherLicensesFilesKeys = otherLicensesFiles.remove
				.filter(attachment => attachment.key)
				.map(file => file.key);

			const filesToRemove = [
				...mocFilesKeys,
				...gdtFilesKeys,
				...molFilesKeys,
				...nssfFilesKeys,
				...otherLicensesFilesKeys,
			];

			if (filesToRemove.length) await awsS3.removeFilesFromS3(filesToRemove);

			// upload files
			const tempMocFiles = await uploadAttachments(mocFiles.files, 'moc_files');

			const tempGdtFiles = await uploadAttachments(gdtFiles.files, 'gdt_files');

			const tempMolFiles = await uploadAttachments(molFiles.files, 'mol_files');

			const tempNssfFiles = await uploadAttachments(nssfFiles.files, 'nssf_files');

			const tempOtherLicense = await uploadAttachments(otherLicensesFiles.files, 'other_license_files');

			data.ministryOfCommerce.attachment = tempMocFiles;
			data.generalDepartmentOfTax.attachment = tempGdtFiles;
			data.labourVocationalTraining = { attachment: tempMolFiles };
			data.nationalSocialSecurityFund = { attachment: tempNssfFiles };
			data.otherLicenses = { attachment: tempOtherLicense };

			await axios.put(`${process.env.REACT_APP_API_URL}/v1/companies/post-registration/add/${id}`, data);
			fetchPostRegistration();
			enqueueSnackbar('Post Registration has been added successfully', {
				variant: 'success',
			});
		} catch (error) {
			console.log('error submit post registration :>> ', error);
			enqueueSnackbar(`${error?.response?.data?.message || 'error submit post registration'}`, {
				variant: 'error',
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)} style={{ paddingBottom: '20px' }}>
			<Paper className={classes.paperRoot}>
				<Grid container style={{ width: '60%' }}>
					<Grid item container xs={12} spacing={2}>
						<Grid item xs={12}>
							<Typography className={classes.titleText}>a. Ministry of Commerce</Typography>
						</Grid>
						<Grid item container xs={12} spacing={2} style={{ padding: '20px' }}>
							<Grid item container xs={12} justifyContent='space-between'>
								<Grid item xs='auto'>
									<Typography>{t('coi-number')}</Typography>
								</Grid>
								<Grid item xs='auto'>
									<Controller
										render={({ field, fieldState: { error } }) => (
											<TextField
												{...field}
												variant='outlined'
												label={t('coi-number-label')}
												type='text'
												size='small'
												onKeyPress={inputNumberOnly}
												error={!!error}
												inputProps={{
													readOnly: isPreviewMode, // read only in preview mode
												}}
											/>
										)}
										control={control}
										name='ministryOfCommerce.coiNumber'
										defaultValue=''
										rules={{ required: true }}
									/>
								</Grid>
							</Grid>
							<Grid item container xs={12} justifyContent='space-between'>
								<Grid item xs='auto'>
									<Typography>{t('coi-date')}</Typography>
								</Grid>
								<Grid item xs='auto'>
									<Controller
										control={control}
										name='ministryOfCommerce.date'
										defaultValue={null}
										rules={{ required: true }}
										render={({ field: { value, onChange }, fieldState: { error } }) => (
											<KeyboardDatePicker
												value={value}
												label='COI Date'
												onChange={onChange}
												className={classes.root}
												autoOk
												variant='inline'
												inputVariant='outlined'
												size='small'
												format='dd/MM/yyyy'
												margin='none'
												KeyboardButtonProps={{
													style: {
														outline: 'none',
														color: theme.palette.primary.main,
													},
												}}
												error={!!error}
												InputProps={{ classes: { input: classes.input1 } }}
												readOnly={isPreviewMode} // read only in preview mode
											/>
										)}
									/>
								</Grid>
							</Grid>
							<Grid item container xs={12}>
								<Grid item xs={12}>
									<Typography>{t('upload-files')}</Typography>
								</Grid>
								<Grid item xs={12} style={{ padding: '8px 0' }}>
									<FileUpload files={mocFiles} setFiles={setMocFiles} isPreviewMode={isPreviewMode} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item container xs={12} spacing={2}>
						<Grid item xs={12}>
							<Typography className={classes.titleText}>b. General department of taxation</Typography>
						</Grid>
						<Grid item container xs={12} spacing={2} style={{ padding: '20px' }}>
							<Grid item container xs={12} justifyContent='space-between'>
								<Grid item xs='auto'>
									<Typography>{t('tax-identification-number')}</Typography>
								</Grid>
								<Grid item xs='auto'>
									<Controller
										render={({ field, fieldState: { error } }) => (
											<TextField
												{...field}
												variant='outlined'
												label={t('tax-identification-number-label')}
												type='text'
												size='small'
												onKeyPress={inputNumberOnly}
												error={!!error}
												inputProps={{
													readOnly: isPreviewMode, // read only in preview mode
												}}
											/>
										)}
										control={control}
										name='generalDepartmentOfTax.tinNumber'
										defaultValue=''
										rules={{ required: true }}
									/>
								</Grid>
							</Grid>
							<Grid item container xs={12} justifyContent='space-between'>
								<Grid item xs='auto'>
									<Typography>{t('patent-tax-number')}</Typography>
								</Grid>
								<Grid item xs='auto'>
									<Controller
										render={({ field, fieldState: { error } }) => (
											<TextField
												{...field}
												variant='outlined'
												label={t('patent-tax-number-label')}
												type='text'
												size='small'
												onKeyPress={inputNumberOnly}
												error={!!error}
												inputProps={{
													readOnly: isPreviewMode, // read only in preview mode
												}}
											/>
										)}
										control={control}
										name='generalDepartmentOfTax.patentNumber'
										defaultValue=''
										rules={{ required: true }}
									/>
								</Grid>
							</Grid>
							<Grid item container xs={12} justifyContent='space-between'>
								<Grid item xs='auto'>
									<Typography>{t('vat-number')}</Typography>
								</Grid>
								<Grid item xs='auto'>
									<Controller
										render={({ field, fieldState: { error } }) => (
											<TextField
												{...field}
												variant='outlined'
												label={t('vat-number-label')}
												type='text'
												size='small'
												onKeyPress={inputNumberOnly}
												error={!!error}
												inputProps={{
													readOnly: isPreviewMode, // read only in preview mode
												}}
											/>
										)}
										control={control}
										name='generalDepartmentOfTax.vatNumber'
										defaultValue=''
										rules={{ required: true }}
									/>
								</Grid>
							</Grid>
							<Grid item container xs={12}>
								<Grid item xs={12}>
									<Typography>{t('upload-files')}</Typography>
								</Grid>
								<Grid item xs={12} style={{ padding: '8px 0' }}>
									<FileUpload files={gdtFiles} setFiles={setGdtFiles} isPreviewMode={isPreviewMode} />
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item container xs={12} spacing={2}>
						<Grid item xs={12}>
							<Typography className={classes.titleText}>
								c. Ministry of labour and vocational training
							</Typography>
						</Grid>
						<Grid item container xs={12} spacing={2} style={{ padding: '25px' }}>
							<Grid item xs={12} style={{ padding: '8px 0' }}>
								<FileUpload files={molFiles} setFiles={setMolFiles} isPreviewMode={isPreviewMode} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item container xs={12} spacing={2}>
						<Grid item xs={12}>
							<Typography className={classes.titleText}>d. National Social Security Fund (NSSF)</Typography>
						</Grid>
						<Grid item container xs={12} spacing={2} style={{ padding: '25px' }}>
							<Grid item xs={12} style={{ padding: '8px 0' }}>
								<FileUpload files={nssfFiles} setFiles={setNssfFiles} isPreviewMode={isPreviewMode} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item container xs={12} spacing={2}>
						<Grid item xs={12}>
							<Typography className={classes.titleText}>d. Other licenses</Typography>
						</Grid>
						<Grid item container xs={12} spacing={2} style={{ padding: '25px' }}>
							<Grid item xs={12} style={{ padding: '8px 0' }}>
								<FileUpload
									files={otherLicensesFiles}
									setFiles={setOtherLicensesFiles}
									isPreviewMode={isPreviewMode}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item style={{ padding: '30px 0' }}>
						<Button type='submit' variant='contained' color='primary' disabled={isLoading}>
							{isLoading ? 'Saving...' : 'Save'}
						</Button>
					</Grid>
				</Grid>
			</Paper>
		</form>
	);
};

export default PostRegistration;
