import * as Staff from '../actions/staff';

export default ( state = {}, action ) => {
    switch( action.type ){
        case Staff.STAFF_LOAD_ACTION: return { ...state, ...action.data };
        case Staff.STAFF_LOAD_SUCCESS: return { ...state, ...action.data };
        case Staff.STAFF_LOAD_FAIL: return { ...state, ...action.data };

        case Staff.STAFF_SAVE_ACTION: return { ...state, ...action.data };
        case Staff.STAFF_SAVE_SUCCESS: {
            return { ...state, data: [ ...state.data, action.data.user ] };
        }
        case Staff.STAFF_SAVE_FAIL: return { ...state, ...action.data }; 
        default: return state;
    }
}