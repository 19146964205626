import * as ForgotPassword from '../actions/forgotPassword';

const initialState = {
	isForgotPasswordFail: false,
	isLoading: false,
};

const forgotPassword = (state = { ...initialState }, action) => {
	switch (action.type) {
		case ForgotPassword.FORGOT_SUCCESS:
			return {
				...state,
				...action.data,
				isForgotPasswordFail: false,
				isLoading: false,
			};
		case ForgotPassword.FORGOT_FAIL:
			return {
				...state,
				...action.data,
				isForgotPasswordFail: true,
				isLoading: false,
			};
		case ForgotPassword.FORGOT_REQUEST:
			return {
				...state,
				isForgotPasswordFail: false,
				isLoading: true,
			};
		default:
			return state;
	}
};

export default forgotPassword;
