import axios from 'axios';
import swal from 'sweetalert2';

export const STAFF_LOAD_SUCCESS = 'STAFF_LOAD_SUCCESS';
export const STAFF_LOAD_FAIL = 'STAFF_LOAD_FAIL';
export const STAFF_LOAD_ACTION = 'STAFF_LOAD_ACTION';

export const STAFF_SAVE_SUCCESS = 'STAFF_SAVE_SUCCESS';
export const STAFF_SAVE_FAIL = 'STAFF_SAVE_FAIL';
export const STAFF_SAVE_ACTION = 'STAFF_SAVE_ACTION';

export const loadSuccess = ( data ) => ({
    type: STAFF_LOAD_SUCCESS,
    data
});

export const loadFail= ( data ) => ({
    type: STAFF_LOAD_FAIL,
    data
});

export const loadAction= ( data ) => ({
    type: STAFF_LOAD_ACTION,
    data
});

export const loadStaff = () => {
    return dispatch => {
        dispatch( loadAction({ loading: true }) );
        // `JWT ${ localStorage.getItem('access_token') }`
        return axios.get( `${ process.env.REACT_APP_API_URL }/staff`, {
            headers: {
                'Authorization': `bearer ${ localStorage.getItem('access_token') }`
            } 
        } ).then( response => {
            dispatch( loadSuccess({ ...response.data ,loading: false, loaded: true }) );
        }).catch( err => {
            dispatch( loadFail({ loading: false, loaded: false }) );
        });
    }
}

export const saveSuccess = ( data ) => ({
    type: STAFF_SAVE_SUCCESS,
    data
});

export const saveFail= ( data ) => ({
    type: STAFF_SAVE_FAIL,
    data
});

export const saveAction= ( data ) => ({
    type: STAFF_SAVE_ACTION,
    data
});

export const saveStaff = ( data ) => {
    return dispatch => {
        dispatch( saveAction({ saving: true }) );
        swal.fire({
            title: 'Saving...',
            allowOutsideClick: false,
            onOpen: () => {
                swal.showLoading();
            }
        });

        // `JWT ${ localStorage.getItem('access_token') }`
        return axios.post( `${ process.env.REACT_APP_API_URL }/signup`, { data }, {
            headers: {
                Authorization: `bearer ${ localStorage.getItem('access_token') }`
            } 
        } ).then( response => {
            dispatch( saveSuccess({ ...response.data , saving: false, saved: true }) );
            swal.fire({
                text: 'Saved',
                timer: 1000,
                icon: 'success',
                onOpen: () => {
                    swal.hideLoading()
                }
            }).then( () => {
                const link = document.getElementById('closeButton');
                link.click();
            });
        }).catch( err => {
            console.error( err.response );
            swal.fire({
                text: err.response.data.message,
                icon: 'error',
                onOpen: () => {
                    swal.hideLoading()
                }
            });
            dispatch( saveFail({ saving: false, saved: false }) );
        });
    }
}