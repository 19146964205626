import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useController } from 'react-hook-form';
import { useTheme } from '@material-ui/core';

const RHFDatePicker = ({
	name,
	label,
	control,
	required = false,
	rules,
	defaultValue,
	variant = 'inline',
	inputVariant = 'outlined',
	size = 'small',
	onChange,
	isPreviewMode = false,
	fullWidth = true,
}) => {
	const {
		field: { ref, value, onChange: ucOnChange, ...inputProps },
		fieldState: { error },
	} = useController({
		name,
		control,
		rules: { required, ...rules },
		defaultValue,
	});

	const theme = useTheme();

	return (
		<KeyboardDatePicker
			fullWidth={fullWidth}
			inputRef={ref}
			value={value}
			label={label}
			onChange={onChange ? (date, value) => onChange(date, value, ucOnChange) : ucOnChange}
			autoOk
			variant={variant}
			inputVariant={inputVariant}
			size={size}
			format='dd/MM/yyyy'
			margin='none'
			KeyboardButtonProps={{
				style: {
					outline: 'none',
					color: theme.palette.primary.main,
				},
			}}
			error={!!error}
			readOnly={isPreviewMode} // read only in preview mode
			{...inputProps}
		/>
	);
};

export default RHFDatePicker;
