// Third party's import
import React from 'react';
import { Button, Box, Typography, makeStyles, useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// Local imports
import DashboardTitle from '../../components/Title';
import TableX from '../../components/tableX';
import tableXConfig from '../../components/tableX/config';
import ActionIconButton from '../../components/action-icon-button';

import BusinessActivityDialog from './business-activity-dialog';
import useBusinessActivity from './use-business-activity';

const useStyles = makeStyles(() => ({
	tableWrapper: {
		'& .tableX th': {
			textAlign: 'inherit !important',
		},
		'& .tableX td': {
			textAlign: 'inherit !important',
		},
		'& .MuiTableRow-root': {
			verticalAlign: 'baseline',
		},
	},
}));

const tableConfig = {
	...tableXConfig,
	url: `${process.env.REACT_APP_API_URL}/v1/business-activity`,
	dataConfig: ['businessActivities'],
};

const BusinessActivityPage = () => {
	const classes = useStyles();
	const theme = useTheme();
	const {
		triggerFetch,
		setTriggerFetch,

		businessActivityDialog,
		handleOpenBusinessActivityDialog,
		handleCloseBusinessActivityDialog,
		handlePreviewBusinessActivity,

		handleDeleteBusinessActivity,
	} = useBusinessActivity();
	const { t } = useTranslation();

	const tableSchema = [
		{
			id: 'no',
			label: t('no.'),
			type: 'numbering',
			sort: false,
		},
		{
			id: 'nameEN',
			label: t('name'),
		},
		{
			id: 'license',
			label: t('license'),
			sort: false,
			type: 'component',
			dataConfig: [],
			components: data => (
				<>
					{data?.license === true ? (
						<CheckIcon htmlColor={theme.palette.success.main} />
					) : (
						<ClearIcon color='error' />
					)}
				</>
			),
		},
		{
			id: 'create-at',
			label: t('created-at'),
			type: 'component',
			dataConfig: [],
			components: data => <Typography>{moment(data?.createdAt).format('DD/MM/YYYY')}</Typography>,
		},
		{
			id: 'component',
			sort: false,
			label: t('action'),
			type: 'component',
			dataConfig: [],
			components: data => {
				return (
					<>
						<ActionIconButton
							variant='view'
							onClick={e => {
								e.stopPropagation();
								handlePreviewBusinessActivity(data);
							}}
						/>
						<ActionIconButton
							variant='edit'
							onClick={e => {
								e.stopPropagation();
								handleOpenBusinessActivityDialog(data);
							}}
						/>
						<ActionIconButton
							variant='delete'
							color='secondary'
							onClick={e => {
								e.stopPropagation();
								handleDeleteBusinessActivity(data._id);
							}}
						/>
					</>
				);
			},
		},
	];

	return (
		<div>
			<Box display='flex' alignItems='center' justifyContent='flex-start' mb='1rem'>
				<DashboardTitle title='Business Activity' />
				<Button color='primary' variant='contained' onClick={() => handleOpenBusinessActivityDialog(null)}>
					Create
				</Button>
			</Box>
			<div className={classes.tableWrapper}>
				<TableX triggerFetch={triggerFetch} config={tableConfig} schema={tableSchema} />
			</div>

			{/* dialog create/update business activity */}
			<BusinessActivityDialog
				isPreviewMode={businessActivityDialog.preview}
				selectedItem={businessActivityDialog.data}
				open={businessActivityDialog.open}
				onClose={handleCloseBusinessActivityDialog}
				setTriggerFetch={setTriggerFetch}
			/>
		</div>
	);
};

export default BusinessActivityPage;
