import { compose } from 'recompose'
import { connect } from 'react-redux';
import { login } from '../../actions/login';
import LoginForm from './presenter';

const mapStateToProps = (state) => ({
    loginInfo: state.LoginInfo,
});

const mapDispatchToProps = ( dispatch ) => ({
    login: ( data ) => dispatch( login( data.email, data.password ) ),
});

export default compose(
    connect( mapStateToProps, mapDispatchToProps ),
)(LoginForm);