import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
	Grid,
	TextField,
	Typography,
	InputAdornment,
	makeStyles,
	Button,
	IconButton,
} from '@material-ui/core';
import Email from '@material-ui/icons/Email';
import { Link } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import LockIcon from '@material-ui/icons/Lock';
import { useSnackbar } from 'notistack';

import logoFourmiAndPartner from '../../assets/images/logo-fourmi-and-partner.webp';

const useStyles = makeStyles(theme => ({
	rootContainer: {
		position: 'relative',
		height: '100vh',
		width: '100%',
		background:
			'url(https://images.unsplash.com/photo-1507679799987-c73779587ccf?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1471&q=80) no-repeat center center/cover',
	},
	container: {
		backgroundColor: 'white',
		borderRadius: '5px',
		maxWidth: '520px',
		padding: '2.5rem',
		boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
		[theme.breakpoints.down('xs')]: {
			borderRadius: '0rem',
			boxShadow: '0px 0px 0px',
			border: '0px',
			padding: '3rem 1rem',
			paddingTop: '5rem',
		},
	},
	loginCredentialText: {
		padding: '25px 5px',
	},
	submitButton: {
		textTransform: 'capitalize',
	},
	reactRouterLink: ({ isLoading }) => ({
		pointerEvents: isLoading ? 'none' : 'auto',
		color: isLoading ? theme.palette.grey['200'] : theme.palette.primary.main,
	}),
}));

const LoginForm = ({ login, loginInfo }) => {
	const classes = useStyles({ isLoading: loginInfo.isLoading });
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const { enqueueSnackbar } = useSnackbar();

	const [showPassword, setShowPassword] = useState(false);

	useEffect(() => {
		if (loginInfo.isLoginFail) {
			if (loginInfo?.response?.status >= 400 && loginInfo?.response?.status <= 500) {
				enqueueSnackbar(loginInfo?.response?.data?.message || 'Something went wrong', {
					variant: 'error',
					preventDuplicate: true,
				});
			}
		}
	}, [loginInfo]);

	const handleClickPassVisibility = () => {
		setShowPassword(!showPassword);
	};

	const onSubmit = async data => {
		await login(data);
	};

	return (
		<>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignItems='center'
				className={classes.rootContainer}
			>
				<Grid item container className={classes.container}>
					<Grid item container xs={12} justifyContent='center'>
						<img alt='sign-in-logo' className={classes.loginLogo} src={logoFourmiAndPartner} width='250' />
						<Typography className={classes.loginCredentialText}>
							Enter your credentials to access your account
						</Typography>
					</Grid>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<TextField
									error={!!errors?.email}
									variant='outlined'
									{...register('email', {
										required: true,
										validate: value => {
											const email = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(value);
											return email;
										},
									})}
									placeholder='Email'
									type='email'
									fullWidth
									helperText={errors.email ? 'Email is required' : ''}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<Email color='primary' />
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									{...register('password', { required: true })}
									variant='outlined'
									error={!!errors?.password}
									placeholder='Password'
									type={showPassword ? 'text' : 'password'}
									fullWidth
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<LockIcon color='primary' />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position='end'>
												<IconButton onClick={handleClickPassVisibility}>
													{showPassword ? <Visibility color='primary' /> : <VisibilityOff />}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</Grid>
							<Grid
								item
								container
								xs={12}
								justifyContent='center'
								style={{ padding: '0.8rem 0.5rem', justifyContent: 'space-between' }}
							>
								<Typography component='div' style={{ fontSize: '14px' }}>
									Forgot your password?
								</Typography>
								<Typography style={{ fontSize: '14px' }}>
									<Link to='/forgot-password' className={classes.reactRouterLink}>
										Reset Password
									</Link>
								</Typography>
							</Grid>
							<Grid item xs={12} container justifyContent='center'>
								<Button
									type='submit'
									variant='contained'
									color='primary'
									fullWidth
									className={classes.submitButton}
									disabled={loginInfo.isLoading}
								>
									Sign In
								</Button>
							</Grid>
						</Grid>
					</form>
					<Grid
						item
						container
						spacing={1}
						xs={12}
						justifyContent='center'
						style={{ paddingTop: '20px', textAlign: 'center' }}
					>
						<Grid item xs={12}>
							<Typography component='div' style={{ fontSize: '14px' }}>
								Don't have an account?
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography component='div' style={{ fontSize: '14px' }}>
								<Link to='/signup' className={classes.reactRouterLink}>
									Sign Up
								</Link>
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='caption'>Version {process.env.REACT_APP_VERSION}</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default LoginForm;
