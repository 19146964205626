import * as Add from '../actions/formInfo'

const add = (state = [], action) => {
    switch (action.type) {
      case Add.FORM_INFO:
        return {
          ...state,
          ...action.data
        }
      default:
        return state
    }
  }
  
  export default add