import React, { useState, useCallback, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import axios from 'axios';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import debounce from 'debounce';
import _get from 'lodash.get';
import { useController } from 'react-hook-form';
// import useRootStyle from '../utils/useRootStyle';

const filter = createFilterOptions();

/* Simple 
<RHFAutocompleteInput
  name="vendor"
  // config where is the data fetch from url. Exp: "product.name", "supplier"   
  dataConfig="suppliers"
  // url to fetch option from | templete search with {search}
  optionsURL={`${process.env.REACT_APP_API}/api/v1/suppliers/all?search={search}&page=1&limit=5`}   
  getOptionLabel={(option) => option.name}
  getOptionSelected={(option, value) => option.name === value.name}
  required
/>; 
*/

/* Simple with add new suggest replace getOptionLabel with getOptionConfig
<RHFAutocompleteInput
  label={t("vendor")}
  name="vendor"
  // select which data to show "Add : {data}" 
  getOptionConfig="name"
  dataConfig="suppliers"
  optionsURL={`${process.env.REACT_APP_API}/api/v1/suppliers/all?search={search}&page=1&limit=5`}   
  getOptionSelected={(option, value) => option.name === value.name}
  required
	// if you to do some extra work when onChange 
	onChange={(e, data, onChange) => {
		onChange(data);
		...your code here
	}}
/>; 
*/

const RHFAutocompleteInput = props => {
	const {
		name,
		control,
		required,
		defaultValue = null,
		variant = 'outlined',
		size = 'small',
		label,
		fullWidth = true,
		// textAlign = 'left',
		className = '',
		rules,
		// helperTextAlign = 'center',
		optionsURL,
		getOptionConfig,
		dataConfig,
		disablePortal = false,
		disableClearable = true,
		onChange,
		onKeyDown,
		freeSolo = false,
		autoFocus = true,
		onInputChange,
		...autoCompleteProps
	} = props; // using react hook form useController as value state

	const {
		field: { ref, value, onChange: ucOnChange },
		fieldState: { error },
	} = useController({
		name,
		control, //optional when use inside formContext
		rules: { required, ...rules },
		defaultValue,
	});

	// const rootClasses = useRootStyle({ textAlign, helperTextAlign });

	const [list, setList] = useState([]);
	const [loading, setLoading] = useState(true);

	const fetchOptions = searchText => {
		setLoading(true);
		axios
			.get(
				templateURL(optionsURL, {
					search: typeof searchText === 'string' ? searchText : '',
				})
			)
			.then(response => {
				setList(_get(response.data, dataConfig, []));
			})
			.catch(err => console.log(err))
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		return () => {
			debounceSearch.clear();
		};
	}, []);

	const debounceSearch = useCallback(
		debounce(text => {
			if (optionsURL) {
				fetchOptions(text);
			}
		}, 500),
		[]
	);

	let optionsConfig = {};
	if (getOptionConfig) {
		optionsConfig = {
			getOptionLabel: option => {
				// Value selected with enter, right from the input
				if (typeof option === 'string') {
					return option;
				}
				// Add "xxx" option created dynamically
				if (option.inputValue) {
					return option.inputValue;
				}
				// Regular option
				return _get(option, getOptionConfig);
			},
			renderOption: option => _get(option, getOptionConfig) || option,
			filterOptions: (options, params) => {
				const filtered = filter(options, params);

				// Suggest the creation of a new value
				if (params.inputValue !== '') {
					filtered.push({
						inputValue: params.inputValue,
						[getOptionConfig]: `Add "${params.inputValue}"`,
					});
				}
				return filtered;
			},
		};
	}

	return (
		<Autocomplete
			loadingText='Loading'
			options={list}
			freeSolo={freeSolo}
			disableClearable={disableClearable}
			value={value}
			blurOnSelect
			loading={loading}
			disablePortal={disablePortal} // stop list from moving to top when ther is no space in the bottom
			onOpen={fetchOptions}
			{...optionsConfig}
			{...autoCompleteProps}
			onChange={onChange ? (e, data, reason) => onChange(e, data, reason, ucOnChange) : (_, data) => ucOnChange(data)}
			onInputChange={onInputChange ? (e, value, reason) => onInputChange(e, value, reason, debounceSearch) : (_, value) => debounceSearch(value)}
			onKeyDown={onKeyDown ? e => onKeyDown(e, data => onChange(e, data, ucOnChange)) : () => {}}
			renderInput={params => {
				return (
					<TextField
						inputRef={ref}
						{...params}
						variant={variant}
						className={`${className}`}
						size={size}
						fullWidth={fullWidth}
						error={!!error}
						autoFocus={autoFocus}
						required={required}
						label={label}
					/>
				);
			}}
		></Autocomplete>
	);
};

const templateURL = (url, data) => {
	return url.replace(/{(\w*)}/g, function (keyExpr, key) {
		return data[key] ?? '';
	});
};

export default RHFAutocompleteInput;
