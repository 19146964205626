import React, {useState} from 'react';
import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Box,
  Typography,
  IconButton,
  Collapse,
  Button,
  useTheme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import generateAddress from '../../../views/Company/ReviewCompany/generateAddress';
import { downloadFile, downloadBlobFile } from '../../../utils';
import { pdfFormApi } from '../../../api';

const useStyles = makeStyles((theme) => ({
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'space-between',
    '&:hover': {
      cursor: 'pointer',
    },
    backgroundColor: 'rgba(1, 106, 178, 0.07)',
    borderRadius:"5px"
  },
  headerText: {
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    paddingLeft:'10px'
  },
  subHeader: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  listItemText: {
    flex: '1 1 0px !important',
    paddingLeft:"30px",
  },
}));

const Detail = ({ legalEntityParticular }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const { id: companyId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);

  const companyInfos = [
    { label: t('company-name-khmer'), value: legalEntityParticular.nameKhmer },
    { label: t('company-name-latin'), value: legalEntityParticular.nameLatin },
    {
      label: t('tax-identification-number'),
      value: legalEntityParticular.taxIdNumber,
    },
    {
      label: t('share-percentage'),
      value: legalEntityParticular.sharePercentage,
    },
    {
      label: t('email'),
      value:legalEntityParticular?.email || 'N/A'
    },
    {
      label: t('mobile-phone-number'),
      value:legalEntityParticular?.mobilePhoneNumber || 'N/A'
    },
    {
      label: t('office-telephone-number'),
      value:legalEntityParticular?.officeTelephoneNumber || 'N/A'
    },
    {
      label: t('registered-address'),
      value: generateAddress(legalEntityParticular.address, 'en'),
    },
  ];

  // const employeeInfos = [
  //   { label: t('estimate-total-number-of-employee'), value: `${legalEntityParticular.employee.totalEmployee} / Female: ${legalEntityParticular.employee.femaleEmployee}` },
  //   { label: t('number-of-foreigner-employee'), value: `${legalEntityParticular.employee.foreignerEmployee} / Female: ${legalEntityParticular.employee.femaleForeignerEmployee}`},
  //   { label: t('total-employee-salary'), value: legalEntityParticular.totalEmployeeSalary },
  //   { label: t('weekly-holiday'), value: legalEntityParticular.weeklyHoliday },
  //   { label: t('number-of-working-per-day-or-week'), value: legalEntityParticular.numberOfWorkingPerDayOrWeek },
  // ]

  const representative = legalEntityParticular.representative;

  const companyRepInfos = [
    {
      label: t('represented-by'),
      value: `${representative.nameLatin} / ${representative.nameKhmer}`,
    },
    { label: t('gender'), value: representative.gender },
    {
      label: t('place-of-birth'),
      value: generateAddress(representative?.placeOfBirth, 'en'),
    },
    {
      label: t('date-of-birth'),
      value: moment(representative.dob).format('DD/MM/YYYY'),
    },
    {
      label: t('phone-number'),
      value: representative.phoneNumber,
    },
    {
      label: t('email'),
      value: representative.email,
    },
    {
      label:
        representative.identity?.isPassport === 'true'
          ? t('passport-number')
          : t('id-card-number'),
      value: representative.identity?.number,
    },
  ];

  const handleDownload = async () =>
    representative?.file?.forEach((eachFile) =>
      downloadFile(eachFile, representative.nameLatin)
    );
  
  const handleDownloadPersonalInfo =async () => { 
    try {
      setIsLoading(true)
      const response = await pdfFormApi.personalInformation(companyId, representative.value, 'legal');
      downloadBlobFile({ filename: 'legal-info.pdf', data: response.data });
    } catch (error) {
      console.log('error download personal information pdf form :>> ', error);
      enqueueSnackbar('error download personal information pdf form', { variant: 'error' });
    } finally {
      setIsLoading(false)
    }
   }

  return (
      <>
        {/* subheader */}
        <ListItem component='div'>
          <Typography variant='h6' className={classes.subHeader}>
            {`A. ${t('company-information')}`}
          </Typography>
        </ListItem>
        {/* info */}
        {/* <ListItem> */}
          <List>
            {companyInfos.map((info, index) => (
              <ListItem disableGutters key={index}>
                <ListItemText
                  className={classes.listItemText}
                  primary={`${info.label}:`}
                />
                <ListItemText
                  className={classes.listItemText}
                  primary={info.value}
                />
              </ListItem>
            ))}
          </List>
        {/* </ListItem> */}
        {/* info */}
        <ListItem component='div'>
          <Typography className={classes.subHeader} variant='h6'>
            {`B. ${t('company-representative-information')}`}
          </Typography>
        </ListItem>
        {/* detail */}
        {/* <ListItem> */}
          <List>
            {companyRepInfos.map((info, index) => (
              <ListItem disableGutters key={index}>
                <ListItemText
                  className={classes.listItemText}
                  primary={`${info.label}:`}
                />
                <ListItemText
                  className={classes.listItemText}
                  primary={info.value}
                />
              </ListItem>
            ))}
          </List>
        {/* </ListItem> */}
        <ListItem component='div'>
          <Button variant='contained' color='primary' onClick={handleDownload}>
            {representative.identity?.isPassport === 'true'
              ? t('download-passport')
              : t('download-id-card')}
          </Button>
          <Button
              variant='contained'
              color='primary'
              onClick={handleDownloadPersonalInfo}
              style={{ marginLeft: theme.spacing(2) }}
              disabled={isLoading}
            >
              {t('download-form-101-b')}
          </Button>
        </ListItem>
        {/* info */}
        {/* <ListItem>
          <Typography className={classes.subHeader} variant='h6'>
            {`c. ${t('company-employee-information')}`}
          </Typography>
        </ListItem> */}
        {/* info */}
        {/* <ListItem> */}
          {/* <List>
            {employeeInfos.map((info, index) => (
              <ListItem disableGutters>
                <ListItemText
                  className={classes.listItemText}
                  primary={`${index + 1}. ${info.label}:`}
                />
                <ListItemText
                  className={classes.listItemText}
                  primary={info.value}
                />
              </ListItem>
            ))}
          </List> */}
        {/* </ListItem> */}
      </>
  );
};

const LegalEntityParticulars = ({ legalEntityParticular }) => {
  const [selectedIndex, setSelectedIndex] = React.useState('');
  const classes = useStyles();

  const handleClick = (index) => {
    if (selectedIndex === index) {
      setSelectedIndex('');
    } else {
      setSelectedIndex(index);
    }
  };

  return (
    <List>
      {legalEntityParticular.map((eachLegalEntityParticular, index) => (
        <React.Fragment key={index}>
          <ListItem>
            {/* header */}
            <Box
              className={classes.headerBox}
              onClick={() => handleClick(index)}
            >
              <Typography className={classes.headerText}>
                {index + 1}. {eachLegalEntityParticular.nameLatin}
              </Typography>
              <IconButton
                color='primary'
                style={{ backgroundColor: '#EDF4FA' }}
              >
                {index === selectedIndex ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            </Box>
          </ListItem>
          {/* detail */}
          <ListItem>
            <Collapse in={index === selectedIndex} timeout='auto' unmountOnExit>
              <Detail legalEntityParticular={eachLegalEntityParticular} />
            </Collapse>
          </ListItem>
        </React.Fragment>
      ))}
    </List>
  );
};

export default LegalEntityParticulars;
