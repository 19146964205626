// Third party's imports
import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReplayIcon from '@material-ui/icons/Replay';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles(theme => ({
	root: {
		marginRight: '5px',
		background: theme.palette.primary.main,
		padding: '7px',
		'& svg': {
			color: 'white',
			fontSize: '16px',
		},
		'&:hover': {
			background: theme.palette.primary.dark,
		},
	},
	disabled: {
		background: `${theme.palette.grey[400]}!important`,
	},
}));

const icons = {
	add: AddIcon,
	edit: EditIcon,
	delete: DeleteIcon,
	confirm: CheckIcon,
	close: CloseIcon,
	view: VisibilityIcon,
	pending: EditIcon,
	accepted: AddCircleIcon,
	rejected: ReplayIcon
};

const ActionIconButton = ({ variant, hidden = false, disabled = false, ...other }) => {
	const classes = useStyles();
	const Icon = icons[variant] || EditIcon;
	if (hidden) return null;
	return (
		<IconButton classes={{ root: classes.root, disabled: classes.disabled }} disabled={disabled} {...other}>
			<Icon />
		</IconButton>
	);
};

export default ActionIconButton;
