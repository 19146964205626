import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Grid, LinearProgress } from '@material-ui/core';
import Title from '../../components/Title';
import RolePermissionRow from './role-permission-row';
import RolePermissionForm from './form';
import AccessControl from '../../components/reusable/access-control';

const RolePermission = () => {
  // const { checkAccess } = useContext( UserContext );
  const [data, setData] = useState( [] );
  const [loading, setLoading] = useState( true );
  const [form, setForm] = useState( false );

  useEffect( () => {
    setLoading( true );
    fetchData();
    // clean up data on unmount
    return () => setData( [] );
  }, [] );

  const fetchData = async () => {
    try {
      const { data } = await axios.get( `${process.env.REACT_APP_API_URL}/v1/roles` );
      setData( data.data );
    } catch ( err ) {
      console.log( err );
    } finally {
      setLoading( false );
    }
  };

  const handleOpenForm = () => setForm( true );
  const handleCloseForm = () => setForm( false );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display='flex' alignItems='center' justifyContent='flex-start'>
          <Box mr={2}>
            <Title title='Roles' />
          </Box>
          <Box>
            <AccessControl feature='role-permission' action='create'>
              <Button variant='contained' color='primary' onClick={handleOpenForm}>
                Create
              </Button>
            </AccessControl>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box marginTop={2}>
          { loading && <LinearProgress /> }            
        </Box>
        {
          data?.map( ( roles, index ) => (
            <Box my={5} key={index}>
              <RolePermissionRow data={roles} fetchData={fetchData} />
            </Box>
          ) )
        }
        <RolePermissionForm open={form} fetchData={fetchData} handleClose={handleCloseForm} />
      </Grid>
    </Grid>
  );
};

export default RolePermission;
