const onlyKhmerCharacter  = ( { value, message ='Please Enter Only Khmer Character' } ) => {
  // allow only khmer character by regex unicode rage included spacebar and spacial character
  const result = /^[\u1780-\u17FF\u200b\u00200-9\W]+$/.test( value );
  return  result || message;
};

const onlyEnglishCharacter  = ( { value, message ='Please Enter Only English Character' } ) => {
   // allow only English character by regex unicode rage
  const result = /^[\u0020-\u007F]+$/.test( value );
  return  result || message;
};

const isEmail = email =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

export { onlyKhmerCharacter, onlyEnglishCharacter, isEmail }