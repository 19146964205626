import React from 'react';
import { Grid, Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const BankInfoCardContent = ({ bank }) => {
	const { t } = useTranslation();

	const leftInfos = [
		{ label: 'bank-name', value: bank.bankName },
		{ label: 'account-name', value: bank.accountName },
		{ label: 'account-number', value: bank.accountNumber },
	];

	const rightInfos = [
		{ label: 'signer', value: bank.signer },
		{ label: 'iBanking', value: bank.iBanking },
	];

	return (
		<Grid container alignItems='flex-start'>
			{/* left */}
			<Grid item container xs={5} spacing={1}>
				{leftInfos.map(info => (
					<React.Fragment key={info.label}>
						<Grid item xs={5}>
							<Typography>{t(info.label)}:</Typography>
						</Grid>
						<Grid item xs={7}>
							<Typography>{info.value}</Typography>
						</Grid>
					</React.Fragment>
				))}
			</Grid>
			{/* right */}
			<Grid item container xs={5} spacing={1}>
				{rightInfos.map(info => (
					<React.Fragment key={info.label}>
						<Grid item xs={5}>
							<Typography>{t(info.label)}:</Typography>
						</Grid>
						<Grid item xs={7}>
							<Typography>{info.value}</Typography>
						</Grid>
					</React.Fragment>
				))}
				<Grid item xs={5}>
					<Typography>{t('bank-info-attachment')}:</Typography>
				</Grid>
				<Grid item container={!!bank.attachments.length} xs={7}>
					{bank.attachments.length ? (
						<>
							{bank.attachments.map(attachment => (
								<Grid item xs={12} key={attachment.key}>
									<Link
										href={attachment.url}
										target='_blank'
										rel='noopener'
										onClick={e => e.stopPropagation()}
									>
										{attachment.key.split('/')[1]}
									</Link>
								</Grid>
							))}
						</>
					) : (
						<Typography>N/A</Typography>
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};

export default BankInfoCardContent;
