import { Avatar, Box, Typography, capitalize } from '@material-ui/core';
import React from 'react';

const AvatarWithName = ({ staff = '', ...other }) => {
  return (
    <Box display='flex' alignItems='center'>
      <Box mr={1}>
        <Avatar alt={staff.nameLatin} style={{ width: 32, height: 32 }} src={staff.image?.key} />
      </Box>
      <Box>
        <Typography variant='body1'>{capitalize(staff.nameLatin)}</Typography>
      </Box>
    </Box>
  );
};

export default AvatarWithName;
