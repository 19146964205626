import React from 'react';
import { FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import { useController } from 'react-hook-form';

const RHFSelect = props => {
  const {
    name,
    label,
    control,
    required = false,
    rules,
    size,
    fullWidth = true,
    defaultValue = '',
    options = [], // options = [{ label: 'label', value: 'value' }]
    variant='outlined',
    ...otherProps
  } = props;

  // using react hook form useController as value state
  const {
    field: { ref, value, onChange, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    defaultValue: defaultValue,
    control, //optional when use inside formContext
    rules: { required, ...rules },
  });

  return (
    <FormControl size={size} variant={variant} fullWidth={fullWidth}>
      {label ? <InputLabel error={!!error}>{label}</InputLabel> : null}
      <Select
        fullWidth
        size="small"
        value={value}
        onChange={e => onChange(e.target.value)}
        inputRef={ref}
        error={!!error}
        label={label}
        {...inputProps}
        {...otherProps}
      >
        {options.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RHFSelect;
