import {
  Avatar,
  Box,
  makeStyles,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import React, { useRef, useState } from 'react';

import CropImage from '../../crop-image';
import s3 from '../../../utils/aws-s3';

const useStyles = makeStyles(() => ({
  userProfile: {
    width: '150px',
    height: '150px',
  },
}));

const Profile = ({ selectImage, setSelectImage, setValue }) => {
  const classes = useStyles();

  const imageInputRef = useRef();
  
  const [openPopup, setOpenPopup] = useState(false);
  const [editedImage, setEditedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const profileChangeHandler = async (e) => {
    // get file
    const file = e.target.files[0];

    if (!file) return;

    const { type } = file;

    if (type.endsWith('png') || type.endsWith('jpeg') || type.endsWith('jpg')) {
      let newImageObj = file
        ? {
            filename: file.name,
            imageURL: URL.createObjectURL(file),
            type: type,
            blob: new Blob([file], { type: type }),
          }
        : {};

      setPreviewImage(newImageObj);
      setEditedImage(newImageObj);
      setOpenPopup(true);
    }
  };

  const handleClosePopUp = () => setOpenPopup(false);

  const uploadProfileHandler = async () => {
    try {
      let newFilename = `edited-${editedImage.filename}`;
      let editedImageFile = new File([editedImage.blob], newFilename, {
        type: editedImage.type,
        lastModified: new Date().getTime(),
      });

      const imageData = await s3.uploadImage({
        image: editedImageFile,
        type: editedImage.type,
        folder: 'profiles',
      });

      const image = {
        key: imageData,
        mimeType: editedImage.type,
      };

      // set image value to hook form
      setValue('image', image);

      // set selectImage to the edited version
      setSelectImage({
        filename: newFilename,
        imageURL: editedImage.imageURL,
        type: editedImage.type,
        blob: editedImage.blob,
      });
      setIsLoading(false);
      setOpenPopup(false);
    } catch (error) {
      console.log('error change profile image :>> ', error);
      setIsLoading(false)
    }
  };

  return (
    <Box
      display='flex'
      alignItems='center'
      flexDirection='column'
      style={{ width: '100%' }}
    >
      <Box style={{ marginBottom: '1rem' }}>
        <Avatar className={classes.userProfile}
          src={selectImage?.imageURL}
        />
      </Box>
      <Box style={{ marginBottom: '1rem' }}>
        <input
          style={{ display: 'none' }}
          type='file'
          ref={imageInputRef}
          name='file'
          onChange={profileChangeHandler}
          accept='image/png, image/jpeg'
        />

        {/* Crop Image Pop up */}
        <Dialog open={openPopup}>
          <DialogTitle style={{ textAlign: 'center', color: '#184782' }}>
            Edit Profile
          </DialogTitle>
          <DialogContent style={{ width: '400px' }}>
            <CropImage
              openPopup={openPopup}
              image={previewImage?.imageURL}
              setEditedImage={setEditedImage}
            />
          </DialogContent>
          <DialogActions
            style={{ padding: '1rem 1.5rem', justifyContent: 'space-between' }}
          >
            <Button color='primary' onClick={handleClosePopUp} disabled={isLoading}>
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={uploadProfileHandler}
              disabled={isLoading}
            >
              {isLoading ? 'Submitting' : 'Submit'}
            </Button>
          </DialogActions>
        </Dialog>

        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            imageInputRef.current.click();
          }}
        >
          Upload your profile picture
        </Button>
      </Box>
      <Box width='60%'>
        <Typography style={{ textAlign: 'center', fontSize: '14px' }}>
          Profile picture should be in the standard format png, jpg & no more
          than 2MB.
        </Typography>
      </Box>
    </Box>
  );
};

export default Profile;
