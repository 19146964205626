import { Box, Button, Grid, makeStyles, Typography, capitalize } from '@material-ui/core';
import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import DashboardTitle from '../../components/Title';
import config from '../../components/tableX/config';
import AvatarWithName from '../../components/staff/avatar-with-name';
import TableX from '../../components/tableX';
import DeleteButton from '../../components/reusable/delete-button';
import EditButton from '../../components/reusable/edit-button';
import IndividualParticularDialog from '../../components/individual-particulars-new/individual-particular-dialog';
import { CustomSweetAlert } from '../../components/custom-sweet-altert2/custom-sweet-alter';

const useStyles = makeStyles(() => ({
  addCircleIcon: {
    cursor: 'pointer',
    marginRight: '0.5rem',
  },
  tableWrapper: {
    '& .tableX th': {
      textAlign: 'inherit !important',
    },
    '& .tableX td': {
      textAlign: 'inherit !important',
    },
  },
  actionBox: {
    '& > *': {
      marginRight: '1rem'
    }
  },
}));

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API_URL}/v1/individual/list`,
  dataConfig: ['individualParticular'],
};

const IndividualParticular = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [triggerFetch, setTriggerFetch] = useState(false);

  // Deleting Row Section
  const handleClickDelete = (individualParticular) => {
    const {_id} = individualParticular
    setTriggerFetch(false)
    CustomSweetAlert.fire({
      title: 'Are you sure?',
      text: 'Once deleted, it is not recoverable.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${process.env.REACT_APP_API_URL}/v1/individual/delete/${_id}`,
            { withCredentials: true }
          )
          .then((res) => {
            Swal.fire({
              title: 'Deleted!',
              text: 'Individual Particular has been deleted.',
              timer: 1000,
              icon: 'success',
              onOpen: () => {
                Swal.hideLoading();
              },
            }).then(() => {
              setTriggerFetch(true)
            });
          })
          .catch((err) => {
            console.log('err', err);
            Swal.fire({
              text: err.response.data.message,
              icon: 'error',
              onOpen: () => {
                Swal.hideLoading();
              },
            });
          });
      }
    });
  };

  // Creating/Editing Row Section
  const [showIndividualDialog, setShowIndividualDialog] = useState(false);
  const [individualDialogMode, setIndividualDialogMode] = useState('');
  const [activeIndividual, setActiveIndividual] = useState({});
  const handleAddIndividualParticular = () => {
    setActiveIndividual(null)
    setIndividualDialogMode('create');
    setShowIndividualDialog(true)
  };

  const handleEdit = (individualParticular) => {
    setActiveIndividual(individualParticular)
    setIndividualDialogMode('update')
    setShowIndividualDialog(true)
  };

  const handleTriggerFetch = () => {
    setTriggerFetch(prev => !prev);
  };

  const tableSchema = [
    {
      id: 'no',
      label: t('no.'),
      type: 'numbering',
      sort: false,
    },
    {
      label: t('full-name'),
      type: 'component',
      dataConfig: [],
      components: (data) => <AvatarWithName staff={data} />
    },
    {
      label: t('gender'),
      type: 'component',
      dataConfig: ['gender'],
      components: data => <Typography>{capitalize(data)}</Typography>
    },
    {
      label: t('nationality'),
      type: 'component',
      dataConfig: ['nationality'],
      components: (data) => <Typography>{data.value || "N/A"}</Typography>
    },
    {
      id: 'email',
      label: t('email-address'),
    },
    {
      id: 'phoneNumber',
      label: t('phone-number'),
      type: 'component',
      dataConfig: ['phoneNumber'],
      components: (data) => <Typography>{data ? data : 'null'}</Typography>
    },
    {
      sort: false,
      label: t('action'),
      type: 'component',
      dataConfig: [],
      components: (data) => {
        return (
          <Box className={classes.actionBox}>
            <EditButton
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(data);
              }}
            />
            <DeleteButton onClick={() => handleClickDelete(data)} />
          </Box>
        );
      },
    },
  ];

  return (
    <Grid container>
      {/* Header Section */}
      <Grid item xs={12}>
        <Box display='flex' alignItems='center' justifyContent='flex-start'>
          <Box mr={2}>
            <DashboardTitle title='Individual' />
          </Box>
          <Button variant='contained' color='primary' onClick={handleAddIndividualParticular}>
            Create
          </Button>
        </Box>
      </Grid>

      {/* Table Section */}
      <Grid item xs={12} className={classes.tableWrapper}>
        <TableX
          onRowClick={() => {}}
          triggerFetch={triggerFetch}
          config={tableConfig}
          schema={tableSchema}
        />
      </Grid>

      {/* Create/Edit Individual Particular Dialog */}
      <IndividualParticularDialog 
        open={showIndividualDialog}
        setOpen={setShowIndividualDialog}
        field={activeIndividual}
        mode={individualDialogMode}
        activeIndividual={activeIndividual}
        handleTriggerFetch={handleTriggerFetch}
      />
    </Grid>
  )
} 

export default IndividualParticular