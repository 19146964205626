/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  makeStyles,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import axios from "axios";
import DateFnsUtils from "@date-io/date-fns";
import { Autocomplete } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import FileUpload from "../../file-upload";
import { RHFSelect } from "../../rhf-controlled-input";

import { getCountry } from "../../../utils";
import ProvinceInfo from "../../../utils/ProvinceInfo";
import { onlyEnglishCharacter, onlyKhmerCharacter } from "../../../utils/functions/validateString";
import { uploadMultipleFilesToS3 } from "../../../utils/functions/upload-multiple-files";
import s3 from "../../../utils/aws-s3";

const useStyles = makeStyles(() => ({
  dialogRoot: {
    zIndex: 9999,
    "& .Mui-disabled": {
      background: "initial !important",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "800px !important",
    },
  },
  button: {
    borderRadius: 4,
    padding: "6px 12px",
    width: "100%",
    backgroundColor: "#EDF4FA",
    color: "#184782",
  },
  root: {
    "& .MuiIconButton-root": {
      padding: 0,
    },
  },
  primButton: {
    backgroundColor: "rgba(227, 0, 77, 0.07)",
    color: "rgba(227, 0, 77, 1)",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2)",
  },
  secButton: {
    backgroundColor: "rgba(1, 106, 178, 0.07)",
    color: "rgba(1, 106, 178)",
    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2)",
  },
}));

// pop-up for viewing, updating, and creating individual particular
// this is kinda separated from everything else
// since the operation takes effect immediately after completion
// no need to pass value around
const IndividualParticularDialog = (props) => {
  // mode can be "view", "update" and "create"
  const {
    open,
    setOpen,
    mode,
    activeIndividual,
    handleTriggerFetch,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    control,
    watch,
    setValue,
    reset,
    handleSubmit,
    trigger,
  } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const isPassport = watch(`identity.isPassport`);

  const placeOfBirth = {
    city: watch(`placeOfBirth.city`),
    commune: watch(`placeOfBirth.commune`),
    district: watch(`placeOfBirth.district`),
  };

  const address = {
    city: watch(`address.city`),
    commune: watch(`address.commune`),
    district: watch(`address.district`),
  };

  const [individualData, setIndividualData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
	const [attachments, setAttachments] = useState({ files: [], remove: [] });

  let title, action;

  switch (mode) {
    case "view":
      title = "view-individual";
      break;
    case "update":
      title = "update-individual";
      action = "Update";
      break;
    case "create":
      title = "create-new-individual";
      action = "Create";
      break;
    default:
      title = "";
      action = "";
  }

  let readOnly = mode === "view";

  const handleClose = () => {
    setOpen(false);
    reset();
    setAttachments({ files: [], remove: [] });
  };

  useEffect(() => {
    if (mode === "create") {
      reset({});
      setAttachments({ files: [], remove: [] });
    } else {
      // when user click view detail set individual data to field
      setIndividualData(activeIndividual);
      setAttachments({ files: activeIndividual.file || [], remove: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndividual]);

  useEffect(() => {
    if (individualData) {
      reset(individualData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualData]);

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)
      if (!attachments.files.length) {
        throw Error('Attachment is required')
      }

      const keys = attachments.remove.filter(attachment => attachment.key).map(file => file.key);
      if (keys.length) await s3.removeFilesFromS3(keys)
  
      let uploadedFiles = [];
  
      const filteredFileToUpload = attachments.files.filter(file => file.file);
      if (filteredFileToUpload.length) {
        // upload to S3
        uploadedFiles = await uploadMultipleFilesToS3(filteredFileToUpload, 'reserved_company');
      }
      // filter file array to get existed file that already upload to S3
      const filteredExistedFile = attachments.files.filter(file => !file.file);
      data.file = [...filteredExistedFile, ...uploadedFiles];
  
      if (mode === "update") {
        await handleUpdate(data);
      } else if (mode === "create") {
        await handleCreate(data);
      }
    } catch (error) {
      setIsLoading(false)
      console.log('error onSubmit :>> ', {...error});
      enqueueSnackbar(`${error || 'Error create/update individual'}`, { variant: 'error' });
    }
  };

  const handleUpdate = async (data) => {
    try {
      setIsLoading(true);
      
      const prepareUploadData = {
        individualParticular: {...data},
      };
      
      await axios.put(
        `${process.env.REACT_APP_API_URL}/v1/individual/update/${activeIndividual?._id}`,
        prepareUploadData,
      );
      setIsLoading(false);
      enqueueSnackbar('Update Successfully', { variant: 'success' });
      handleClose();
      handleTriggerFetch && handleTriggerFetch(); // refetch individual data
    } catch (error) {
        setIsLoading(false);
        console.log('error update individual :>> ', error);
        enqueueSnackbar('Error update individual', { variant: 'error' });
    }
  };

  const handleCreate = async (data) => {
    try {
      setIsLoading(true)

      await axios.post(`${process.env.REACT_APP_API_URL}/v1/individual/create`, data);

      setIsLoading(false)
      enqueueSnackbar('Create Successfully', { variant: 'success' });
      handleClose();
      handleTriggerFetch && handleTriggerFetch(); // refetch individual data
    } catch (error) {
      setIsLoading(false)
      console.log('error create individual :>> ', error);
      enqueueSnackbar('Error create individual', { variant: 'error' });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        className={classes.dialogRoot}
        onClose={isLoading ? null : handleClose}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            style={{
              textAlign: "center",
              color: "#184782",
              padding: "20px 10px",
            }}
          >
            {t(title)}
          </DialogTitle>
          <DialogContent>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid
                item
                container
                spacing={2}
                style={{ paddingBottom: "2rem" }}
              >
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item container xs={5}>
                    <Typography>{t("full-name-latin")}</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Controller
                      control={control}
                      render={({
                        field: { value, onChange, ref },
                        fieldState: { error },
                      }) => (
                        <TextField
                          variant="outlined"
                          label={t("full-name-latin-label")}
                          type="text"
                          fullWidth
                          inputRef={ref}
                          size="small"
                          value={value || ""}
                          onChange={(e) => {
                            onChange(e);
                            trigger("nameLatin");
                          }}
                          error={!!error}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          helperText={error?.message}
                        />
                      )}
                      name={`nameLatin`}
                      defaultValue=""
                      rules={{
                        required: true,
                        validate: (value) => onlyEnglishCharacter( { value } )
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item container xs={5}>
                    <Typography>{t("full-name-khmer")}</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Controller
                      control={control}
                      render={({
                        field: { value, onChange, ref },
                        fieldState: { error },
                      }) => (
                        <TextField
                          variant="outlined"
                          label={t("full-name-khmer-label")}
                          type="text"
                          fullWidth
                          inputRef={ref}
                          size="small"
                          value={value || ""}
                          onChange={(e) => {
                            onChange(e);
                            // trigger validate on every input
                            trigger("nameKhmer");
                          }}
                          error={!!error}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                          helperText={error?.message}
                        />
                      )}
                      name={`nameKhmer`}
                      defaultValue=""
                      rules={{
                        required: true,
                        validate: (value) => onlyKhmerCharacter( { value } )
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item container xs={5}>
                    <Typography>{t("gender")}</Typography>
                  </Grid>
                  <Grid item container xs={7} alignContent="center">
                    <Controller
                      name={`gender`}
                      control={control}
                      defaultValue={"male"}
                      render={({ field: { value, onChange } }) => (
                        <RadioGroup
                          row
                          aria-label="gender"
                          value={value || "male"}
                          onChange={onChange}
                        >
                          <FormControlLabel
                            value="male"
                            disabled={readOnly}
                            control={<Radio color="primary" />}
                            label={t("male")}
                          />
                          <FormControlLabel
                            disabled={readOnly}
                            value="female"
                            control={<Radio color="primary" />}
                            label={t("female")}
                          />
                        </RadioGroup>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item container xs={5}>
                    <Typography>{t("nationality")}</Typography>
                  </Grid>
                  <Grid item container xs={7} alignContent="center">
                    <Controller
                      name={`nationality`}
                      control={control}
                      defaultValue={null}
                      rules={{ required: true }}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          autoHighlight
                          disabled={readOnly}
                          style={{ width: "100%" }}
                          value={value || null}
                          options={getCountry()}
                          getOptionLabel={(option) =>
                            option ? option.label : null
                          }
                          getOptionSelected={(option, value) => {
                            if (value === null) {
                              return true;
                            } else if (value === "undefined") {
                              return true;
                            } else if (value.label === option.label) {
                              return true;
                            }
                          }}
                          onChange={(e, data) => onChange(data)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("nationality-label")}
                              variant="outlined"
                              size="small"
                              error={!!error}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item container xs={5}>
                    <Typography>{t("place-of-birth")}</Typography>
                  </Grid>
                  <Grid item container xs={7}>
                    <Grid item xs={6} style={{ padding: "0 8px 16px 0" }}>
                      <Controller
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            autoHighlight
                            disabled={readOnly}
                            value={value}
                            options={ProvinceInfo.generateCityOptions()}
                            getOptionLabel={(option) =>
                              option ? option.en : null
                            }
                            getOptionSelected={(option, value) => {
                              if (value === null) {
                                return true;
                              } else if (typeof value === "undefined") {
                                return true;
                              } else if (value.value === option.value) {
                                return true;
                              }
                            }}
                            onChange={(e, data) => {
                              onChange(data);
                              setValue(`placeOfBirth.district`, null);
                              setValue(`placeOfBirth.commune`, null);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("city")}
                                variant="outlined"
                                size="small"
                                error={!!error}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                              />
                            )}
                          />
                        )}
                        control={control}
                        name={`placeOfBirth.city`}
                        defaultValue={null}
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ padding: "0 0 16px 8px" }}>
                      <Controller
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            autoHighlight
                            disabled={readOnly}
                            value={value}
                            options={ProvinceInfo.generateDistrictOptions(
                              placeOfBirth
                            )}
                            getOptionLabel={(option) =>
                              option ? option.en : null
                            }
                            getOptionSelected={(option, value) => {
                              if (value === null) {
                                return true;
                              } else if (typeof value === "undefined") {
                                return true;
                              } else if (value.value === option.value) {
                                return true;
                              }
                            }}
                            onChange={(e, data) => {
                              onChange(data);
                              setValue(`placeOfBirth.commune`, null);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("district")}
                                variant="outlined"
                                size="small"
                                error={!!error}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                              />
                            )}
                          />
                        )}
                        control={control}
                        name={`placeOfBirth.district`}
                        defaultValue={null}
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ padding: "0 8px 0px 0" }}>
                      <Controller
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            autoHighlight
                            disabled={readOnly}
                            value={value}
                            options={ProvinceInfo.generateCommuneOptions(
                              placeOfBirth
                            )}
                            getOptionLabel={(option) =>
                              option ? option.en : null
                            }
                            getOptionSelected={(option, value) => {
                              if (value === null) {
                                return true;
                              } else if (typeof value === "undefined") {
                                return true;
                              } else if (value.value === option.value) {
                                return true;
                              }
                            }}
                            onChange={(e, data) => onChange(data)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("commune")}
                                variant="outlined"
                                size="small"
                                error={!!error}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                              />
                            )}
                          />
                        )}
                        control={control}
                        name={`placeOfBirth.commune`}
                        defaultValue={null}
                        rules={{ required: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item xs={5}>
                    <Typography>{t("date-of-birth")}</Typography>
                  </Grid>
                  <Grid item container xs={7}>
                    <Grid item xs={6} style={{ padding: "0 8px 0px 0" }}>
                      <Controller
                        control={control}
                        name={`dob`}
                        defaultValue={null}
                        rules={{ required: true }}
                        render={({
                          field: { value, onChange, ref },
                          fieldState: { error },
                        }) => (
                          <KeyboardDatePicker
                            disabled={readOnly}
                            className={classes.root}
                            initialFocusedDate={new Date("1990/01/01")}
                            autoOk
                            label={t("ថ្ងៃខែឆ្នាំកំណើត")}
                            variant="inlined"
                            inputVariant="outlined"
                            size="small"
                            format="dd/MM/yyyy"
                            margin="none"
                            value={value}
                            onChange={onChange}
                            clearable
                            KeyboardButtonProps={{
                              style: {
                                outline: "none",
                              },
                            }}
                            error={!!error}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item container xs={5}>
                    <Typography>{t("occupation")}</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <RHFSelect
                      disabled={readOnly}
                      name='occupation'
                      control={control}
                      required
                      size='small'
                      label={t('occupation-label')}
                      options={[{ label: 'ពាណិជ្ជករ', value: 'ពាណិជ្ជករ' }]}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Grid item container xs={5}>
                    <Typography>{t("current-address")}</Typography>
                  </Grid>
                  <Grid item container xs={7}>
                    <Grid item xs={6} style={{ padding: "0 8px 16px 0" }}>
                      <Controller
                        control={control}
                        render={({
                          field: { value, onChange, ref },
                          fieldState: { error },
                        }) => (
                          <TextField
                            variant="outlined"
                            label={t("No")}
                            type="text"
                            fullWidth
                            inputRef={ref}
                            size="small"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            inputProps={{
                              readOnly: readOnly,
                            }}
                          />
                        )}
                        name={`address.homeNumber`}
                        defaultValue=""
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ padding: "0 0 16px 8px" }}>
                      <Controller
                        control={control}
                        render={({
                          field: { value, onChange, ref },
                          fieldState: { error },
                        }) => (
                          <TextField
                            variant="outlined"
                            label={t("street")}
                            type="text"
                            fullWidth
                            inputRef={ref}
                            size="small"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            inputProps={{
                              readOnly: readOnly,
                            }}
                          />
                        )}
                        name={`address.street`}
                        defaultValue=""
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ padding: "0 8px 16px 0" }}>
                      <Controller
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            autoHighlight
                            disabled={readOnly}
                            value={value}
                            options={ProvinceInfo.generateCityOptions()}
                            getOptionLabel={(option) =>
                              option ? option.en : null
                            }
                            getOptionSelected={(option, value) => {
                              if (value === null) {
                                return true;
                              } else if (typeof value === "undefined") {
                                return true;
                              } else if (value.value === option.value) {
                                return true;
                              }
                            }}
                            onChange={(e, data) => {
                              onChange(data);
                              setValue(`address.district`, null);
                              setValue(`address.commune`, null);
                              setValue(`address.village`, null);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("city")}
                                variant="outlined"
                                size="small"
                                error={!!error}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                              />
                            )}
                          />
                        )}
                        control={control}
                        name={`address.city`}
                        defaultValue={null}
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ padding: "0 0 16px 8px" }}>
                      <Controller
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            autoHighlight
                            disabled={readOnly}
                            value={value}
                            options={ProvinceInfo.generateDistrictOptions(
                              address
                            )}
                            getOptionLabel={(option) =>
                              option ? option.en : null
                            }
                            getOptionSelected={(option, value) => {
                              if (value === null) {
                                return true;
                              } else if (typeof value === "undefined") {
                                return true;
                              } else if (value.value === option.value) {
                                return true;
                              }
                            }}
                            onChange={(e, data) => {
                              onChange(data);
                              setValue(`address.commune`, null);
                              setValue(`address.village`, null);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("district")}
                                variant="outlined"
                                size="small"
                                error={!!error}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                              />
                            )}
                          />
                        )}
                        control={control}
                        name={`address.district`}
                        defaultValue={null}
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ padding: "0 8px 16px 0" }}>
                      <Controller
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            autoHighlight
                            disabled={readOnly}
                            value={value}
                            options={ProvinceInfo.generateCommuneOptions(
                              address
                            )}
                            getOptionLabel={(option) =>
                              option ? option.en : null
                            }
                            getOptionSelected={(option, value) => {
                              if (value === null) {
                                return true;
                              } else if (typeof value === "undefined") {
                                return true;
                              } else if (value.value === option.value) {
                                return true;
                              }
                            }}
                            onChange={(e, data) => {
                              onChange(data);
                              setValue(`address.village`, null);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("commune")}
                                variant="outlined"
                                size="small"
                                error={!!error}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                              />
                            )}
                          />
                        )}
                        control={control}
                        name={`address.commune`}
                        defaultValue={null}
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ padding: "0 0 16px 8px" }}>
                      <Controller
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Autocomplete
                            autoHighlight
                            disabled={readOnly}
                            value={value}
                            options={ProvinceInfo.generateVillageOptions(
                              address
                            )}
                            getOptionLabel={(option) =>
                              option ? option.en : null
                            }
                            getOptionSelected={(option, value) => {
                              if (value === null) {
                                return true;
                              } else if (typeof value === "undefined") {
                                return true;
                              } else if (value.value === option.value) {
                                return true;
                              }
                            }}
                            onChange={(e, data) => onChange(data)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("village")}
                                variant="outlined"
                                size="small"
                                error={!!error}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password",
                                }}
                              />
                            )}
                          />
                        )}
                        control={control}
                        name={`address.village`}
                        defaultValue={null}
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ padding: "0 8px 0 0" }}>
                      <Controller
                        control={control}
                        render={({
                          field: { value, onChange, ref },
                          fieldState: { error },
                        }) => (
                          <TextField
                            variant="outlined"
                            label={t("group")}
                            type="text"
                            fullWidth
                            inputRef={ref}
                            size="small"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            inputProps={{
                              readOnly: readOnly,
                            }}
                          />
                        )}
                        name={`address.group`}
                        defaultValue=""
                        // rules={{ required: true }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item container xs={5}>
                    <Typography>{t("phone-number")}</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Controller
                      control={control}
                      render={({
                        field: { value, onChange, ref },
                        fieldState: { error },
                      }) => (
                        <TextField
                          variant="outlined"
                          label={t("phone-number-label")}
                          type="text"
                          fullWidth
                          inputRef={ref}
                          size="small"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      )}
                      name={`phoneNumber`}
                      defaultValue=""
                      rules={{ required: true }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item container xs={5}>
                    <Typography>{t("email")}</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Controller
                      control={control}
                      render={({
                        field: { value, onChange, ref },
                        fieldState: { error },
                      }) => (
                        <TextField
                          variant="outlined"
                          label={t("email-label")}
                          type="email"
                          fullWidth
                          inputRef={ref}
                          size="small"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          inputProps={{
                            readOnly: readOnly,
                          }}
                        />
                      )}
                      name={`email`}
                      defaultValue=""
                      rules={{ required: true }}
                    />
                  </Grid>
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="space-between"
                >
                  <Grid item container xs={5}>
                    <Typography>{t("id-or-passport")}</Typography>
                  </Grid>
                  <Grid item container xs={7}>
                    <Grid item xs={12}>
                      <Controller
                        name={`identity.isPassport`}
                        control={control}
                        defaultValue={"false"}
                        render={({ field: { value, onChange } }) => (
                          <RadioGroup
                            row={true}
                            value={String(value)}
                            onChange={onChange}
                          >
                            <FormControlLabel
                              disabled={readOnly}
                              value="false"
                              control={<Radio color="primary" />}
                              label={t("id-card-label")}
                            />
                            <FormControlLabel
                              disabled={readOnly}
                              value="true"
                              control={<Radio color="primary" />}
                              label={t("passport-label")}
                            />
                          </RadioGroup>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} style={{ padding: "0 8px 16px 0" }}>
                      <Controller
                        control={control}
                        render={({
                          field: { value, onChange, ref },
                          fieldState: { error },
                        }) => (
                          <TextField
                            variant="outlined"
                            label={
                              isPassport === "true"
                                ? `លេខលិខិតឆ្លងដែន`
                                : `លេខអត្តសញ្ញាណប័ណ្ណ`
                            }
                            type="text"
                            fullWidth
                            inputRef={ref}
                            size="small"
                            value={value}
                            onChange={onChange}
                            error={!!error}
                            inputProps={{
                              readOnly: readOnly,
                            }}
                          />
                        )}
                        name={`identity.number`}
                        defaultValue=""
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid
                      item
                      container
                      xs={6}
                      style={{ padding: "0 0 16px 8px" }}
                    >
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name={`identity.issuedDate`}
                          defaultValue={null}
                          rules={{ required: true }}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <KeyboardDatePicker
                              disabled={readOnly}
                              className={classes.root}
                              // initialFocusedDate={new Date('1990/01/01')}
                              autoOk
                              label={t("ថ្ងៃចេញឱ្យប្រើប្រាស់")}
                              variant="inline"
                              inputVariant="outlined"
                              size="small"
                              format="dd/MM/yyyy"
                              margin="none"
                              value={value}
                              onChange={(date)=>{
                                let start = new Date(date);
                                let end = new Date (watch(`identity.expiryDate`));
                                // check if issued date selection is greater than expiry date => clear expiry date
                                if(start.getTime() >= end.getTime()) {
                                  setValue('identity.expiryDate', null)
                                }
                                onChange(date)
                              }}
                              KeyboardButtonProps={{
                                style: {
                                  outline: "none",
                                },
                              }}
                              error={!!error}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={6}
                      style={{ padding: "0 8px 16px 0" }}
                    >
                      <Grid item xs={12}>
                        <Controller
                          control={control}
                          name={`identity.expiryDate`}
                          defaultValue={null}
                          rules={{ required: true }}
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <KeyboardDatePicker
                              disabled={readOnly}
                              className={classes.root}
                              // initialFocusedDate={new Date('1990/01/01')}
                              autoOk
                              label={t("ថ្ងៃ​ផុតកំណត់")}
                              variant="inline"
                              inputVariant="outlined"
                              size="small"
                              format="dd/MM/yyyy"
                              margin="none"
                              value={value}
                              minDate={watch(`identity.issuedDate`)}
                              onChange={onChange}
                              KeyboardButtonProps={{
                                style: {
                                  outline: "none",
                                },
                              }}
                              error={!!error}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid item container xs={5}>
                    <Typography>{t("attachment")}</Typography>
                  </Grid>
                  <Grid item container xs={7} spacing={1}>
                    <Grid item xs={12}>
                      <Typography>{t("file-attachment-label")}</Typography>
                    </Grid>
                    <Grid item xs={true}>
                      <FileUpload
                        files={attachments}
                        setFiles={setAttachments}
                        isPreviewMode={mode === 'view' ? true : false}
                        acceptFileTypes={['.png', '.jpeg', '.jpg', '.pdf']} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions
            style={{ padding: "1rem 1.5rem", justifyContent: "space-between" }}
          >
            <Button onClick={handleClose}  color="secondary" disabled={isLoading}>
              Close
            </Button>
            {mode !== "view" && (
              <Button variant="contained" color="primary" type="submit" disabled={isLoading}>
                {action}
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default IndividualParticularDialog;
