import React, { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { Slider, Typography, Grid } from '@material-ui/core';

const CropImage = ({ image, setEditedImage }) => {
  const [scaleValue, setScaleValue] = useState(10);
  const [rotate, setRotate] = useState(0);
  const editorRef = useRef();

  const getImage = async () => {
    const canvas = editorRef.current.getImageScaledToCanvas().toDataURL();
    let res = await fetch(canvas);
    let blob = await res.blob();
    let imageURL = URL.createObjectURL(blob);
    return { blob, imageURL };
  };

  const handleScaleValueChange = async (e, val) => {
    setScaleValue(val);

    let { blob, imageURL } = await getImage();

    setEditedImage({ blob, imageURL });
  };

  const handleRotation = async (e, val) => {
    setRotate(val);
    let { blob, imageURL } = await getImage();

    setEditedImage({ blob, imageURL });
  };

  return (
    <>
      <AvatarEditor
        ref={editorRef}
        image={image}
        width={150}
        height={150}
        border={50}
        color={[255, 255, 255, 0.6]}
        scale={scaleValue / 10}
        rotate={rotate}
        borderRadius={200}
        backgroundColor='#fff'
      />
      <Grid container style={{ marginTop: '1rem' }}>
        <Grid item container xs={12}>
          <Grid item xs={3}>
            <Typography style={{ paddingRight: '1rem' }}>Zoom:</Typography>
          </Grid>
          <Grid item xs={9}>
            <Slider
              value={scaleValue}
              onChange={handleScaleValueChange}
              min={10}
              max={100}
              valueLabelDisplay='auto'
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Grid item xs={3}>
            <Typography style={{ paddingRight: '1rem' }}>Ratate:</Typography>
          </Grid>
          <Grid item xs={9}>
            <Slider
              value={rotate}
              onChange={handleRotation}
              min={0}
              max={360}
              valueLabelDisplay='auto'
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CropImage;
