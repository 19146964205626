import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	makeStyles,
	TextField,
	Typography,
	Box,
	useTheme,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import FileUpload from '../../file-upload';

import { uploadMultipleFilesToS3 } from '../../../utils/functions/upload-multiple-files';

const useStyles = makeStyles(theme => ({
	dialogActionContainer: {
		justifyContent: 'space-between',
		padding: `${theme.spacing(2.5)}px ${theme.spacing(4)}px`,
	},
}));

const textInputs = [
	{ label: 'bank-name', placeholder: 'bank-name', name: 'bankName' },
	{ label: 'account-name', placeholder: 'account-name', name: 'accountName' },
	{
		label: 'account-number',
		placeholder: 'account-number',
		name: 'accountNumber',
	},
	{ label: 'signer', placeholder: 'signer', name: 'signer' },
	{ label: 'iBanking', placeholder: 'iBanking', name: 'iBanking' },
];

const AddBankAccountDialog = ({ open, setOpen, fetchBanks, selectedBank, handleAppendNewBankHistory }) => {
	const classes = useStyles();
	const theme = useTheme();
	const { t } = useTranslation();
	const {
		handleSubmit,
		control,
		formState: { errors },
		reset,
	} = useForm();
	const { id } = useParams(); // company id
	const { enqueueSnackbar } = useSnackbar();

	const [attachments, setAttachments] = useState({ files: [], remove: [] });
	const [isLoading, setIsLoading] = useState(false);
	const [dialogTitle, setDialogTitle] = useState('add-bank-account');

	const handleClose = () => {
		setOpen(false);
	};

	const onSubmit = async data => {
		try {
			setIsLoading(true);
			let uploadedFiles = [];
			let responseMessage = '';

			// filter file array to get file object which has `file` property
			const filteredFileToUpload = attachments.files.filter(file => file.file);
			if (filteredFileToUpload.length) {
				// upload file to S3
				uploadedFiles = await uploadMultipleFilesToS3(filteredFileToUpload, 'bank');
			}
			// filter file array to get existed file that already upload to S3
			const filteredExistedFile = attachments.files.filter(file => !file.file);
			data.attachments = [...filteredExistedFile, ...uploadedFiles];

			if (selectedBank) {
				data._id = selectedBank._id;
				// update
				const { data: response } = await axios.put(
					`${process.env.REACT_APP_API_URL}/v1/companies/bank/edit/${id}`,
					data
				);
				responseMessage = 'Update bank successfully';
				// append new bank history
				handleAppendNewBankHistory(selectedBank._id, response.oldBankInfo);
			} else {
				// create
				await axios.put(`${process.env.REACT_APP_API_URL}/v1/companies/bank/add/${id}`, data);
				responseMessage = 'Add bank successfully';
			}

			enqueueSnackbar(`${responseMessage}`, { variant: 'success' });
			fetchBanks();
			setIsLoading(false);
			handleClose();
		} catch (error) {
			console.log('error add bank :>> ', error);
			setIsLoading(false);
			enqueueSnackbar(`${error?.response?.date?.message || 'Error add bank account'}`, {
				variant: 'error',
			});
		}
	};

	const handleResetField = () => {
		reset({
			bankName: '',
			accountName: '',
			accountNumber: '',
			signer: '',
			iBanking: '',
		});
		setAttachments({ files: [], remove: [] });
	};

	const handleResetEditField = () => {
		setDialogTitle(selectedBank ? 'edit-bank-account' : 'add-bank-account');

		if (selectedBank) {
			reset({
				bankName: selectedBank.bankName,
				accountName: selectedBank.accountName,
				accountNumber: selectedBank.accountNumber,
				signer: selectedBank.signer,
				iBanking: selectedBank.iBanking,
			});
			setAttachments({ files: selectedBank.attachments, remove: [] });
		}
	};

	return (
		<Dialog
			open={open}
			keepMounted
			fullWidth={true}
			TransitionProps={{
				onExited: () => handleResetField(), // reset form after exit dialog
				onEntering: () => handleResetEditField(), // when open dialog
			}}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogTitle style={{ textAlign: 'center', color: theme.palette.primary.main }}>
					{t(dialogTitle)}
				</DialogTitle>
				<DialogContent>
					<Box style={{ padding: 12 }}>
						<Grid container justifyContent='center' spacing={2}>
							{/* upper text inputs */}
							{textInputs?.map((textInput, index) => (
								<Grid key={index} item container spacing={1}>
									<Grid item container xs={12}>
										<Typography>
											{index + 1}. {t(textInput.label)}
										</Typography>
									</Grid>
									<Grid item xs={12}>
										<Controller
											render={({ field: { ref, ...field } }) => (
												<TextField
													{...field}
													inputRef={ref}
													error={!!errors[textInput.name]}
													variant='outlined'
													placeholder={t(textInput.placeholder)}
													type='text'
													fullWidth
													size='small'
												/>
											)}
											control={control}
											name={textInput.name}
											defaultValue=''
											rules={{ required: true }}
										/>
									</Grid>
								</Grid>
							))}
							{/* attachment */}
							<Grid item xs={12}>
								<Typography>
									{textInputs?.length + 1}. {t('attachment')}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<FileUpload files={attachments} setFiles={setAttachments} />
							</Grid>
						</Grid>
					</Box>
				</DialogContent>
				<DialogActions className={classes.dialogActionContainer}>
					<Button onClick={handleClose} variant='contained' disabled={isLoading}>
						Back
					</Button>
					<Button type='submit' variant='contained' color='primary' disabled={isLoading}>
						{isLoading ? 'Submitting...' : 'Submit'}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default AddBankAccountDialog;
