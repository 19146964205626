import React, { forwardRef, useEffect, useState, useImperativeHandle, useCallback } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Grid, Typography, Button, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

import FileUpload from '../file-upload';
import { RHFAutocompleteInput, RFHAutocompleteWithExistingData, RHFTextInput } from '../../components/rhf-controlled-input';
import { onlyEnglishCharacter, onlyKhmerCharacter } from '../../utils/functions/validateString';

const BusinessActivity = forwardRef((props, ref) => {
	const { t } = useTranslation();
	const { control, watch, setValue } = useFormContext();
	const businessActivity = watch('businessActivity');
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'businessActivity',
	});

	const [count, setCount] = useState(0);
	const { files, setFiles, setDeleteFiles } = props;
	useImperativeHandle(ref, () => ({
		count,
	}));

	useEffect(
		() => {
			if (fields.length === 0) {
				append({ mainBusinessActivity: null, subActivities: [] });
				setFiles([{ files: [], remove: [] }]);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[fields.length]
	);

	const onDropFile = useCallback(
		async (acceptedFiles, key) => {
			if (acceptedFiles.length > 0) {
				let newFile = acceptedFiles.filter(file => {
					if (files.length > 0) {
						return !files.find(image => JSON.stringify(image) === JSON.stringify(file));
					}
					return file;
				});

				// pre convert to ObjectURL to prevent lagging
				newFile = newFile.map(file => ({
					file: file,
					ObjectURL: URL.createObjectURL(file),
				}));

				const newFilesArray = [...files];
				newFilesArray[key].files.push(...newFile)

				setFiles(newFilesArray);
			}
		},
		[files, setFiles]
	);

	const handleRemoveFile = (index, key) => {
		const newArrayFiles = [...files];

		const fileToRemove = newArrayFiles?.[key]?.files[index];

		newArrayFiles[key].remove.push(fileToRemove);

		newArrayFiles[key].files.splice(index, 1);

		setFiles(newArrayFiles);
	};

	const handleFetchBusinessSubActivities = key => {
		const selectedMainBusinessActivity = businessActivity[key].mainBusinessActivity;
		return selectedMainBusinessActivity?.subItems || [];
	};

	const handleDelete = key => {
		if (key !== 0) {
			remove(key); // remove businessActivity field by key
			setCount(prev => prev - 1);
			const newArrayFiles = [...files];

			// get the deleted business activity's file
			const deleteFiles = [...newArrayFiles[key].files, ...newArrayFiles[key].remove]; 
			// set delete files to deleteFiles state to remove from S3
			setDeleteFiles(prev => [...prev, ...deleteFiles.filter(file => file.key).map(file => file.key)]);

			// remove business activities files from businessAttachments state
			newArrayFiles.splice(key, 1); 
			setFiles(newArrayFiles);
		}
	};

	const handleAppendBusinessActivity = () => {
		append({ mainBusinessActivity: null, subActivities: [] });
		setCount(prev => prev + 1);
		setFiles(prev => [...prev, { files: [], remove: [] }]);
	};

	// double arrows function
	const handleBusinessActivityOnChange = key => (e, data, reason, onChange) => {
		onChange(data);
		setValue(`businessActivity[${key}].subActivities`, []); // clear sub activity

		if (!data?.license) {
			// remove files when license is `false`
			const newFilesArray = [...files];
			newFilesArray[key].remove = [...newFilesArray[key].files];
			newFilesArray[key].files = [];

			setFiles(newFilesArray);	
		}
	};

	const generateActivities = () => {
		return fields.map((field, key) => (
			<div key={field.id} style={{ width: '100%' }}>
				<Grid
					item
					container
					xs={12}
					direction='row'
					justifyContent='space-between'
					style={{ padding: '8px 0' }}
				>
					<Grid item xs={5}>
						<Typography>{t('main-activity')}</Typography>
					</Grid>
					<Grid item xs={6}>
						<RHFAutocompleteInput
							autoHighlight
							name={`businessActivity[${key}].mainBusinessActivity`}
							rules={{ required: true }}
							defaultValue={''}
							optionsURL={`${process.env.REACT_APP_API_URL}/v1/business-activity?search={search}&page=1&limit=10`}
							getOptionSelected={(option, value) => option._id === value._id}
							onChange={handleBusinessActivityOnChange(key)}
							getOptionLabel={option => option.label || option.nameEN || ''}
							renderOption={option => option.nameEN}
							dataConfig={'businessActivities'}
							noOptionsText={t('no-business-activity-found')}
							disableClearable={false}
							label={t('ជ្រើសរើសសកម្មភាពអាជីវកម្ម')}
						/>
					</Grid>
					<Grid item xs={1}>
						{key !== 0 && (
							<IconButton onClick={() => handleDelete(key)}>
								<DeleteIcon color='secondary' />
							</IconButton>
						)}
					</Grid>
				</Grid>
				<Grid
					item
					container
					xs={12}
					direction='row'
					justifyContent='space-between'
					style={{ padding: '8px 0' }}
				>
					<Grid item xs={5}>
						<Typography>{t('sub-activity')}</Typography>
					</Grid>
					<Grid item xs={6}>
						<RFHAutocompleteWithExistingData
							autoHighlight
							multiple
							// disabled when mainActivity autocomplete's value is empty
							disabled={!watch(`businessActivity[${key}].mainBusinessActivity`)}
							options={handleFetchBusinessSubActivities(key)}
							label={t('ជ្រើសរើសសកម្មភាពបន្ទាប់បន្សំ')}
							name={`businessActivity[${key}].subActivities`}
							required={true}
							defaultValue={[]}
							getOptionLabel={option => option.label || option.nameEN}
							getOptionSelected={(option, value) => option._id === value._id}
						/>
					</Grid>
					<Grid item xs={1} />
				</Grid>
				{/* Check if user selected either main or sub activity's license is true => upload file is required */}
				{(watch(`businessActivity[${key}].mainBusinessActivity`)?.license === true ||
					watch(`businessActivity[${key}].subActivities`)?.license === true) && (
					<Grid
						item
						container
						xs={12}
						direction='row'
						justifyContent='space-between'
						style={{ padding: '8px 0' }}
					>
						<Grid item xs={5}>
							<Typography>{t('File Upload')}</Typography>
						</Grid>
						<Grid item xs={6}>
							<FileUpload
								files={files?.[key] || []}
								customOnDropFn={acceptedFiles => {
									onDropFile(acceptedFiles, key);
								}}
								customRemoveFileFn={index => {
									handleRemoveFile(index, key);
								}}
							/>
						</Grid>
						<Grid item xs={1} />
					</Grid>
				)}
			</div>
		));
	};

	return (
		<Grid container spacing={2}>
			<Grid item container xs={12} direction="row" justifyContent="space-between">
				<Grid item container xs={5}>
						<Typography>
								{t('activity-on-patent-certificate-in-khmer')}
						</Typography>
				</Grid>
				<Grid item xs={6}>
					<RHFTextInput
						name='activityOnPatentCertificateInKhmer'
						required
						label={t('activity-patent-certificate-in-khmer-label')}
						rules={{
							validate: value => onlyKhmerCharacter({ value })
						}}
					/>
				</Grid>
				<Grid item xs={1}/>
			</Grid>
			<Grid item container xs={12} direction="row" justifyContent="space-between">
				<Grid item container xs={5}>
						<Typography>
								{t('activity-on-patent-certificate-in-latin')}
						</Typography>
				</Grid>
				<Grid item xs={6}>
					<RHFTextInput
						name='activityOnPatentCertificateInLatin'
						required
						label={t('activity-patent-certificate-in-latin-label')}
						rules={{
							validate: value => onlyEnglishCharacter({ value })
						}}
					/>
				</Grid>
				<Grid item xs={1}/>
			</Grid>
			<Grid item container xs={12}>
				{generateActivities()}
			</Grid>
			<Grid item container xs={11} justifyContent='flex-end'>
				<Button color='primary' variant='contained' onClick={handleAppendBusinessActivity}>
					<AddCircleIcon style={{ marginRight: '0.5rem' }} />
					{t('add-more-activity-btn')}
				</Button>
			</Grid>
		</Grid>
	);
});

export default BusinessActivity;
