import axios from 'axios';
import Cookies from 'js-cookie';

import history from '../history';

/**
 * config axios setting
 */

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/v1`;

axios.defaults.withCredentials = true;

axios.interceptors.request.use(
	config => {
		return config;
	},
	error => {
		console.log('axios request interceptors error :>> ', error);
		return Promise.reject(error);
	}
);

axios.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		console.log('axios response interceptors error :>> ', error);
		if (error?.response?.status === 401) {
			Cookies.remove('user');
			history.push('/');
		}
		return Promise.reject(error);
	}
);
