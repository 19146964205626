// Third party's imports
import React, { useEffect, useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import axios from 'axios';

// Local imports
import BasicTabs from '../../../components/basic-tabs';
import CompanyInfoTab from '../../../components/company-info-tab';
import BankInfo from '../../../components/bank-info';
import PostRegistration from '../../../components/post-registration';

const useStyles = makeStyles((theme) => ({
  tabs: {
    '& .MuiTab-root.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: '#ffffff',
    },
    '& .MuiTab-root': {
      backgroundColor: '#E6E7E8',
      color: '#000000',
      'text-transform': 'capitalize !important',
    },
  },
}));

const RegisteredCompanyView = () => {
  const classes = useStyles();
  const { id } = useParams();

  const [company, setCompany] = useState();

  // get company data
  useEffect(() => {
    const fetchCompany = () => {
      axios
        .get(`${process.env.REACT_APP_API_URL}/v1/company/${id}`, {
          withCredentials: true,
        })
        .then((res) => {
          setCompany(res.data?.data);
        })
        .catch((err) => console.log(err));
    };

    fetchCompany();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!company) return <></>;

  const tabs = [
    { label: 'Company Info', component: <CompanyInfoTab company={company} /> },
    { label: 'Bank Info', component: <BankInfo /> },
    { label: 'Post Registration', component: <PostRegistration isPreviewMode={true} /> },
  ];

  return (
    <>
      <Typography variant='h4' style={{ marginBottom: '1rem' }}>
        {company?.companyNameLatin}
      </Typography>
      <BasicTabs
        tabs={tabs}
        className={classes.tabs}
        TabIndicatorProps={{ style: { display: 'none' } }}
      />
    </>
  );
};

export default RegisteredCompanyView;
