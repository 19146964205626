import {
  Button,
  capitalize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

const RepresentativeSelectionDialog = ({
  company,
  locale,
  open,
  handleClose,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, control, reset, formState: { errors } } = useForm();

  const [shareholders, setShareholders] = useState([]); // representative
  const [companies, setCompanies] = useState([]); // investing company

  const fetchShareholders = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/v1/company/${company._id}/shareholders`,
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        const { data } = res;
        setShareholders(data.shareholders);
        setCompanies(data.companies);
      })
      .catch((error) => {
        console.log('error fetch shareholder :>> ', error);
      })
  };

  useEffect(() => {
    if (open) fetchShareholders();
  }, [company, open]);

  const onSubmit = (data) => {
    axios({
			url: `${process.env.REACT_APP_API_URL}/v1/docs/moc-shareholders-resolution/${company._id}`,
			method: 'GET',
			responseType: 'blob', // important
			params: {
				investingCompany: data.investingCompany.value,
				repNameKh: data.representative.nameKhmer,
				repNameEn: data.representative.nameLatin,
				numberOfShare: data.numberOfShare,
				identityNumber: data.representative.identity?.number,
				identityIssuedDate: data.representative.identity?.issuedDate,
				lang: locale,
			},
		})
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', `${company.companyNameLatin}_share_resolution.docx`);
				document.body.appendChild(link);
				link.click();
			})
      .catch(error => {
        console.log('error generate shareholder resolution doc :>> ', error);
				handleClose();
				Swal.fire({
					title: 'An Error Occurred',
					text: 'Downloading Shareholder Resolution Unsuccessful',
					icon: 'error',
				});
			});
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      fullWidth={true}
      TransitionProps={{ 
        onExited: () => reset({
          representative: null,
          investingCompany: null,
          numberOfShare: 0,
        })
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle style={{ textAlign: 'center', color: '#184782' }}>
          {t('pick-a-representative')}
        </DialogTitle>
        <DialogContent style={{ padding: '24px 24px' }}>
          <Grid container spacing={3}>
            <Grid container item xs={12} alignItems='center'>
              <Grid item xs={4}>
                Select a Representative:
              </Grid>
              <Grid item xs={8}>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      value={value}
                      id='representative'
                      options={shareholders}
                      getOptionLabel={(option) => option.label || ''}
                      getOptionSelected={(option, value) => option?.label === value?.label}
                      onChange={(e, data) => onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Representative'
                          variant='outlined'
                          size='small'
                          error={!!errors['representative']}
                        />
                      )}
                    />
                  )}
                  defaultValue={null}
                  control={control}
                  name='representative'
                  rules={{ required: true }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems='center'>
              <Grid item xs={4}>
                Select the Investing Comapany:
              </Grid>
              <Grid item xs={8}>
                <Controller
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      value={value}
                      id='investing-company'
                      options={companies}
                      getOptionLabel={(option) =>
                        option.label ? capitalize(option.label) : ''
                      }
                      getOptionSelected={(option, value)=> option?.label === value?.label}
                      onChange={(e, data) => onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label='Investing Company'
                          variant='outlined'
                          size='small'
                          error={!!errors['investingCompany']}
                        />
                      )}
                    />
                  )}
                  defaultValue={null}
                  control={control}
                  name='investingCompany'
                  rules={{ required: true }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} alignItems='center'>
              <Grid item xs={4}>
                Number of Share:
              </Grid>
              <Grid item xs={8}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant='outlined'
                      label='Number of Share'
                      type='number'
                      fullWidth
                      size='small'
                      error={!!errors['numberOfShare']}
                    />
                  )}
                  defaultValue=''
                  control={control}
                  name='numberOfShare'
                  rules={{
                    required: true, 
                    validate: {
                      positive: value => Number(value) > 0,
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          style={{ padding: '1rem 1.5rem', justifyContent: 'space-between' }}
        >
          <Button
            type='submit'
            variant='contained'
            color='primary'
          >
            Generate
          </Button>
          <Button
            onClick={() => handleClose()}
            variant='contained'
            color='secondary'
          >
            Close
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RepresentativeSelectionDialog;
