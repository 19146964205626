import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";

import DashboardTitle from "../../components/Title";
import { useTranslation } from "react-i18next";
import config from "../../components/tableX/config";
import TableX from "../../components/tableX";
import DeleteButton from "../../components/reusable/delete-button";
import EditButton from "../../components/reusable/edit-button";
import OtherCompanyPopupForm from "./OtherCompanyForm";
import AccessControl from "../../components/reusable/access-control";
import usePermission from '../../hooks/use-permission';


const useStyles = makeStyles((theme) => ({
  addCircleIcon: {
    cursor: "pointer",
    marginRight: "0.5rem",
  },
  tableWrapper: {
    "& .tableX th": {
      textAlign: "inherit !important",
    },
    "& .tableX td": {
      textAlign: "inherit !important",
    },
  },
  actionBox: {
    '& > *': {
      marginRight: '1rem'
    }
  },
}));

const tableConfig = {
  ...config,
  url: `${process.env.REACT_APP_API_URL}/v1/other-company`,
  dataConfig: ["otherCompanies"],
};

const OtherCompany = () => {
  // Styling
  const classes = useStyles();
  // Languages
  const { t } = useTranslation();
  const { checkPermission } = usePermission();
  const updateOtherCompanyPermission = checkPermission({
    operator: 'and',
    permissionsAndFeatures: [{ feature: 'other-company', action: 'update' }],
  });

  const deleteOtherCompanyPermission = checkPermission({
    operator: 'and',
    permissionsAndFeatures: [{ feature: 'other-company', action: 'delete' }],
  });

  const [triggerFetch, setTriggerFetch] = useState(false);
  // Deleting Row Section
  const handleClickDelete = (otherCompany) => {
    const { _id } = otherCompany;
    setTriggerFetch(false);
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, it is not recoverable.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${process.env.REACT_APP_API_URL}/v1/other-company/delete/${_id}`,
            { withCredentials: true }
          )
          .then((res) => {
            Swal.fire({
              title: "Deleted!",
              text: "Other Company has been deleted.",
              timer: 1000,
              icon: "success",
              onOpen: () => {
                Swal.hideLoading();
              },
            }).then(() => {
              setTriggerFetch(true);
            });
          })
          .catch((err) => {
            console.log("err", err);
            Swal.fire({
              text: err.response.data.message,
              icon: "error",
              onOpen: () => {
                Swal.hideLoading();
              },
            });
          });
      }
    });
  };
  // Creating/Editing Row Section
  const [dialogData, setDialogData] = useState({ open: false, data: null });
  const handleClose = () => {
    setDialogData({ open: false, data: null });
  };
  const handleAdd = () => {
    setDialogData({ open: true, data: null });
  };
  const handleEdit = (data) => {
    setDialogData({ open: true, data });
    // const { _id } = data
    // history.push(`/dashboard/other-company/${_id}`)
  };

  const tableSchema = [
    {
      id: 'no',
      label: t("no."),
      type: "numbering",
      sort: false,
    },
    {
      id: "companyNameLatin",
      label: t("company-name"),
    },
    {
      id: "created-at",
      label: t("created-at"),
      type: "component",
      dataConfig: [],
      components: (data) => {
        return (
          <Typography>
            {moment(data?.createdAt).format("DD/MM/YYYY")}
          </Typography>
        );
      },
    },
    {
      id: "registrationInfo",
      label: "Registration Number",
      type: "component",
      dataConfig: [],
      components: (data) => {
        const { registrationInfo } = data;
        return <Typography>{registrationInfo.number}</Typography>;
      },
    },
    {
      id: "component",
      sort: false,
      label: t("action"),
      type: "component",
      dataConfig: [],
      components: (data) => {
        return (
          <Box className={classes.actionBox}>
            <EditButton
              disabled={!updateOtherCompanyPermission}
              onClick={(e) => {
                e.stopPropagation();
                handleEdit(data);
              }}
            />
            <DeleteButton disabled={!deleteOtherCompanyPermission} onClick={() => handleClickDelete(data)} />
          </Box>
        );
      },
    },
  ];

  return (
    <Grid container>
      {/* Header Section */}
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <Box mr={2}>
            <DashboardTitle title="External Company" />
          </Box>
          <Box>
            <AccessControl feature='other-company' action='create'>
              <Button variant="contained" color="primary" onClick={handleAdd}>
                Create
              </Button>
            </AccessControl>
          </Box>
        </Box>
      </Grid>

      {/* Table Section */}
      <Grid item xs={12} className={classes.tableWrapper}>
        <TableX
          onRowClick={() => {}}
          triggerFetch={triggerFetch}
          config={tableConfig}
          schema={tableSchema}
        />
      </Grid>

      <OtherCompanyPopupForm
        open={dialogData.open}
        data={dialogData.data}
        handleClose={handleClose}
        setTriggerFetch={setTriggerFetch}
      />
    </Grid>
  );
};

export default OtherCompany;
