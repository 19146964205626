// Third party's imports
import React, { useState, useEffect, useRef } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { Grid, Box, Button, Collapse, IconButton, Typography, makeStyles, MenuItem, Paper, Dialog, DialogContent, DialogActions, Select, DialogTitle } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { useParams, useHistory } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useSnackbar } from 'notistack';

// Local imports
import './index.css';

import IndividualParticularDialog from '../../../components/individual-particulars-new/individual-particular-dialog';
// import IndividualParticularBox from '../../../components/individual-particulars-new/individual-particular-box';
// import PositionAndSharePercentage from '../../../components/individual-particulars-new/position-and-share-percentage';
import awsS3 from '../../../utils/aws-s3';
import Title from '../../../components/Title';
import CompanyInfo from '../../../components/companyInfo';
import LegalEntityParticular from '../../../components/legalEntityParticular';
// import IndividualParticularsNew from '../../../components/individual-particulars-new';
import BusinessActivity from '../../../components/business-activity';
import usePermission from '../../../hooks/use-permission';
import AddIndividual from '../../../components/individual-particulars-new/add-individual';
import { RESERVED, DRAFT } from '../../../constants/company-status';

const useStyles = makeStyles((theme) => ({
    paperRoot: { padding: '30px 30px 10px 30px', backgroundColor: theme.palette.primary.light },
    headerBox: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        justifyContent: 'space-between',
        '&:hover': {
            cursor: 'pointer',
        },
        backgroundColor: 'rgba(1, 106, 178, 0.07)',
        borderRadius:theme.shape.borderRadius,
    },
}))

const getSteps = () => {
    return ['Company Info', 'Business Activity', 'Individual', 'Legal Entity'];
}

const defaultValues = {
    businessActivity: [{}],
    registeredAddressManual: false,
    registeredAddress: {
        isRental: 'false'
    },
    branchAddress: { isRental: 'false' },
    individualParticular: [{
        position: {
            isShareholders: false,
            isChairman: false,
            isDirector: false,
            isRepresentative: false
        },
        individual: null,
        sharePercentage: '',
    }],
    legalParticular: [{
        companyNameLatinSelection: null,
        sharePercentage: '',
        nameKhmer: '',
        nameLatin: '',
        registrationInfo: {
            number: '',
            issuedDate:null
        },
        taxIdNumber: '',
        address: {
            isRental: 'false',
            group: '',
            homeNumber: '',
            street: '',
            city:null,
            commune: null,
            district: null,
            village: null,
            rentalFee: '',
            propertyIdentificationNumber: '',
            issuedDate: null,
        },
        representative: null,
        individualLegalParticular: [{
            nameLatin: null,
            nameKhmer: null,
            position: {
                isShareholders: false,
                isChairman: false,
                isDirector: false,
                isRepresentative: false
            },
            identity: {
                isPassport: false
            },
            gender: 'male',
        }]
    }]
}

const NewCompany = () => {
    const methods = useForm({ defaultValues });
    const { trigger, getValues } = methods;
    const { t } = useTranslation();
    const classes = useStyles();
    const { id } = useParams();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const businessRef = useRef(null);

    const [step3, setStep3] = useState(0);
    const [businessActivitiesFiles, setBusinessActivitiesFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [deleteFiles, setDeleteFiles] = useState([]);
    // Permission Section
    const { checkPermission } = usePermission();
    const reviewPermission = checkPermission({
        operator: 'and',
        permissionsAndFeatures: [
            { feature: 'reviewing-company', action: 'update' },
        ],
    });

    const { fields: legalParticular, append: appendLegal, remove: removeLegal } = useFieldArray({
        control: methods.control,
        name: 'legalParticular'
    });

    const [activeStep, setActiveStep] = useState(0);

    const [isLegalExpanded, setIsLegalExpanded] = useState({
        [legalParticular[0]?.id]: true
    })

    const [showIndividualDialog, setShowIndividualDialog] = useState(false);
    const [individualDialogMode, setIndividualDialogMode] = useState('');
    const [activeIndividual, setActiveIndividual] = useState({});

    // legal entity
    const [isLocalForm, setIsLocalForm] = useState(true);
    const [openLegalDialog, setOpenLegalDialog] = useState(false);
    const [legalEntityAttachment, setLegalEntityAttachment] = useState([]);

    useEffect(() => {
        setIsLegalExpanded({
            ...isLegalExpanded,
            [legalParticular[legalParticular.length - 1]?.id]: true
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [legalParticular])


    const handleCreateIndividualParticular = async (e) => {
        e.preventDefault();
        setActiveIndividual(null);
        setShowIndividualDialog(true);
        setIndividualDialogMode('create');
    };

    const handleAddLegalParticular = () => {
        setOpenLegalDialog(true)           
    }

    const handleDeleteLegal = (key) => {
        removeLegal(key)        
        const newArrayFiles = [...legalEntityAttachment];
        
        // get deleted legal's files
        const deleteFiles = [...newArrayFiles[key].files, ...newArrayFiles[key].remove];
        // set delete files to deleteFiles state to remove from S3      
        setDeleteFiles(prev => [...prev, ...deleteFiles.filter(file => file.key).map(file => file.key)]);

        // remove legal file from state
        newArrayFiles.splice(key, 1);
        setLegalEntityAttachment(newArrayFiles);
    }

    const steps = getSteps();

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    const handleNext = async () => {
        // check validation before enable next step
        const companyInfoValidation = ['companyNameLatin', 'formOfBusiness', 'companyNameKhmer','shareCapital', 'registeredAddress.homeNumber', 'registeredAddress.street', 'registeredAddress.city', 'registeredAddress.commune', 'registeredAddress.district', 'registeredAddress.village', 'registeredAddress.group', 'registeredAddress.isRental', 'registeredAddress.propertyIdentificationNumber', 'registeredAddress.rentalPrice', 'registeredAddress.issuedDate', 'employee.totalEmployee', 'employee.femaleEmployee', 'employee.foreignerEmployee', 'employee.femaleForeignerEmployee', 'totalEmployeeSalary', 'weeklyHoliday', 'numberOfWorkingPerDayOrWeek', 'email', 'mobilePhoneNumber', 'officeTelephoneNumber', 'geographicalCoordinate.longitude', 'geographicalCoordinate.latitude'];
        const businessActivityValidation = ['businessActivity[0].mainBusinessActivity', 'businessActivity[0].subActivities', 'activityOnPatentCertificateInKhmer', 'activityOnPatentCertificateInLatin'];
        const individualParticulars = ['individualParticular[0].nameLatin', 'individualParticular[0].nameLatinSelection', 'individualParticular[0].nameKhmer',
            'individualParticular[0].sharePercentage', 'individualParticular[0].placeOfBirth.city',
            'individualParticular[0].placeOfBirth.district', 'individualParticular[0].placeOfBirth.commune',
            'individualParticular[0].address.homeNumber', 'individualParticular[0].address.street',
            'individualParticular[0].address.city', 'individualParticular[0].address.city',
            'individualParticular[0].address.district', 'individualParticular[0].address.commune',
            'individualParticular[0].address.village', 'individualParticular[0].address.group',
            'individualParticular[0].phoneNumber', 'individualParticular[0].email',
            'individualParticular[0].identity.number', 'individualParticular[0].nationality',
            'individualParticular[0].identity.issuedDate', 'individualParticular[0].dob',
            'individualParticular[0].file',
        ];
        const businessActivities = businessActivityValidation
        if (businessRef.current?.count > 0) {
            for (let b = 1; b <= businessRef.current?.count; b++) {
                businessActivities.push(`businessActivity[${b}].mainBusinessActivity`);
                businessActivities.push(`businessActivity[${b}].subActivities`);
            }
        }
        if (step3 > 0) {
            for (let i = 1; i <= step3; i++) {
                individualParticulars.push(`individualParticular[${i}].nameLatin`)
                individualParticulars.push(`individualParticular[${i}].nameLatinSelection`)
                individualParticulars.push(`individualParticular[${i}].nameKhmer`)
                individualParticulars.push(`individualParticular[${i}].sharePercentage`)
                individualParticulars.push(`individualParticular[${i}].nationality`)
                individualParticulars.push(`individualParticular[${i}].placeOfBirth.city`)
                individualParticulars.push(`individualParticular[${i}].placeOfBirth.district`)
                individualParticulars.push(`individualParticular[${i}].placeOfBirth.commune`)
                individualParticulars.push(`individualParticular[${i}].address.homeNumber`)
                individualParticulars.push(`individualParticular[${i}].address.street`)
                individualParticulars.push(`individualParticular[${i}].address.city`)
                individualParticulars.push(`individualParticular[${i}].address.district`)
                individualParticulars.push(`individualParticular[${i}].address.commune`)
                individualParticulars.push(`individualParticular[${i}].address.village`)
                individualParticulars.push(`individualParticular[${i}].address.group`)
                individualParticulars.push(`individualParticular[${i}].phoneNumber`)
                individualParticulars.push(`individualParticular[${i}].email`)
                individualParticulars.push(`individualParticular[${i}].identity.number`)
                individualParticulars.push(`individualParticular[${i}].identity.issuedDate`)
                individualParticulars.push(`individualParticular[${i}].file`)
            }
        }
        const forms = {
            0: companyInfoValidation,
            1: businessActivities,
            2: individualParticulars
        };
        const validated = await trigger(forms[activeStep]);
        if (validated) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleUploadMultipleFiles = async (arrayOfFiles) => {
        const attachment = await Promise.all(
            arrayOfFiles.map(async (fileObject) => {
                // check if business activity has files attachment
                if (fileObject.files.length) {
                    let tempFiles = [];
                    // upload each file
                    await Promise.all(
                        fileObject.files.map(async item => {
                            // upload file which does not have key property
                            if (!item.hasOwnProperty('key')) {
                                const response = await awsS3.uploadImage({
                                    image: item.file,
                                    type: item.file.type,
                                    folder: 'business_activity/file_attachment'
                                })
                                const file = {
                                    key: response,
                                    mimeType: item.file
                                }
                                return tempFiles.push(file);
                            }
                            return tempFiles.push(item)
                        })
                    )
                    return tempFiles
                } else {
                    return []
                }
            })
        );
        return attachment
    };  
    
    // filter to get file's key use for removing file from S3
    const getRemoveFilesKey = (files) => {
        let keys = [];

        files.forEach(fileObject => {
            const fileKeys = fileObject.remove.filter(file => file.key).map(file => file.key);
            if (fileKeys.length) keys.push(...fileKeys)
        })

        return keys;
    }

    const onSubmit = async data => {

        Swal.fire({
            title: 'Form is submitting...',
            showConfirmButton: false,
            onOpen: async () => {
                try {
                    Swal.showLoading();
                    
                    // handle remove files from S3
                    const businessFileKeys = getRemoveFilesKey(businessActivitiesFiles)

                    const legalEntityFileKeys = getRemoveFilesKey(legalEntityAttachment);

                    const filesToRemove = [...businessFileKeys, ...legalEntityFileKeys, ...deleteFiles];

                    if (filesToRemove.length) awsS3.removeFilesFromS3(filesToRemove)
                    
                    // handle upload files
                    if (data.businessActivity.length) {
                        // handle upload business activities files
                        const businessActivitiesAttachment = await handleUploadMultipleFiles(businessActivitiesFiles);
                        businessActivitiesAttachment.forEach((item, index) => {
                            data.businessActivity[index].fileAttachment = [...item];
                        });
                    }

                    if (data.legalParticular.length) {
                        // handle upload legal entity files
                        const legalEntityFiles = await handleUploadMultipleFiles(legalEntityAttachment);
                        legalEntityFiles.forEach((item, index) => {
                            data.legalParticular[index].fileAttachment = [...item]
                        });
                    }

                    // call api
                    if (reviewPermission && (data?.type === 'reviewing' || data?.type === 'reviewed')) {
                        // review company
                        await axios.put(`${process.env.REACT_APP_API_URL}/v1/companies/review/update/${id}`, data);
                        Swal.hideLoading();
                        Swal.fire({
                            icon: 'success',
                            text: 'Company has been reviewed successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            onOpen: () => {
                                Swal.hideLoading();
                            },
                        });
                    } else {
                        // create company
                        await axios.post(`${process.env.REACT_APP_API_URL}/v1/companies/create-approve`, {
                            ...data,
                            reference: id,
                        });
                        Swal.hideLoading();
                        Swal.fire({
                            icon: 'success',
                            text: 'Company has been created successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            onOpen: () => {
                                Swal.hideLoading();
                            },
                        });
                    }

                    switch (data.type) {
                        case 'draft':
                            history.push('/dashboard/company-status/reviewing');
                            break;
                        case 'reserved':
                            history.push('/dashboard/company-status/reviewing');
                            break;
                        case 'reviewed':
                            history.push('/dashboard/company-status/reviewing')
                            break;
                        case 'reviewing':
                            history.push('/dashboard/company-status/reviewing');
                            break;
                        case 'approved':
                            history.push('/dashboard/company-status/registered-company');
                            break;
                        default:
                            history.push('/dashboard');
                    }
                } catch (error) {
                    console.log('error submit company :>> ', error);
                    Swal.fire({
                        text: error.response?.data.message || error,
                        icon: 'error',
                        onOpen: () => {
                            Swal.hideLoading();
                        },
                    });
                }
            },
        });
    };

    const onExpandLegalForm = (id) => {
        setIsLegalExpanded(prev => {
            return {
                ...prev,
                [id]: !prev[id]
            }
        })
    }

    const handleSaveDraft = async () => {
        try {
            setIsLoading(true)
            const data = getValues();  // get all values from hook form

            // handle remove file
            const businessFileKeys = getRemoveFilesKey(businessActivitiesFiles)        

            const legalEntityFileKeys = getRemoveFilesKey(legalEntityAttachment);

            const filesToRemove = [...businessFileKeys, ...legalEntityFileKeys, ...deleteFiles];

            if (filesToRemove.length) awsS3.removeFilesFromS3(filesToRemove)

            // handle upload business activities files
            if (data?.businessActivity?.length) {   
                const businessActivitiesAttachment = await handleUploadMultipleFiles(businessActivitiesFiles);
                businessActivitiesAttachment.forEach((item, index) => {
                    data.businessActivity[index].fileAttachment = [...item];
                });
            }

            if (data?.legalParticular.length) {
                // handle upload legal entity files
                const legalEntityFiles = await handleUploadMultipleFiles(legalEntityAttachment);
                legalEntityFiles.forEach((item, index) => {
                    data.legalParticular[index].fileAttachment = [...item]
                });
            }

            await axios.post(`${process.env.REACT_APP_API_URL}/v1/companies/save-draft`, { ...data, reference: id });
            enqueueSnackbar('Your work has been saved', { variant: 'success' });
            setIsLoading(false)
            history.push('/dashboard/company-status/draft');
        } catch (error) {
            console.log('error save draft :>> ', error);
            enqueueSnackbar(`${error?.response?.data?.message || 'error save as draft'}`, { variant: 'error' });
            setIsLoading(false)
        }
    }

    // handle change firm type `local` or `international` before choose legal entity
    const handleChange = (event) => {
        // handle change firm type 
        setIsLocalForm(event.target.value);
    }

    // confirm after select firm type
    const selectFormHandler = () => {
        setOpenLegalDialog(false);

        const oldLegalExpands = isLegalExpanded;
        Object.keys(isLegalExpanded).forEach(item => {
            oldLegalExpands[item] = false;
        });
        setIsLegalExpanded(oldLegalExpands);
        // add legal entity form
        appendLegal({
            ...defaultValues.legalParticular[0],
            isLocal: isLocalForm
        })
        // add legal entity files array
        setLegalEntityAttachment(prev => [...prev, { files: [], remove: [] }]);
    }

    // close choose firm type dialog
    const handleCloseLegalDialog = () => {
        setOpenLegalDialog(false);
    }

    const handleReject = () => {
        const data = getValues();
        const { feedback, _id } = data;
        let prepareUploadData = {
            feedback, status: 'rejected', id: _id
        }

        Swal.fire({
            title: 'Are you sure?',
            text: 'Once reject, it is not recoverable.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Reject',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.put(`${process.env.REACT_APP_API_URL}/v1/companies/update-status`, prepareUploadData)
                    .then(res => {
                        Swal.fire('Rejected!', 'Company has been rejected.', 'success');
                        history.push('/dashboard/company-status/reserved-company')
                    })
                    .catch((error) => console.log('error reject company :>> ', error));
            }
        });
    }

    const getStepContent = (step) => {
        switch(step) {
            case 0:
                return <CompanyInfo setFiles={setLegalEntityAttachment} setBusinessFiles={setBusinessActivitiesFiles}  />
            case 1:
                return <BusinessActivity ref={businessRef} files={businessActivitiesFiles} setFiles={setBusinessActivitiesFiles} setDeleteFiles={setDeleteFiles} />
            case 2:
                return (
                    <>
                        <Grid
                            item
                            container
                            xs={12}
                            style={{padding:"15px 0"}}
                        >
                            <Button
                                color='primary'
                                variant='outlined'
                                onClick={handleCreateIndividualParticular}
                                startIcon={<AddIcon />}
                            >                            
                            {t('create-individual')}
                            </Button>
                        </Grid>
                        <AddIndividual
                            control={methods.control}
                            name='individualParticular'
                            afterAddCallBack={() => { setStep3(prev => prev + 1); }}
                            render={ (children) =>  <Box py={2}><Paper className={classes.paperRoot} >{children}</Paper></Box> }
                        />
                    </>
                )
            case 3:
                return (
                    <>
                        {
                            legalParticular.map((field, key) => (
                                <div key={field.id} style={{padding:"10px 0"}}>
                                    {
                                        legalParticular[key]?.id && (
                                            <Box className={classes.headerBox}>
                                                <Grid item container xs={12} justifyContent='space-between' alignItems='center'>
                                                    <Grid item xs='auto'>
                                                        <Typography color='primary' style={{ textTransform: 'uppercase', paddingLeft:'10px' }}>
                                                            {getValues(`legalParticular[${key}].nameLatin`) !== '' ? getValues(`legalParticular[${key}].nameLatin`): 'N/A'}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs='auto'>
                                                        <IconButton onClick={()=> handleDeleteLegal(key)}>
                                                            <DeleteIcon color='secondary' />
                                                        </IconButton>
                                                        <IconButton onClick={() => onExpandLegalForm(field.id)}>
                                                            {isLegalExpanded[field.id] ? <KeyboardArrowUp color='primary' /> : <KeyboardArrowDown color='primary' />}
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        )
                                    }
                                    <Collapse in={isLegalExpanded[field.id]} timeout="auto">
                                        <LegalEntityParticular field={field} ipKey={key} isLocalForm={field.isLocal} files={legalEntityAttachment} setFiles={setLegalEntityAttachment} />
                                    </Collapse>
                                </div>
                            ))
                        }
                        <Grid item container xs={12} justifyContent='flex-end' style={{ padding: '0.5rem 0' }}>
                            <Button color='primary' variant="contained" onClick={handleAddLegalParticular}
                                startIcon={<AddCircleIcon/>}
                            >
                                {t('add-legal-entity')}
                            </Button>
                        </Grid>
                        <Dialog open={openLegalDialog} onClose={handleCloseLegalDialog} fullWidth maxWidth='xs'>
                            <DialogTitle>
                                Please Select Form
                            </DialogTitle>
                            <DialogContent>
                                <Select 
                                    value={isLocalForm}
                                    label="Legal Particular Form"
                                    onChange={handleChange}
                                    fullWidth
                                >
                                    <MenuItem value={Boolean(true)}>Local Firm</MenuItem>
                                    <MenuItem value={Boolean(false)}>International Firm</MenuItem>
                                </Select>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseLegalDialog} color='secondary'>
                                    Cancel
                                </Button>
                                <Button onClick={selectFormHandler} color='primary' variant='contained'>
                                    Ok
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </>
                )
            default:
                return 'Unknown step';
        }
    }

    return (
        <>
            <Grid container style={{ padding: "1rem 2rem" }}>
                <Grid item container xs={12}>
                    <Title title={'add-new-company'} alignStart />
                </Grid>
                <FormProvider {...methods}>
                    <form
                        onSubmit={methods.handleSubmit(onSubmit)}
                        style={{ width: '70%', padding: '1rem 0' }}
                    >
                        <Box>
                            <Stepper activeStep={activeStep} alternativeLabel>
                                {
                                    steps.map((label, index) => {
                                        return (
                                            <Step key={index}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        )
                                    })
                                }
                            </Stepper>
                            <Box>
                                {getStepContent(activeStep)}
                                <Grid container item xs={12} direction='row' spacing={2} style={{ padding: "1.5rem 0" }}>
                                    {
                                        activeStep !== 0 && (
                                            <Grid item xs='auto'>
                                                <Button variant='outlined' onClick={handleBack} disabled={isLoading}>
                                                    Back
                                                </Button>
                                            </Grid>
                                        )
                                    }
                                    {/* display save draft button only when company type field is reserved or draft */}
                                    {[RESERVED, DRAFT].includes(getValues().type) &&
                                        <Grid item xs='auto'>
                                            <Button
                                                variant='outlined'
                                                onClick={handleSaveDraft}
                                                color="primary"
                                                disabled={isLoading}
                                            >
                                                Save Draft
                                            </Button>
                                        </Grid>
                                    }
                                    {
                                        // company in reviewing stage
                                        (activeStep === 3 && getValues().type === 'reviewing' ) && (
                                            <Grid item xs='auto'>
                                            <Button
                                                variant='outlined'
                                                onClick={handleReject}
                                                color="secondary"
                                                disabled={isLoading}
                                            >
                                                Reject
                                            </Button>
                                            </Grid>
                                        )  
                                    }
                                    <Grid item xs='auto'>
                                        {
                                            activeStep === 3 && <Button
                                                type='submit'
                                                variant="contained"
                                                color="primary"
                                                disabled={isLoading}
                                            >
                                                {getValues().type === 'reviewing' ? 'Accept' : 'Submit'}
                                            </Button>
                                        }
                                        { activeStep < 3 && <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            disabled={isLoading}
                                        >
                                            Next
                                        </Button>}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </form>
                </FormProvider>
            </Grid>
            <IndividualParticularDialog
                open={showIndividualDialog}
                setOpen={setShowIndividualDialog}
                field={activeIndividual}
                mode={individualDialogMode}
                activeIndividual={activeIndividual}
            />
        </>
    )
}

export default NewCompany;
// we export this show we can use the 'defaultValues' where else in form
export { defaultValues }
