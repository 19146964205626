import { Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const PasswordSection = ({ control, id }) => {
	const { t } = useTranslation();
	const password = useWatch({ control, name: 'password' });

	return (
		<Grid item container xs={12} spacing={2}>
			<Grid item xs={4}>
				<Typography>Password</Typography>
			</Grid>
			<Grid item xs={8}>
				<Controller
					render={({ field, fieldState: { error } }) => (
						<TextField
							{...field}
							variant='outlined'
							label={t('password')}
							type='text'
							fullWidth
							size='small'
							error={!!error}
						/>
					)}
					control={control}
					name='password'
					defaultValue=''
					rules={{ required: id ? false : true }} // id create staff password is required
				/>
			</Grid>
			<Grid item xs={4}>
				<Typography>Confirm Password</Typography>
			</Grid>
			<Grid item xs={8}>
				<Controller
					render={({ field, fieldState: { error } }) => (
						<TextField
							{...field}
							variant='outlined'
							label={t('confirm-password')}
							type='text'
							fullWidth
							size='small'
							error={!!error}
						/>
					)}
					control={control}
					name='confirmPassword'
					defaultValue=''
					rules={{
						required: id ? false : true,
						validate: {
							confirmPassword: value => value === password,
						},
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default PasswordSection;
