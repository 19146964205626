import React from 'react';
import { Chip, makeStyles, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
	root: {
		padding: '0 0.5rem',
	},
}));

const StatusChip = ({ currentStatus, ...other }) => {
	const classes = useStyles();
	const theme = useTheme();
	const { t } = useTranslation();

	const statusBackground = {
		accepted: {
			backgroundColor: theme.palette.success.main,
			color: theme.palette.common.white,
		},
		rejected: {
			backgroundColor: theme.palette.error.main,
			color: theme.palette.error.contrastText,
		},
		pending: {
			backgroundColor: theme.palette.grey['500'],
			color: theme.palette.common.white,
		},
	};

	return (
		<Chip
			label={currentStatus ? t(`${currentStatus}`) : 'Draft'}
			className={classes.root}
			{...other}
			style={statusBackground[currentStatus]}
		/>
	);
};

export default StatusChip;
