import axios from 'axios';
import swal from 'sweetalert2';
import history from '../history';

export const COMPANY_LOAD_SUCCESS = 'COMPANY_LOAD_SUCCESS';
export const COMPANY_LOAD_FAIL = 'COMPANY_LOAD_FAIL';
export const COMPANY_LOAD_ACTION = 'COMPANY_LOAD_ACTION';

export const loadSuccess = ( data ) => ({
    type: COMPANY_LOAD_SUCCESS,
    data
});

export const loadFail = ( data ) => ({
    type: COMPANY_LOAD_FAIL,
    data
});

export const loadAction = ( data ) => ({
    type: COMPANY_LOAD_ACTION,
    data
});

export const loadCompany = (offset, limit, search) => {
    return dispatch => {
        dispatch( loadAction({ loading: true }) );
        // `JWT ${ localStorage.getItem('access_token') }`
        return axios.get( `${ process.env.REACT_APP_API_URL }/company?offset=${offset}&limit=${limit}&search=${search}`, {
            headers: {
                'Authorization': `bearer ${ localStorage.getItem('access_token') }`
            } 
        } ).then( response => {
            dispatch( loadSuccess({ ...response.data ,loading: false, loaded: true }) );
        }).catch( err => {
            dispatch( loadFail({ loading: false, loaded: false }) );
        });
    }
}

export const SINGLE_COMPANY_LOAD_ACTION = 'SINGLE_COMPANY_LOAD_ACTION';
export const SINGLE_COMPANY_LOAD_SUCCESS = 'SINGLE_COMPANY_LOAD_SUCCESS';
export const SINGLE_COMPANY_LOAD_FAIL = 'SINGLE_COMPANY_LOAD_FAIL';

const loadSingleCompanyAction = data => ({
    type: SINGLE_COMPANY_LOAD_ACTION,
    data
});

const loadSingleCompanySuccess = data => ({
    type: SINGLE_COMPANY_LOAD_SUCCESS,
    data
});

const loadSingleCompanyFail = data => ({
    type: SINGLE_COMPANY_LOAD_FAIL,
    data
});

export const loadCompanyById = companyId => {
    return dispatch => {
        dispatch( loadSingleCompanyAction({ loading: true }) );

        return axios.get( `${ process.env.REACT_APP_API_URL }/company/${companyId}`, {
            headers: {
                'Authorization': `bearer ${ localStorage.getItem('access_token') }`
            }
        } ).then( res => {
            dispatch( loadSingleCompanySuccess({ ...res.data, loading: false, loaded: true }));
        }).catch( err => {
            dispatch( loadSingleCompanyFail({ loading: false, loaded: false }));
        });
    }
}

export const COMPANY_SAVE_ACTION = 'COMPANY_SAVE_ACTION';
export const COMPANY_SAVE_SUCCESS = 'COMPANY_SAVE_SUCCESS';
export const COMPANY_SAVE_FAIL = 'COMPANY_SAVE_FAIL';

const saveAction = data => ({
    type: COMPANY_SAVE_ACTION,
    data
});

const saveSuccess = data => ({
    type: COMPANY_SAVE_SUCCESS,
    data
});

const saveFail = data => ({
    type: COMPANY_SAVE_FAIL,
    data
});

export const saveCompany = ( companyInfo ) => {
    return dispatch => {
        swal.fire({
            title: 'Saving...',
            allowOutsideClick: false,
            onOpen: () => {
                swal.showLoading();
            }
        })
        
        dispatch( saveAction({ saving: true }) );
        return axios.post(`${ process.env.REACT_APP_API_URL }/company`, { data: companyInfo }, {
            headers: {
                Authorization: `bearer ${ localStorage.getItem('access_token') }`
            }
        }).then( response => {
            dispatch( saveSuccess({ saving: false, success: true }) );
            swal.fire({
                text: 'Saved',
                timer: 1000,
                icon: 'success',
                onOpen: () => {
                    swal.hideLoading()
                }
            }).then( () => {
                history.push('/dashboard');
            });
        }).catch( err => {
            swal.fire({
                text: err.response?.data.message || err,
                icon: 'error',
                onOpen: () => {
                    swal.hideLoading()
                }
            })
            dispatch( saveFail({ saving: false, success: false }) );
        });
    }
}

export const updateCompany = ( companyInfo, companyId ) => {
    return dispatch => {
        swal.fire({
            title: 'Saving...',
            allowOutsideClick: false,
            onOpen: () => {
                swal.showLoading();
            }
        });
        
        dispatch( saveAction({ saving: true }) );
        return axios.put(`${ process.env.REACT_APP_API_URL }/company/${companyId}`, { data: companyInfo }, {
            headers: {
                Authorization: `bearer ${ localStorage.getItem('access_token') }`
            }
        }).then( response => {
            dispatch( saveSuccess({ saving: false, success: true }) );
            swal.fire({
                text: 'Saved',
                timer: 1000,
                icon: 'success',
                onOpen: () => {
                    swal.hideLoading()
                }
            }).then( () => {
                history.push('/dashboard');
            });
        }).catch( err => {
            console.log( err );
            swal.fire({
                text: err.response?.data.message || err,
                icon: 'error',
                onOpen: () => {
                    swal.hideLoading()
                }
            })
            dispatch( saveFail({ saving: false, success: false }) );
        });
    }
}