import { IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: '0px 3px 6px 1px rgba(9, 59, 118, 0.11)',
    '& svg': {
      fontSize: '16px',
    },
    padding: '7px',
  },
}));

const EditButton = ({ ...other }) => {
  const classes = useStyles();

  return (
    <IconButton color='primary' className={classes.root} {...other}>
      <EditIcon />
    </IconButton>
  );
};

export default EditButton;
